import { default as _91code_93MPQnYFUe72Meta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/activity/[code].vue?macro=true";
import { default as _91code_93gKh9stJqvJMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/activity/largeScreen/[code].vue?macro=true";
import { default as _91id_93EVioydxR2sMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/address/edit/[id].vue?macro=true";
import { default as listsEPBhraajzMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/address/list.vue?macro=true";
import { default as _91id_93V53o8a8PGGMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/apply/[id].vue?macro=true";
import { default as _91id_93YH2Vy2iPDTMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/detail/[id].vue?macro=true";
import { default as _91id_93krulT0A0XzMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/history/[id].vue?macro=true";
import { default as listdWXsS6UMHDMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/list.vue?macro=true";
import { default as allGJ09XQe6ztMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/all.vue?macro=true";
import { default as _91code_93vI2Rm4D1NHMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/article/[code].vue?macro=true";
import { default as _91id_93Vtc64trnOLMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/buy/[id].vue?macro=true";
import { default as indexNM0aHWYcFOMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/index.vue?macro=true";
import { default as indexqJfXaDXFcTMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/search/index.vue?macro=true";
import { default as indexULAEgcreFuMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/select/index.vue?macro=true";
import { default as cartdYOH1LH6Q1Meta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cart.vue?macro=true";
import { default as indexvTKgzPa6nXMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/category/index.vue?macro=true";
import { default as chatc1C17krJFBMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/chat.vue?macro=true";
import { default as collectgpoJze0p4yMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/collect.vue?macro=true";
import { default as couponsx8tknAXHZJMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/coupons.vue?macro=true";
import { default as _500PLkbg5JiDrMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/error/500.vue?macro=true";
import { default as historyWIXGWN50jSMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/history.vue?macro=true";
import { default as indexljQQTyVQhqMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/index.vue?macro=true";
import { default as _91id_93XUAWLgR4wSMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/instruction/detail/[id].vue?macro=true";
import { default as _91id_93S77443gutfMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/instruction/list/[id].vue?macro=true";
import { default as integralqyOkbpUF4KMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/integral.vue?macro=true";
import { default as index3J8BAxp4ruMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/login/index.vue?macro=true";
import { default as wechatBind43SBmP2eU2Meta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/login/wechatBind.vue?macro=true";
import { default as myIFfWoHBz8aMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/my.vue?macro=true";
import { default as _91_91id_93_936QFjLbisgwMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/newki/[[id]].vue?macro=true";
import { default as _91id_93xN2rJVLPBfMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/detail/[id].vue?macro=true";
import { default as listv4VjYDosHoMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/list.vue?macro=true";
import { default as indexJMjrrXzgJHMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/search/index.vue?macro=true";
import { default as formRMGeoVdxcuMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/form.vue?macro=true";
import { default as mpayVTZWHOI1BDMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/mpay.vue?macro=true";
import { default as mpayOldfcCbTMliOtMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/mpayOld.vue?macro=true";
import { default as tapgovjLXYE5ONKMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/tapgo.vue?macro=true";
import { default as _91payId_933eE7d2h192Meta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/transfer-[paymentCode]/[payId].vue?macro=true";
import { default as _91payId_93jOk07kwtsPMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/[payId].vue?macro=true";
import { default as _91payId_93Y01xANPzQxMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/loading/[payId].vue?macro=true";
import { default as _91payId_93FpRGWnCH8hMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/success/[payId].vue?macro=true";
import { default as _91id_93K9iRFu8HU3Meta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/post/detail/[id].vue?macro=true";
import { default as presaleWxgzYYhFAZMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/presale.vue?macro=true";
import { default as privacyAgreement14Cg3X6HJjMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/privacyAgreement.vue?macro=true";
import { default as _91_91contentId_93_93hXs35S5OPzMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/product/[skuId]/[[contentId]].vue?macro=true";
import { default as _91code_93c1Ktm6zJy5Meta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/promotion/[code].vue?macro=true";
import { default as pwdLogin6QIPNNB162Meta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pwdLogin.vue?macro=true";
import { default as registerAgreementoXdbepnCGZMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/registerAgreement.vue?macro=true";
import { default as retrievePwdomTTL8xF00Meta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/retrievePwd.vue?macro=true";
import { default as _91keyword_930H2kMDIqo2Meta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/search/[keyword].vue?macro=true";
import { default as indexe1uqThM2KrMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/search/index.vue?macro=true";
import { default as setPwdJyPIpcAFlvMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setPwd.vue?macro=true";
import { default as indexTUkIlrGAXTMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/index.vue?macro=true";
import { default as emaildSDdg8ATc9Meta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/email.vue?macro=true";
import { default as nicknamexo5LR2DrFiMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/nickname.vue?macro=true";
import { default as phoneq2tZVAuLguMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/phone.vue?macro=true";
import { default as indexXt61dKXHu9Meta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/index.vue?macro=true";
import { default as oldPwdLoCk100djIMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/oldPwd.vue?macro=true";
import { default as smsqAwPUojNY2Meta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/sms.vue?macro=true";
import { default as userInfoLn2qwELqeXMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/userInfo.vue?macro=true";
import { default as _91orderId_93fZpLyZyIusMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/share/[orderId].vue?macro=true";
import { default as listeBiD1ZDqrvMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/share/list.vue?macro=true";
import { default as _91id_93Vb3ROE3gSDMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/detail/[id].vue?macro=true";
import { default as listIpdMPcIRI8Meta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/list.vue?macro=true";
import { default as indexLtdldiBZWSMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/search/index.vue?macro=true";
import { default as detail_45_91id_93KQcmIi0ajWMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/track/detail-[id].vue?macro=true";
import { default as list_45_91billNo_93iSA1ISahEJMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/track/list-[billNo].vue?macro=true";
import { default as widget6WNDAOmh1NMeta } from "/root/workspace/f2f-e3plus-newki-front_NY1p/pages/widget.vue?macro=true";
export default [
  {
    name: _91code_93MPQnYFUe72Meta?.name ?? "hk__activity-code",
    path: _91code_93MPQnYFUe72Meta?.path ?? "/hk/activity/:code()",
    meta: {...(_91code_93MPQnYFUe72Meta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"activity-code"}},
    alias: _91code_93MPQnYFUe72Meta?.alias || [],
    redirect: _91code_93MPQnYFUe72Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/activity/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93MPQnYFUe72Meta?.name ?? "hk__zh-MO__activity-code",
    path: _91code_93MPQnYFUe72Meta?.path ?? "/hk/zh-MO/activity/:code()",
    meta: {...(_91code_93MPQnYFUe72Meta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"activity-code"}},
    alias: _91code_93MPQnYFUe72Meta?.alias || [],
    redirect: _91code_93MPQnYFUe72Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/activity/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93MPQnYFUe72Meta?.name ?? "activity-code",
    path: _91code_93MPQnYFUe72Meta?.path ?? "/activity/:code()",
    meta: {...(_91code_93MPQnYFUe72Meta || {}), ...{"siteCode":"","i18nCode":"","truePath":"activity-code"}},
    alias: _91code_93MPQnYFUe72Meta?.alias || [],
    redirect: _91code_93MPQnYFUe72Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/activity/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93MPQnYFUe72Meta?.name ?? "hk__zh-HK__activity-code",
    path: _91code_93MPQnYFUe72Meta?.path ?? "/hk/zh-HK/activity/:code()",
    meta: {...(_91code_93MPQnYFUe72Meta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"activity-code"}},
    alias: _91code_93MPQnYFUe72Meta?.alias || [],
    redirect: _91code_93MPQnYFUe72Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/activity/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93MPQnYFUe72Meta?.name ?? "hk__zh-CN__activity-code",
    path: _91code_93MPQnYFUe72Meta?.path ?? "/hk/zh-CN/activity/:code()",
    meta: {...(_91code_93MPQnYFUe72Meta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"activity-code"}},
    alias: _91code_93MPQnYFUe72Meta?.alias || [],
    redirect: _91code_93MPQnYFUe72Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/activity/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93MPQnYFUe72Meta?.name ?? "hk__en__activity-code",
    path: _91code_93MPQnYFUe72Meta?.path ?? "/hk/en/activity/:code()",
    meta: {...(_91code_93MPQnYFUe72Meta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"activity-code"}},
    alias: _91code_93MPQnYFUe72Meta?.alias || [],
    redirect: _91code_93MPQnYFUe72Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/activity/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93MPQnYFUe72Meta?.name ?? "mo__activity-code",
    path: _91code_93MPQnYFUe72Meta?.path ?? "/mo/activity/:code()",
    meta: {...(_91code_93MPQnYFUe72Meta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"activity-code"}},
    alias: _91code_93MPQnYFUe72Meta?.alias || [],
    redirect: _91code_93MPQnYFUe72Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/activity/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93MPQnYFUe72Meta?.name ?? "mo__zh-MO__activity-code",
    path: _91code_93MPQnYFUe72Meta?.path ?? "/mo/zh-MO/activity/:code()",
    meta: {...(_91code_93MPQnYFUe72Meta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"activity-code"}},
    alias: _91code_93MPQnYFUe72Meta?.alias || [],
    redirect: _91code_93MPQnYFUe72Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/activity/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93MPQnYFUe72Meta?.name ?? "mo__zh-HK__activity-code",
    path: _91code_93MPQnYFUe72Meta?.path ?? "/mo/zh-HK/activity/:code()",
    meta: {...(_91code_93MPQnYFUe72Meta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"activity-code"}},
    alias: _91code_93MPQnYFUe72Meta?.alias || [],
    redirect: _91code_93MPQnYFUe72Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/activity/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93MPQnYFUe72Meta?.name ?? "mo__zh-CN__activity-code",
    path: _91code_93MPQnYFUe72Meta?.path ?? "/mo/zh-CN/activity/:code()",
    meta: {...(_91code_93MPQnYFUe72Meta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"activity-code"}},
    alias: _91code_93MPQnYFUe72Meta?.alias || [],
    redirect: _91code_93MPQnYFUe72Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/activity/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93MPQnYFUe72Meta?.name ?? "mo__en__activity-code",
    path: _91code_93MPQnYFUe72Meta?.path ?? "/mo/en/activity/:code()",
    meta: {...(_91code_93MPQnYFUe72Meta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"activity-code"}},
    alias: _91code_93MPQnYFUe72Meta?.alias || [],
    redirect: _91code_93MPQnYFUe72Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/activity/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93MPQnYFUe72Meta?.name ?? "cn__activity-code",
    path: _91code_93MPQnYFUe72Meta?.path ?? "/cn/activity/:code()",
    meta: {...(_91code_93MPQnYFUe72Meta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"activity-code"}},
    alias: _91code_93MPQnYFUe72Meta?.alias || [],
    redirect: _91code_93MPQnYFUe72Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/activity/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93MPQnYFUe72Meta?.name ?? "cn__zh-MO__activity-code",
    path: _91code_93MPQnYFUe72Meta?.path ?? "/cn/zh-MO/activity/:code()",
    meta: {...(_91code_93MPQnYFUe72Meta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"activity-code"}},
    alias: _91code_93MPQnYFUe72Meta?.alias || [],
    redirect: _91code_93MPQnYFUe72Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/activity/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93MPQnYFUe72Meta?.name ?? "cn__zh-HK__activity-code",
    path: _91code_93MPQnYFUe72Meta?.path ?? "/cn/zh-HK/activity/:code()",
    meta: {...(_91code_93MPQnYFUe72Meta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"activity-code"}},
    alias: _91code_93MPQnYFUe72Meta?.alias || [],
    redirect: _91code_93MPQnYFUe72Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/activity/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93MPQnYFUe72Meta?.name ?? "cn__zh-CN__activity-code",
    path: _91code_93MPQnYFUe72Meta?.path ?? "/cn/zh-CN/activity/:code()",
    meta: {...(_91code_93MPQnYFUe72Meta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"activity-code"}},
    alias: _91code_93MPQnYFUe72Meta?.alias || [],
    redirect: _91code_93MPQnYFUe72Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/activity/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93MPQnYFUe72Meta?.name ?? "cn__en__activity-code",
    path: _91code_93MPQnYFUe72Meta?.path ?? "/cn/en/activity/:code()",
    meta: {...(_91code_93MPQnYFUe72Meta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"activity-code"}},
    alias: _91code_93MPQnYFUe72Meta?.alias || [],
    redirect: _91code_93MPQnYFUe72Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/activity/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93gKh9stJqvJMeta?.name ?? "hk__activity-largeScreen-code",
    path: _91code_93gKh9stJqvJMeta?.path ?? "/hk/activity/largeScreen/:code()",
    meta: {...(_91code_93gKh9stJqvJMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"activity-largeScreen-code"}},
    alias: _91code_93gKh9stJqvJMeta?.alias || [],
    redirect: _91code_93gKh9stJqvJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/activity/largeScreen/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93gKh9stJqvJMeta?.name ?? "hk__zh-MO__activity-largeScreen-code",
    path: _91code_93gKh9stJqvJMeta?.path ?? "/hk/zh-MO/activity/largeScreen/:code()",
    meta: {...(_91code_93gKh9stJqvJMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"activity-largeScreen-code"}},
    alias: _91code_93gKh9stJqvJMeta?.alias || [],
    redirect: _91code_93gKh9stJqvJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/activity/largeScreen/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93gKh9stJqvJMeta?.name ?? "activity-largeScreen-code",
    path: _91code_93gKh9stJqvJMeta?.path ?? "/activity/largeScreen/:code()",
    meta: {...(_91code_93gKh9stJqvJMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"activity-largeScreen-code"}},
    alias: _91code_93gKh9stJqvJMeta?.alias || [],
    redirect: _91code_93gKh9stJqvJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/activity/largeScreen/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93gKh9stJqvJMeta?.name ?? "hk__zh-HK__activity-largeScreen-code",
    path: _91code_93gKh9stJqvJMeta?.path ?? "/hk/zh-HK/activity/largeScreen/:code()",
    meta: {...(_91code_93gKh9stJqvJMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"activity-largeScreen-code"}},
    alias: _91code_93gKh9stJqvJMeta?.alias || [],
    redirect: _91code_93gKh9stJqvJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/activity/largeScreen/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93gKh9stJqvJMeta?.name ?? "hk__zh-CN__activity-largeScreen-code",
    path: _91code_93gKh9stJqvJMeta?.path ?? "/hk/zh-CN/activity/largeScreen/:code()",
    meta: {...(_91code_93gKh9stJqvJMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"activity-largeScreen-code"}},
    alias: _91code_93gKh9stJqvJMeta?.alias || [],
    redirect: _91code_93gKh9stJqvJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/activity/largeScreen/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93gKh9stJqvJMeta?.name ?? "hk__en__activity-largeScreen-code",
    path: _91code_93gKh9stJqvJMeta?.path ?? "/hk/en/activity/largeScreen/:code()",
    meta: {...(_91code_93gKh9stJqvJMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"activity-largeScreen-code"}},
    alias: _91code_93gKh9stJqvJMeta?.alias || [],
    redirect: _91code_93gKh9stJqvJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/activity/largeScreen/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93gKh9stJqvJMeta?.name ?? "mo__activity-largeScreen-code",
    path: _91code_93gKh9stJqvJMeta?.path ?? "/mo/activity/largeScreen/:code()",
    meta: {...(_91code_93gKh9stJqvJMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"activity-largeScreen-code"}},
    alias: _91code_93gKh9stJqvJMeta?.alias || [],
    redirect: _91code_93gKh9stJqvJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/activity/largeScreen/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93gKh9stJqvJMeta?.name ?? "mo__zh-MO__activity-largeScreen-code",
    path: _91code_93gKh9stJqvJMeta?.path ?? "/mo/zh-MO/activity/largeScreen/:code()",
    meta: {...(_91code_93gKh9stJqvJMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"activity-largeScreen-code"}},
    alias: _91code_93gKh9stJqvJMeta?.alias || [],
    redirect: _91code_93gKh9stJqvJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/activity/largeScreen/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93gKh9stJqvJMeta?.name ?? "mo__zh-HK__activity-largeScreen-code",
    path: _91code_93gKh9stJqvJMeta?.path ?? "/mo/zh-HK/activity/largeScreen/:code()",
    meta: {...(_91code_93gKh9stJqvJMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"activity-largeScreen-code"}},
    alias: _91code_93gKh9stJqvJMeta?.alias || [],
    redirect: _91code_93gKh9stJqvJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/activity/largeScreen/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93gKh9stJqvJMeta?.name ?? "mo__zh-CN__activity-largeScreen-code",
    path: _91code_93gKh9stJqvJMeta?.path ?? "/mo/zh-CN/activity/largeScreen/:code()",
    meta: {...(_91code_93gKh9stJqvJMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"activity-largeScreen-code"}},
    alias: _91code_93gKh9stJqvJMeta?.alias || [],
    redirect: _91code_93gKh9stJqvJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/activity/largeScreen/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93gKh9stJqvJMeta?.name ?? "mo__en__activity-largeScreen-code",
    path: _91code_93gKh9stJqvJMeta?.path ?? "/mo/en/activity/largeScreen/:code()",
    meta: {...(_91code_93gKh9stJqvJMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"activity-largeScreen-code"}},
    alias: _91code_93gKh9stJqvJMeta?.alias || [],
    redirect: _91code_93gKh9stJqvJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/activity/largeScreen/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93gKh9stJqvJMeta?.name ?? "cn__activity-largeScreen-code",
    path: _91code_93gKh9stJqvJMeta?.path ?? "/cn/activity/largeScreen/:code()",
    meta: {...(_91code_93gKh9stJqvJMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"activity-largeScreen-code"}},
    alias: _91code_93gKh9stJqvJMeta?.alias || [],
    redirect: _91code_93gKh9stJqvJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/activity/largeScreen/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93gKh9stJqvJMeta?.name ?? "cn__zh-MO__activity-largeScreen-code",
    path: _91code_93gKh9stJqvJMeta?.path ?? "/cn/zh-MO/activity/largeScreen/:code()",
    meta: {...(_91code_93gKh9stJqvJMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"activity-largeScreen-code"}},
    alias: _91code_93gKh9stJqvJMeta?.alias || [],
    redirect: _91code_93gKh9stJqvJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/activity/largeScreen/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93gKh9stJqvJMeta?.name ?? "cn__zh-HK__activity-largeScreen-code",
    path: _91code_93gKh9stJqvJMeta?.path ?? "/cn/zh-HK/activity/largeScreen/:code()",
    meta: {...(_91code_93gKh9stJqvJMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"activity-largeScreen-code"}},
    alias: _91code_93gKh9stJqvJMeta?.alias || [],
    redirect: _91code_93gKh9stJqvJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/activity/largeScreen/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93gKh9stJqvJMeta?.name ?? "cn__zh-CN__activity-largeScreen-code",
    path: _91code_93gKh9stJqvJMeta?.path ?? "/cn/zh-CN/activity/largeScreen/:code()",
    meta: {...(_91code_93gKh9stJqvJMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"activity-largeScreen-code"}},
    alias: _91code_93gKh9stJqvJMeta?.alias || [],
    redirect: _91code_93gKh9stJqvJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/activity/largeScreen/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93gKh9stJqvJMeta?.name ?? "cn__en__activity-largeScreen-code",
    path: _91code_93gKh9stJqvJMeta?.path ?? "/cn/en/activity/largeScreen/:code()",
    meta: {...(_91code_93gKh9stJqvJMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"activity-largeScreen-code"}},
    alias: _91code_93gKh9stJqvJMeta?.alias || [],
    redirect: _91code_93gKh9stJqvJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/activity/largeScreen/[code].vue").then(m => m.default || m)
  },
  {
    name: _91id_93EVioydxR2sMeta?.name ?? "hk__address-edit-id",
    path: _91id_93EVioydxR2sMeta?.path ?? "/hk/address/edit/:id()",
    meta: {...(_91id_93EVioydxR2sMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"address-edit-id"}},
    alias: _91id_93EVioydxR2sMeta?.alias || [],
    redirect: _91id_93EVioydxR2sMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/address/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93EVioydxR2sMeta?.name ?? "hk__zh-MO__address-edit-id",
    path: _91id_93EVioydxR2sMeta?.path ?? "/hk/zh-MO/address/edit/:id()",
    meta: {...(_91id_93EVioydxR2sMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"address-edit-id"}},
    alias: _91id_93EVioydxR2sMeta?.alias || [],
    redirect: _91id_93EVioydxR2sMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/address/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93EVioydxR2sMeta?.name ?? "address-edit-id",
    path: _91id_93EVioydxR2sMeta?.path ?? "/address/edit/:id()",
    meta: {...(_91id_93EVioydxR2sMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"address-edit-id"}},
    alias: _91id_93EVioydxR2sMeta?.alias || [],
    redirect: _91id_93EVioydxR2sMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/address/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93EVioydxR2sMeta?.name ?? "hk__zh-HK__address-edit-id",
    path: _91id_93EVioydxR2sMeta?.path ?? "/hk/zh-HK/address/edit/:id()",
    meta: {...(_91id_93EVioydxR2sMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"address-edit-id"}},
    alias: _91id_93EVioydxR2sMeta?.alias || [],
    redirect: _91id_93EVioydxR2sMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/address/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93EVioydxR2sMeta?.name ?? "hk__zh-CN__address-edit-id",
    path: _91id_93EVioydxR2sMeta?.path ?? "/hk/zh-CN/address/edit/:id()",
    meta: {...(_91id_93EVioydxR2sMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"address-edit-id"}},
    alias: _91id_93EVioydxR2sMeta?.alias || [],
    redirect: _91id_93EVioydxR2sMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/address/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93EVioydxR2sMeta?.name ?? "hk__en__address-edit-id",
    path: _91id_93EVioydxR2sMeta?.path ?? "/hk/en/address/edit/:id()",
    meta: {...(_91id_93EVioydxR2sMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"address-edit-id"}},
    alias: _91id_93EVioydxR2sMeta?.alias || [],
    redirect: _91id_93EVioydxR2sMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/address/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93EVioydxR2sMeta?.name ?? "mo__address-edit-id",
    path: _91id_93EVioydxR2sMeta?.path ?? "/mo/address/edit/:id()",
    meta: {...(_91id_93EVioydxR2sMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"address-edit-id"}},
    alias: _91id_93EVioydxR2sMeta?.alias || [],
    redirect: _91id_93EVioydxR2sMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/address/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93EVioydxR2sMeta?.name ?? "mo__zh-MO__address-edit-id",
    path: _91id_93EVioydxR2sMeta?.path ?? "/mo/zh-MO/address/edit/:id()",
    meta: {...(_91id_93EVioydxR2sMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"address-edit-id"}},
    alias: _91id_93EVioydxR2sMeta?.alias || [],
    redirect: _91id_93EVioydxR2sMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/address/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93EVioydxR2sMeta?.name ?? "mo__zh-HK__address-edit-id",
    path: _91id_93EVioydxR2sMeta?.path ?? "/mo/zh-HK/address/edit/:id()",
    meta: {...(_91id_93EVioydxR2sMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"address-edit-id"}},
    alias: _91id_93EVioydxR2sMeta?.alias || [],
    redirect: _91id_93EVioydxR2sMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/address/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93EVioydxR2sMeta?.name ?? "mo__zh-CN__address-edit-id",
    path: _91id_93EVioydxR2sMeta?.path ?? "/mo/zh-CN/address/edit/:id()",
    meta: {...(_91id_93EVioydxR2sMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"address-edit-id"}},
    alias: _91id_93EVioydxR2sMeta?.alias || [],
    redirect: _91id_93EVioydxR2sMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/address/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93EVioydxR2sMeta?.name ?? "mo__en__address-edit-id",
    path: _91id_93EVioydxR2sMeta?.path ?? "/mo/en/address/edit/:id()",
    meta: {...(_91id_93EVioydxR2sMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"address-edit-id"}},
    alias: _91id_93EVioydxR2sMeta?.alias || [],
    redirect: _91id_93EVioydxR2sMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/address/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93EVioydxR2sMeta?.name ?? "cn__address-edit-id",
    path: _91id_93EVioydxR2sMeta?.path ?? "/cn/address/edit/:id()",
    meta: {...(_91id_93EVioydxR2sMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"address-edit-id"}},
    alias: _91id_93EVioydxR2sMeta?.alias || [],
    redirect: _91id_93EVioydxR2sMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/address/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93EVioydxR2sMeta?.name ?? "cn__zh-MO__address-edit-id",
    path: _91id_93EVioydxR2sMeta?.path ?? "/cn/zh-MO/address/edit/:id()",
    meta: {...(_91id_93EVioydxR2sMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"address-edit-id"}},
    alias: _91id_93EVioydxR2sMeta?.alias || [],
    redirect: _91id_93EVioydxR2sMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/address/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93EVioydxR2sMeta?.name ?? "cn__zh-HK__address-edit-id",
    path: _91id_93EVioydxR2sMeta?.path ?? "/cn/zh-HK/address/edit/:id()",
    meta: {...(_91id_93EVioydxR2sMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"address-edit-id"}},
    alias: _91id_93EVioydxR2sMeta?.alias || [],
    redirect: _91id_93EVioydxR2sMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/address/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93EVioydxR2sMeta?.name ?? "cn__zh-CN__address-edit-id",
    path: _91id_93EVioydxR2sMeta?.path ?? "/cn/zh-CN/address/edit/:id()",
    meta: {...(_91id_93EVioydxR2sMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"address-edit-id"}},
    alias: _91id_93EVioydxR2sMeta?.alias || [],
    redirect: _91id_93EVioydxR2sMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/address/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93EVioydxR2sMeta?.name ?? "cn__en__address-edit-id",
    path: _91id_93EVioydxR2sMeta?.path ?? "/cn/en/address/edit/:id()",
    meta: {...(_91id_93EVioydxR2sMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"address-edit-id"}},
    alias: _91id_93EVioydxR2sMeta?.alias || [],
    redirect: _91id_93EVioydxR2sMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/address/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: listsEPBhraajzMeta?.name ?? "hk__address-list",
    path: listsEPBhraajzMeta?.path ?? "/hk/address/list",
    meta: {...(listsEPBhraajzMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"address-list"}},
    alias: listsEPBhraajzMeta?.alias || [],
    redirect: listsEPBhraajzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/address/list.vue").then(m => m.default || m)
  },
  {
    name: listsEPBhraajzMeta?.name ?? "hk__zh-MO__address-list",
    path: listsEPBhraajzMeta?.path ?? "/hk/zh-MO/address/list",
    meta: {...(listsEPBhraajzMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"address-list"}},
    alias: listsEPBhraajzMeta?.alias || [],
    redirect: listsEPBhraajzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/address/list.vue").then(m => m.default || m)
  },
  {
    name: listsEPBhraajzMeta?.name ?? "address-list",
    path: listsEPBhraajzMeta?.path ?? "/address/list",
    meta: {...(listsEPBhraajzMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"address-list"}},
    alias: listsEPBhraajzMeta?.alias || [],
    redirect: listsEPBhraajzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/address/list.vue").then(m => m.default || m)
  },
  {
    name: listsEPBhraajzMeta?.name ?? "hk__zh-HK__address-list",
    path: listsEPBhraajzMeta?.path ?? "/hk/zh-HK/address/list",
    meta: {...(listsEPBhraajzMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"address-list"}},
    alias: listsEPBhraajzMeta?.alias || [],
    redirect: listsEPBhraajzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/address/list.vue").then(m => m.default || m)
  },
  {
    name: listsEPBhraajzMeta?.name ?? "hk__zh-CN__address-list",
    path: listsEPBhraajzMeta?.path ?? "/hk/zh-CN/address/list",
    meta: {...(listsEPBhraajzMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"address-list"}},
    alias: listsEPBhraajzMeta?.alias || [],
    redirect: listsEPBhraajzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/address/list.vue").then(m => m.default || m)
  },
  {
    name: listsEPBhraajzMeta?.name ?? "hk__en__address-list",
    path: listsEPBhraajzMeta?.path ?? "/hk/en/address/list",
    meta: {...(listsEPBhraajzMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"address-list"}},
    alias: listsEPBhraajzMeta?.alias || [],
    redirect: listsEPBhraajzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/address/list.vue").then(m => m.default || m)
  },
  {
    name: listsEPBhraajzMeta?.name ?? "mo__address-list",
    path: listsEPBhraajzMeta?.path ?? "/mo/address/list",
    meta: {...(listsEPBhraajzMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"address-list"}},
    alias: listsEPBhraajzMeta?.alias || [],
    redirect: listsEPBhraajzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/address/list.vue").then(m => m.default || m)
  },
  {
    name: listsEPBhraajzMeta?.name ?? "mo__zh-MO__address-list",
    path: listsEPBhraajzMeta?.path ?? "/mo/zh-MO/address/list",
    meta: {...(listsEPBhraajzMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"address-list"}},
    alias: listsEPBhraajzMeta?.alias || [],
    redirect: listsEPBhraajzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/address/list.vue").then(m => m.default || m)
  },
  {
    name: listsEPBhraajzMeta?.name ?? "mo__zh-HK__address-list",
    path: listsEPBhraajzMeta?.path ?? "/mo/zh-HK/address/list",
    meta: {...(listsEPBhraajzMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"address-list"}},
    alias: listsEPBhraajzMeta?.alias || [],
    redirect: listsEPBhraajzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/address/list.vue").then(m => m.default || m)
  },
  {
    name: listsEPBhraajzMeta?.name ?? "mo__zh-CN__address-list",
    path: listsEPBhraajzMeta?.path ?? "/mo/zh-CN/address/list",
    meta: {...(listsEPBhraajzMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"address-list"}},
    alias: listsEPBhraajzMeta?.alias || [],
    redirect: listsEPBhraajzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/address/list.vue").then(m => m.default || m)
  },
  {
    name: listsEPBhraajzMeta?.name ?? "mo__en__address-list",
    path: listsEPBhraajzMeta?.path ?? "/mo/en/address/list",
    meta: {...(listsEPBhraajzMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"address-list"}},
    alias: listsEPBhraajzMeta?.alias || [],
    redirect: listsEPBhraajzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/address/list.vue").then(m => m.default || m)
  },
  {
    name: listsEPBhraajzMeta?.name ?? "cn__address-list",
    path: listsEPBhraajzMeta?.path ?? "/cn/address/list",
    meta: {...(listsEPBhraajzMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"address-list"}},
    alias: listsEPBhraajzMeta?.alias || [],
    redirect: listsEPBhraajzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/address/list.vue").then(m => m.default || m)
  },
  {
    name: listsEPBhraajzMeta?.name ?? "cn__zh-MO__address-list",
    path: listsEPBhraajzMeta?.path ?? "/cn/zh-MO/address/list",
    meta: {...(listsEPBhraajzMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"address-list"}},
    alias: listsEPBhraajzMeta?.alias || [],
    redirect: listsEPBhraajzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/address/list.vue").then(m => m.default || m)
  },
  {
    name: listsEPBhraajzMeta?.name ?? "cn__zh-HK__address-list",
    path: listsEPBhraajzMeta?.path ?? "/cn/zh-HK/address/list",
    meta: {...(listsEPBhraajzMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"address-list"}},
    alias: listsEPBhraajzMeta?.alias || [],
    redirect: listsEPBhraajzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/address/list.vue").then(m => m.default || m)
  },
  {
    name: listsEPBhraajzMeta?.name ?? "cn__zh-CN__address-list",
    path: listsEPBhraajzMeta?.path ?? "/cn/zh-CN/address/list",
    meta: {...(listsEPBhraajzMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"address-list"}},
    alias: listsEPBhraajzMeta?.alias || [],
    redirect: listsEPBhraajzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/address/list.vue").then(m => m.default || m)
  },
  {
    name: listsEPBhraajzMeta?.name ?? "cn__en__address-list",
    path: listsEPBhraajzMeta?.path ?? "/cn/en/address/list",
    meta: {...(listsEPBhraajzMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"address-list"}},
    alias: listsEPBhraajzMeta?.alias || [],
    redirect: listsEPBhraajzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/address/list.vue").then(m => m.default || m)
  },
  {
    name: _91id_93V53o8a8PGGMeta?.name ?? "hk__afterSale-apply-id",
    path: _91id_93V53o8a8PGGMeta?.path ?? "/hk/afterSale/apply/:id()",
    meta: {...(_91id_93V53o8a8PGGMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"afterSale-apply-id"}},
    alias: _91id_93V53o8a8PGGMeta?.alias || [],
    redirect: _91id_93V53o8a8PGGMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/apply/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93V53o8a8PGGMeta?.name ?? "hk__zh-MO__afterSale-apply-id",
    path: _91id_93V53o8a8PGGMeta?.path ?? "/hk/zh-MO/afterSale/apply/:id()",
    meta: {...(_91id_93V53o8a8PGGMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"afterSale-apply-id"}},
    alias: _91id_93V53o8a8PGGMeta?.alias || [],
    redirect: _91id_93V53o8a8PGGMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/apply/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93V53o8a8PGGMeta?.name ?? "afterSale-apply-id",
    path: _91id_93V53o8a8PGGMeta?.path ?? "/afterSale/apply/:id()",
    meta: {...(_91id_93V53o8a8PGGMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"afterSale-apply-id"}},
    alias: _91id_93V53o8a8PGGMeta?.alias || [],
    redirect: _91id_93V53o8a8PGGMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/apply/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93V53o8a8PGGMeta?.name ?? "hk__zh-HK__afterSale-apply-id",
    path: _91id_93V53o8a8PGGMeta?.path ?? "/hk/zh-HK/afterSale/apply/:id()",
    meta: {...(_91id_93V53o8a8PGGMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"afterSale-apply-id"}},
    alias: _91id_93V53o8a8PGGMeta?.alias || [],
    redirect: _91id_93V53o8a8PGGMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/apply/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93V53o8a8PGGMeta?.name ?? "hk__zh-CN__afterSale-apply-id",
    path: _91id_93V53o8a8PGGMeta?.path ?? "/hk/zh-CN/afterSale/apply/:id()",
    meta: {...(_91id_93V53o8a8PGGMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"afterSale-apply-id"}},
    alias: _91id_93V53o8a8PGGMeta?.alias || [],
    redirect: _91id_93V53o8a8PGGMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/apply/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93V53o8a8PGGMeta?.name ?? "hk__en__afterSale-apply-id",
    path: _91id_93V53o8a8PGGMeta?.path ?? "/hk/en/afterSale/apply/:id()",
    meta: {...(_91id_93V53o8a8PGGMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"afterSale-apply-id"}},
    alias: _91id_93V53o8a8PGGMeta?.alias || [],
    redirect: _91id_93V53o8a8PGGMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/apply/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93V53o8a8PGGMeta?.name ?? "mo__afterSale-apply-id",
    path: _91id_93V53o8a8PGGMeta?.path ?? "/mo/afterSale/apply/:id()",
    meta: {...(_91id_93V53o8a8PGGMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"afterSale-apply-id"}},
    alias: _91id_93V53o8a8PGGMeta?.alias || [],
    redirect: _91id_93V53o8a8PGGMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/apply/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93V53o8a8PGGMeta?.name ?? "mo__zh-MO__afterSale-apply-id",
    path: _91id_93V53o8a8PGGMeta?.path ?? "/mo/zh-MO/afterSale/apply/:id()",
    meta: {...(_91id_93V53o8a8PGGMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"afterSale-apply-id"}},
    alias: _91id_93V53o8a8PGGMeta?.alias || [],
    redirect: _91id_93V53o8a8PGGMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/apply/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93V53o8a8PGGMeta?.name ?? "mo__zh-HK__afterSale-apply-id",
    path: _91id_93V53o8a8PGGMeta?.path ?? "/mo/zh-HK/afterSale/apply/:id()",
    meta: {...(_91id_93V53o8a8PGGMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"afterSale-apply-id"}},
    alias: _91id_93V53o8a8PGGMeta?.alias || [],
    redirect: _91id_93V53o8a8PGGMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/apply/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93V53o8a8PGGMeta?.name ?? "mo__zh-CN__afterSale-apply-id",
    path: _91id_93V53o8a8PGGMeta?.path ?? "/mo/zh-CN/afterSale/apply/:id()",
    meta: {...(_91id_93V53o8a8PGGMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"afterSale-apply-id"}},
    alias: _91id_93V53o8a8PGGMeta?.alias || [],
    redirect: _91id_93V53o8a8PGGMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/apply/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93V53o8a8PGGMeta?.name ?? "mo__en__afterSale-apply-id",
    path: _91id_93V53o8a8PGGMeta?.path ?? "/mo/en/afterSale/apply/:id()",
    meta: {...(_91id_93V53o8a8PGGMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"afterSale-apply-id"}},
    alias: _91id_93V53o8a8PGGMeta?.alias || [],
    redirect: _91id_93V53o8a8PGGMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/apply/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93V53o8a8PGGMeta?.name ?? "cn__afterSale-apply-id",
    path: _91id_93V53o8a8PGGMeta?.path ?? "/cn/afterSale/apply/:id()",
    meta: {...(_91id_93V53o8a8PGGMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"afterSale-apply-id"}},
    alias: _91id_93V53o8a8PGGMeta?.alias || [],
    redirect: _91id_93V53o8a8PGGMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/apply/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93V53o8a8PGGMeta?.name ?? "cn__zh-MO__afterSale-apply-id",
    path: _91id_93V53o8a8PGGMeta?.path ?? "/cn/zh-MO/afterSale/apply/:id()",
    meta: {...(_91id_93V53o8a8PGGMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"afterSale-apply-id"}},
    alias: _91id_93V53o8a8PGGMeta?.alias || [],
    redirect: _91id_93V53o8a8PGGMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/apply/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93V53o8a8PGGMeta?.name ?? "cn__zh-HK__afterSale-apply-id",
    path: _91id_93V53o8a8PGGMeta?.path ?? "/cn/zh-HK/afterSale/apply/:id()",
    meta: {...(_91id_93V53o8a8PGGMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"afterSale-apply-id"}},
    alias: _91id_93V53o8a8PGGMeta?.alias || [],
    redirect: _91id_93V53o8a8PGGMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/apply/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93V53o8a8PGGMeta?.name ?? "cn__zh-CN__afterSale-apply-id",
    path: _91id_93V53o8a8PGGMeta?.path ?? "/cn/zh-CN/afterSale/apply/:id()",
    meta: {...(_91id_93V53o8a8PGGMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"afterSale-apply-id"}},
    alias: _91id_93V53o8a8PGGMeta?.alias || [],
    redirect: _91id_93V53o8a8PGGMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/apply/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93V53o8a8PGGMeta?.name ?? "cn__en__afterSale-apply-id",
    path: _91id_93V53o8a8PGGMeta?.path ?? "/cn/en/afterSale/apply/:id()",
    meta: {...(_91id_93V53o8a8PGGMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"afterSale-apply-id"}},
    alias: _91id_93V53o8a8PGGMeta?.alias || [],
    redirect: _91id_93V53o8a8PGGMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/apply/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93YH2Vy2iPDTMeta?.name ?? "hk__afterSale-detail-id",
    path: _91id_93YH2Vy2iPDTMeta?.path ?? "/hk/afterSale/detail/:id()",
    meta: {...(_91id_93YH2Vy2iPDTMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"afterSale-detail-id"}},
    alias: _91id_93YH2Vy2iPDTMeta?.alias || [],
    redirect: _91id_93YH2Vy2iPDTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93YH2Vy2iPDTMeta?.name ?? "hk__zh-MO__afterSale-detail-id",
    path: _91id_93YH2Vy2iPDTMeta?.path ?? "/hk/zh-MO/afterSale/detail/:id()",
    meta: {...(_91id_93YH2Vy2iPDTMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"afterSale-detail-id"}},
    alias: _91id_93YH2Vy2iPDTMeta?.alias || [],
    redirect: _91id_93YH2Vy2iPDTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93YH2Vy2iPDTMeta?.name ?? "afterSale-detail-id",
    path: _91id_93YH2Vy2iPDTMeta?.path ?? "/afterSale/detail/:id()",
    meta: {...(_91id_93YH2Vy2iPDTMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"afterSale-detail-id"}},
    alias: _91id_93YH2Vy2iPDTMeta?.alias || [],
    redirect: _91id_93YH2Vy2iPDTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93YH2Vy2iPDTMeta?.name ?? "hk__zh-HK__afterSale-detail-id",
    path: _91id_93YH2Vy2iPDTMeta?.path ?? "/hk/zh-HK/afterSale/detail/:id()",
    meta: {...(_91id_93YH2Vy2iPDTMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"afterSale-detail-id"}},
    alias: _91id_93YH2Vy2iPDTMeta?.alias || [],
    redirect: _91id_93YH2Vy2iPDTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93YH2Vy2iPDTMeta?.name ?? "hk__zh-CN__afterSale-detail-id",
    path: _91id_93YH2Vy2iPDTMeta?.path ?? "/hk/zh-CN/afterSale/detail/:id()",
    meta: {...(_91id_93YH2Vy2iPDTMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"afterSale-detail-id"}},
    alias: _91id_93YH2Vy2iPDTMeta?.alias || [],
    redirect: _91id_93YH2Vy2iPDTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93YH2Vy2iPDTMeta?.name ?? "hk__en__afterSale-detail-id",
    path: _91id_93YH2Vy2iPDTMeta?.path ?? "/hk/en/afterSale/detail/:id()",
    meta: {...(_91id_93YH2Vy2iPDTMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"afterSale-detail-id"}},
    alias: _91id_93YH2Vy2iPDTMeta?.alias || [],
    redirect: _91id_93YH2Vy2iPDTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93YH2Vy2iPDTMeta?.name ?? "mo__afterSale-detail-id",
    path: _91id_93YH2Vy2iPDTMeta?.path ?? "/mo/afterSale/detail/:id()",
    meta: {...(_91id_93YH2Vy2iPDTMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"afterSale-detail-id"}},
    alias: _91id_93YH2Vy2iPDTMeta?.alias || [],
    redirect: _91id_93YH2Vy2iPDTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93YH2Vy2iPDTMeta?.name ?? "mo__zh-MO__afterSale-detail-id",
    path: _91id_93YH2Vy2iPDTMeta?.path ?? "/mo/zh-MO/afterSale/detail/:id()",
    meta: {...(_91id_93YH2Vy2iPDTMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"afterSale-detail-id"}},
    alias: _91id_93YH2Vy2iPDTMeta?.alias || [],
    redirect: _91id_93YH2Vy2iPDTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93YH2Vy2iPDTMeta?.name ?? "mo__zh-HK__afterSale-detail-id",
    path: _91id_93YH2Vy2iPDTMeta?.path ?? "/mo/zh-HK/afterSale/detail/:id()",
    meta: {...(_91id_93YH2Vy2iPDTMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"afterSale-detail-id"}},
    alias: _91id_93YH2Vy2iPDTMeta?.alias || [],
    redirect: _91id_93YH2Vy2iPDTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93YH2Vy2iPDTMeta?.name ?? "mo__zh-CN__afterSale-detail-id",
    path: _91id_93YH2Vy2iPDTMeta?.path ?? "/mo/zh-CN/afterSale/detail/:id()",
    meta: {...(_91id_93YH2Vy2iPDTMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"afterSale-detail-id"}},
    alias: _91id_93YH2Vy2iPDTMeta?.alias || [],
    redirect: _91id_93YH2Vy2iPDTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93YH2Vy2iPDTMeta?.name ?? "mo__en__afterSale-detail-id",
    path: _91id_93YH2Vy2iPDTMeta?.path ?? "/mo/en/afterSale/detail/:id()",
    meta: {...(_91id_93YH2Vy2iPDTMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"afterSale-detail-id"}},
    alias: _91id_93YH2Vy2iPDTMeta?.alias || [],
    redirect: _91id_93YH2Vy2iPDTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93YH2Vy2iPDTMeta?.name ?? "cn__afterSale-detail-id",
    path: _91id_93YH2Vy2iPDTMeta?.path ?? "/cn/afterSale/detail/:id()",
    meta: {...(_91id_93YH2Vy2iPDTMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"afterSale-detail-id"}},
    alias: _91id_93YH2Vy2iPDTMeta?.alias || [],
    redirect: _91id_93YH2Vy2iPDTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93YH2Vy2iPDTMeta?.name ?? "cn__zh-MO__afterSale-detail-id",
    path: _91id_93YH2Vy2iPDTMeta?.path ?? "/cn/zh-MO/afterSale/detail/:id()",
    meta: {...(_91id_93YH2Vy2iPDTMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"afterSale-detail-id"}},
    alias: _91id_93YH2Vy2iPDTMeta?.alias || [],
    redirect: _91id_93YH2Vy2iPDTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93YH2Vy2iPDTMeta?.name ?? "cn__zh-HK__afterSale-detail-id",
    path: _91id_93YH2Vy2iPDTMeta?.path ?? "/cn/zh-HK/afterSale/detail/:id()",
    meta: {...(_91id_93YH2Vy2iPDTMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"afterSale-detail-id"}},
    alias: _91id_93YH2Vy2iPDTMeta?.alias || [],
    redirect: _91id_93YH2Vy2iPDTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93YH2Vy2iPDTMeta?.name ?? "cn__zh-CN__afterSale-detail-id",
    path: _91id_93YH2Vy2iPDTMeta?.path ?? "/cn/zh-CN/afterSale/detail/:id()",
    meta: {...(_91id_93YH2Vy2iPDTMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"afterSale-detail-id"}},
    alias: _91id_93YH2Vy2iPDTMeta?.alias || [],
    redirect: _91id_93YH2Vy2iPDTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93YH2Vy2iPDTMeta?.name ?? "cn__en__afterSale-detail-id",
    path: _91id_93YH2Vy2iPDTMeta?.path ?? "/cn/en/afterSale/detail/:id()",
    meta: {...(_91id_93YH2Vy2iPDTMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"afterSale-detail-id"}},
    alias: _91id_93YH2Vy2iPDTMeta?.alias || [],
    redirect: _91id_93YH2Vy2iPDTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93krulT0A0XzMeta?.name ?? "hk__afterSale-history-id",
    path: _91id_93krulT0A0XzMeta?.path ?? "/hk/afterSale/history/:id()",
    meta: {...(_91id_93krulT0A0XzMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"afterSale-history-id"}},
    alias: _91id_93krulT0A0XzMeta?.alias || [],
    redirect: _91id_93krulT0A0XzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/history/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93krulT0A0XzMeta?.name ?? "hk__zh-MO__afterSale-history-id",
    path: _91id_93krulT0A0XzMeta?.path ?? "/hk/zh-MO/afterSale/history/:id()",
    meta: {...(_91id_93krulT0A0XzMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"afterSale-history-id"}},
    alias: _91id_93krulT0A0XzMeta?.alias || [],
    redirect: _91id_93krulT0A0XzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/history/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93krulT0A0XzMeta?.name ?? "afterSale-history-id",
    path: _91id_93krulT0A0XzMeta?.path ?? "/afterSale/history/:id()",
    meta: {...(_91id_93krulT0A0XzMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"afterSale-history-id"}},
    alias: _91id_93krulT0A0XzMeta?.alias || [],
    redirect: _91id_93krulT0A0XzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/history/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93krulT0A0XzMeta?.name ?? "hk__zh-HK__afterSale-history-id",
    path: _91id_93krulT0A0XzMeta?.path ?? "/hk/zh-HK/afterSale/history/:id()",
    meta: {...(_91id_93krulT0A0XzMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"afterSale-history-id"}},
    alias: _91id_93krulT0A0XzMeta?.alias || [],
    redirect: _91id_93krulT0A0XzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/history/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93krulT0A0XzMeta?.name ?? "hk__zh-CN__afterSale-history-id",
    path: _91id_93krulT0A0XzMeta?.path ?? "/hk/zh-CN/afterSale/history/:id()",
    meta: {...(_91id_93krulT0A0XzMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"afterSale-history-id"}},
    alias: _91id_93krulT0A0XzMeta?.alias || [],
    redirect: _91id_93krulT0A0XzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/history/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93krulT0A0XzMeta?.name ?? "hk__en__afterSale-history-id",
    path: _91id_93krulT0A0XzMeta?.path ?? "/hk/en/afterSale/history/:id()",
    meta: {...(_91id_93krulT0A0XzMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"afterSale-history-id"}},
    alias: _91id_93krulT0A0XzMeta?.alias || [],
    redirect: _91id_93krulT0A0XzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/history/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93krulT0A0XzMeta?.name ?? "mo__afterSale-history-id",
    path: _91id_93krulT0A0XzMeta?.path ?? "/mo/afterSale/history/:id()",
    meta: {...(_91id_93krulT0A0XzMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"afterSale-history-id"}},
    alias: _91id_93krulT0A0XzMeta?.alias || [],
    redirect: _91id_93krulT0A0XzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/history/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93krulT0A0XzMeta?.name ?? "mo__zh-MO__afterSale-history-id",
    path: _91id_93krulT0A0XzMeta?.path ?? "/mo/zh-MO/afterSale/history/:id()",
    meta: {...(_91id_93krulT0A0XzMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"afterSale-history-id"}},
    alias: _91id_93krulT0A0XzMeta?.alias || [],
    redirect: _91id_93krulT0A0XzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/history/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93krulT0A0XzMeta?.name ?? "mo__zh-HK__afterSale-history-id",
    path: _91id_93krulT0A0XzMeta?.path ?? "/mo/zh-HK/afterSale/history/:id()",
    meta: {...(_91id_93krulT0A0XzMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"afterSale-history-id"}},
    alias: _91id_93krulT0A0XzMeta?.alias || [],
    redirect: _91id_93krulT0A0XzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/history/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93krulT0A0XzMeta?.name ?? "mo__zh-CN__afterSale-history-id",
    path: _91id_93krulT0A0XzMeta?.path ?? "/mo/zh-CN/afterSale/history/:id()",
    meta: {...(_91id_93krulT0A0XzMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"afterSale-history-id"}},
    alias: _91id_93krulT0A0XzMeta?.alias || [],
    redirect: _91id_93krulT0A0XzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/history/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93krulT0A0XzMeta?.name ?? "mo__en__afterSale-history-id",
    path: _91id_93krulT0A0XzMeta?.path ?? "/mo/en/afterSale/history/:id()",
    meta: {...(_91id_93krulT0A0XzMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"afterSale-history-id"}},
    alias: _91id_93krulT0A0XzMeta?.alias || [],
    redirect: _91id_93krulT0A0XzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/history/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93krulT0A0XzMeta?.name ?? "cn__afterSale-history-id",
    path: _91id_93krulT0A0XzMeta?.path ?? "/cn/afterSale/history/:id()",
    meta: {...(_91id_93krulT0A0XzMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"afterSale-history-id"}},
    alias: _91id_93krulT0A0XzMeta?.alias || [],
    redirect: _91id_93krulT0A0XzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/history/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93krulT0A0XzMeta?.name ?? "cn__zh-MO__afterSale-history-id",
    path: _91id_93krulT0A0XzMeta?.path ?? "/cn/zh-MO/afterSale/history/:id()",
    meta: {...(_91id_93krulT0A0XzMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"afterSale-history-id"}},
    alias: _91id_93krulT0A0XzMeta?.alias || [],
    redirect: _91id_93krulT0A0XzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/history/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93krulT0A0XzMeta?.name ?? "cn__zh-HK__afterSale-history-id",
    path: _91id_93krulT0A0XzMeta?.path ?? "/cn/zh-HK/afterSale/history/:id()",
    meta: {...(_91id_93krulT0A0XzMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"afterSale-history-id"}},
    alias: _91id_93krulT0A0XzMeta?.alias || [],
    redirect: _91id_93krulT0A0XzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/history/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93krulT0A0XzMeta?.name ?? "cn__zh-CN__afterSale-history-id",
    path: _91id_93krulT0A0XzMeta?.path ?? "/cn/zh-CN/afterSale/history/:id()",
    meta: {...(_91id_93krulT0A0XzMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"afterSale-history-id"}},
    alias: _91id_93krulT0A0XzMeta?.alias || [],
    redirect: _91id_93krulT0A0XzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/history/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93krulT0A0XzMeta?.name ?? "cn__en__afterSale-history-id",
    path: _91id_93krulT0A0XzMeta?.path ?? "/cn/en/afterSale/history/:id()",
    meta: {...(_91id_93krulT0A0XzMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"afterSale-history-id"}},
    alias: _91id_93krulT0A0XzMeta?.alias || [],
    redirect: _91id_93krulT0A0XzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/history/[id].vue").then(m => m.default || m)
  },
  {
    name: listdWXsS6UMHDMeta?.name ?? "hk__afterSale-list",
    path: listdWXsS6UMHDMeta?.path ?? "/hk/afterSale/list",
    meta: {...(listdWXsS6UMHDMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"afterSale-list"}},
    alias: listdWXsS6UMHDMeta?.alias || [],
    redirect: listdWXsS6UMHDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/list.vue").then(m => m.default || m)
  },
  {
    name: listdWXsS6UMHDMeta?.name ?? "hk__zh-MO__afterSale-list",
    path: listdWXsS6UMHDMeta?.path ?? "/hk/zh-MO/afterSale/list",
    meta: {...(listdWXsS6UMHDMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"afterSale-list"}},
    alias: listdWXsS6UMHDMeta?.alias || [],
    redirect: listdWXsS6UMHDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/list.vue").then(m => m.default || m)
  },
  {
    name: listdWXsS6UMHDMeta?.name ?? "afterSale-list",
    path: listdWXsS6UMHDMeta?.path ?? "/afterSale/list",
    meta: {...(listdWXsS6UMHDMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"afterSale-list"}},
    alias: listdWXsS6UMHDMeta?.alias || [],
    redirect: listdWXsS6UMHDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/list.vue").then(m => m.default || m)
  },
  {
    name: listdWXsS6UMHDMeta?.name ?? "hk__zh-HK__afterSale-list",
    path: listdWXsS6UMHDMeta?.path ?? "/hk/zh-HK/afterSale/list",
    meta: {...(listdWXsS6UMHDMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"afterSale-list"}},
    alias: listdWXsS6UMHDMeta?.alias || [],
    redirect: listdWXsS6UMHDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/list.vue").then(m => m.default || m)
  },
  {
    name: listdWXsS6UMHDMeta?.name ?? "hk__zh-CN__afterSale-list",
    path: listdWXsS6UMHDMeta?.path ?? "/hk/zh-CN/afterSale/list",
    meta: {...(listdWXsS6UMHDMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"afterSale-list"}},
    alias: listdWXsS6UMHDMeta?.alias || [],
    redirect: listdWXsS6UMHDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/list.vue").then(m => m.default || m)
  },
  {
    name: listdWXsS6UMHDMeta?.name ?? "hk__en__afterSale-list",
    path: listdWXsS6UMHDMeta?.path ?? "/hk/en/afterSale/list",
    meta: {...(listdWXsS6UMHDMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"afterSale-list"}},
    alias: listdWXsS6UMHDMeta?.alias || [],
    redirect: listdWXsS6UMHDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/list.vue").then(m => m.default || m)
  },
  {
    name: listdWXsS6UMHDMeta?.name ?? "mo__afterSale-list",
    path: listdWXsS6UMHDMeta?.path ?? "/mo/afterSale/list",
    meta: {...(listdWXsS6UMHDMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"afterSale-list"}},
    alias: listdWXsS6UMHDMeta?.alias || [],
    redirect: listdWXsS6UMHDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/list.vue").then(m => m.default || m)
  },
  {
    name: listdWXsS6UMHDMeta?.name ?? "mo__zh-MO__afterSale-list",
    path: listdWXsS6UMHDMeta?.path ?? "/mo/zh-MO/afterSale/list",
    meta: {...(listdWXsS6UMHDMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"afterSale-list"}},
    alias: listdWXsS6UMHDMeta?.alias || [],
    redirect: listdWXsS6UMHDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/list.vue").then(m => m.default || m)
  },
  {
    name: listdWXsS6UMHDMeta?.name ?? "mo__zh-HK__afterSale-list",
    path: listdWXsS6UMHDMeta?.path ?? "/mo/zh-HK/afterSale/list",
    meta: {...(listdWXsS6UMHDMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"afterSale-list"}},
    alias: listdWXsS6UMHDMeta?.alias || [],
    redirect: listdWXsS6UMHDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/list.vue").then(m => m.default || m)
  },
  {
    name: listdWXsS6UMHDMeta?.name ?? "mo__zh-CN__afterSale-list",
    path: listdWXsS6UMHDMeta?.path ?? "/mo/zh-CN/afterSale/list",
    meta: {...(listdWXsS6UMHDMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"afterSale-list"}},
    alias: listdWXsS6UMHDMeta?.alias || [],
    redirect: listdWXsS6UMHDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/list.vue").then(m => m.default || m)
  },
  {
    name: listdWXsS6UMHDMeta?.name ?? "mo__en__afterSale-list",
    path: listdWXsS6UMHDMeta?.path ?? "/mo/en/afterSale/list",
    meta: {...(listdWXsS6UMHDMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"afterSale-list"}},
    alias: listdWXsS6UMHDMeta?.alias || [],
    redirect: listdWXsS6UMHDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/list.vue").then(m => m.default || m)
  },
  {
    name: listdWXsS6UMHDMeta?.name ?? "cn__afterSale-list",
    path: listdWXsS6UMHDMeta?.path ?? "/cn/afterSale/list",
    meta: {...(listdWXsS6UMHDMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"afterSale-list"}},
    alias: listdWXsS6UMHDMeta?.alias || [],
    redirect: listdWXsS6UMHDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/list.vue").then(m => m.default || m)
  },
  {
    name: listdWXsS6UMHDMeta?.name ?? "cn__zh-MO__afterSale-list",
    path: listdWXsS6UMHDMeta?.path ?? "/cn/zh-MO/afterSale/list",
    meta: {...(listdWXsS6UMHDMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"afterSale-list"}},
    alias: listdWXsS6UMHDMeta?.alias || [],
    redirect: listdWXsS6UMHDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/list.vue").then(m => m.default || m)
  },
  {
    name: listdWXsS6UMHDMeta?.name ?? "cn__zh-HK__afterSale-list",
    path: listdWXsS6UMHDMeta?.path ?? "/cn/zh-HK/afterSale/list",
    meta: {...(listdWXsS6UMHDMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"afterSale-list"}},
    alias: listdWXsS6UMHDMeta?.alias || [],
    redirect: listdWXsS6UMHDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/list.vue").then(m => m.default || m)
  },
  {
    name: listdWXsS6UMHDMeta?.name ?? "cn__zh-CN__afterSale-list",
    path: listdWXsS6UMHDMeta?.path ?? "/cn/zh-CN/afterSale/list",
    meta: {...(listdWXsS6UMHDMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"afterSale-list"}},
    alias: listdWXsS6UMHDMeta?.alias || [],
    redirect: listdWXsS6UMHDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/list.vue").then(m => m.default || m)
  },
  {
    name: listdWXsS6UMHDMeta?.name ?? "cn__en__afterSale-list",
    path: listdWXsS6UMHDMeta?.path ?? "/cn/en/afterSale/list",
    meta: {...(listdWXsS6UMHDMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"afterSale-list"}},
    alias: listdWXsS6UMHDMeta?.alias || [],
    redirect: listdWXsS6UMHDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/afterSale/list.vue").then(m => m.default || m)
  },
  {
    name: allGJ09XQe6ztMeta?.name ?? "hk__all",
    path: allGJ09XQe6ztMeta?.path ?? "/hk/all",
    meta: {...(allGJ09XQe6ztMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"all"}},
    alias: allGJ09XQe6ztMeta?.alias || [],
    redirect: allGJ09XQe6ztMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/all.vue").then(m => m.default || m)
  },
  {
    name: allGJ09XQe6ztMeta?.name ?? "hk__zh-MO__all",
    path: allGJ09XQe6ztMeta?.path ?? "/hk/zh-MO/all",
    meta: {...(allGJ09XQe6ztMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"all"}},
    alias: allGJ09XQe6ztMeta?.alias || [],
    redirect: allGJ09XQe6ztMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/all.vue").then(m => m.default || m)
  },
  {
    name: allGJ09XQe6ztMeta?.name ?? "all",
    path: allGJ09XQe6ztMeta?.path ?? "/all",
    meta: {...(allGJ09XQe6ztMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"all"}},
    alias: allGJ09XQe6ztMeta?.alias || [],
    redirect: allGJ09XQe6ztMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/all.vue").then(m => m.default || m)
  },
  {
    name: allGJ09XQe6ztMeta?.name ?? "hk__zh-HK__all",
    path: allGJ09XQe6ztMeta?.path ?? "/hk/zh-HK/all",
    meta: {...(allGJ09XQe6ztMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"all"}},
    alias: allGJ09XQe6ztMeta?.alias || [],
    redirect: allGJ09XQe6ztMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/all.vue").then(m => m.default || m)
  },
  {
    name: allGJ09XQe6ztMeta?.name ?? "hk__zh-CN__all",
    path: allGJ09XQe6ztMeta?.path ?? "/hk/zh-CN/all",
    meta: {...(allGJ09XQe6ztMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"all"}},
    alias: allGJ09XQe6ztMeta?.alias || [],
    redirect: allGJ09XQe6ztMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/all.vue").then(m => m.default || m)
  },
  {
    name: allGJ09XQe6ztMeta?.name ?? "hk__en__all",
    path: allGJ09XQe6ztMeta?.path ?? "/hk/en/all",
    meta: {...(allGJ09XQe6ztMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"all"}},
    alias: allGJ09XQe6ztMeta?.alias || [],
    redirect: allGJ09XQe6ztMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/all.vue").then(m => m.default || m)
  },
  {
    name: allGJ09XQe6ztMeta?.name ?? "mo__all",
    path: allGJ09XQe6ztMeta?.path ?? "/mo/all",
    meta: {...(allGJ09XQe6ztMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"all"}},
    alias: allGJ09XQe6ztMeta?.alias || [],
    redirect: allGJ09XQe6ztMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/all.vue").then(m => m.default || m)
  },
  {
    name: allGJ09XQe6ztMeta?.name ?? "mo__zh-MO__all",
    path: allGJ09XQe6ztMeta?.path ?? "/mo/zh-MO/all",
    meta: {...(allGJ09XQe6ztMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"all"}},
    alias: allGJ09XQe6ztMeta?.alias || [],
    redirect: allGJ09XQe6ztMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/all.vue").then(m => m.default || m)
  },
  {
    name: allGJ09XQe6ztMeta?.name ?? "mo__zh-HK__all",
    path: allGJ09XQe6ztMeta?.path ?? "/mo/zh-HK/all",
    meta: {...(allGJ09XQe6ztMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"all"}},
    alias: allGJ09XQe6ztMeta?.alias || [],
    redirect: allGJ09XQe6ztMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/all.vue").then(m => m.default || m)
  },
  {
    name: allGJ09XQe6ztMeta?.name ?? "mo__zh-CN__all",
    path: allGJ09XQe6ztMeta?.path ?? "/mo/zh-CN/all",
    meta: {...(allGJ09XQe6ztMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"all"}},
    alias: allGJ09XQe6ztMeta?.alias || [],
    redirect: allGJ09XQe6ztMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/all.vue").then(m => m.default || m)
  },
  {
    name: allGJ09XQe6ztMeta?.name ?? "mo__en__all",
    path: allGJ09XQe6ztMeta?.path ?? "/mo/en/all",
    meta: {...(allGJ09XQe6ztMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"all"}},
    alias: allGJ09XQe6ztMeta?.alias || [],
    redirect: allGJ09XQe6ztMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/all.vue").then(m => m.default || m)
  },
  {
    name: allGJ09XQe6ztMeta?.name ?? "cn__all",
    path: allGJ09XQe6ztMeta?.path ?? "/cn/all",
    meta: {...(allGJ09XQe6ztMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"all"}},
    alias: allGJ09XQe6ztMeta?.alias || [],
    redirect: allGJ09XQe6ztMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/all.vue").then(m => m.default || m)
  },
  {
    name: allGJ09XQe6ztMeta?.name ?? "cn__zh-MO__all",
    path: allGJ09XQe6ztMeta?.path ?? "/cn/zh-MO/all",
    meta: {...(allGJ09XQe6ztMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"all"}},
    alias: allGJ09XQe6ztMeta?.alias || [],
    redirect: allGJ09XQe6ztMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/all.vue").then(m => m.default || m)
  },
  {
    name: allGJ09XQe6ztMeta?.name ?? "cn__zh-HK__all",
    path: allGJ09XQe6ztMeta?.path ?? "/cn/zh-HK/all",
    meta: {...(allGJ09XQe6ztMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"all"}},
    alias: allGJ09XQe6ztMeta?.alias || [],
    redirect: allGJ09XQe6ztMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/all.vue").then(m => m.default || m)
  },
  {
    name: allGJ09XQe6ztMeta?.name ?? "cn__zh-CN__all",
    path: allGJ09XQe6ztMeta?.path ?? "/cn/zh-CN/all",
    meta: {...(allGJ09XQe6ztMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"all"}},
    alias: allGJ09XQe6ztMeta?.alias || [],
    redirect: allGJ09XQe6ztMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/all.vue").then(m => m.default || m)
  },
  {
    name: allGJ09XQe6ztMeta?.name ?? "cn__en__all",
    path: allGJ09XQe6ztMeta?.path ?? "/cn/en/all",
    meta: {...(allGJ09XQe6ztMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"all"}},
    alias: allGJ09XQe6ztMeta?.alias || [],
    redirect: allGJ09XQe6ztMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/all.vue").then(m => m.default || m)
  },
  {
    name: _91code_93vI2Rm4D1NHMeta?.name ?? "hk__article-code",
    path: _91code_93vI2Rm4D1NHMeta?.path ?? "/hk/article/:code()",
    meta: {...(_91code_93vI2Rm4D1NHMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"article-code"}},
    alias: _91code_93vI2Rm4D1NHMeta?.alias || [],
    redirect: _91code_93vI2Rm4D1NHMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/article/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93vI2Rm4D1NHMeta?.name ?? "hk__zh-MO__article-code",
    path: _91code_93vI2Rm4D1NHMeta?.path ?? "/hk/zh-MO/article/:code()",
    meta: {...(_91code_93vI2Rm4D1NHMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"article-code"}},
    alias: _91code_93vI2Rm4D1NHMeta?.alias || [],
    redirect: _91code_93vI2Rm4D1NHMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/article/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93vI2Rm4D1NHMeta?.name ?? "article-code",
    path: _91code_93vI2Rm4D1NHMeta?.path ?? "/article/:code()",
    meta: {...(_91code_93vI2Rm4D1NHMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"article-code"}},
    alias: _91code_93vI2Rm4D1NHMeta?.alias || [],
    redirect: _91code_93vI2Rm4D1NHMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/article/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93vI2Rm4D1NHMeta?.name ?? "hk__zh-HK__article-code",
    path: _91code_93vI2Rm4D1NHMeta?.path ?? "/hk/zh-HK/article/:code()",
    meta: {...(_91code_93vI2Rm4D1NHMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"article-code"}},
    alias: _91code_93vI2Rm4D1NHMeta?.alias || [],
    redirect: _91code_93vI2Rm4D1NHMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/article/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93vI2Rm4D1NHMeta?.name ?? "hk__zh-CN__article-code",
    path: _91code_93vI2Rm4D1NHMeta?.path ?? "/hk/zh-CN/article/:code()",
    meta: {...(_91code_93vI2Rm4D1NHMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"article-code"}},
    alias: _91code_93vI2Rm4D1NHMeta?.alias || [],
    redirect: _91code_93vI2Rm4D1NHMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/article/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93vI2Rm4D1NHMeta?.name ?? "hk__en__article-code",
    path: _91code_93vI2Rm4D1NHMeta?.path ?? "/hk/en/article/:code()",
    meta: {...(_91code_93vI2Rm4D1NHMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"article-code"}},
    alias: _91code_93vI2Rm4D1NHMeta?.alias || [],
    redirect: _91code_93vI2Rm4D1NHMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/article/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93vI2Rm4D1NHMeta?.name ?? "mo__article-code",
    path: _91code_93vI2Rm4D1NHMeta?.path ?? "/mo/article/:code()",
    meta: {...(_91code_93vI2Rm4D1NHMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"article-code"}},
    alias: _91code_93vI2Rm4D1NHMeta?.alias || [],
    redirect: _91code_93vI2Rm4D1NHMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/article/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93vI2Rm4D1NHMeta?.name ?? "mo__zh-MO__article-code",
    path: _91code_93vI2Rm4D1NHMeta?.path ?? "/mo/zh-MO/article/:code()",
    meta: {...(_91code_93vI2Rm4D1NHMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"article-code"}},
    alias: _91code_93vI2Rm4D1NHMeta?.alias || [],
    redirect: _91code_93vI2Rm4D1NHMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/article/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93vI2Rm4D1NHMeta?.name ?? "mo__zh-HK__article-code",
    path: _91code_93vI2Rm4D1NHMeta?.path ?? "/mo/zh-HK/article/:code()",
    meta: {...(_91code_93vI2Rm4D1NHMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"article-code"}},
    alias: _91code_93vI2Rm4D1NHMeta?.alias || [],
    redirect: _91code_93vI2Rm4D1NHMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/article/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93vI2Rm4D1NHMeta?.name ?? "mo__zh-CN__article-code",
    path: _91code_93vI2Rm4D1NHMeta?.path ?? "/mo/zh-CN/article/:code()",
    meta: {...(_91code_93vI2Rm4D1NHMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"article-code"}},
    alias: _91code_93vI2Rm4D1NHMeta?.alias || [],
    redirect: _91code_93vI2Rm4D1NHMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/article/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93vI2Rm4D1NHMeta?.name ?? "mo__en__article-code",
    path: _91code_93vI2Rm4D1NHMeta?.path ?? "/mo/en/article/:code()",
    meta: {...(_91code_93vI2Rm4D1NHMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"article-code"}},
    alias: _91code_93vI2Rm4D1NHMeta?.alias || [],
    redirect: _91code_93vI2Rm4D1NHMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/article/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93vI2Rm4D1NHMeta?.name ?? "cn__article-code",
    path: _91code_93vI2Rm4D1NHMeta?.path ?? "/cn/article/:code()",
    meta: {...(_91code_93vI2Rm4D1NHMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"article-code"}},
    alias: _91code_93vI2Rm4D1NHMeta?.alias || [],
    redirect: _91code_93vI2Rm4D1NHMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/article/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93vI2Rm4D1NHMeta?.name ?? "cn__zh-MO__article-code",
    path: _91code_93vI2Rm4D1NHMeta?.path ?? "/cn/zh-MO/article/:code()",
    meta: {...(_91code_93vI2Rm4D1NHMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"article-code"}},
    alias: _91code_93vI2Rm4D1NHMeta?.alias || [],
    redirect: _91code_93vI2Rm4D1NHMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/article/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93vI2Rm4D1NHMeta?.name ?? "cn__zh-HK__article-code",
    path: _91code_93vI2Rm4D1NHMeta?.path ?? "/cn/zh-HK/article/:code()",
    meta: {...(_91code_93vI2Rm4D1NHMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"article-code"}},
    alias: _91code_93vI2Rm4D1NHMeta?.alias || [],
    redirect: _91code_93vI2Rm4D1NHMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/article/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93vI2Rm4D1NHMeta?.name ?? "cn__zh-CN__article-code",
    path: _91code_93vI2Rm4D1NHMeta?.path ?? "/cn/zh-CN/article/:code()",
    meta: {...(_91code_93vI2Rm4D1NHMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"article-code"}},
    alias: _91code_93vI2Rm4D1NHMeta?.alias || [],
    redirect: _91code_93vI2Rm4D1NHMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/article/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93vI2Rm4D1NHMeta?.name ?? "cn__en__article-code",
    path: _91code_93vI2Rm4D1NHMeta?.path ?? "/cn/en/article/:code()",
    meta: {...(_91code_93vI2Rm4D1NHMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"article-code"}},
    alias: _91code_93vI2Rm4D1NHMeta?.alias || [],
    redirect: _91code_93vI2Rm4D1NHMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/article/[code].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Vtc64trnOLMeta?.name ?? "hk__buy-id",
    path: _91id_93Vtc64trnOLMeta?.path ?? "/hk/buy/:id()",
    meta: {...(_91id_93Vtc64trnOLMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"buy-id"}},
    alias: _91id_93Vtc64trnOLMeta?.alias || [],
    redirect: _91id_93Vtc64trnOLMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/buy/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Vtc64trnOLMeta?.name ?? "hk__zh-MO__buy-id",
    path: _91id_93Vtc64trnOLMeta?.path ?? "/hk/zh-MO/buy/:id()",
    meta: {...(_91id_93Vtc64trnOLMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"buy-id"}},
    alias: _91id_93Vtc64trnOLMeta?.alias || [],
    redirect: _91id_93Vtc64trnOLMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/buy/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Vtc64trnOLMeta?.name ?? "buy-id",
    path: _91id_93Vtc64trnOLMeta?.path ?? "/buy/:id()",
    meta: {...(_91id_93Vtc64trnOLMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"buy-id"}},
    alias: _91id_93Vtc64trnOLMeta?.alias || [],
    redirect: _91id_93Vtc64trnOLMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/buy/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Vtc64trnOLMeta?.name ?? "hk__zh-HK__buy-id",
    path: _91id_93Vtc64trnOLMeta?.path ?? "/hk/zh-HK/buy/:id()",
    meta: {...(_91id_93Vtc64trnOLMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"buy-id"}},
    alias: _91id_93Vtc64trnOLMeta?.alias || [],
    redirect: _91id_93Vtc64trnOLMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/buy/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Vtc64trnOLMeta?.name ?? "hk__zh-CN__buy-id",
    path: _91id_93Vtc64trnOLMeta?.path ?? "/hk/zh-CN/buy/:id()",
    meta: {...(_91id_93Vtc64trnOLMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"buy-id"}},
    alias: _91id_93Vtc64trnOLMeta?.alias || [],
    redirect: _91id_93Vtc64trnOLMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/buy/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Vtc64trnOLMeta?.name ?? "hk__en__buy-id",
    path: _91id_93Vtc64trnOLMeta?.path ?? "/hk/en/buy/:id()",
    meta: {...(_91id_93Vtc64trnOLMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"buy-id"}},
    alias: _91id_93Vtc64trnOLMeta?.alias || [],
    redirect: _91id_93Vtc64trnOLMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/buy/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Vtc64trnOLMeta?.name ?? "mo__buy-id",
    path: _91id_93Vtc64trnOLMeta?.path ?? "/mo/buy/:id()",
    meta: {...(_91id_93Vtc64trnOLMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"buy-id"}},
    alias: _91id_93Vtc64trnOLMeta?.alias || [],
    redirect: _91id_93Vtc64trnOLMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/buy/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Vtc64trnOLMeta?.name ?? "mo__zh-MO__buy-id",
    path: _91id_93Vtc64trnOLMeta?.path ?? "/mo/zh-MO/buy/:id()",
    meta: {...(_91id_93Vtc64trnOLMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"buy-id"}},
    alias: _91id_93Vtc64trnOLMeta?.alias || [],
    redirect: _91id_93Vtc64trnOLMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/buy/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Vtc64trnOLMeta?.name ?? "mo__zh-HK__buy-id",
    path: _91id_93Vtc64trnOLMeta?.path ?? "/mo/zh-HK/buy/:id()",
    meta: {...(_91id_93Vtc64trnOLMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"buy-id"}},
    alias: _91id_93Vtc64trnOLMeta?.alias || [],
    redirect: _91id_93Vtc64trnOLMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/buy/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Vtc64trnOLMeta?.name ?? "mo__zh-CN__buy-id",
    path: _91id_93Vtc64trnOLMeta?.path ?? "/mo/zh-CN/buy/:id()",
    meta: {...(_91id_93Vtc64trnOLMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"buy-id"}},
    alias: _91id_93Vtc64trnOLMeta?.alias || [],
    redirect: _91id_93Vtc64trnOLMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/buy/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Vtc64trnOLMeta?.name ?? "mo__en__buy-id",
    path: _91id_93Vtc64trnOLMeta?.path ?? "/mo/en/buy/:id()",
    meta: {...(_91id_93Vtc64trnOLMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"buy-id"}},
    alias: _91id_93Vtc64trnOLMeta?.alias || [],
    redirect: _91id_93Vtc64trnOLMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/buy/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Vtc64trnOLMeta?.name ?? "cn__buy-id",
    path: _91id_93Vtc64trnOLMeta?.path ?? "/cn/buy/:id()",
    meta: {...(_91id_93Vtc64trnOLMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"buy-id"}},
    alias: _91id_93Vtc64trnOLMeta?.alias || [],
    redirect: _91id_93Vtc64trnOLMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/buy/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Vtc64trnOLMeta?.name ?? "cn__zh-MO__buy-id",
    path: _91id_93Vtc64trnOLMeta?.path ?? "/cn/zh-MO/buy/:id()",
    meta: {...(_91id_93Vtc64trnOLMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"buy-id"}},
    alias: _91id_93Vtc64trnOLMeta?.alias || [],
    redirect: _91id_93Vtc64trnOLMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/buy/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Vtc64trnOLMeta?.name ?? "cn__zh-HK__buy-id",
    path: _91id_93Vtc64trnOLMeta?.path ?? "/cn/zh-HK/buy/:id()",
    meta: {...(_91id_93Vtc64trnOLMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"buy-id"}},
    alias: _91id_93Vtc64trnOLMeta?.alias || [],
    redirect: _91id_93Vtc64trnOLMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/buy/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Vtc64trnOLMeta?.name ?? "cn__zh-CN__buy-id",
    path: _91id_93Vtc64trnOLMeta?.path ?? "/cn/zh-CN/buy/:id()",
    meta: {...(_91id_93Vtc64trnOLMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"buy-id"}},
    alias: _91id_93Vtc64trnOLMeta?.alias || [],
    redirect: _91id_93Vtc64trnOLMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/buy/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Vtc64trnOLMeta?.name ?? "cn__en__buy-id",
    path: _91id_93Vtc64trnOLMeta?.path ?? "/cn/en/buy/:id()",
    meta: {...(_91id_93Vtc64trnOLMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"buy-id"}},
    alias: _91id_93Vtc64trnOLMeta?.alias || [],
    redirect: _91id_93Vtc64trnOLMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/buy/[id].vue").then(m => m.default || m)
  },
  {
    name: indexNM0aHWYcFOMeta?.name ?? "hk__cabinet",
    path: indexNM0aHWYcFOMeta?.path ?? "/hk/cabinet",
    meta: {...(indexNM0aHWYcFOMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"cabinet"}},
    alias: indexNM0aHWYcFOMeta?.alias || [],
    redirect: indexNM0aHWYcFOMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: indexNM0aHWYcFOMeta?.name ?? "hk__zh-MO__cabinet",
    path: indexNM0aHWYcFOMeta?.path ?? "/hk/zh-MO/cabinet",
    meta: {...(indexNM0aHWYcFOMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"cabinet"}},
    alias: indexNM0aHWYcFOMeta?.alias || [],
    redirect: indexNM0aHWYcFOMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: indexNM0aHWYcFOMeta?.name ?? "cabinet",
    path: indexNM0aHWYcFOMeta?.path ?? "/cabinet",
    meta: {...(indexNM0aHWYcFOMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"cabinet"}},
    alias: indexNM0aHWYcFOMeta?.alias || [],
    redirect: indexNM0aHWYcFOMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: indexNM0aHWYcFOMeta?.name ?? "hk__zh-HK__cabinet",
    path: indexNM0aHWYcFOMeta?.path ?? "/hk/zh-HK/cabinet",
    meta: {...(indexNM0aHWYcFOMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"cabinet"}},
    alias: indexNM0aHWYcFOMeta?.alias || [],
    redirect: indexNM0aHWYcFOMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: indexNM0aHWYcFOMeta?.name ?? "hk__zh-CN__cabinet",
    path: indexNM0aHWYcFOMeta?.path ?? "/hk/zh-CN/cabinet",
    meta: {...(indexNM0aHWYcFOMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"cabinet"}},
    alias: indexNM0aHWYcFOMeta?.alias || [],
    redirect: indexNM0aHWYcFOMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: indexNM0aHWYcFOMeta?.name ?? "hk__en__cabinet",
    path: indexNM0aHWYcFOMeta?.path ?? "/hk/en/cabinet",
    meta: {...(indexNM0aHWYcFOMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"cabinet"}},
    alias: indexNM0aHWYcFOMeta?.alias || [],
    redirect: indexNM0aHWYcFOMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: indexNM0aHWYcFOMeta?.name ?? "mo__cabinet",
    path: indexNM0aHWYcFOMeta?.path ?? "/mo/cabinet",
    meta: {...(indexNM0aHWYcFOMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"cabinet"}},
    alias: indexNM0aHWYcFOMeta?.alias || [],
    redirect: indexNM0aHWYcFOMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: indexNM0aHWYcFOMeta?.name ?? "mo__zh-MO__cabinet",
    path: indexNM0aHWYcFOMeta?.path ?? "/mo/zh-MO/cabinet",
    meta: {...(indexNM0aHWYcFOMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"cabinet"}},
    alias: indexNM0aHWYcFOMeta?.alias || [],
    redirect: indexNM0aHWYcFOMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: indexNM0aHWYcFOMeta?.name ?? "mo__zh-HK__cabinet",
    path: indexNM0aHWYcFOMeta?.path ?? "/mo/zh-HK/cabinet",
    meta: {...(indexNM0aHWYcFOMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"cabinet"}},
    alias: indexNM0aHWYcFOMeta?.alias || [],
    redirect: indexNM0aHWYcFOMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: indexNM0aHWYcFOMeta?.name ?? "mo__zh-CN__cabinet",
    path: indexNM0aHWYcFOMeta?.path ?? "/mo/zh-CN/cabinet",
    meta: {...(indexNM0aHWYcFOMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"cabinet"}},
    alias: indexNM0aHWYcFOMeta?.alias || [],
    redirect: indexNM0aHWYcFOMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: indexNM0aHWYcFOMeta?.name ?? "mo__en__cabinet",
    path: indexNM0aHWYcFOMeta?.path ?? "/mo/en/cabinet",
    meta: {...(indexNM0aHWYcFOMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"cabinet"}},
    alias: indexNM0aHWYcFOMeta?.alias || [],
    redirect: indexNM0aHWYcFOMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: indexNM0aHWYcFOMeta?.name ?? "cn__cabinet",
    path: indexNM0aHWYcFOMeta?.path ?? "/cn/cabinet",
    meta: {...(indexNM0aHWYcFOMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"cabinet"}},
    alias: indexNM0aHWYcFOMeta?.alias || [],
    redirect: indexNM0aHWYcFOMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: indexNM0aHWYcFOMeta?.name ?? "cn__zh-MO__cabinet",
    path: indexNM0aHWYcFOMeta?.path ?? "/cn/zh-MO/cabinet",
    meta: {...(indexNM0aHWYcFOMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"cabinet"}},
    alias: indexNM0aHWYcFOMeta?.alias || [],
    redirect: indexNM0aHWYcFOMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: indexNM0aHWYcFOMeta?.name ?? "cn__zh-HK__cabinet",
    path: indexNM0aHWYcFOMeta?.path ?? "/cn/zh-HK/cabinet",
    meta: {...(indexNM0aHWYcFOMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"cabinet"}},
    alias: indexNM0aHWYcFOMeta?.alias || [],
    redirect: indexNM0aHWYcFOMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: indexNM0aHWYcFOMeta?.name ?? "cn__zh-CN__cabinet",
    path: indexNM0aHWYcFOMeta?.path ?? "/cn/zh-CN/cabinet",
    meta: {...(indexNM0aHWYcFOMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"cabinet"}},
    alias: indexNM0aHWYcFOMeta?.alias || [],
    redirect: indexNM0aHWYcFOMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: indexNM0aHWYcFOMeta?.name ?? "cn__en__cabinet",
    path: indexNM0aHWYcFOMeta?.path ?? "/cn/en/cabinet",
    meta: {...(indexNM0aHWYcFOMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"cabinet"}},
    alias: indexNM0aHWYcFOMeta?.alias || [],
    redirect: indexNM0aHWYcFOMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: indexqJfXaDXFcTMeta?.name ?? "hk__cabinet-search",
    path: indexqJfXaDXFcTMeta?.path ?? "/hk/cabinet/search",
    meta: {...(indexqJfXaDXFcTMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"cabinet-search"}},
    alias: indexqJfXaDXFcTMeta?.alias || [],
    redirect: indexqJfXaDXFcTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexqJfXaDXFcTMeta?.name ?? "hk__zh-MO__cabinet-search",
    path: indexqJfXaDXFcTMeta?.path ?? "/hk/zh-MO/cabinet/search",
    meta: {...(indexqJfXaDXFcTMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"cabinet-search"}},
    alias: indexqJfXaDXFcTMeta?.alias || [],
    redirect: indexqJfXaDXFcTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexqJfXaDXFcTMeta?.name ?? "cabinet-search",
    path: indexqJfXaDXFcTMeta?.path ?? "/cabinet/search",
    meta: {...(indexqJfXaDXFcTMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"cabinet-search"}},
    alias: indexqJfXaDXFcTMeta?.alias || [],
    redirect: indexqJfXaDXFcTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexqJfXaDXFcTMeta?.name ?? "hk__zh-HK__cabinet-search",
    path: indexqJfXaDXFcTMeta?.path ?? "/hk/zh-HK/cabinet/search",
    meta: {...(indexqJfXaDXFcTMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"cabinet-search"}},
    alias: indexqJfXaDXFcTMeta?.alias || [],
    redirect: indexqJfXaDXFcTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexqJfXaDXFcTMeta?.name ?? "hk__zh-CN__cabinet-search",
    path: indexqJfXaDXFcTMeta?.path ?? "/hk/zh-CN/cabinet/search",
    meta: {...(indexqJfXaDXFcTMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"cabinet-search"}},
    alias: indexqJfXaDXFcTMeta?.alias || [],
    redirect: indexqJfXaDXFcTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexqJfXaDXFcTMeta?.name ?? "hk__en__cabinet-search",
    path: indexqJfXaDXFcTMeta?.path ?? "/hk/en/cabinet/search",
    meta: {...(indexqJfXaDXFcTMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"cabinet-search"}},
    alias: indexqJfXaDXFcTMeta?.alias || [],
    redirect: indexqJfXaDXFcTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexqJfXaDXFcTMeta?.name ?? "mo__cabinet-search",
    path: indexqJfXaDXFcTMeta?.path ?? "/mo/cabinet/search",
    meta: {...(indexqJfXaDXFcTMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"cabinet-search"}},
    alias: indexqJfXaDXFcTMeta?.alias || [],
    redirect: indexqJfXaDXFcTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexqJfXaDXFcTMeta?.name ?? "mo__zh-MO__cabinet-search",
    path: indexqJfXaDXFcTMeta?.path ?? "/mo/zh-MO/cabinet/search",
    meta: {...(indexqJfXaDXFcTMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"cabinet-search"}},
    alias: indexqJfXaDXFcTMeta?.alias || [],
    redirect: indexqJfXaDXFcTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexqJfXaDXFcTMeta?.name ?? "mo__zh-HK__cabinet-search",
    path: indexqJfXaDXFcTMeta?.path ?? "/mo/zh-HK/cabinet/search",
    meta: {...(indexqJfXaDXFcTMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"cabinet-search"}},
    alias: indexqJfXaDXFcTMeta?.alias || [],
    redirect: indexqJfXaDXFcTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexqJfXaDXFcTMeta?.name ?? "mo__zh-CN__cabinet-search",
    path: indexqJfXaDXFcTMeta?.path ?? "/mo/zh-CN/cabinet/search",
    meta: {...(indexqJfXaDXFcTMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"cabinet-search"}},
    alias: indexqJfXaDXFcTMeta?.alias || [],
    redirect: indexqJfXaDXFcTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexqJfXaDXFcTMeta?.name ?? "mo__en__cabinet-search",
    path: indexqJfXaDXFcTMeta?.path ?? "/mo/en/cabinet/search",
    meta: {...(indexqJfXaDXFcTMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"cabinet-search"}},
    alias: indexqJfXaDXFcTMeta?.alias || [],
    redirect: indexqJfXaDXFcTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexqJfXaDXFcTMeta?.name ?? "cn__cabinet-search",
    path: indexqJfXaDXFcTMeta?.path ?? "/cn/cabinet/search",
    meta: {...(indexqJfXaDXFcTMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"cabinet-search"}},
    alias: indexqJfXaDXFcTMeta?.alias || [],
    redirect: indexqJfXaDXFcTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexqJfXaDXFcTMeta?.name ?? "cn__zh-MO__cabinet-search",
    path: indexqJfXaDXFcTMeta?.path ?? "/cn/zh-MO/cabinet/search",
    meta: {...(indexqJfXaDXFcTMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"cabinet-search"}},
    alias: indexqJfXaDXFcTMeta?.alias || [],
    redirect: indexqJfXaDXFcTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexqJfXaDXFcTMeta?.name ?? "cn__zh-HK__cabinet-search",
    path: indexqJfXaDXFcTMeta?.path ?? "/cn/zh-HK/cabinet/search",
    meta: {...(indexqJfXaDXFcTMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"cabinet-search"}},
    alias: indexqJfXaDXFcTMeta?.alias || [],
    redirect: indexqJfXaDXFcTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexqJfXaDXFcTMeta?.name ?? "cn__zh-CN__cabinet-search",
    path: indexqJfXaDXFcTMeta?.path ?? "/cn/zh-CN/cabinet/search",
    meta: {...(indexqJfXaDXFcTMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"cabinet-search"}},
    alias: indexqJfXaDXFcTMeta?.alias || [],
    redirect: indexqJfXaDXFcTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexqJfXaDXFcTMeta?.name ?? "cn__en__cabinet-search",
    path: indexqJfXaDXFcTMeta?.path ?? "/cn/en/cabinet/search",
    meta: {...(indexqJfXaDXFcTMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"cabinet-search"}},
    alias: indexqJfXaDXFcTMeta?.alias || [],
    redirect: indexqJfXaDXFcTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexULAEgcreFuMeta?.name ?? "hk__cabinet-select",
    path: indexULAEgcreFuMeta?.path ?? "/hk/cabinet/select",
    meta: {...(indexULAEgcreFuMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"cabinet-select"}},
    alias: indexULAEgcreFuMeta?.alias || [],
    redirect: indexULAEgcreFuMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/select/index.vue").then(m => m.default || m)
  },
  {
    name: indexULAEgcreFuMeta?.name ?? "hk__zh-MO__cabinet-select",
    path: indexULAEgcreFuMeta?.path ?? "/hk/zh-MO/cabinet/select",
    meta: {...(indexULAEgcreFuMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"cabinet-select"}},
    alias: indexULAEgcreFuMeta?.alias || [],
    redirect: indexULAEgcreFuMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/select/index.vue").then(m => m.default || m)
  },
  {
    name: indexULAEgcreFuMeta?.name ?? "cabinet-select",
    path: indexULAEgcreFuMeta?.path ?? "/cabinet/select",
    meta: {...(indexULAEgcreFuMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"cabinet-select"}},
    alias: indexULAEgcreFuMeta?.alias || [],
    redirect: indexULAEgcreFuMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/select/index.vue").then(m => m.default || m)
  },
  {
    name: indexULAEgcreFuMeta?.name ?? "hk__zh-HK__cabinet-select",
    path: indexULAEgcreFuMeta?.path ?? "/hk/zh-HK/cabinet/select",
    meta: {...(indexULAEgcreFuMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"cabinet-select"}},
    alias: indexULAEgcreFuMeta?.alias || [],
    redirect: indexULAEgcreFuMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/select/index.vue").then(m => m.default || m)
  },
  {
    name: indexULAEgcreFuMeta?.name ?? "hk__zh-CN__cabinet-select",
    path: indexULAEgcreFuMeta?.path ?? "/hk/zh-CN/cabinet/select",
    meta: {...(indexULAEgcreFuMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"cabinet-select"}},
    alias: indexULAEgcreFuMeta?.alias || [],
    redirect: indexULAEgcreFuMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/select/index.vue").then(m => m.default || m)
  },
  {
    name: indexULAEgcreFuMeta?.name ?? "hk__en__cabinet-select",
    path: indexULAEgcreFuMeta?.path ?? "/hk/en/cabinet/select",
    meta: {...(indexULAEgcreFuMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"cabinet-select"}},
    alias: indexULAEgcreFuMeta?.alias || [],
    redirect: indexULAEgcreFuMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/select/index.vue").then(m => m.default || m)
  },
  {
    name: indexULAEgcreFuMeta?.name ?? "mo__cabinet-select",
    path: indexULAEgcreFuMeta?.path ?? "/mo/cabinet/select",
    meta: {...(indexULAEgcreFuMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"cabinet-select"}},
    alias: indexULAEgcreFuMeta?.alias || [],
    redirect: indexULAEgcreFuMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/select/index.vue").then(m => m.default || m)
  },
  {
    name: indexULAEgcreFuMeta?.name ?? "mo__zh-MO__cabinet-select",
    path: indexULAEgcreFuMeta?.path ?? "/mo/zh-MO/cabinet/select",
    meta: {...(indexULAEgcreFuMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"cabinet-select"}},
    alias: indexULAEgcreFuMeta?.alias || [],
    redirect: indexULAEgcreFuMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/select/index.vue").then(m => m.default || m)
  },
  {
    name: indexULAEgcreFuMeta?.name ?? "mo__zh-HK__cabinet-select",
    path: indexULAEgcreFuMeta?.path ?? "/mo/zh-HK/cabinet/select",
    meta: {...(indexULAEgcreFuMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"cabinet-select"}},
    alias: indexULAEgcreFuMeta?.alias || [],
    redirect: indexULAEgcreFuMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/select/index.vue").then(m => m.default || m)
  },
  {
    name: indexULAEgcreFuMeta?.name ?? "mo__zh-CN__cabinet-select",
    path: indexULAEgcreFuMeta?.path ?? "/mo/zh-CN/cabinet/select",
    meta: {...(indexULAEgcreFuMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"cabinet-select"}},
    alias: indexULAEgcreFuMeta?.alias || [],
    redirect: indexULAEgcreFuMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/select/index.vue").then(m => m.default || m)
  },
  {
    name: indexULAEgcreFuMeta?.name ?? "mo__en__cabinet-select",
    path: indexULAEgcreFuMeta?.path ?? "/mo/en/cabinet/select",
    meta: {...(indexULAEgcreFuMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"cabinet-select"}},
    alias: indexULAEgcreFuMeta?.alias || [],
    redirect: indexULAEgcreFuMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/select/index.vue").then(m => m.default || m)
  },
  {
    name: indexULAEgcreFuMeta?.name ?? "cn__cabinet-select",
    path: indexULAEgcreFuMeta?.path ?? "/cn/cabinet/select",
    meta: {...(indexULAEgcreFuMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"cabinet-select"}},
    alias: indexULAEgcreFuMeta?.alias || [],
    redirect: indexULAEgcreFuMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/select/index.vue").then(m => m.default || m)
  },
  {
    name: indexULAEgcreFuMeta?.name ?? "cn__zh-MO__cabinet-select",
    path: indexULAEgcreFuMeta?.path ?? "/cn/zh-MO/cabinet/select",
    meta: {...(indexULAEgcreFuMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"cabinet-select"}},
    alias: indexULAEgcreFuMeta?.alias || [],
    redirect: indexULAEgcreFuMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/select/index.vue").then(m => m.default || m)
  },
  {
    name: indexULAEgcreFuMeta?.name ?? "cn__zh-HK__cabinet-select",
    path: indexULAEgcreFuMeta?.path ?? "/cn/zh-HK/cabinet/select",
    meta: {...(indexULAEgcreFuMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"cabinet-select"}},
    alias: indexULAEgcreFuMeta?.alias || [],
    redirect: indexULAEgcreFuMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/select/index.vue").then(m => m.default || m)
  },
  {
    name: indexULAEgcreFuMeta?.name ?? "cn__zh-CN__cabinet-select",
    path: indexULAEgcreFuMeta?.path ?? "/cn/zh-CN/cabinet/select",
    meta: {...(indexULAEgcreFuMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"cabinet-select"}},
    alias: indexULAEgcreFuMeta?.alias || [],
    redirect: indexULAEgcreFuMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/select/index.vue").then(m => m.default || m)
  },
  {
    name: indexULAEgcreFuMeta?.name ?? "cn__en__cabinet-select",
    path: indexULAEgcreFuMeta?.path ?? "/cn/en/cabinet/select",
    meta: {...(indexULAEgcreFuMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"cabinet-select"}},
    alias: indexULAEgcreFuMeta?.alias || [],
    redirect: indexULAEgcreFuMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cabinet/select/index.vue").then(m => m.default || m)
  },
  {
    name: cartdYOH1LH6Q1Meta?.name ?? "hk__cart",
    path: cartdYOH1LH6Q1Meta?.path ?? "/hk/cart",
    meta: {...(cartdYOH1LH6Q1Meta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"cart"}},
    alias: cartdYOH1LH6Q1Meta?.alias || [],
    redirect: cartdYOH1LH6Q1Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: cartdYOH1LH6Q1Meta?.name ?? "hk__zh-MO__cart",
    path: cartdYOH1LH6Q1Meta?.path ?? "/hk/zh-MO/cart",
    meta: {...(cartdYOH1LH6Q1Meta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"cart"}},
    alias: cartdYOH1LH6Q1Meta?.alias || [],
    redirect: cartdYOH1LH6Q1Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: cartdYOH1LH6Q1Meta?.name ?? "cart",
    path: cartdYOH1LH6Q1Meta?.path ?? "/cart",
    meta: {...(cartdYOH1LH6Q1Meta || {}), ...{"siteCode":"","i18nCode":"","truePath":"cart"}},
    alias: cartdYOH1LH6Q1Meta?.alias || [],
    redirect: cartdYOH1LH6Q1Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: cartdYOH1LH6Q1Meta?.name ?? "hk__zh-HK__cart",
    path: cartdYOH1LH6Q1Meta?.path ?? "/hk/zh-HK/cart",
    meta: {...(cartdYOH1LH6Q1Meta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"cart"}},
    alias: cartdYOH1LH6Q1Meta?.alias || [],
    redirect: cartdYOH1LH6Q1Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: cartdYOH1LH6Q1Meta?.name ?? "hk__zh-CN__cart",
    path: cartdYOH1LH6Q1Meta?.path ?? "/hk/zh-CN/cart",
    meta: {...(cartdYOH1LH6Q1Meta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"cart"}},
    alias: cartdYOH1LH6Q1Meta?.alias || [],
    redirect: cartdYOH1LH6Q1Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: cartdYOH1LH6Q1Meta?.name ?? "hk__en__cart",
    path: cartdYOH1LH6Q1Meta?.path ?? "/hk/en/cart",
    meta: {...(cartdYOH1LH6Q1Meta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"cart"}},
    alias: cartdYOH1LH6Q1Meta?.alias || [],
    redirect: cartdYOH1LH6Q1Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: cartdYOH1LH6Q1Meta?.name ?? "mo__cart",
    path: cartdYOH1LH6Q1Meta?.path ?? "/mo/cart",
    meta: {...(cartdYOH1LH6Q1Meta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"cart"}},
    alias: cartdYOH1LH6Q1Meta?.alias || [],
    redirect: cartdYOH1LH6Q1Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: cartdYOH1LH6Q1Meta?.name ?? "mo__zh-MO__cart",
    path: cartdYOH1LH6Q1Meta?.path ?? "/mo/zh-MO/cart",
    meta: {...(cartdYOH1LH6Q1Meta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"cart"}},
    alias: cartdYOH1LH6Q1Meta?.alias || [],
    redirect: cartdYOH1LH6Q1Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: cartdYOH1LH6Q1Meta?.name ?? "mo__zh-HK__cart",
    path: cartdYOH1LH6Q1Meta?.path ?? "/mo/zh-HK/cart",
    meta: {...(cartdYOH1LH6Q1Meta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"cart"}},
    alias: cartdYOH1LH6Q1Meta?.alias || [],
    redirect: cartdYOH1LH6Q1Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: cartdYOH1LH6Q1Meta?.name ?? "mo__zh-CN__cart",
    path: cartdYOH1LH6Q1Meta?.path ?? "/mo/zh-CN/cart",
    meta: {...(cartdYOH1LH6Q1Meta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"cart"}},
    alias: cartdYOH1LH6Q1Meta?.alias || [],
    redirect: cartdYOH1LH6Q1Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: cartdYOH1LH6Q1Meta?.name ?? "mo__en__cart",
    path: cartdYOH1LH6Q1Meta?.path ?? "/mo/en/cart",
    meta: {...(cartdYOH1LH6Q1Meta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"cart"}},
    alias: cartdYOH1LH6Q1Meta?.alias || [],
    redirect: cartdYOH1LH6Q1Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: cartdYOH1LH6Q1Meta?.name ?? "cn__cart",
    path: cartdYOH1LH6Q1Meta?.path ?? "/cn/cart",
    meta: {...(cartdYOH1LH6Q1Meta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"cart"}},
    alias: cartdYOH1LH6Q1Meta?.alias || [],
    redirect: cartdYOH1LH6Q1Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: cartdYOH1LH6Q1Meta?.name ?? "cn__zh-MO__cart",
    path: cartdYOH1LH6Q1Meta?.path ?? "/cn/zh-MO/cart",
    meta: {...(cartdYOH1LH6Q1Meta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"cart"}},
    alias: cartdYOH1LH6Q1Meta?.alias || [],
    redirect: cartdYOH1LH6Q1Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: cartdYOH1LH6Q1Meta?.name ?? "cn__zh-HK__cart",
    path: cartdYOH1LH6Q1Meta?.path ?? "/cn/zh-HK/cart",
    meta: {...(cartdYOH1LH6Q1Meta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"cart"}},
    alias: cartdYOH1LH6Q1Meta?.alias || [],
    redirect: cartdYOH1LH6Q1Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: cartdYOH1LH6Q1Meta?.name ?? "cn__zh-CN__cart",
    path: cartdYOH1LH6Q1Meta?.path ?? "/cn/zh-CN/cart",
    meta: {...(cartdYOH1LH6Q1Meta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"cart"}},
    alias: cartdYOH1LH6Q1Meta?.alias || [],
    redirect: cartdYOH1LH6Q1Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: cartdYOH1LH6Q1Meta?.name ?? "cn__en__cart",
    path: cartdYOH1LH6Q1Meta?.path ?? "/cn/en/cart",
    meta: {...(cartdYOH1LH6Q1Meta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"cart"}},
    alias: cartdYOH1LH6Q1Meta?.alias || [],
    redirect: cartdYOH1LH6Q1Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: indexvTKgzPa6nXMeta?.name ?? "hk__category",
    path: indexvTKgzPa6nXMeta?.path ?? "/hk/category",
    meta: {...(indexvTKgzPa6nXMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"category"}},
    alias: indexvTKgzPa6nXMeta?.alias || [],
    redirect: indexvTKgzPa6nXMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: indexvTKgzPa6nXMeta?.name ?? "hk__zh-MO__category",
    path: indexvTKgzPa6nXMeta?.path ?? "/hk/zh-MO/category",
    meta: {...(indexvTKgzPa6nXMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"category"}},
    alias: indexvTKgzPa6nXMeta?.alias || [],
    redirect: indexvTKgzPa6nXMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: indexvTKgzPa6nXMeta?.name ?? "category",
    path: indexvTKgzPa6nXMeta?.path ?? "/category",
    meta: {...(indexvTKgzPa6nXMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"category"}},
    alias: indexvTKgzPa6nXMeta?.alias || [],
    redirect: indexvTKgzPa6nXMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: indexvTKgzPa6nXMeta?.name ?? "hk__zh-HK__category",
    path: indexvTKgzPa6nXMeta?.path ?? "/hk/zh-HK/category",
    meta: {...(indexvTKgzPa6nXMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"category"}},
    alias: indexvTKgzPa6nXMeta?.alias || [],
    redirect: indexvTKgzPa6nXMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: indexvTKgzPa6nXMeta?.name ?? "hk__zh-CN__category",
    path: indexvTKgzPa6nXMeta?.path ?? "/hk/zh-CN/category",
    meta: {...(indexvTKgzPa6nXMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"category"}},
    alias: indexvTKgzPa6nXMeta?.alias || [],
    redirect: indexvTKgzPa6nXMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: indexvTKgzPa6nXMeta?.name ?? "hk__en__category",
    path: indexvTKgzPa6nXMeta?.path ?? "/hk/en/category",
    meta: {...(indexvTKgzPa6nXMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"category"}},
    alias: indexvTKgzPa6nXMeta?.alias || [],
    redirect: indexvTKgzPa6nXMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: indexvTKgzPa6nXMeta?.name ?? "mo__category",
    path: indexvTKgzPa6nXMeta?.path ?? "/mo/category",
    meta: {...(indexvTKgzPa6nXMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"category"}},
    alias: indexvTKgzPa6nXMeta?.alias || [],
    redirect: indexvTKgzPa6nXMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: indexvTKgzPa6nXMeta?.name ?? "mo__zh-MO__category",
    path: indexvTKgzPa6nXMeta?.path ?? "/mo/zh-MO/category",
    meta: {...(indexvTKgzPa6nXMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"category"}},
    alias: indexvTKgzPa6nXMeta?.alias || [],
    redirect: indexvTKgzPa6nXMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: indexvTKgzPa6nXMeta?.name ?? "mo__zh-HK__category",
    path: indexvTKgzPa6nXMeta?.path ?? "/mo/zh-HK/category",
    meta: {...(indexvTKgzPa6nXMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"category"}},
    alias: indexvTKgzPa6nXMeta?.alias || [],
    redirect: indexvTKgzPa6nXMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: indexvTKgzPa6nXMeta?.name ?? "mo__zh-CN__category",
    path: indexvTKgzPa6nXMeta?.path ?? "/mo/zh-CN/category",
    meta: {...(indexvTKgzPa6nXMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"category"}},
    alias: indexvTKgzPa6nXMeta?.alias || [],
    redirect: indexvTKgzPa6nXMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: indexvTKgzPa6nXMeta?.name ?? "mo__en__category",
    path: indexvTKgzPa6nXMeta?.path ?? "/mo/en/category",
    meta: {...(indexvTKgzPa6nXMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"category"}},
    alias: indexvTKgzPa6nXMeta?.alias || [],
    redirect: indexvTKgzPa6nXMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: indexvTKgzPa6nXMeta?.name ?? "cn__category",
    path: indexvTKgzPa6nXMeta?.path ?? "/cn/category",
    meta: {...(indexvTKgzPa6nXMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"category"}},
    alias: indexvTKgzPa6nXMeta?.alias || [],
    redirect: indexvTKgzPa6nXMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: indexvTKgzPa6nXMeta?.name ?? "cn__zh-MO__category",
    path: indexvTKgzPa6nXMeta?.path ?? "/cn/zh-MO/category",
    meta: {...(indexvTKgzPa6nXMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"category"}},
    alias: indexvTKgzPa6nXMeta?.alias || [],
    redirect: indexvTKgzPa6nXMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: indexvTKgzPa6nXMeta?.name ?? "cn__zh-HK__category",
    path: indexvTKgzPa6nXMeta?.path ?? "/cn/zh-HK/category",
    meta: {...(indexvTKgzPa6nXMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"category"}},
    alias: indexvTKgzPa6nXMeta?.alias || [],
    redirect: indexvTKgzPa6nXMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: indexvTKgzPa6nXMeta?.name ?? "cn__zh-CN__category",
    path: indexvTKgzPa6nXMeta?.path ?? "/cn/zh-CN/category",
    meta: {...(indexvTKgzPa6nXMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"category"}},
    alias: indexvTKgzPa6nXMeta?.alias || [],
    redirect: indexvTKgzPa6nXMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: indexvTKgzPa6nXMeta?.name ?? "cn__en__category",
    path: indexvTKgzPa6nXMeta?.path ?? "/cn/en/category",
    meta: {...(indexvTKgzPa6nXMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"category"}},
    alias: indexvTKgzPa6nXMeta?.alias || [],
    redirect: indexvTKgzPa6nXMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: chatc1C17krJFBMeta?.name ?? "hk__chat",
    path: chatc1C17krJFBMeta?.path ?? "/hk/chat",
    meta: {...(chatc1C17krJFBMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"chat"}},
    alias: chatc1C17krJFBMeta?.alias || [],
    redirect: chatc1C17krJFBMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/chat.vue").then(m => m.default || m)
  },
  {
    name: chatc1C17krJFBMeta?.name ?? "hk__zh-MO__chat",
    path: chatc1C17krJFBMeta?.path ?? "/hk/zh-MO/chat",
    meta: {...(chatc1C17krJFBMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"chat"}},
    alias: chatc1C17krJFBMeta?.alias || [],
    redirect: chatc1C17krJFBMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/chat.vue").then(m => m.default || m)
  },
  {
    name: chatc1C17krJFBMeta?.name ?? "chat",
    path: chatc1C17krJFBMeta?.path ?? "/chat",
    meta: {...(chatc1C17krJFBMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"chat"}},
    alias: chatc1C17krJFBMeta?.alias || [],
    redirect: chatc1C17krJFBMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/chat.vue").then(m => m.default || m)
  },
  {
    name: chatc1C17krJFBMeta?.name ?? "hk__zh-HK__chat",
    path: chatc1C17krJFBMeta?.path ?? "/hk/zh-HK/chat",
    meta: {...(chatc1C17krJFBMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"chat"}},
    alias: chatc1C17krJFBMeta?.alias || [],
    redirect: chatc1C17krJFBMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/chat.vue").then(m => m.default || m)
  },
  {
    name: chatc1C17krJFBMeta?.name ?? "hk__zh-CN__chat",
    path: chatc1C17krJFBMeta?.path ?? "/hk/zh-CN/chat",
    meta: {...(chatc1C17krJFBMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"chat"}},
    alias: chatc1C17krJFBMeta?.alias || [],
    redirect: chatc1C17krJFBMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/chat.vue").then(m => m.default || m)
  },
  {
    name: chatc1C17krJFBMeta?.name ?? "hk__en__chat",
    path: chatc1C17krJFBMeta?.path ?? "/hk/en/chat",
    meta: {...(chatc1C17krJFBMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"chat"}},
    alias: chatc1C17krJFBMeta?.alias || [],
    redirect: chatc1C17krJFBMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/chat.vue").then(m => m.default || m)
  },
  {
    name: chatc1C17krJFBMeta?.name ?? "mo__chat",
    path: chatc1C17krJFBMeta?.path ?? "/mo/chat",
    meta: {...(chatc1C17krJFBMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"chat"}},
    alias: chatc1C17krJFBMeta?.alias || [],
    redirect: chatc1C17krJFBMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/chat.vue").then(m => m.default || m)
  },
  {
    name: chatc1C17krJFBMeta?.name ?? "mo__zh-MO__chat",
    path: chatc1C17krJFBMeta?.path ?? "/mo/zh-MO/chat",
    meta: {...(chatc1C17krJFBMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"chat"}},
    alias: chatc1C17krJFBMeta?.alias || [],
    redirect: chatc1C17krJFBMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/chat.vue").then(m => m.default || m)
  },
  {
    name: chatc1C17krJFBMeta?.name ?? "mo__zh-HK__chat",
    path: chatc1C17krJFBMeta?.path ?? "/mo/zh-HK/chat",
    meta: {...(chatc1C17krJFBMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"chat"}},
    alias: chatc1C17krJFBMeta?.alias || [],
    redirect: chatc1C17krJFBMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/chat.vue").then(m => m.default || m)
  },
  {
    name: chatc1C17krJFBMeta?.name ?? "mo__zh-CN__chat",
    path: chatc1C17krJFBMeta?.path ?? "/mo/zh-CN/chat",
    meta: {...(chatc1C17krJFBMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"chat"}},
    alias: chatc1C17krJFBMeta?.alias || [],
    redirect: chatc1C17krJFBMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/chat.vue").then(m => m.default || m)
  },
  {
    name: chatc1C17krJFBMeta?.name ?? "mo__en__chat",
    path: chatc1C17krJFBMeta?.path ?? "/mo/en/chat",
    meta: {...(chatc1C17krJFBMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"chat"}},
    alias: chatc1C17krJFBMeta?.alias || [],
    redirect: chatc1C17krJFBMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/chat.vue").then(m => m.default || m)
  },
  {
    name: chatc1C17krJFBMeta?.name ?? "cn__chat",
    path: chatc1C17krJFBMeta?.path ?? "/cn/chat",
    meta: {...(chatc1C17krJFBMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"chat"}},
    alias: chatc1C17krJFBMeta?.alias || [],
    redirect: chatc1C17krJFBMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/chat.vue").then(m => m.default || m)
  },
  {
    name: chatc1C17krJFBMeta?.name ?? "cn__zh-MO__chat",
    path: chatc1C17krJFBMeta?.path ?? "/cn/zh-MO/chat",
    meta: {...(chatc1C17krJFBMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"chat"}},
    alias: chatc1C17krJFBMeta?.alias || [],
    redirect: chatc1C17krJFBMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/chat.vue").then(m => m.default || m)
  },
  {
    name: chatc1C17krJFBMeta?.name ?? "cn__zh-HK__chat",
    path: chatc1C17krJFBMeta?.path ?? "/cn/zh-HK/chat",
    meta: {...(chatc1C17krJFBMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"chat"}},
    alias: chatc1C17krJFBMeta?.alias || [],
    redirect: chatc1C17krJFBMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/chat.vue").then(m => m.default || m)
  },
  {
    name: chatc1C17krJFBMeta?.name ?? "cn__zh-CN__chat",
    path: chatc1C17krJFBMeta?.path ?? "/cn/zh-CN/chat",
    meta: {...(chatc1C17krJFBMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"chat"}},
    alias: chatc1C17krJFBMeta?.alias || [],
    redirect: chatc1C17krJFBMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/chat.vue").then(m => m.default || m)
  },
  {
    name: chatc1C17krJFBMeta?.name ?? "cn__en__chat",
    path: chatc1C17krJFBMeta?.path ?? "/cn/en/chat",
    meta: {...(chatc1C17krJFBMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"chat"}},
    alias: chatc1C17krJFBMeta?.alias || [],
    redirect: chatc1C17krJFBMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/chat.vue").then(m => m.default || m)
  },
  {
    name: collectgpoJze0p4yMeta?.name ?? "hk__collect",
    path: collectgpoJze0p4yMeta?.path ?? "/hk/collect",
    meta: {...(collectgpoJze0p4yMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"collect"}},
    alias: collectgpoJze0p4yMeta?.alias || [],
    redirect: collectgpoJze0p4yMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/collect.vue").then(m => m.default || m)
  },
  {
    name: collectgpoJze0p4yMeta?.name ?? "hk__zh-MO__collect",
    path: collectgpoJze0p4yMeta?.path ?? "/hk/zh-MO/collect",
    meta: {...(collectgpoJze0p4yMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"collect"}},
    alias: collectgpoJze0p4yMeta?.alias || [],
    redirect: collectgpoJze0p4yMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/collect.vue").then(m => m.default || m)
  },
  {
    name: collectgpoJze0p4yMeta?.name ?? "collect",
    path: collectgpoJze0p4yMeta?.path ?? "/collect",
    meta: {...(collectgpoJze0p4yMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"collect"}},
    alias: collectgpoJze0p4yMeta?.alias || [],
    redirect: collectgpoJze0p4yMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/collect.vue").then(m => m.default || m)
  },
  {
    name: collectgpoJze0p4yMeta?.name ?? "hk__zh-HK__collect",
    path: collectgpoJze0p4yMeta?.path ?? "/hk/zh-HK/collect",
    meta: {...(collectgpoJze0p4yMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"collect"}},
    alias: collectgpoJze0p4yMeta?.alias || [],
    redirect: collectgpoJze0p4yMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/collect.vue").then(m => m.default || m)
  },
  {
    name: collectgpoJze0p4yMeta?.name ?? "hk__zh-CN__collect",
    path: collectgpoJze0p4yMeta?.path ?? "/hk/zh-CN/collect",
    meta: {...(collectgpoJze0p4yMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"collect"}},
    alias: collectgpoJze0p4yMeta?.alias || [],
    redirect: collectgpoJze0p4yMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/collect.vue").then(m => m.default || m)
  },
  {
    name: collectgpoJze0p4yMeta?.name ?? "hk__en__collect",
    path: collectgpoJze0p4yMeta?.path ?? "/hk/en/collect",
    meta: {...(collectgpoJze0p4yMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"collect"}},
    alias: collectgpoJze0p4yMeta?.alias || [],
    redirect: collectgpoJze0p4yMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/collect.vue").then(m => m.default || m)
  },
  {
    name: collectgpoJze0p4yMeta?.name ?? "mo__collect",
    path: collectgpoJze0p4yMeta?.path ?? "/mo/collect",
    meta: {...(collectgpoJze0p4yMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"collect"}},
    alias: collectgpoJze0p4yMeta?.alias || [],
    redirect: collectgpoJze0p4yMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/collect.vue").then(m => m.default || m)
  },
  {
    name: collectgpoJze0p4yMeta?.name ?? "mo__zh-MO__collect",
    path: collectgpoJze0p4yMeta?.path ?? "/mo/zh-MO/collect",
    meta: {...(collectgpoJze0p4yMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"collect"}},
    alias: collectgpoJze0p4yMeta?.alias || [],
    redirect: collectgpoJze0p4yMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/collect.vue").then(m => m.default || m)
  },
  {
    name: collectgpoJze0p4yMeta?.name ?? "mo__zh-HK__collect",
    path: collectgpoJze0p4yMeta?.path ?? "/mo/zh-HK/collect",
    meta: {...(collectgpoJze0p4yMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"collect"}},
    alias: collectgpoJze0p4yMeta?.alias || [],
    redirect: collectgpoJze0p4yMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/collect.vue").then(m => m.default || m)
  },
  {
    name: collectgpoJze0p4yMeta?.name ?? "mo__zh-CN__collect",
    path: collectgpoJze0p4yMeta?.path ?? "/mo/zh-CN/collect",
    meta: {...(collectgpoJze0p4yMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"collect"}},
    alias: collectgpoJze0p4yMeta?.alias || [],
    redirect: collectgpoJze0p4yMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/collect.vue").then(m => m.default || m)
  },
  {
    name: collectgpoJze0p4yMeta?.name ?? "mo__en__collect",
    path: collectgpoJze0p4yMeta?.path ?? "/mo/en/collect",
    meta: {...(collectgpoJze0p4yMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"collect"}},
    alias: collectgpoJze0p4yMeta?.alias || [],
    redirect: collectgpoJze0p4yMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/collect.vue").then(m => m.default || m)
  },
  {
    name: collectgpoJze0p4yMeta?.name ?? "cn__collect",
    path: collectgpoJze0p4yMeta?.path ?? "/cn/collect",
    meta: {...(collectgpoJze0p4yMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"collect"}},
    alias: collectgpoJze0p4yMeta?.alias || [],
    redirect: collectgpoJze0p4yMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/collect.vue").then(m => m.default || m)
  },
  {
    name: collectgpoJze0p4yMeta?.name ?? "cn__zh-MO__collect",
    path: collectgpoJze0p4yMeta?.path ?? "/cn/zh-MO/collect",
    meta: {...(collectgpoJze0p4yMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"collect"}},
    alias: collectgpoJze0p4yMeta?.alias || [],
    redirect: collectgpoJze0p4yMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/collect.vue").then(m => m.default || m)
  },
  {
    name: collectgpoJze0p4yMeta?.name ?? "cn__zh-HK__collect",
    path: collectgpoJze0p4yMeta?.path ?? "/cn/zh-HK/collect",
    meta: {...(collectgpoJze0p4yMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"collect"}},
    alias: collectgpoJze0p4yMeta?.alias || [],
    redirect: collectgpoJze0p4yMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/collect.vue").then(m => m.default || m)
  },
  {
    name: collectgpoJze0p4yMeta?.name ?? "cn__zh-CN__collect",
    path: collectgpoJze0p4yMeta?.path ?? "/cn/zh-CN/collect",
    meta: {...(collectgpoJze0p4yMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"collect"}},
    alias: collectgpoJze0p4yMeta?.alias || [],
    redirect: collectgpoJze0p4yMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/collect.vue").then(m => m.default || m)
  },
  {
    name: collectgpoJze0p4yMeta?.name ?? "cn__en__collect",
    path: collectgpoJze0p4yMeta?.path ?? "/cn/en/collect",
    meta: {...(collectgpoJze0p4yMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"collect"}},
    alias: collectgpoJze0p4yMeta?.alias || [],
    redirect: collectgpoJze0p4yMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/collect.vue").then(m => m.default || m)
  },
  {
    name: couponsx8tknAXHZJMeta?.name ?? "hk__coupons",
    path: couponsx8tknAXHZJMeta?.path ?? "/hk/coupons",
    meta: {...(couponsx8tknAXHZJMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"coupons"}},
    alias: couponsx8tknAXHZJMeta?.alias || [],
    redirect: couponsx8tknAXHZJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/coupons.vue").then(m => m.default || m)
  },
  {
    name: couponsx8tknAXHZJMeta?.name ?? "hk__zh-MO__coupons",
    path: couponsx8tknAXHZJMeta?.path ?? "/hk/zh-MO/coupons",
    meta: {...(couponsx8tknAXHZJMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"coupons"}},
    alias: couponsx8tknAXHZJMeta?.alias || [],
    redirect: couponsx8tknAXHZJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/coupons.vue").then(m => m.default || m)
  },
  {
    name: couponsx8tknAXHZJMeta?.name ?? "coupons",
    path: couponsx8tknAXHZJMeta?.path ?? "/coupons",
    meta: {...(couponsx8tknAXHZJMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"coupons"}},
    alias: couponsx8tknAXHZJMeta?.alias || [],
    redirect: couponsx8tknAXHZJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/coupons.vue").then(m => m.default || m)
  },
  {
    name: couponsx8tknAXHZJMeta?.name ?? "hk__zh-HK__coupons",
    path: couponsx8tknAXHZJMeta?.path ?? "/hk/zh-HK/coupons",
    meta: {...(couponsx8tknAXHZJMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"coupons"}},
    alias: couponsx8tknAXHZJMeta?.alias || [],
    redirect: couponsx8tknAXHZJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/coupons.vue").then(m => m.default || m)
  },
  {
    name: couponsx8tknAXHZJMeta?.name ?? "hk__zh-CN__coupons",
    path: couponsx8tknAXHZJMeta?.path ?? "/hk/zh-CN/coupons",
    meta: {...(couponsx8tknAXHZJMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"coupons"}},
    alias: couponsx8tknAXHZJMeta?.alias || [],
    redirect: couponsx8tknAXHZJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/coupons.vue").then(m => m.default || m)
  },
  {
    name: couponsx8tknAXHZJMeta?.name ?? "hk__en__coupons",
    path: couponsx8tknAXHZJMeta?.path ?? "/hk/en/coupons",
    meta: {...(couponsx8tknAXHZJMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"coupons"}},
    alias: couponsx8tknAXHZJMeta?.alias || [],
    redirect: couponsx8tknAXHZJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/coupons.vue").then(m => m.default || m)
  },
  {
    name: couponsx8tknAXHZJMeta?.name ?? "mo__coupons",
    path: couponsx8tknAXHZJMeta?.path ?? "/mo/coupons",
    meta: {...(couponsx8tknAXHZJMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"coupons"}},
    alias: couponsx8tknAXHZJMeta?.alias || [],
    redirect: couponsx8tknAXHZJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/coupons.vue").then(m => m.default || m)
  },
  {
    name: couponsx8tknAXHZJMeta?.name ?? "mo__zh-MO__coupons",
    path: couponsx8tknAXHZJMeta?.path ?? "/mo/zh-MO/coupons",
    meta: {...(couponsx8tknAXHZJMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"coupons"}},
    alias: couponsx8tknAXHZJMeta?.alias || [],
    redirect: couponsx8tknAXHZJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/coupons.vue").then(m => m.default || m)
  },
  {
    name: couponsx8tknAXHZJMeta?.name ?? "mo__zh-HK__coupons",
    path: couponsx8tknAXHZJMeta?.path ?? "/mo/zh-HK/coupons",
    meta: {...(couponsx8tknAXHZJMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"coupons"}},
    alias: couponsx8tknAXHZJMeta?.alias || [],
    redirect: couponsx8tknAXHZJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/coupons.vue").then(m => m.default || m)
  },
  {
    name: couponsx8tknAXHZJMeta?.name ?? "mo__zh-CN__coupons",
    path: couponsx8tknAXHZJMeta?.path ?? "/mo/zh-CN/coupons",
    meta: {...(couponsx8tknAXHZJMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"coupons"}},
    alias: couponsx8tknAXHZJMeta?.alias || [],
    redirect: couponsx8tknAXHZJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/coupons.vue").then(m => m.default || m)
  },
  {
    name: couponsx8tknAXHZJMeta?.name ?? "mo__en__coupons",
    path: couponsx8tknAXHZJMeta?.path ?? "/mo/en/coupons",
    meta: {...(couponsx8tknAXHZJMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"coupons"}},
    alias: couponsx8tknAXHZJMeta?.alias || [],
    redirect: couponsx8tknAXHZJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/coupons.vue").then(m => m.default || m)
  },
  {
    name: couponsx8tknAXHZJMeta?.name ?? "cn__coupons",
    path: couponsx8tknAXHZJMeta?.path ?? "/cn/coupons",
    meta: {...(couponsx8tknAXHZJMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"coupons"}},
    alias: couponsx8tknAXHZJMeta?.alias || [],
    redirect: couponsx8tknAXHZJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/coupons.vue").then(m => m.default || m)
  },
  {
    name: couponsx8tknAXHZJMeta?.name ?? "cn__zh-MO__coupons",
    path: couponsx8tknAXHZJMeta?.path ?? "/cn/zh-MO/coupons",
    meta: {...(couponsx8tknAXHZJMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"coupons"}},
    alias: couponsx8tknAXHZJMeta?.alias || [],
    redirect: couponsx8tknAXHZJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/coupons.vue").then(m => m.default || m)
  },
  {
    name: couponsx8tknAXHZJMeta?.name ?? "cn__zh-HK__coupons",
    path: couponsx8tknAXHZJMeta?.path ?? "/cn/zh-HK/coupons",
    meta: {...(couponsx8tknAXHZJMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"coupons"}},
    alias: couponsx8tknAXHZJMeta?.alias || [],
    redirect: couponsx8tknAXHZJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/coupons.vue").then(m => m.default || m)
  },
  {
    name: couponsx8tknAXHZJMeta?.name ?? "cn__zh-CN__coupons",
    path: couponsx8tknAXHZJMeta?.path ?? "/cn/zh-CN/coupons",
    meta: {...(couponsx8tknAXHZJMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"coupons"}},
    alias: couponsx8tknAXHZJMeta?.alias || [],
    redirect: couponsx8tknAXHZJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/coupons.vue").then(m => m.default || m)
  },
  {
    name: couponsx8tknAXHZJMeta?.name ?? "cn__en__coupons",
    path: couponsx8tknAXHZJMeta?.path ?? "/cn/en/coupons",
    meta: {...(couponsx8tknAXHZJMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"coupons"}},
    alias: couponsx8tknAXHZJMeta?.alias || [],
    redirect: couponsx8tknAXHZJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/coupons.vue").then(m => m.default || m)
  },
  {
    name: _500PLkbg5JiDrMeta?.name ?? "hk__error-500",
    path: _500PLkbg5JiDrMeta?.path ?? "/hk/error/500",
    meta: {...(_500PLkbg5JiDrMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"error-500"}},
    alias: _500PLkbg5JiDrMeta?.alias || [],
    redirect: _500PLkbg5JiDrMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/error/500.vue").then(m => m.default || m)
  },
  {
    name: _500PLkbg5JiDrMeta?.name ?? "hk__zh-MO__error-500",
    path: _500PLkbg5JiDrMeta?.path ?? "/hk/zh-MO/error/500",
    meta: {...(_500PLkbg5JiDrMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"error-500"}},
    alias: _500PLkbg5JiDrMeta?.alias || [],
    redirect: _500PLkbg5JiDrMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/error/500.vue").then(m => m.default || m)
  },
  {
    name: _500PLkbg5JiDrMeta?.name ?? "error-500",
    path: _500PLkbg5JiDrMeta?.path ?? "/error/500",
    meta: {...(_500PLkbg5JiDrMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"error-500"}},
    alias: _500PLkbg5JiDrMeta?.alias || [],
    redirect: _500PLkbg5JiDrMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/error/500.vue").then(m => m.default || m)
  },
  {
    name: _500PLkbg5JiDrMeta?.name ?? "hk__zh-HK__error-500",
    path: _500PLkbg5JiDrMeta?.path ?? "/hk/zh-HK/error/500",
    meta: {...(_500PLkbg5JiDrMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"error-500"}},
    alias: _500PLkbg5JiDrMeta?.alias || [],
    redirect: _500PLkbg5JiDrMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/error/500.vue").then(m => m.default || m)
  },
  {
    name: _500PLkbg5JiDrMeta?.name ?? "hk__zh-CN__error-500",
    path: _500PLkbg5JiDrMeta?.path ?? "/hk/zh-CN/error/500",
    meta: {...(_500PLkbg5JiDrMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"error-500"}},
    alias: _500PLkbg5JiDrMeta?.alias || [],
    redirect: _500PLkbg5JiDrMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/error/500.vue").then(m => m.default || m)
  },
  {
    name: _500PLkbg5JiDrMeta?.name ?? "hk__en__error-500",
    path: _500PLkbg5JiDrMeta?.path ?? "/hk/en/error/500",
    meta: {...(_500PLkbg5JiDrMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"error-500"}},
    alias: _500PLkbg5JiDrMeta?.alias || [],
    redirect: _500PLkbg5JiDrMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/error/500.vue").then(m => m.default || m)
  },
  {
    name: _500PLkbg5JiDrMeta?.name ?? "mo__error-500",
    path: _500PLkbg5JiDrMeta?.path ?? "/mo/error/500",
    meta: {...(_500PLkbg5JiDrMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"error-500"}},
    alias: _500PLkbg5JiDrMeta?.alias || [],
    redirect: _500PLkbg5JiDrMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/error/500.vue").then(m => m.default || m)
  },
  {
    name: _500PLkbg5JiDrMeta?.name ?? "mo__zh-MO__error-500",
    path: _500PLkbg5JiDrMeta?.path ?? "/mo/zh-MO/error/500",
    meta: {...(_500PLkbg5JiDrMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"error-500"}},
    alias: _500PLkbg5JiDrMeta?.alias || [],
    redirect: _500PLkbg5JiDrMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/error/500.vue").then(m => m.default || m)
  },
  {
    name: _500PLkbg5JiDrMeta?.name ?? "mo__zh-HK__error-500",
    path: _500PLkbg5JiDrMeta?.path ?? "/mo/zh-HK/error/500",
    meta: {...(_500PLkbg5JiDrMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"error-500"}},
    alias: _500PLkbg5JiDrMeta?.alias || [],
    redirect: _500PLkbg5JiDrMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/error/500.vue").then(m => m.default || m)
  },
  {
    name: _500PLkbg5JiDrMeta?.name ?? "mo__zh-CN__error-500",
    path: _500PLkbg5JiDrMeta?.path ?? "/mo/zh-CN/error/500",
    meta: {...(_500PLkbg5JiDrMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"error-500"}},
    alias: _500PLkbg5JiDrMeta?.alias || [],
    redirect: _500PLkbg5JiDrMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/error/500.vue").then(m => m.default || m)
  },
  {
    name: _500PLkbg5JiDrMeta?.name ?? "mo__en__error-500",
    path: _500PLkbg5JiDrMeta?.path ?? "/mo/en/error/500",
    meta: {...(_500PLkbg5JiDrMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"error-500"}},
    alias: _500PLkbg5JiDrMeta?.alias || [],
    redirect: _500PLkbg5JiDrMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/error/500.vue").then(m => m.default || m)
  },
  {
    name: _500PLkbg5JiDrMeta?.name ?? "cn__error-500",
    path: _500PLkbg5JiDrMeta?.path ?? "/cn/error/500",
    meta: {...(_500PLkbg5JiDrMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"error-500"}},
    alias: _500PLkbg5JiDrMeta?.alias || [],
    redirect: _500PLkbg5JiDrMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/error/500.vue").then(m => m.default || m)
  },
  {
    name: _500PLkbg5JiDrMeta?.name ?? "cn__zh-MO__error-500",
    path: _500PLkbg5JiDrMeta?.path ?? "/cn/zh-MO/error/500",
    meta: {...(_500PLkbg5JiDrMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"error-500"}},
    alias: _500PLkbg5JiDrMeta?.alias || [],
    redirect: _500PLkbg5JiDrMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/error/500.vue").then(m => m.default || m)
  },
  {
    name: _500PLkbg5JiDrMeta?.name ?? "cn__zh-HK__error-500",
    path: _500PLkbg5JiDrMeta?.path ?? "/cn/zh-HK/error/500",
    meta: {...(_500PLkbg5JiDrMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"error-500"}},
    alias: _500PLkbg5JiDrMeta?.alias || [],
    redirect: _500PLkbg5JiDrMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/error/500.vue").then(m => m.default || m)
  },
  {
    name: _500PLkbg5JiDrMeta?.name ?? "cn__zh-CN__error-500",
    path: _500PLkbg5JiDrMeta?.path ?? "/cn/zh-CN/error/500",
    meta: {...(_500PLkbg5JiDrMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"error-500"}},
    alias: _500PLkbg5JiDrMeta?.alias || [],
    redirect: _500PLkbg5JiDrMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/error/500.vue").then(m => m.default || m)
  },
  {
    name: _500PLkbg5JiDrMeta?.name ?? "cn__en__error-500",
    path: _500PLkbg5JiDrMeta?.path ?? "/cn/en/error/500",
    meta: {...(_500PLkbg5JiDrMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"error-500"}},
    alias: _500PLkbg5JiDrMeta?.alias || [],
    redirect: _500PLkbg5JiDrMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/error/500.vue").then(m => m.default || m)
  },
  {
    name: historyWIXGWN50jSMeta?.name ?? "hk__history",
    path: historyWIXGWN50jSMeta?.path ?? "/hk/history",
    meta: {...(historyWIXGWN50jSMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"history"}},
    alias: historyWIXGWN50jSMeta?.alias || [],
    redirect: historyWIXGWN50jSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/history.vue").then(m => m.default || m)
  },
  {
    name: historyWIXGWN50jSMeta?.name ?? "hk__zh-MO__history",
    path: historyWIXGWN50jSMeta?.path ?? "/hk/zh-MO/history",
    meta: {...(historyWIXGWN50jSMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"history"}},
    alias: historyWIXGWN50jSMeta?.alias || [],
    redirect: historyWIXGWN50jSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/history.vue").then(m => m.default || m)
  },
  {
    name: historyWIXGWN50jSMeta?.name ?? "history",
    path: historyWIXGWN50jSMeta?.path ?? "/history",
    meta: {...(historyWIXGWN50jSMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"history"}},
    alias: historyWIXGWN50jSMeta?.alias || [],
    redirect: historyWIXGWN50jSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/history.vue").then(m => m.default || m)
  },
  {
    name: historyWIXGWN50jSMeta?.name ?? "hk__zh-HK__history",
    path: historyWIXGWN50jSMeta?.path ?? "/hk/zh-HK/history",
    meta: {...(historyWIXGWN50jSMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"history"}},
    alias: historyWIXGWN50jSMeta?.alias || [],
    redirect: historyWIXGWN50jSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/history.vue").then(m => m.default || m)
  },
  {
    name: historyWIXGWN50jSMeta?.name ?? "hk__zh-CN__history",
    path: historyWIXGWN50jSMeta?.path ?? "/hk/zh-CN/history",
    meta: {...(historyWIXGWN50jSMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"history"}},
    alias: historyWIXGWN50jSMeta?.alias || [],
    redirect: historyWIXGWN50jSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/history.vue").then(m => m.default || m)
  },
  {
    name: historyWIXGWN50jSMeta?.name ?? "hk__en__history",
    path: historyWIXGWN50jSMeta?.path ?? "/hk/en/history",
    meta: {...(historyWIXGWN50jSMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"history"}},
    alias: historyWIXGWN50jSMeta?.alias || [],
    redirect: historyWIXGWN50jSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/history.vue").then(m => m.default || m)
  },
  {
    name: historyWIXGWN50jSMeta?.name ?? "mo__history",
    path: historyWIXGWN50jSMeta?.path ?? "/mo/history",
    meta: {...(historyWIXGWN50jSMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"history"}},
    alias: historyWIXGWN50jSMeta?.alias || [],
    redirect: historyWIXGWN50jSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/history.vue").then(m => m.default || m)
  },
  {
    name: historyWIXGWN50jSMeta?.name ?? "mo__zh-MO__history",
    path: historyWIXGWN50jSMeta?.path ?? "/mo/zh-MO/history",
    meta: {...(historyWIXGWN50jSMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"history"}},
    alias: historyWIXGWN50jSMeta?.alias || [],
    redirect: historyWIXGWN50jSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/history.vue").then(m => m.default || m)
  },
  {
    name: historyWIXGWN50jSMeta?.name ?? "mo__zh-HK__history",
    path: historyWIXGWN50jSMeta?.path ?? "/mo/zh-HK/history",
    meta: {...(historyWIXGWN50jSMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"history"}},
    alias: historyWIXGWN50jSMeta?.alias || [],
    redirect: historyWIXGWN50jSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/history.vue").then(m => m.default || m)
  },
  {
    name: historyWIXGWN50jSMeta?.name ?? "mo__zh-CN__history",
    path: historyWIXGWN50jSMeta?.path ?? "/mo/zh-CN/history",
    meta: {...(historyWIXGWN50jSMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"history"}},
    alias: historyWIXGWN50jSMeta?.alias || [],
    redirect: historyWIXGWN50jSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/history.vue").then(m => m.default || m)
  },
  {
    name: historyWIXGWN50jSMeta?.name ?? "mo__en__history",
    path: historyWIXGWN50jSMeta?.path ?? "/mo/en/history",
    meta: {...(historyWIXGWN50jSMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"history"}},
    alias: historyWIXGWN50jSMeta?.alias || [],
    redirect: historyWIXGWN50jSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/history.vue").then(m => m.default || m)
  },
  {
    name: historyWIXGWN50jSMeta?.name ?? "cn__history",
    path: historyWIXGWN50jSMeta?.path ?? "/cn/history",
    meta: {...(historyWIXGWN50jSMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"history"}},
    alias: historyWIXGWN50jSMeta?.alias || [],
    redirect: historyWIXGWN50jSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/history.vue").then(m => m.default || m)
  },
  {
    name: historyWIXGWN50jSMeta?.name ?? "cn__zh-MO__history",
    path: historyWIXGWN50jSMeta?.path ?? "/cn/zh-MO/history",
    meta: {...(historyWIXGWN50jSMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"history"}},
    alias: historyWIXGWN50jSMeta?.alias || [],
    redirect: historyWIXGWN50jSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/history.vue").then(m => m.default || m)
  },
  {
    name: historyWIXGWN50jSMeta?.name ?? "cn__zh-HK__history",
    path: historyWIXGWN50jSMeta?.path ?? "/cn/zh-HK/history",
    meta: {...(historyWIXGWN50jSMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"history"}},
    alias: historyWIXGWN50jSMeta?.alias || [],
    redirect: historyWIXGWN50jSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/history.vue").then(m => m.default || m)
  },
  {
    name: historyWIXGWN50jSMeta?.name ?? "cn__zh-CN__history",
    path: historyWIXGWN50jSMeta?.path ?? "/cn/zh-CN/history",
    meta: {...(historyWIXGWN50jSMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"history"}},
    alias: historyWIXGWN50jSMeta?.alias || [],
    redirect: historyWIXGWN50jSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/history.vue").then(m => m.default || m)
  },
  {
    name: historyWIXGWN50jSMeta?.name ?? "cn__en__history",
    path: historyWIXGWN50jSMeta?.path ?? "/cn/en/history",
    meta: {...(historyWIXGWN50jSMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"history"}},
    alias: historyWIXGWN50jSMeta?.alias || [],
    redirect: historyWIXGWN50jSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/history.vue").then(m => m.default || m)
  },
  {
    name: indexljQQTyVQhqMeta?.name ?? "hk__index",
    path: indexljQQTyVQhqMeta?.path ?? "/hk/",
    meta: {...(indexljQQTyVQhqMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"index"}},
    alias: indexljQQTyVQhqMeta?.alias || [],
    redirect: indexljQQTyVQhqMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexljQQTyVQhqMeta?.name ?? "hk__zh-MO__index",
    path: indexljQQTyVQhqMeta?.path ?? "/hk/zh-MO/",
    meta: {...(indexljQQTyVQhqMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"index"}},
    alias: indexljQQTyVQhqMeta?.alias || [],
    redirect: indexljQQTyVQhqMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexljQQTyVQhqMeta?.name ?? "index",
    path: indexljQQTyVQhqMeta?.path ?? "/",
    meta: {...(indexljQQTyVQhqMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"index"}},
    alias: indexljQQTyVQhqMeta?.alias || [],
    redirect: indexljQQTyVQhqMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexljQQTyVQhqMeta?.name ?? "hk__zh-HK__index",
    path: indexljQQTyVQhqMeta?.path ?? "/hk/zh-HK/",
    meta: {...(indexljQQTyVQhqMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"index"}},
    alias: indexljQQTyVQhqMeta?.alias || [],
    redirect: indexljQQTyVQhqMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexljQQTyVQhqMeta?.name ?? "hk__zh-CN__index",
    path: indexljQQTyVQhqMeta?.path ?? "/hk/zh-CN/",
    meta: {...(indexljQQTyVQhqMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"index"}},
    alias: indexljQQTyVQhqMeta?.alias || [],
    redirect: indexljQQTyVQhqMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexljQQTyVQhqMeta?.name ?? "hk__en__index",
    path: indexljQQTyVQhqMeta?.path ?? "/hk/en/",
    meta: {...(indexljQQTyVQhqMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"index"}},
    alias: indexljQQTyVQhqMeta?.alias || [],
    redirect: indexljQQTyVQhqMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexljQQTyVQhqMeta?.name ?? "mo__index",
    path: indexljQQTyVQhqMeta?.path ?? "/mo/",
    meta: {...(indexljQQTyVQhqMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"index"}},
    alias: indexljQQTyVQhqMeta?.alias || [],
    redirect: indexljQQTyVQhqMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexljQQTyVQhqMeta?.name ?? "mo__zh-MO__index",
    path: indexljQQTyVQhqMeta?.path ?? "/mo/zh-MO/",
    meta: {...(indexljQQTyVQhqMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"index"}},
    alias: indexljQQTyVQhqMeta?.alias || [],
    redirect: indexljQQTyVQhqMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexljQQTyVQhqMeta?.name ?? "mo__zh-HK__index",
    path: indexljQQTyVQhqMeta?.path ?? "/mo/zh-HK/",
    meta: {...(indexljQQTyVQhqMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"index"}},
    alias: indexljQQTyVQhqMeta?.alias || [],
    redirect: indexljQQTyVQhqMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexljQQTyVQhqMeta?.name ?? "mo__zh-CN__index",
    path: indexljQQTyVQhqMeta?.path ?? "/mo/zh-CN/",
    meta: {...(indexljQQTyVQhqMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"index"}},
    alias: indexljQQTyVQhqMeta?.alias || [],
    redirect: indexljQQTyVQhqMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexljQQTyVQhqMeta?.name ?? "mo__en__index",
    path: indexljQQTyVQhqMeta?.path ?? "/mo/en/",
    meta: {...(indexljQQTyVQhqMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"index"}},
    alias: indexljQQTyVQhqMeta?.alias || [],
    redirect: indexljQQTyVQhqMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexljQQTyVQhqMeta?.name ?? "cn__index",
    path: indexljQQTyVQhqMeta?.path ?? "/cn/",
    meta: {...(indexljQQTyVQhqMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"index"}},
    alias: indexljQQTyVQhqMeta?.alias || [],
    redirect: indexljQQTyVQhqMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexljQQTyVQhqMeta?.name ?? "cn__zh-MO__index",
    path: indexljQQTyVQhqMeta?.path ?? "/cn/zh-MO/",
    meta: {...(indexljQQTyVQhqMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"index"}},
    alias: indexljQQTyVQhqMeta?.alias || [],
    redirect: indexljQQTyVQhqMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexljQQTyVQhqMeta?.name ?? "cn__zh-HK__index",
    path: indexljQQTyVQhqMeta?.path ?? "/cn/zh-HK/",
    meta: {...(indexljQQTyVQhqMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"index"}},
    alias: indexljQQTyVQhqMeta?.alias || [],
    redirect: indexljQQTyVQhqMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexljQQTyVQhqMeta?.name ?? "cn__zh-CN__index",
    path: indexljQQTyVQhqMeta?.path ?? "/cn/zh-CN/",
    meta: {...(indexljQQTyVQhqMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"index"}},
    alias: indexljQQTyVQhqMeta?.alias || [],
    redirect: indexljQQTyVQhqMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexljQQTyVQhqMeta?.name ?? "cn__en__index",
    path: indexljQQTyVQhqMeta?.path ?? "/cn/en/",
    meta: {...(indexljQQTyVQhqMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"index"}},
    alias: indexljQQTyVQhqMeta?.alias || [],
    redirect: indexljQQTyVQhqMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93XUAWLgR4wSMeta?.name ?? "hk__instruction-detail-id",
    path: _91id_93XUAWLgR4wSMeta?.path ?? "/hk/instruction/detail/:id()",
    meta: {...(_91id_93XUAWLgR4wSMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"instruction-detail-id"}},
    alias: _91id_93XUAWLgR4wSMeta?.alias || [],
    redirect: _91id_93XUAWLgR4wSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/instruction/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93XUAWLgR4wSMeta?.name ?? "hk__zh-MO__instruction-detail-id",
    path: _91id_93XUAWLgR4wSMeta?.path ?? "/hk/zh-MO/instruction/detail/:id()",
    meta: {...(_91id_93XUAWLgR4wSMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"instruction-detail-id"}},
    alias: _91id_93XUAWLgR4wSMeta?.alias || [],
    redirect: _91id_93XUAWLgR4wSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/instruction/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93XUAWLgR4wSMeta?.name ?? "instruction-detail-id",
    path: _91id_93XUAWLgR4wSMeta?.path ?? "/instruction/detail/:id()",
    meta: {...(_91id_93XUAWLgR4wSMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"instruction-detail-id"}},
    alias: _91id_93XUAWLgR4wSMeta?.alias || [],
    redirect: _91id_93XUAWLgR4wSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/instruction/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93XUAWLgR4wSMeta?.name ?? "hk__zh-HK__instruction-detail-id",
    path: _91id_93XUAWLgR4wSMeta?.path ?? "/hk/zh-HK/instruction/detail/:id()",
    meta: {...(_91id_93XUAWLgR4wSMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"instruction-detail-id"}},
    alias: _91id_93XUAWLgR4wSMeta?.alias || [],
    redirect: _91id_93XUAWLgR4wSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/instruction/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93XUAWLgR4wSMeta?.name ?? "hk__zh-CN__instruction-detail-id",
    path: _91id_93XUAWLgR4wSMeta?.path ?? "/hk/zh-CN/instruction/detail/:id()",
    meta: {...(_91id_93XUAWLgR4wSMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"instruction-detail-id"}},
    alias: _91id_93XUAWLgR4wSMeta?.alias || [],
    redirect: _91id_93XUAWLgR4wSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/instruction/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93XUAWLgR4wSMeta?.name ?? "hk__en__instruction-detail-id",
    path: _91id_93XUAWLgR4wSMeta?.path ?? "/hk/en/instruction/detail/:id()",
    meta: {...(_91id_93XUAWLgR4wSMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"instruction-detail-id"}},
    alias: _91id_93XUAWLgR4wSMeta?.alias || [],
    redirect: _91id_93XUAWLgR4wSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/instruction/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93XUAWLgR4wSMeta?.name ?? "mo__instruction-detail-id",
    path: _91id_93XUAWLgR4wSMeta?.path ?? "/mo/instruction/detail/:id()",
    meta: {...(_91id_93XUAWLgR4wSMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"instruction-detail-id"}},
    alias: _91id_93XUAWLgR4wSMeta?.alias || [],
    redirect: _91id_93XUAWLgR4wSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/instruction/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93XUAWLgR4wSMeta?.name ?? "mo__zh-MO__instruction-detail-id",
    path: _91id_93XUAWLgR4wSMeta?.path ?? "/mo/zh-MO/instruction/detail/:id()",
    meta: {...(_91id_93XUAWLgR4wSMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"instruction-detail-id"}},
    alias: _91id_93XUAWLgR4wSMeta?.alias || [],
    redirect: _91id_93XUAWLgR4wSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/instruction/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93XUAWLgR4wSMeta?.name ?? "mo__zh-HK__instruction-detail-id",
    path: _91id_93XUAWLgR4wSMeta?.path ?? "/mo/zh-HK/instruction/detail/:id()",
    meta: {...(_91id_93XUAWLgR4wSMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"instruction-detail-id"}},
    alias: _91id_93XUAWLgR4wSMeta?.alias || [],
    redirect: _91id_93XUAWLgR4wSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/instruction/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93XUAWLgR4wSMeta?.name ?? "mo__zh-CN__instruction-detail-id",
    path: _91id_93XUAWLgR4wSMeta?.path ?? "/mo/zh-CN/instruction/detail/:id()",
    meta: {...(_91id_93XUAWLgR4wSMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"instruction-detail-id"}},
    alias: _91id_93XUAWLgR4wSMeta?.alias || [],
    redirect: _91id_93XUAWLgR4wSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/instruction/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93XUAWLgR4wSMeta?.name ?? "mo__en__instruction-detail-id",
    path: _91id_93XUAWLgR4wSMeta?.path ?? "/mo/en/instruction/detail/:id()",
    meta: {...(_91id_93XUAWLgR4wSMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"instruction-detail-id"}},
    alias: _91id_93XUAWLgR4wSMeta?.alias || [],
    redirect: _91id_93XUAWLgR4wSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/instruction/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93XUAWLgR4wSMeta?.name ?? "cn__instruction-detail-id",
    path: _91id_93XUAWLgR4wSMeta?.path ?? "/cn/instruction/detail/:id()",
    meta: {...(_91id_93XUAWLgR4wSMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"instruction-detail-id"}},
    alias: _91id_93XUAWLgR4wSMeta?.alias || [],
    redirect: _91id_93XUAWLgR4wSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/instruction/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93XUAWLgR4wSMeta?.name ?? "cn__zh-MO__instruction-detail-id",
    path: _91id_93XUAWLgR4wSMeta?.path ?? "/cn/zh-MO/instruction/detail/:id()",
    meta: {...(_91id_93XUAWLgR4wSMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"instruction-detail-id"}},
    alias: _91id_93XUAWLgR4wSMeta?.alias || [],
    redirect: _91id_93XUAWLgR4wSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/instruction/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93XUAWLgR4wSMeta?.name ?? "cn__zh-HK__instruction-detail-id",
    path: _91id_93XUAWLgR4wSMeta?.path ?? "/cn/zh-HK/instruction/detail/:id()",
    meta: {...(_91id_93XUAWLgR4wSMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"instruction-detail-id"}},
    alias: _91id_93XUAWLgR4wSMeta?.alias || [],
    redirect: _91id_93XUAWLgR4wSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/instruction/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93XUAWLgR4wSMeta?.name ?? "cn__zh-CN__instruction-detail-id",
    path: _91id_93XUAWLgR4wSMeta?.path ?? "/cn/zh-CN/instruction/detail/:id()",
    meta: {...(_91id_93XUAWLgR4wSMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"instruction-detail-id"}},
    alias: _91id_93XUAWLgR4wSMeta?.alias || [],
    redirect: _91id_93XUAWLgR4wSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/instruction/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93XUAWLgR4wSMeta?.name ?? "cn__en__instruction-detail-id",
    path: _91id_93XUAWLgR4wSMeta?.path ?? "/cn/en/instruction/detail/:id()",
    meta: {...(_91id_93XUAWLgR4wSMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"instruction-detail-id"}},
    alias: _91id_93XUAWLgR4wSMeta?.alias || [],
    redirect: _91id_93XUAWLgR4wSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/instruction/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93S77443gutfMeta?.name ?? "hk__instruction-list-id",
    path: _91id_93S77443gutfMeta?.path ?? "/hk/instruction/list/:id()",
    meta: {...(_91id_93S77443gutfMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"instruction-list-id"}},
    alias: _91id_93S77443gutfMeta?.alias || [],
    redirect: _91id_93S77443gutfMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/instruction/list/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93S77443gutfMeta?.name ?? "hk__zh-MO__instruction-list-id",
    path: _91id_93S77443gutfMeta?.path ?? "/hk/zh-MO/instruction/list/:id()",
    meta: {...(_91id_93S77443gutfMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"instruction-list-id"}},
    alias: _91id_93S77443gutfMeta?.alias || [],
    redirect: _91id_93S77443gutfMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/instruction/list/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93S77443gutfMeta?.name ?? "instruction-list-id",
    path: _91id_93S77443gutfMeta?.path ?? "/instruction/list/:id()",
    meta: {...(_91id_93S77443gutfMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"instruction-list-id"}},
    alias: _91id_93S77443gutfMeta?.alias || [],
    redirect: _91id_93S77443gutfMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/instruction/list/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93S77443gutfMeta?.name ?? "hk__zh-HK__instruction-list-id",
    path: _91id_93S77443gutfMeta?.path ?? "/hk/zh-HK/instruction/list/:id()",
    meta: {...(_91id_93S77443gutfMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"instruction-list-id"}},
    alias: _91id_93S77443gutfMeta?.alias || [],
    redirect: _91id_93S77443gutfMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/instruction/list/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93S77443gutfMeta?.name ?? "hk__zh-CN__instruction-list-id",
    path: _91id_93S77443gutfMeta?.path ?? "/hk/zh-CN/instruction/list/:id()",
    meta: {...(_91id_93S77443gutfMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"instruction-list-id"}},
    alias: _91id_93S77443gutfMeta?.alias || [],
    redirect: _91id_93S77443gutfMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/instruction/list/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93S77443gutfMeta?.name ?? "hk__en__instruction-list-id",
    path: _91id_93S77443gutfMeta?.path ?? "/hk/en/instruction/list/:id()",
    meta: {...(_91id_93S77443gutfMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"instruction-list-id"}},
    alias: _91id_93S77443gutfMeta?.alias || [],
    redirect: _91id_93S77443gutfMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/instruction/list/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93S77443gutfMeta?.name ?? "mo__instruction-list-id",
    path: _91id_93S77443gutfMeta?.path ?? "/mo/instruction/list/:id()",
    meta: {...(_91id_93S77443gutfMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"instruction-list-id"}},
    alias: _91id_93S77443gutfMeta?.alias || [],
    redirect: _91id_93S77443gutfMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/instruction/list/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93S77443gutfMeta?.name ?? "mo__zh-MO__instruction-list-id",
    path: _91id_93S77443gutfMeta?.path ?? "/mo/zh-MO/instruction/list/:id()",
    meta: {...(_91id_93S77443gutfMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"instruction-list-id"}},
    alias: _91id_93S77443gutfMeta?.alias || [],
    redirect: _91id_93S77443gutfMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/instruction/list/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93S77443gutfMeta?.name ?? "mo__zh-HK__instruction-list-id",
    path: _91id_93S77443gutfMeta?.path ?? "/mo/zh-HK/instruction/list/:id()",
    meta: {...(_91id_93S77443gutfMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"instruction-list-id"}},
    alias: _91id_93S77443gutfMeta?.alias || [],
    redirect: _91id_93S77443gutfMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/instruction/list/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93S77443gutfMeta?.name ?? "mo__zh-CN__instruction-list-id",
    path: _91id_93S77443gutfMeta?.path ?? "/mo/zh-CN/instruction/list/:id()",
    meta: {...(_91id_93S77443gutfMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"instruction-list-id"}},
    alias: _91id_93S77443gutfMeta?.alias || [],
    redirect: _91id_93S77443gutfMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/instruction/list/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93S77443gutfMeta?.name ?? "mo__en__instruction-list-id",
    path: _91id_93S77443gutfMeta?.path ?? "/mo/en/instruction/list/:id()",
    meta: {...(_91id_93S77443gutfMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"instruction-list-id"}},
    alias: _91id_93S77443gutfMeta?.alias || [],
    redirect: _91id_93S77443gutfMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/instruction/list/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93S77443gutfMeta?.name ?? "cn__instruction-list-id",
    path: _91id_93S77443gutfMeta?.path ?? "/cn/instruction/list/:id()",
    meta: {...(_91id_93S77443gutfMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"instruction-list-id"}},
    alias: _91id_93S77443gutfMeta?.alias || [],
    redirect: _91id_93S77443gutfMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/instruction/list/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93S77443gutfMeta?.name ?? "cn__zh-MO__instruction-list-id",
    path: _91id_93S77443gutfMeta?.path ?? "/cn/zh-MO/instruction/list/:id()",
    meta: {...(_91id_93S77443gutfMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"instruction-list-id"}},
    alias: _91id_93S77443gutfMeta?.alias || [],
    redirect: _91id_93S77443gutfMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/instruction/list/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93S77443gutfMeta?.name ?? "cn__zh-HK__instruction-list-id",
    path: _91id_93S77443gutfMeta?.path ?? "/cn/zh-HK/instruction/list/:id()",
    meta: {...(_91id_93S77443gutfMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"instruction-list-id"}},
    alias: _91id_93S77443gutfMeta?.alias || [],
    redirect: _91id_93S77443gutfMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/instruction/list/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93S77443gutfMeta?.name ?? "cn__zh-CN__instruction-list-id",
    path: _91id_93S77443gutfMeta?.path ?? "/cn/zh-CN/instruction/list/:id()",
    meta: {...(_91id_93S77443gutfMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"instruction-list-id"}},
    alias: _91id_93S77443gutfMeta?.alias || [],
    redirect: _91id_93S77443gutfMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/instruction/list/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93S77443gutfMeta?.name ?? "cn__en__instruction-list-id",
    path: _91id_93S77443gutfMeta?.path ?? "/cn/en/instruction/list/:id()",
    meta: {...(_91id_93S77443gutfMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"instruction-list-id"}},
    alias: _91id_93S77443gutfMeta?.alias || [],
    redirect: _91id_93S77443gutfMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/instruction/list/[id].vue").then(m => m.default || m)
  },
  {
    name: integralqyOkbpUF4KMeta?.name ?? "hk__integral",
    path: integralqyOkbpUF4KMeta?.path ?? "/hk/integral",
    meta: {...(integralqyOkbpUF4KMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"integral"}},
    alias: integralqyOkbpUF4KMeta?.alias || [],
    redirect: integralqyOkbpUF4KMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/integral.vue").then(m => m.default || m)
  },
  {
    name: integralqyOkbpUF4KMeta?.name ?? "hk__zh-MO__integral",
    path: integralqyOkbpUF4KMeta?.path ?? "/hk/zh-MO/integral",
    meta: {...(integralqyOkbpUF4KMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"integral"}},
    alias: integralqyOkbpUF4KMeta?.alias || [],
    redirect: integralqyOkbpUF4KMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/integral.vue").then(m => m.default || m)
  },
  {
    name: integralqyOkbpUF4KMeta?.name ?? "integral",
    path: integralqyOkbpUF4KMeta?.path ?? "/integral",
    meta: {...(integralqyOkbpUF4KMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"integral"}},
    alias: integralqyOkbpUF4KMeta?.alias || [],
    redirect: integralqyOkbpUF4KMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/integral.vue").then(m => m.default || m)
  },
  {
    name: integralqyOkbpUF4KMeta?.name ?? "hk__zh-HK__integral",
    path: integralqyOkbpUF4KMeta?.path ?? "/hk/zh-HK/integral",
    meta: {...(integralqyOkbpUF4KMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"integral"}},
    alias: integralqyOkbpUF4KMeta?.alias || [],
    redirect: integralqyOkbpUF4KMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/integral.vue").then(m => m.default || m)
  },
  {
    name: integralqyOkbpUF4KMeta?.name ?? "hk__zh-CN__integral",
    path: integralqyOkbpUF4KMeta?.path ?? "/hk/zh-CN/integral",
    meta: {...(integralqyOkbpUF4KMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"integral"}},
    alias: integralqyOkbpUF4KMeta?.alias || [],
    redirect: integralqyOkbpUF4KMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/integral.vue").then(m => m.default || m)
  },
  {
    name: integralqyOkbpUF4KMeta?.name ?? "hk__en__integral",
    path: integralqyOkbpUF4KMeta?.path ?? "/hk/en/integral",
    meta: {...(integralqyOkbpUF4KMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"integral"}},
    alias: integralqyOkbpUF4KMeta?.alias || [],
    redirect: integralqyOkbpUF4KMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/integral.vue").then(m => m.default || m)
  },
  {
    name: integralqyOkbpUF4KMeta?.name ?? "mo__integral",
    path: integralqyOkbpUF4KMeta?.path ?? "/mo/integral",
    meta: {...(integralqyOkbpUF4KMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"integral"}},
    alias: integralqyOkbpUF4KMeta?.alias || [],
    redirect: integralqyOkbpUF4KMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/integral.vue").then(m => m.default || m)
  },
  {
    name: integralqyOkbpUF4KMeta?.name ?? "mo__zh-MO__integral",
    path: integralqyOkbpUF4KMeta?.path ?? "/mo/zh-MO/integral",
    meta: {...(integralqyOkbpUF4KMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"integral"}},
    alias: integralqyOkbpUF4KMeta?.alias || [],
    redirect: integralqyOkbpUF4KMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/integral.vue").then(m => m.default || m)
  },
  {
    name: integralqyOkbpUF4KMeta?.name ?? "mo__zh-HK__integral",
    path: integralqyOkbpUF4KMeta?.path ?? "/mo/zh-HK/integral",
    meta: {...(integralqyOkbpUF4KMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"integral"}},
    alias: integralqyOkbpUF4KMeta?.alias || [],
    redirect: integralqyOkbpUF4KMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/integral.vue").then(m => m.default || m)
  },
  {
    name: integralqyOkbpUF4KMeta?.name ?? "mo__zh-CN__integral",
    path: integralqyOkbpUF4KMeta?.path ?? "/mo/zh-CN/integral",
    meta: {...(integralqyOkbpUF4KMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"integral"}},
    alias: integralqyOkbpUF4KMeta?.alias || [],
    redirect: integralqyOkbpUF4KMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/integral.vue").then(m => m.default || m)
  },
  {
    name: integralqyOkbpUF4KMeta?.name ?? "mo__en__integral",
    path: integralqyOkbpUF4KMeta?.path ?? "/mo/en/integral",
    meta: {...(integralqyOkbpUF4KMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"integral"}},
    alias: integralqyOkbpUF4KMeta?.alias || [],
    redirect: integralqyOkbpUF4KMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/integral.vue").then(m => m.default || m)
  },
  {
    name: integralqyOkbpUF4KMeta?.name ?? "cn__integral",
    path: integralqyOkbpUF4KMeta?.path ?? "/cn/integral",
    meta: {...(integralqyOkbpUF4KMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"integral"}},
    alias: integralqyOkbpUF4KMeta?.alias || [],
    redirect: integralqyOkbpUF4KMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/integral.vue").then(m => m.default || m)
  },
  {
    name: integralqyOkbpUF4KMeta?.name ?? "cn__zh-MO__integral",
    path: integralqyOkbpUF4KMeta?.path ?? "/cn/zh-MO/integral",
    meta: {...(integralqyOkbpUF4KMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"integral"}},
    alias: integralqyOkbpUF4KMeta?.alias || [],
    redirect: integralqyOkbpUF4KMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/integral.vue").then(m => m.default || m)
  },
  {
    name: integralqyOkbpUF4KMeta?.name ?? "cn__zh-HK__integral",
    path: integralqyOkbpUF4KMeta?.path ?? "/cn/zh-HK/integral",
    meta: {...(integralqyOkbpUF4KMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"integral"}},
    alias: integralqyOkbpUF4KMeta?.alias || [],
    redirect: integralqyOkbpUF4KMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/integral.vue").then(m => m.default || m)
  },
  {
    name: integralqyOkbpUF4KMeta?.name ?? "cn__zh-CN__integral",
    path: integralqyOkbpUF4KMeta?.path ?? "/cn/zh-CN/integral",
    meta: {...(integralqyOkbpUF4KMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"integral"}},
    alias: integralqyOkbpUF4KMeta?.alias || [],
    redirect: integralqyOkbpUF4KMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/integral.vue").then(m => m.default || m)
  },
  {
    name: integralqyOkbpUF4KMeta?.name ?? "cn__en__integral",
    path: integralqyOkbpUF4KMeta?.path ?? "/cn/en/integral",
    meta: {...(integralqyOkbpUF4KMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"integral"}},
    alias: integralqyOkbpUF4KMeta?.alias || [],
    redirect: integralqyOkbpUF4KMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/integral.vue").then(m => m.default || m)
  },
  {
    name: index3J8BAxp4ruMeta?.name ?? "hk__login",
    path: index3J8BAxp4ruMeta?.path ?? "/hk/login",
    meta: {...(index3J8BAxp4ruMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"login"}},
    alias: index3J8BAxp4ruMeta?.alias || [],
    redirect: index3J8BAxp4ruMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: index3J8BAxp4ruMeta?.name ?? "hk__zh-MO__login",
    path: index3J8BAxp4ruMeta?.path ?? "/hk/zh-MO/login",
    meta: {...(index3J8BAxp4ruMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"login"}},
    alias: index3J8BAxp4ruMeta?.alias || [],
    redirect: index3J8BAxp4ruMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: index3J8BAxp4ruMeta?.name ?? "login",
    path: index3J8BAxp4ruMeta?.path ?? "/login",
    meta: {...(index3J8BAxp4ruMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"login"}},
    alias: index3J8BAxp4ruMeta?.alias || [],
    redirect: index3J8BAxp4ruMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: index3J8BAxp4ruMeta?.name ?? "hk__zh-HK__login",
    path: index3J8BAxp4ruMeta?.path ?? "/hk/zh-HK/login",
    meta: {...(index3J8BAxp4ruMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"login"}},
    alias: index3J8BAxp4ruMeta?.alias || [],
    redirect: index3J8BAxp4ruMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: index3J8BAxp4ruMeta?.name ?? "hk__zh-CN__login",
    path: index3J8BAxp4ruMeta?.path ?? "/hk/zh-CN/login",
    meta: {...(index3J8BAxp4ruMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"login"}},
    alias: index3J8BAxp4ruMeta?.alias || [],
    redirect: index3J8BAxp4ruMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: index3J8BAxp4ruMeta?.name ?? "hk__en__login",
    path: index3J8BAxp4ruMeta?.path ?? "/hk/en/login",
    meta: {...(index3J8BAxp4ruMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"login"}},
    alias: index3J8BAxp4ruMeta?.alias || [],
    redirect: index3J8BAxp4ruMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: index3J8BAxp4ruMeta?.name ?? "mo__login",
    path: index3J8BAxp4ruMeta?.path ?? "/mo/login",
    meta: {...(index3J8BAxp4ruMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"login"}},
    alias: index3J8BAxp4ruMeta?.alias || [],
    redirect: index3J8BAxp4ruMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: index3J8BAxp4ruMeta?.name ?? "mo__zh-MO__login",
    path: index3J8BAxp4ruMeta?.path ?? "/mo/zh-MO/login",
    meta: {...(index3J8BAxp4ruMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"login"}},
    alias: index3J8BAxp4ruMeta?.alias || [],
    redirect: index3J8BAxp4ruMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: index3J8BAxp4ruMeta?.name ?? "mo__zh-HK__login",
    path: index3J8BAxp4ruMeta?.path ?? "/mo/zh-HK/login",
    meta: {...(index3J8BAxp4ruMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"login"}},
    alias: index3J8BAxp4ruMeta?.alias || [],
    redirect: index3J8BAxp4ruMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: index3J8BAxp4ruMeta?.name ?? "mo__zh-CN__login",
    path: index3J8BAxp4ruMeta?.path ?? "/mo/zh-CN/login",
    meta: {...(index3J8BAxp4ruMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"login"}},
    alias: index3J8BAxp4ruMeta?.alias || [],
    redirect: index3J8BAxp4ruMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: index3J8BAxp4ruMeta?.name ?? "mo__en__login",
    path: index3J8BAxp4ruMeta?.path ?? "/mo/en/login",
    meta: {...(index3J8BAxp4ruMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"login"}},
    alias: index3J8BAxp4ruMeta?.alias || [],
    redirect: index3J8BAxp4ruMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: index3J8BAxp4ruMeta?.name ?? "cn__login",
    path: index3J8BAxp4ruMeta?.path ?? "/cn/login",
    meta: {...(index3J8BAxp4ruMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"login"}},
    alias: index3J8BAxp4ruMeta?.alias || [],
    redirect: index3J8BAxp4ruMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: index3J8BAxp4ruMeta?.name ?? "cn__zh-MO__login",
    path: index3J8BAxp4ruMeta?.path ?? "/cn/zh-MO/login",
    meta: {...(index3J8BAxp4ruMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"login"}},
    alias: index3J8BAxp4ruMeta?.alias || [],
    redirect: index3J8BAxp4ruMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: index3J8BAxp4ruMeta?.name ?? "cn__zh-HK__login",
    path: index3J8BAxp4ruMeta?.path ?? "/cn/zh-HK/login",
    meta: {...(index3J8BAxp4ruMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"login"}},
    alias: index3J8BAxp4ruMeta?.alias || [],
    redirect: index3J8BAxp4ruMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: index3J8BAxp4ruMeta?.name ?? "cn__zh-CN__login",
    path: index3J8BAxp4ruMeta?.path ?? "/cn/zh-CN/login",
    meta: {...(index3J8BAxp4ruMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"login"}},
    alias: index3J8BAxp4ruMeta?.alias || [],
    redirect: index3J8BAxp4ruMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: index3J8BAxp4ruMeta?.name ?? "cn__en__login",
    path: index3J8BAxp4ruMeta?.path ?? "/cn/en/login",
    meta: {...(index3J8BAxp4ruMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"login"}},
    alias: index3J8BAxp4ruMeta?.alias || [],
    redirect: index3J8BAxp4ruMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: wechatBind43SBmP2eU2Meta?.name ?? "hk__login-wechatBind",
    path: wechatBind43SBmP2eU2Meta?.path ?? "/hk/login/wechatBind",
    meta: {...(wechatBind43SBmP2eU2Meta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"login-wechatBind"}},
    alias: wechatBind43SBmP2eU2Meta?.alias || [],
    redirect: wechatBind43SBmP2eU2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/login/wechatBind.vue").then(m => m.default || m)
  },
  {
    name: wechatBind43SBmP2eU2Meta?.name ?? "hk__zh-MO__login-wechatBind",
    path: wechatBind43SBmP2eU2Meta?.path ?? "/hk/zh-MO/login/wechatBind",
    meta: {...(wechatBind43SBmP2eU2Meta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"login-wechatBind"}},
    alias: wechatBind43SBmP2eU2Meta?.alias || [],
    redirect: wechatBind43SBmP2eU2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/login/wechatBind.vue").then(m => m.default || m)
  },
  {
    name: wechatBind43SBmP2eU2Meta?.name ?? "login-wechatBind",
    path: wechatBind43SBmP2eU2Meta?.path ?? "/login/wechatBind",
    meta: {...(wechatBind43SBmP2eU2Meta || {}), ...{"siteCode":"","i18nCode":"","truePath":"login-wechatBind"}},
    alias: wechatBind43SBmP2eU2Meta?.alias || [],
    redirect: wechatBind43SBmP2eU2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/login/wechatBind.vue").then(m => m.default || m)
  },
  {
    name: wechatBind43SBmP2eU2Meta?.name ?? "hk__zh-HK__login-wechatBind",
    path: wechatBind43SBmP2eU2Meta?.path ?? "/hk/zh-HK/login/wechatBind",
    meta: {...(wechatBind43SBmP2eU2Meta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"login-wechatBind"}},
    alias: wechatBind43SBmP2eU2Meta?.alias || [],
    redirect: wechatBind43SBmP2eU2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/login/wechatBind.vue").then(m => m.default || m)
  },
  {
    name: wechatBind43SBmP2eU2Meta?.name ?? "hk__zh-CN__login-wechatBind",
    path: wechatBind43SBmP2eU2Meta?.path ?? "/hk/zh-CN/login/wechatBind",
    meta: {...(wechatBind43SBmP2eU2Meta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"login-wechatBind"}},
    alias: wechatBind43SBmP2eU2Meta?.alias || [],
    redirect: wechatBind43SBmP2eU2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/login/wechatBind.vue").then(m => m.default || m)
  },
  {
    name: wechatBind43SBmP2eU2Meta?.name ?? "hk__en__login-wechatBind",
    path: wechatBind43SBmP2eU2Meta?.path ?? "/hk/en/login/wechatBind",
    meta: {...(wechatBind43SBmP2eU2Meta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"login-wechatBind"}},
    alias: wechatBind43SBmP2eU2Meta?.alias || [],
    redirect: wechatBind43SBmP2eU2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/login/wechatBind.vue").then(m => m.default || m)
  },
  {
    name: wechatBind43SBmP2eU2Meta?.name ?? "mo__login-wechatBind",
    path: wechatBind43SBmP2eU2Meta?.path ?? "/mo/login/wechatBind",
    meta: {...(wechatBind43SBmP2eU2Meta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"login-wechatBind"}},
    alias: wechatBind43SBmP2eU2Meta?.alias || [],
    redirect: wechatBind43SBmP2eU2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/login/wechatBind.vue").then(m => m.default || m)
  },
  {
    name: wechatBind43SBmP2eU2Meta?.name ?? "mo__zh-MO__login-wechatBind",
    path: wechatBind43SBmP2eU2Meta?.path ?? "/mo/zh-MO/login/wechatBind",
    meta: {...(wechatBind43SBmP2eU2Meta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"login-wechatBind"}},
    alias: wechatBind43SBmP2eU2Meta?.alias || [],
    redirect: wechatBind43SBmP2eU2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/login/wechatBind.vue").then(m => m.default || m)
  },
  {
    name: wechatBind43SBmP2eU2Meta?.name ?? "mo__zh-HK__login-wechatBind",
    path: wechatBind43SBmP2eU2Meta?.path ?? "/mo/zh-HK/login/wechatBind",
    meta: {...(wechatBind43SBmP2eU2Meta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"login-wechatBind"}},
    alias: wechatBind43SBmP2eU2Meta?.alias || [],
    redirect: wechatBind43SBmP2eU2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/login/wechatBind.vue").then(m => m.default || m)
  },
  {
    name: wechatBind43SBmP2eU2Meta?.name ?? "mo__zh-CN__login-wechatBind",
    path: wechatBind43SBmP2eU2Meta?.path ?? "/mo/zh-CN/login/wechatBind",
    meta: {...(wechatBind43SBmP2eU2Meta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"login-wechatBind"}},
    alias: wechatBind43SBmP2eU2Meta?.alias || [],
    redirect: wechatBind43SBmP2eU2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/login/wechatBind.vue").then(m => m.default || m)
  },
  {
    name: wechatBind43SBmP2eU2Meta?.name ?? "mo__en__login-wechatBind",
    path: wechatBind43SBmP2eU2Meta?.path ?? "/mo/en/login/wechatBind",
    meta: {...(wechatBind43SBmP2eU2Meta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"login-wechatBind"}},
    alias: wechatBind43SBmP2eU2Meta?.alias || [],
    redirect: wechatBind43SBmP2eU2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/login/wechatBind.vue").then(m => m.default || m)
  },
  {
    name: wechatBind43SBmP2eU2Meta?.name ?? "cn__login-wechatBind",
    path: wechatBind43SBmP2eU2Meta?.path ?? "/cn/login/wechatBind",
    meta: {...(wechatBind43SBmP2eU2Meta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"login-wechatBind"}},
    alias: wechatBind43SBmP2eU2Meta?.alias || [],
    redirect: wechatBind43SBmP2eU2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/login/wechatBind.vue").then(m => m.default || m)
  },
  {
    name: wechatBind43SBmP2eU2Meta?.name ?? "cn__zh-MO__login-wechatBind",
    path: wechatBind43SBmP2eU2Meta?.path ?? "/cn/zh-MO/login/wechatBind",
    meta: {...(wechatBind43SBmP2eU2Meta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"login-wechatBind"}},
    alias: wechatBind43SBmP2eU2Meta?.alias || [],
    redirect: wechatBind43SBmP2eU2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/login/wechatBind.vue").then(m => m.default || m)
  },
  {
    name: wechatBind43SBmP2eU2Meta?.name ?? "cn__zh-HK__login-wechatBind",
    path: wechatBind43SBmP2eU2Meta?.path ?? "/cn/zh-HK/login/wechatBind",
    meta: {...(wechatBind43SBmP2eU2Meta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"login-wechatBind"}},
    alias: wechatBind43SBmP2eU2Meta?.alias || [],
    redirect: wechatBind43SBmP2eU2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/login/wechatBind.vue").then(m => m.default || m)
  },
  {
    name: wechatBind43SBmP2eU2Meta?.name ?? "cn__zh-CN__login-wechatBind",
    path: wechatBind43SBmP2eU2Meta?.path ?? "/cn/zh-CN/login/wechatBind",
    meta: {...(wechatBind43SBmP2eU2Meta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"login-wechatBind"}},
    alias: wechatBind43SBmP2eU2Meta?.alias || [],
    redirect: wechatBind43SBmP2eU2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/login/wechatBind.vue").then(m => m.default || m)
  },
  {
    name: wechatBind43SBmP2eU2Meta?.name ?? "cn__en__login-wechatBind",
    path: wechatBind43SBmP2eU2Meta?.path ?? "/cn/en/login/wechatBind",
    meta: {...(wechatBind43SBmP2eU2Meta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"login-wechatBind"}},
    alias: wechatBind43SBmP2eU2Meta?.alias || [],
    redirect: wechatBind43SBmP2eU2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/login/wechatBind.vue").then(m => m.default || m)
  },
  {
    name: myIFfWoHBz8aMeta?.name ?? "hk__my",
    path: myIFfWoHBz8aMeta?.path ?? "/hk/my",
    meta: {...(myIFfWoHBz8aMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"my"}},
    alias: myIFfWoHBz8aMeta?.alias || [],
    redirect: myIFfWoHBz8aMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/my.vue").then(m => m.default || m)
  },
  {
    name: myIFfWoHBz8aMeta?.name ?? "hk__zh-MO__my",
    path: myIFfWoHBz8aMeta?.path ?? "/hk/zh-MO/my",
    meta: {...(myIFfWoHBz8aMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"my"}},
    alias: myIFfWoHBz8aMeta?.alias || [],
    redirect: myIFfWoHBz8aMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/my.vue").then(m => m.default || m)
  },
  {
    name: myIFfWoHBz8aMeta?.name ?? "my",
    path: myIFfWoHBz8aMeta?.path ?? "/my",
    meta: {...(myIFfWoHBz8aMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"my"}},
    alias: myIFfWoHBz8aMeta?.alias || [],
    redirect: myIFfWoHBz8aMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/my.vue").then(m => m.default || m)
  },
  {
    name: myIFfWoHBz8aMeta?.name ?? "hk__zh-HK__my",
    path: myIFfWoHBz8aMeta?.path ?? "/hk/zh-HK/my",
    meta: {...(myIFfWoHBz8aMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"my"}},
    alias: myIFfWoHBz8aMeta?.alias || [],
    redirect: myIFfWoHBz8aMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/my.vue").then(m => m.default || m)
  },
  {
    name: myIFfWoHBz8aMeta?.name ?? "hk__zh-CN__my",
    path: myIFfWoHBz8aMeta?.path ?? "/hk/zh-CN/my",
    meta: {...(myIFfWoHBz8aMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"my"}},
    alias: myIFfWoHBz8aMeta?.alias || [],
    redirect: myIFfWoHBz8aMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/my.vue").then(m => m.default || m)
  },
  {
    name: myIFfWoHBz8aMeta?.name ?? "hk__en__my",
    path: myIFfWoHBz8aMeta?.path ?? "/hk/en/my",
    meta: {...(myIFfWoHBz8aMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"my"}},
    alias: myIFfWoHBz8aMeta?.alias || [],
    redirect: myIFfWoHBz8aMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/my.vue").then(m => m.default || m)
  },
  {
    name: myIFfWoHBz8aMeta?.name ?? "mo__my",
    path: myIFfWoHBz8aMeta?.path ?? "/mo/my",
    meta: {...(myIFfWoHBz8aMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"my"}},
    alias: myIFfWoHBz8aMeta?.alias || [],
    redirect: myIFfWoHBz8aMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/my.vue").then(m => m.default || m)
  },
  {
    name: myIFfWoHBz8aMeta?.name ?? "mo__zh-MO__my",
    path: myIFfWoHBz8aMeta?.path ?? "/mo/zh-MO/my",
    meta: {...(myIFfWoHBz8aMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"my"}},
    alias: myIFfWoHBz8aMeta?.alias || [],
    redirect: myIFfWoHBz8aMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/my.vue").then(m => m.default || m)
  },
  {
    name: myIFfWoHBz8aMeta?.name ?? "mo__zh-HK__my",
    path: myIFfWoHBz8aMeta?.path ?? "/mo/zh-HK/my",
    meta: {...(myIFfWoHBz8aMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"my"}},
    alias: myIFfWoHBz8aMeta?.alias || [],
    redirect: myIFfWoHBz8aMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/my.vue").then(m => m.default || m)
  },
  {
    name: myIFfWoHBz8aMeta?.name ?? "mo__zh-CN__my",
    path: myIFfWoHBz8aMeta?.path ?? "/mo/zh-CN/my",
    meta: {...(myIFfWoHBz8aMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"my"}},
    alias: myIFfWoHBz8aMeta?.alias || [],
    redirect: myIFfWoHBz8aMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/my.vue").then(m => m.default || m)
  },
  {
    name: myIFfWoHBz8aMeta?.name ?? "mo__en__my",
    path: myIFfWoHBz8aMeta?.path ?? "/mo/en/my",
    meta: {...(myIFfWoHBz8aMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"my"}},
    alias: myIFfWoHBz8aMeta?.alias || [],
    redirect: myIFfWoHBz8aMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/my.vue").then(m => m.default || m)
  },
  {
    name: myIFfWoHBz8aMeta?.name ?? "cn__my",
    path: myIFfWoHBz8aMeta?.path ?? "/cn/my",
    meta: {...(myIFfWoHBz8aMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"my"}},
    alias: myIFfWoHBz8aMeta?.alias || [],
    redirect: myIFfWoHBz8aMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/my.vue").then(m => m.default || m)
  },
  {
    name: myIFfWoHBz8aMeta?.name ?? "cn__zh-MO__my",
    path: myIFfWoHBz8aMeta?.path ?? "/cn/zh-MO/my",
    meta: {...(myIFfWoHBz8aMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"my"}},
    alias: myIFfWoHBz8aMeta?.alias || [],
    redirect: myIFfWoHBz8aMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/my.vue").then(m => m.default || m)
  },
  {
    name: myIFfWoHBz8aMeta?.name ?? "cn__zh-HK__my",
    path: myIFfWoHBz8aMeta?.path ?? "/cn/zh-HK/my",
    meta: {...(myIFfWoHBz8aMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"my"}},
    alias: myIFfWoHBz8aMeta?.alias || [],
    redirect: myIFfWoHBz8aMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/my.vue").then(m => m.default || m)
  },
  {
    name: myIFfWoHBz8aMeta?.name ?? "cn__zh-CN__my",
    path: myIFfWoHBz8aMeta?.path ?? "/cn/zh-CN/my",
    meta: {...(myIFfWoHBz8aMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"my"}},
    alias: myIFfWoHBz8aMeta?.alias || [],
    redirect: myIFfWoHBz8aMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/my.vue").then(m => m.default || m)
  },
  {
    name: myIFfWoHBz8aMeta?.name ?? "cn__en__my",
    path: myIFfWoHBz8aMeta?.path ?? "/cn/en/my",
    meta: {...(myIFfWoHBz8aMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"my"}},
    alias: myIFfWoHBz8aMeta?.alias || [],
    redirect: myIFfWoHBz8aMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/my.vue").then(m => m.default || m)
  },
  {
    name: _91_91id_93_936QFjLbisgwMeta?.name ?? "hk__newki-id",
    path: _91_91id_93_936QFjLbisgwMeta?.path ?? "/hk/newki/:id?",
    meta: {...(_91_91id_93_936QFjLbisgwMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"newki-id"}},
    alias: _91_91id_93_936QFjLbisgwMeta?.alias || [],
    redirect: _91_91id_93_936QFjLbisgwMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/newki/[[id]].vue").then(m => m.default || m)
  },
  {
    name: _91_91id_93_936QFjLbisgwMeta?.name ?? "hk__zh-MO__newki-id",
    path: _91_91id_93_936QFjLbisgwMeta?.path ?? "/hk/zh-MO/newki/:id?",
    meta: {...(_91_91id_93_936QFjLbisgwMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"newki-id"}},
    alias: _91_91id_93_936QFjLbisgwMeta?.alias || [],
    redirect: _91_91id_93_936QFjLbisgwMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/newki/[[id]].vue").then(m => m.default || m)
  },
  {
    name: _91_91id_93_936QFjLbisgwMeta?.name ?? "newki-id",
    path: _91_91id_93_936QFjLbisgwMeta?.path ?? "/newki/:id?",
    meta: {...(_91_91id_93_936QFjLbisgwMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"newki-id"}},
    alias: _91_91id_93_936QFjLbisgwMeta?.alias || [],
    redirect: _91_91id_93_936QFjLbisgwMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/newki/[[id]].vue").then(m => m.default || m)
  },
  {
    name: _91_91id_93_936QFjLbisgwMeta?.name ?? "hk__zh-HK__newki-id",
    path: _91_91id_93_936QFjLbisgwMeta?.path ?? "/hk/zh-HK/newki/:id?",
    meta: {...(_91_91id_93_936QFjLbisgwMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"newki-id"}},
    alias: _91_91id_93_936QFjLbisgwMeta?.alias || [],
    redirect: _91_91id_93_936QFjLbisgwMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/newki/[[id]].vue").then(m => m.default || m)
  },
  {
    name: _91_91id_93_936QFjLbisgwMeta?.name ?? "hk__zh-CN__newki-id",
    path: _91_91id_93_936QFjLbisgwMeta?.path ?? "/hk/zh-CN/newki/:id?",
    meta: {...(_91_91id_93_936QFjLbisgwMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"newki-id"}},
    alias: _91_91id_93_936QFjLbisgwMeta?.alias || [],
    redirect: _91_91id_93_936QFjLbisgwMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/newki/[[id]].vue").then(m => m.default || m)
  },
  {
    name: _91_91id_93_936QFjLbisgwMeta?.name ?? "hk__en__newki-id",
    path: _91_91id_93_936QFjLbisgwMeta?.path ?? "/hk/en/newki/:id?",
    meta: {...(_91_91id_93_936QFjLbisgwMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"newki-id"}},
    alias: _91_91id_93_936QFjLbisgwMeta?.alias || [],
    redirect: _91_91id_93_936QFjLbisgwMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/newki/[[id]].vue").then(m => m.default || m)
  },
  {
    name: _91_91id_93_936QFjLbisgwMeta?.name ?? "mo__newki-id",
    path: _91_91id_93_936QFjLbisgwMeta?.path ?? "/mo/newki/:id?",
    meta: {...(_91_91id_93_936QFjLbisgwMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"newki-id"}},
    alias: _91_91id_93_936QFjLbisgwMeta?.alias || [],
    redirect: _91_91id_93_936QFjLbisgwMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/newki/[[id]].vue").then(m => m.default || m)
  },
  {
    name: _91_91id_93_936QFjLbisgwMeta?.name ?? "mo__zh-MO__newki-id",
    path: _91_91id_93_936QFjLbisgwMeta?.path ?? "/mo/zh-MO/newki/:id?",
    meta: {...(_91_91id_93_936QFjLbisgwMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"newki-id"}},
    alias: _91_91id_93_936QFjLbisgwMeta?.alias || [],
    redirect: _91_91id_93_936QFjLbisgwMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/newki/[[id]].vue").then(m => m.default || m)
  },
  {
    name: _91_91id_93_936QFjLbisgwMeta?.name ?? "mo__zh-HK__newki-id",
    path: _91_91id_93_936QFjLbisgwMeta?.path ?? "/mo/zh-HK/newki/:id?",
    meta: {...(_91_91id_93_936QFjLbisgwMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"newki-id"}},
    alias: _91_91id_93_936QFjLbisgwMeta?.alias || [],
    redirect: _91_91id_93_936QFjLbisgwMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/newki/[[id]].vue").then(m => m.default || m)
  },
  {
    name: _91_91id_93_936QFjLbisgwMeta?.name ?? "mo__zh-CN__newki-id",
    path: _91_91id_93_936QFjLbisgwMeta?.path ?? "/mo/zh-CN/newki/:id?",
    meta: {...(_91_91id_93_936QFjLbisgwMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"newki-id"}},
    alias: _91_91id_93_936QFjLbisgwMeta?.alias || [],
    redirect: _91_91id_93_936QFjLbisgwMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/newki/[[id]].vue").then(m => m.default || m)
  },
  {
    name: _91_91id_93_936QFjLbisgwMeta?.name ?? "mo__en__newki-id",
    path: _91_91id_93_936QFjLbisgwMeta?.path ?? "/mo/en/newki/:id?",
    meta: {...(_91_91id_93_936QFjLbisgwMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"newki-id"}},
    alias: _91_91id_93_936QFjLbisgwMeta?.alias || [],
    redirect: _91_91id_93_936QFjLbisgwMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/newki/[[id]].vue").then(m => m.default || m)
  },
  {
    name: _91_91id_93_936QFjLbisgwMeta?.name ?? "cn__newki-id",
    path: _91_91id_93_936QFjLbisgwMeta?.path ?? "/cn/newki/:id?",
    meta: {...(_91_91id_93_936QFjLbisgwMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"newki-id"}},
    alias: _91_91id_93_936QFjLbisgwMeta?.alias || [],
    redirect: _91_91id_93_936QFjLbisgwMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/newki/[[id]].vue").then(m => m.default || m)
  },
  {
    name: _91_91id_93_936QFjLbisgwMeta?.name ?? "cn__zh-MO__newki-id",
    path: _91_91id_93_936QFjLbisgwMeta?.path ?? "/cn/zh-MO/newki/:id?",
    meta: {...(_91_91id_93_936QFjLbisgwMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"newki-id"}},
    alias: _91_91id_93_936QFjLbisgwMeta?.alias || [],
    redirect: _91_91id_93_936QFjLbisgwMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/newki/[[id]].vue").then(m => m.default || m)
  },
  {
    name: _91_91id_93_936QFjLbisgwMeta?.name ?? "cn__zh-HK__newki-id",
    path: _91_91id_93_936QFjLbisgwMeta?.path ?? "/cn/zh-HK/newki/:id?",
    meta: {...(_91_91id_93_936QFjLbisgwMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"newki-id"}},
    alias: _91_91id_93_936QFjLbisgwMeta?.alias || [],
    redirect: _91_91id_93_936QFjLbisgwMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/newki/[[id]].vue").then(m => m.default || m)
  },
  {
    name: _91_91id_93_936QFjLbisgwMeta?.name ?? "cn__zh-CN__newki-id",
    path: _91_91id_93_936QFjLbisgwMeta?.path ?? "/cn/zh-CN/newki/:id?",
    meta: {...(_91_91id_93_936QFjLbisgwMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"newki-id"}},
    alias: _91_91id_93_936QFjLbisgwMeta?.alias || [],
    redirect: _91_91id_93_936QFjLbisgwMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/newki/[[id]].vue").then(m => m.default || m)
  },
  {
    name: _91_91id_93_936QFjLbisgwMeta?.name ?? "cn__en__newki-id",
    path: _91_91id_93_936QFjLbisgwMeta?.path ?? "/cn/en/newki/:id?",
    meta: {...(_91_91id_93_936QFjLbisgwMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"newki-id"}},
    alias: _91_91id_93_936QFjLbisgwMeta?.alias || [],
    redirect: _91_91id_93_936QFjLbisgwMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/newki/[[id]].vue").then(m => m.default || m)
  },
  {
    name: _91id_93xN2rJVLPBfMeta?.name ?? "hk__order-detail-id",
    path: _91id_93xN2rJVLPBfMeta?.path ?? "/hk/order/detail/:id()",
    meta: {...(_91id_93xN2rJVLPBfMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"order-detail-id"}},
    alias: _91id_93xN2rJVLPBfMeta?.alias || [],
    redirect: _91id_93xN2rJVLPBfMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93xN2rJVLPBfMeta?.name ?? "hk__zh-MO__order-detail-id",
    path: _91id_93xN2rJVLPBfMeta?.path ?? "/hk/zh-MO/order/detail/:id()",
    meta: {...(_91id_93xN2rJVLPBfMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"order-detail-id"}},
    alias: _91id_93xN2rJVLPBfMeta?.alias || [],
    redirect: _91id_93xN2rJVLPBfMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93xN2rJVLPBfMeta?.name ?? "order-detail-id",
    path: _91id_93xN2rJVLPBfMeta?.path ?? "/order/detail/:id()",
    meta: {...(_91id_93xN2rJVLPBfMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"order-detail-id"}},
    alias: _91id_93xN2rJVLPBfMeta?.alias || [],
    redirect: _91id_93xN2rJVLPBfMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93xN2rJVLPBfMeta?.name ?? "hk__zh-HK__order-detail-id",
    path: _91id_93xN2rJVLPBfMeta?.path ?? "/hk/zh-HK/order/detail/:id()",
    meta: {...(_91id_93xN2rJVLPBfMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"order-detail-id"}},
    alias: _91id_93xN2rJVLPBfMeta?.alias || [],
    redirect: _91id_93xN2rJVLPBfMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93xN2rJVLPBfMeta?.name ?? "hk__zh-CN__order-detail-id",
    path: _91id_93xN2rJVLPBfMeta?.path ?? "/hk/zh-CN/order/detail/:id()",
    meta: {...(_91id_93xN2rJVLPBfMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"order-detail-id"}},
    alias: _91id_93xN2rJVLPBfMeta?.alias || [],
    redirect: _91id_93xN2rJVLPBfMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93xN2rJVLPBfMeta?.name ?? "hk__en__order-detail-id",
    path: _91id_93xN2rJVLPBfMeta?.path ?? "/hk/en/order/detail/:id()",
    meta: {...(_91id_93xN2rJVLPBfMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"order-detail-id"}},
    alias: _91id_93xN2rJVLPBfMeta?.alias || [],
    redirect: _91id_93xN2rJVLPBfMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93xN2rJVLPBfMeta?.name ?? "mo__order-detail-id",
    path: _91id_93xN2rJVLPBfMeta?.path ?? "/mo/order/detail/:id()",
    meta: {...(_91id_93xN2rJVLPBfMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"order-detail-id"}},
    alias: _91id_93xN2rJVLPBfMeta?.alias || [],
    redirect: _91id_93xN2rJVLPBfMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93xN2rJVLPBfMeta?.name ?? "mo__zh-MO__order-detail-id",
    path: _91id_93xN2rJVLPBfMeta?.path ?? "/mo/zh-MO/order/detail/:id()",
    meta: {...(_91id_93xN2rJVLPBfMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"order-detail-id"}},
    alias: _91id_93xN2rJVLPBfMeta?.alias || [],
    redirect: _91id_93xN2rJVLPBfMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93xN2rJVLPBfMeta?.name ?? "mo__zh-HK__order-detail-id",
    path: _91id_93xN2rJVLPBfMeta?.path ?? "/mo/zh-HK/order/detail/:id()",
    meta: {...(_91id_93xN2rJVLPBfMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"order-detail-id"}},
    alias: _91id_93xN2rJVLPBfMeta?.alias || [],
    redirect: _91id_93xN2rJVLPBfMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93xN2rJVLPBfMeta?.name ?? "mo__zh-CN__order-detail-id",
    path: _91id_93xN2rJVLPBfMeta?.path ?? "/mo/zh-CN/order/detail/:id()",
    meta: {...(_91id_93xN2rJVLPBfMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"order-detail-id"}},
    alias: _91id_93xN2rJVLPBfMeta?.alias || [],
    redirect: _91id_93xN2rJVLPBfMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93xN2rJVLPBfMeta?.name ?? "mo__en__order-detail-id",
    path: _91id_93xN2rJVLPBfMeta?.path ?? "/mo/en/order/detail/:id()",
    meta: {...(_91id_93xN2rJVLPBfMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"order-detail-id"}},
    alias: _91id_93xN2rJVLPBfMeta?.alias || [],
    redirect: _91id_93xN2rJVLPBfMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93xN2rJVLPBfMeta?.name ?? "cn__order-detail-id",
    path: _91id_93xN2rJVLPBfMeta?.path ?? "/cn/order/detail/:id()",
    meta: {...(_91id_93xN2rJVLPBfMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"order-detail-id"}},
    alias: _91id_93xN2rJVLPBfMeta?.alias || [],
    redirect: _91id_93xN2rJVLPBfMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93xN2rJVLPBfMeta?.name ?? "cn__zh-MO__order-detail-id",
    path: _91id_93xN2rJVLPBfMeta?.path ?? "/cn/zh-MO/order/detail/:id()",
    meta: {...(_91id_93xN2rJVLPBfMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"order-detail-id"}},
    alias: _91id_93xN2rJVLPBfMeta?.alias || [],
    redirect: _91id_93xN2rJVLPBfMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93xN2rJVLPBfMeta?.name ?? "cn__zh-HK__order-detail-id",
    path: _91id_93xN2rJVLPBfMeta?.path ?? "/cn/zh-HK/order/detail/:id()",
    meta: {...(_91id_93xN2rJVLPBfMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"order-detail-id"}},
    alias: _91id_93xN2rJVLPBfMeta?.alias || [],
    redirect: _91id_93xN2rJVLPBfMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93xN2rJVLPBfMeta?.name ?? "cn__zh-CN__order-detail-id",
    path: _91id_93xN2rJVLPBfMeta?.path ?? "/cn/zh-CN/order/detail/:id()",
    meta: {...(_91id_93xN2rJVLPBfMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"order-detail-id"}},
    alias: _91id_93xN2rJVLPBfMeta?.alias || [],
    redirect: _91id_93xN2rJVLPBfMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93xN2rJVLPBfMeta?.name ?? "cn__en__order-detail-id",
    path: _91id_93xN2rJVLPBfMeta?.path ?? "/cn/en/order/detail/:id()",
    meta: {...(_91id_93xN2rJVLPBfMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"order-detail-id"}},
    alias: _91id_93xN2rJVLPBfMeta?.alias || [],
    redirect: _91id_93xN2rJVLPBfMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: listv4VjYDosHoMeta?.name ?? "hk__order-list",
    path: listv4VjYDosHoMeta?.path ?? "/hk/order/list",
    meta: {...(listv4VjYDosHoMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"order-list"}},
    alias: listv4VjYDosHoMeta?.alias || [],
    redirect: listv4VjYDosHoMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/list.vue").then(m => m.default || m)
  },
  {
    name: listv4VjYDosHoMeta?.name ?? "hk__zh-MO__order-list",
    path: listv4VjYDosHoMeta?.path ?? "/hk/zh-MO/order/list",
    meta: {...(listv4VjYDosHoMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"order-list"}},
    alias: listv4VjYDosHoMeta?.alias || [],
    redirect: listv4VjYDosHoMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/list.vue").then(m => m.default || m)
  },
  {
    name: listv4VjYDosHoMeta?.name ?? "order-list",
    path: listv4VjYDosHoMeta?.path ?? "/order/list",
    meta: {...(listv4VjYDosHoMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"order-list"}},
    alias: listv4VjYDosHoMeta?.alias || [],
    redirect: listv4VjYDosHoMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/list.vue").then(m => m.default || m)
  },
  {
    name: listv4VjYDosHoMeta?.name ?? "hk__zh-HK__order-list",
    path: listv4VjYDosHoMeta?.path ?? "/hk/zh-HK/order/list",
    meta: {...(listv4VjYDosHoMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"order-list"}},
    alias: listv4VjYDosHoMeta?.alias || [],
    redirect: listv4VjYDosHoMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/list.vue").then(m => m.default || m)
  },
  {
    name: listv4VjYDosHoMeta?.name ?? "hk__zh-CN__order-list",
    path: listv4VjYDosHoMeta?.path ?? "/hk/zh-CN/order/list",
    meta: {...(listv4VjYDosHoMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"order-list"}},
    alias: listv4VjYDosHoMeta?.alias || [],
    redirect: listv4VjYDosHoMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/list.vue").then(m => m.default || m)
  },
  {
    name: listv4VjYDosHoMeta?.name ?? "hk__en__order-list",
    path: listv4VjYDosHoMeta?.path ?? "/hk/en/order/list",
    meta: {...(listv4VjYDosHoMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"order-list"}},
    alias: listv4VjYDosHoMeta?.alias || [],
    redirect: listv4VjYDosHoMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/list.vue").then(m => m.default || m)
  },
  {
    name: listv4VjYDosHoMeta?.name ?? "mo__order-list",
    path: listv4VjYDosHoMeta?.path ?? "/mo/order/list",
    meta: {...(listv4VjYDosHoMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"order-list"}},
    alias: listv4VjYDosHoMeta?.alias || [],
    redirect: listv4VjYDosHoMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/list.vue").then(m => m.default || m)
  },
  {
    name: listv4VjYDosHoMeta?.name ?? "mo__zh-MO__order-list",
    path: listv4VjYDosHoMeta?.path ?? "/mo/zh-MO/order/list",
    meta: {...(listv4VjYDosHoMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"order-list"}},
    alias: listv4VjYDosHoMeta?.alias || [],
    redirect: listv4VjYDosHoMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/list.vue").then(m => m.default || m)
  },
  {
    name: listv4VjYDosHoMeta?.name ?? "mo__zh-HK__order-list",
    path: listv4VjYDosHoMeta?.path ?? "/mo/zh-HK/order/list",
    meta: {...(listv4VjYDosHoMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"order-list"}},
    alias: listv4VjYDosHoMeta?.alias || [],
    redirect: listv4VjYDosHoMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/list.vue").then(m => m.default || m)
  },
  {
    name: listv4VjYDosHoMeta?.name ?? "mo__zh-CN__order-list",
    path: listv4VjYDosHoMeta?.path ?? "/mo/zh-CN/order/list",
    meta: {...(listv4VjYDosHoMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"order-list"}},
    alias: listv4VjYDosHoMeta?.alias || [],
    redirect: listv4VjYDosHoMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/list.vue").then(m => m.default || m)
  },
  {
    name: listv4VjYDosHoMeta?.name ?? "mo__en__order-list",
    path: listv4VjYDosHoMeta?.path ?? "/mo/en/order/list",
    meta: {...(listv4VjYDosHoMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"order-list"}},
    alias: listv4VjYDosHoMeta?.alias || [],
    redirect: listv4VjYDosHoMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/list.vue").then(m => m.default || m)
  },
  {
    name: listv4VjYDosHoMeta?.name ?? "cn__order-list",
    path: listv4VjYDosHoMeta?.path ?? "/cn/order/list",
    meta: {...(listv4VjYDosHoMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"order-list"}},
    alias: listv4VjYDosHoMeta?.alias || [],
    redirect: listv4VjYDosHoMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/list.vue").then(m => m.default || m)
  },
  {
    name: listv4VjYDosHoMeta?.name ?? "cn__zh-MO__order-list",
    path: listv4VjYDosHoMeta?.path ?? "/cn/zh-MO/order/list",
    meta: {...(listv4VjYDosHoMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"order-list"}},
    alias: listv4VjYDosHoMeta?.alias || [],
    redirect: listv4VjYDosHoMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/list.vue").then(m => m.default || m)
  },
  {
    name: listv4VjYDosHoMeta?.name ?? "cn__zh-HK__order-list",
    path: listv4VjYDosHoMeta?.path ?? "/cn/zh-HK/order/list",
    meta: {...(listv4VjYDosHoMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"order-list"}},
    alias: listv4VjYDosHoMeta?.alias || [],
    redirect: listv4VjYDosHoMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/list.vue").then(m => m.default || m)
  },
  {
    name: listv4VjYDosHoMeta?.name ?? "cn__zh-CN__order-list",
    path: listv4VjYDosHoMeta?.path ?? "/cn/zh-CN/order/list",
    meta: {...(listv4VjYDosHoMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"order-list"}},
    alias: listv4VjYDosHoMeta?.alias || [],
    redirect: listv4VjYDosHoMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/list.vue").then(m => m.default || m)
  },
  {
    name: listv4VjYDosHoMeta?.name ?? "cn__en__order-list",
    path: listv4VjYDosHoMeta?.path ?? "/cn/en/order/list",
    meta: {...(listv4VjYDosHoMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"order-list"}},
    alias: listv4VjYDosHoMeta?.alias || [],
    redirect: listv4VjYDosHoMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/list.vue").then(m => m.default || m)
  },
  {
    name: indexJMjrrXzgJHMeta?.name ?? "hk__order-search",
    path: indexJMjrrXzgJHMeta?.path ?? "/hk/order/search",
    meta: {...(indexJMjrrXzgJHMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"order-search"}},
    alias: indexJMjrrXzgJHMeta?.alias || [],
    redirect: indexJMjrrXzgJHMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexJMjrrXzgJHMeta?.name ?? "hk__zh-MO__order-search",
    path: indexJMjrrXzgJHMeta?.path ?? "/hk/zh-MO/order/search",
    meta: {...(indexJMjrrXzgJHMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"order-search"}},
    alias: indexJMjrrXzgJHMeta?.alias || [],
    redirect: indexJMjrrXzgJHMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexJMjrrXzgJHMeta?.name ?? "order-search",
    path: indexJMjrrXzgJHMeta?.path ?? "/order/search",
    meta: {...(indexJMjrrXzgJHMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"order-search"}},
    alias: indexJMjrrXzgJHMeta?.alias || [],
    redirect: indexJMjrrXzgJHMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexJMjrrXzgJHMeta?.name ?? "hk__zh-HK__order-search",
    path: indexJMjrrXzgJHMeta?.path ?? "/hk/zh-HK/order/search",
    meta: {...(indexJMjrrXzgJHMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"order-search"}},
    alias: indexJMjrrXzgJHMeta?.alias || [],
    redirect: indexJMjrrXzgJHMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexJMjrrXzgJHMeta?.name ?? "hk__zh-CN__order-search",
    path: indexJMjrrXzgJHMeta?.path ?? "/hk/zh-CN/order/search",
    meta: {...(indexJMjrrXzgJHMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"order-search"}},
    alias: indexJMjrrXzgJHMeta?.alias || [],
    redirect: indexJMjrrXzgJHMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexJMjrrXzgJHMeta?.name ?? "hk__en__order-search",
    path: indexJMjrrXzgJHMeta?.path ?? "/hk/en/order/search",
    meta: {...(indexJMjrrXzgJHMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"order-search"}},
    alias: indexJMjrrXzgJHMeta?.alias || [],
    redirect: indexJMjrrXzgJHMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexJMjrrXzgJHMeta?.name ?? "mo__order-search",
    path: indexJMjrrXzgJHMeta?.path ?? "/mo/order/search",
    meta: {...(indexJMjrrXzgJHMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"order-search"}},
    alias: indexJMjrrXzgJHMeta?.alias || [],
    redirect: indexJMjrrXzgJHMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexJMjrrXzgJHMeta?.name ?? "mo__zh-MO__order-search",
    path: indexJMjrrXzgJHMeta?.path ?? "/mo/zh-MO/order/search",
    meta: {...(indexJMjrrXzgJHMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"order-search"}},
    alias: indexJMjrrXzgJHMeta?.alias || [],
    redirect: indexJMjrrXzgJHMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexJMjrrXzgJHMeta?.name ?? "mo__zh-HK__order-search",
    path: indexJMjrrXzgJHMeta?.path ?? "/mo/zh-HK/order/search",
    meta: {...(indexJMjrrXzgJHMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"order-search"}},
    alias: indexJMjrrXzgJHMeta?.alias || [],
    redirect: indexJMjrrXzgJHMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexJMjrrXzgJHMeta?.name ?? "mo__zh-CN__order-search",
    path: indexJMjrrXzgJHMeta?.path ?? "/mo/zh-CN/order/search",
    meta: {...(indexJMjrrXzgJHMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"order-search"}},
    alias: indexJMjrrXzgJHMeta?.alias || [],
    redirect: indexJMjrrXzgJHMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexJMjrrXzgJHMeta?.name ?? "mo__en__order-search",
    path: indexJMjrrXzgJHMeta?.path ?? "/mo/en/order/search",
    meta: {...(indexJMjrrXzgJHMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"order-search"}},
    alias: indexJMjrrXzgJHMeta?.alias || [],
    redirect: indexJMjrrXzgJHMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexJMjrrXzgJHMeta?.name ?? "cn__order-search",
    path: indexJMjrrXzgJHMeta?.path ?? "/cn/order/search",
    meta: {...(indexJMjrrXzgJHMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"order-search"}},
    alias: indexJMjrrXzgJHMeta?.alias || [],
    redirect: indexJMjrrXzgJHMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexJMjrrXzgJHMeta?.name ?? "cn__zh-MO__order-search",
    path: indexJMjrrXzgJHMeta?.path ?? "/cn/zh-MO/order/search",
    meta: {...(indexJMjrrXzgJHMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"order-search"}},
    alias: indexJMjrrXzgJHMeta?.alias || [],
    redirect: indexJMjrrXzgJHMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexJMjrrXzgJHMeta?.name ?? "cn__zh-HK__order-search",
    path: indexJMjrrXzgJHMeta?.path ?? "/cn/zh-HK/order/search",
    meta: {...(indexJMjrrXzgJHMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"order-search"}},
    alias: indexJMjrrXzgJHMeta?.alias || [],
    redirect: indexJMjrrXzgJHMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexJMjrrXzgJHMeta?.name ?? "cn__zh-CN__order-search",
    path: indexJMjrrXzgJHMeta?.path ?? "/cn/zh-CN/order/search",
    meta: {...(indexJMjrrXzgJHMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"order-search"}},
    alias: indexJMjrrXzgJHMeta?.alias || [],
    redirect: indexJMjrrXzgJHMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexJMjrrXzgJHMeta?.name ?? "cn__en__order-search",
    path: indexJMjrrXzgJHMeta?.path ?? "/cn/en/order/search",
    meta: {...(indexJMjrrXzgJHMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"order-search"}},
    alias: indexJMjrrXzgJHMeta?.alias || [],
    redirect: indexJMjrrXzgJHMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/order/search/index.vue").then(m => m.default || m)
  },
  {
    name: formRMGeoVdxcuMeta?.name ?? "hk__pay-way-form",
    path: formRMGeoVdxcuMeta?.path ?? "/hk/pay-way/form",
    meta: {...(formRMGeoVdxcuMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"pay-way-form"}},
    alias: formRMGeoVdxcuMeta?.alias || [],
    redirect: formRMGeoVdxcuMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/form.vue").then(m => m.default || m)
  },
  {
    name: formRMGeoVdxcuMeta?.name ?? "hk__zh-MO__pay-way-form",
    path: formRMGeoVdxcuMeta?.path ?? "/hk/zh-MO/pay-way/form",
    meta: {...(formRMGeoVdxcuMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"pay-way-form"}},
    alias: formRMGeoVdxcuMeta?.alias || [],
    redirect: formRMGeoVdxcuMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/form.vue").then(m => m.default || m)
  },
  {
    name: formRMGeoVdxcuMeta?.name ?? "pay-way-form",
    path: formRMGeoVdxcuMeta?.path ?? "/pay-way/form",
    meta: {...(formRMGeoVdxcuMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"pay-way-form"}},
    alias: formRMGeoVdxcuMeta?.alias || [],
    redirect: formRMGeoVdxcuMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/form.vue").then(m => m.default || m)
  },
  {
    name: formRMGeoVdxcuMeta?.name ?? "hk__zh-HK__pay-way-form",
    path: formRMGeoVdxcuMeta?.path ?? "/hk/zh-HK/pay-way/form",
    meta: {...(formRMGeoVdxcuMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"pay-way-form"}},
    alias: formRMGeoVdxcuMeta?.alias || [],
    redirect: formRMGeoVdxcuMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/form.vue").then(m => m.default || m)
  },
  {
    name: formRMGeoVdxcuMeta?.name ?? "hk__zh-CN__pay-way-form",
    path: formRMGeoVdxcuMeta?.path ?? "/hk/zh-CN/pay-way/form",
    meta: {...(formRMGeoVdxcuMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"pay-way-form"}},
    alias: formRMGeoVdxcuMeta?.alias || [],
    redirect: formRMGeoVdxcuMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/form.vue").then(m => m.default || m)
  },
  {
    name: formRMGeoVdxcuMeta?.name ?? "hk__en__pay-way-form",
    path: formRMGeoVdxcuMeta?.path ?? "/hk/en/pay-way/form",
    meta: {...(formRMGeoVdxcuMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"pay-way-form"}},
    alias: formRMGeoVdxcuMeta?.alias || [],
    redirect: formRMGeoVdxcuMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/form.vue").then(m => m.default || m)
  },
  {
    name: formRMGeoVdxcuMeta?.name ?? "mo__pay-way-form",
    path: formRMGeoVdxcuMeta?.path ?? "/mo/pay-way/form",
    meta: {...(formRMGeoVdxcuMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"pay-way-form"}},
    alias: formRMGeoVdxcuMeta?.alias || [],
    redirect: formRMGeoVdxcuMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/form.vue").then(m => m.default || m)
  },
  {
    name: formRMGeoVdxcuMeta?.name ?? "mo__zh-MO__pay-way-form",
    path: formRMGeoVdxcuMeta?.path ?? "/mo/zh-MO/pay-way/form",
    meta: {...(formRMGeoVdxcuMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"pay-way-form"}},
    alias: formRMGeoVdxcuMeta?.alias || [],
    redirect: formRMGeoVdxcuMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/form.vue").then(m => m.default || m)
  },
  {
    name: formRMGeoVdxcuMeta?.name ?? "mo__zh-HK__pay-way-form",
    path: formRMGeoVdxcuMeta?.path ?? "/mo/zh-HK/pay-way/form",
    meta: {...(formRMGeoVdxcuMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"pay-way-form"}},
    alias: formRMGeoVdxcuMeta?.alias || [],
    redirect: formRMGeoVdxcuMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/form.vue").then(m => m.default || m)
  },
  {
    name: formRMGeoVdxcuMeta?.name ?? "mo__zh-CN__pay-way-form",
    path: formRMGeoVdxcuMeta?.path ?? "/mo/zh-CN/pay-way/form",
    meta: {...(formRMGeoVdxcuMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"pay-way-form"}},
    alias: formRMGeoVdxcuMeta?.alias || [],
    redirect: formRMGeoVdxcuMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/form.vue").then(m => m.default || m)
  },
  {
    name: formRMGeoVdxcuMeta?.name ?? "mo__en__pay-way-form",
    path: formRMGeoVdxcuMeta?.path ?? "/mo/en/pay-way/form",
    meta: {...(formRMGeoVdxcuMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"pay-way-form"}},
    alias: formRMGeoVdxcuMeta?.alias || [],
    redirect: formRMGeoVdxcuMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/form.vue").then(m => m.default || m)
  },
  {
    name: formRMGeoVdxcuMeta?.name ?? "cn__pay-way-form",
    path: formRMGeoVdxcuMeta?.path ?? "/cn/pay-way/form",
    meta: {...(formRMGeoVdxcuMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"pay-way-form"}},
    alias: formRMGeoVdxcuMeta?.alias || [],
    redirect: formRMGeoVdxcuMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/form.vue").then(m => m.default || m)
  },
  {
    name: formRMGeoVdxcuMeta?.name ?? "cn__zh-MO__pay-way-form",
    path: formRMGeoVdxcuMeta?.path ?? "/cn/zh-MO/pay-way/form",
    meta: {...(formRMGeoVdxcuMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"pay-way-form"}},
    alias: formRMGeoVdxcuMeta?.alias || [],
    redirect: formRMGeoVdxcuMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/form.vue").then(m => m.default || m)
  },
  {
    name: formRMGeoVdxcuMeta?.name ?? "cn__zh-HK__pay-way-form",
    path: formRMGeoVdxcuMeta?.path ?? "/cn/zh-HK/pay-way/form",
    meta: {...(formRMGeoVdxcuMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"pay-way-form"}},
    alias: formRMGeoVdxcuMeta?.alias || [],
    redirect: formRMGeoVdxcuMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/form.vue").then(m => m.default || m)
  },
  {
    name: formRMGeoVdxcuMeta?.name ?? "cn__zh-CN__pay-way-form",
    path: formRMGeoVdxcuMeta?.path ?? "/cn/zh-CN/pay-way/form",
    meta: {...(formRMGeoVdxcuMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"pay-way-form"}},
    alias: formRMGeoVdxcuMeta?.alias || [],
    redirect: formRMGeoVdxcuMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/form.vue").then(m => m.default || m)
  },
  {
    name: formRMGeoVdxcuMeta?.name ?? "cn__en__pay-way-form",
    path: formRMGeoVdxcuMeta?.path ?? "/cn/en/pay-way/form",
    meta: {...(formRMGeoVdxcuMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"pay-way-form"}},
    alias: formRMGeoVdxcuMeta?.alias || [],
    redirect: formRMGeoVdxcuMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/form.vue").then(m => m.default || m)
  },
  {
    name: mpayVTZWHOI1BDMeta?.name ?? "hk__pay-way-mpay",
    path: mpayVTZWHOI1BDMeta?.path ?? "/hk/pay-way/mpay",
    meta: {...(mpayVTZWHOI1BDMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"pay-way-mpay"}},
    alias: mpayVTZWHOI1BDMeta?.alias || [],
    redirect: mpayVTZWHOI1BDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/mpay.vue").then(m => m.default || m)
  },
  {
    name: mpayVTZWHOI1BDMeta?.name ?? "hk__zh-MO__pay-way-mpay",
    path: mpayVTZWHOI1BDMeta?.path ?? "/hk/zh-MO/pay-way/mpay",
    meta: {...(mpayVTZWHOI1BDMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"pay-way-mpay"}},
    alias: mpayVTZWHOI1BDMeta?.alias || [],
    redirect: mpayVTZWHOI1BDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/mpay.vue").then(m => m.default || m)
  },
  {
    name: mpayVTZWHOI1BDMeta?.name ?? "pay-way-mpay",
    path: mpayVTZWHOI1BDMeta?.path ?? "/pay-way/mpay",
    meta: {...(mpayVTZWHOI1BDMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"pay-way-mpay"}},
    alias: mpayVTZWHOI1BDMeta?.alias || [],
    redirect: mpayVTZWHOI1BDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/mpay.vue").then(m => m.default || m)
  },
  {
    name: mpayVTZWHOI1BDMeta?.name ?? "hk__zh-HK__pay-way-mpay",
    path: mpayVTZWHOI1BDMeta?.path ?? "/hk/zh-HK/pay-way/mpay",
    meta: {...(mpayVTZWHOI1BDMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"pay-way-mpay"}},
    alias: mpayVTZWHOI1BDMeta?.alias || [],
    redirect: mpayVTZWHOI1BDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/mpay.vue").then(m => m.default || m)
  },
  {
    name: mpayVTZWHOI1BDMeta?.name ?? "hk__zh-CN__pay-way-mpay",
    path: mpayVTZWHOI1BDMeta?.path ?? "/hk/zh-CN/pay-way/mpay",
    meta: {...(mpayVTZWHOI1BDMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"pay-way-mpay"}},
    alias: mpayVTZWHOI1BDMeta?.alias || [],
    redirect: mpayVTZWHOI1BDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/mpay.vue").then(m => m.default || m)
  },
  {
    name: mpayVTZWHOI1BDMeta?.name ?? "hk__en__pay-way-mpay",
    path: mpayVTZWHOI1BDMeta?.path ?? "/hk/en/pay-way/mpay",
    meta: {...(mpayVTZWHOI1BDMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"pay-way-mpay"}},
    alias: mpayVTZWHOI1BDMeta?.alias || [],
    redirect: mpayVTZWHOI1BDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/mpay.vue").then(m => m.default || m)
  },
  {
    name: mpayVTZWHOI1BDMeta?.name ?? "mo__pay-way-mpay",
    path: mpayVTZWHOI1BDMeta?.path ?? "/mo/pay-way/mpay",
    meta: {...(mpayVTZWHOI1BDMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"pay-way-mpay"}},
    alias: mpayVTZWHOI1BDMeta?.alias || [],
    redirect: mpayVTZWHOI1BDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/mpay.vue").then(m => m.default || m)
  },
  {
    name: mpayVTZWHOI1BDMeta?.name ?? "mo__zh-MO__pay-way-mpay",
    path: mpayVTZWHOI1BDMeta?.path ?? "/mo/zh-MO/pay-way/mpay",
    meta: {...(mpayVTZWHOI1BDMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"pay-way-mpay"}},
    alias: mpayVTZWHOI1BDMeta?.alias || [],
    redirect: mpayVTZWHOI1BDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/mpay.vue").then(m => m.default || m)
  },
  {
    name: mpayVTZWHOI1BDMeta?.name ?? "mo__zh-HK__pay-way-mpay",
    path: mpayVTZWHOI1BDMeta?.path ?? "/mo/zh-HK/pay-way/mpay",
    meta: {...(mpayVTZWHOI1BDMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"pay-way-mpay"}},
    alias: mpayVTZWHOI1BDMeta?.alias || [],
    redirect: mpayVTZWHOI1BDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/mpay.vue").then(m => m.default || m)
  },
  {
    name: mpayVTZWHOI1BDMeta?.name ?? "mo__zh-CN__pay-way-mpay",
    path: mpayVTZWHOI1BDMeta?.path ?? "/mo/zh-CN/pay-way/mpay",
    meta: {...(mpayVTZWHOI1BDMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"pay-way-mpay"}},
    alias: mpayVTZWHOI1BDMeta?.alias || [],
    redirect: mpayVTZWHOI1BDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/mpay.vue").then(m => m.default || m)
  },
  {
    name: mpayVTZWHOI1BDMeta?.name ?? "mo__en__pay-way-mpay",
    path: mpayVTZWHOI1BDMeta?.path ?? "/mo/en/pay-way/mpay",
    meta: {...(mpayVTZWHOI1BDMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"pay-way-mpay"}},
    alias: mpayVTZWHOI1BDMeta?.alias || [],
    redirect: mpayVTZWHOI1BDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/mpay.vue").then(m => m.default || m)
  },
  {
    name: mpayVTZWHOI1BDMeta?.name ?? "cn__pay-way-mpay",
    path: mpayVTZWHOI1BDMeta?.path ?? "/cn/pay-way/mpay",
    meta: {...(mpayVTZWHOI1BDMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"pay-way-mpay"}},
    alias: mpayVTZWHOI1BDMeta?.alias || [],
    redirect: mpayVTZWHOI1BDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/mpay.vue").then(m => m.default || m)
  },
  {
    name: mpayVTZWHOI1BDMeta?.name ?? "cn__zh-MO__pay-way-mpay",
    path: mpayVTZWHOI1BDMeta?.path ?? "/cn/zh-MO/pay-way/mpay",
    meta: {...(mpayVTZWHOI1BDMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"pay-way-mpay"}},
    alias: mpayVTZWHOI1BDMeta?.alias || [],
    redirect: mpayVTZWHOI1BDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/mpay.vue").then(m => m.default || m)
  },
  {
    name: mpayVTZWHOI1BDMeta?.name ?? "cn__zh-HK__pay-way-mpay",
    path: mpayVTZWHOI1BDMeta?.path ?? "/cn/zh-HK/pay-way/mpay",
    meta: {...(mpayVTZWHOI1BDMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"pay-way-mpay"}},
    alias: mpayVTZWHOI1BDMeta?.alias || [],
    redirect: mpayVTZWHOI1BDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/mpay.vue").then(m => m.default || m)
  },
  {
    name: mpayVTZWHOI1BDMeta?.name ?? "cn__zh-CN__pay-way-mpay",
    path: mpayVTZWHOI1BDMeta?.path ?? "/cn/zh-CN/pay-way/mpay",
    meta: {...(mpayVTZWHOI1BDMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"pay-way-mpay"}},
    alias: mpayVTZWHOI1BDMeta?.alias || [],
    redirect: mpayVTZWHOI1BDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/mpay.vue").then(m => m.default || m)
  },
  {
    name: mpayVTZWHOI1BDMeta?.name ?? "cn__en__pay-way-mpay",
    path: mpayVTZWHOI1BDMeta?.path ?? "/cn/en/pay-way/mpay",
    meta: {...(mpayVTZWHOI1BDMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"pay-way-mpay"}},
    alias: mpayVTZWHOI1BDMeta?.alias || [],
    redirect: mpayVTZWHOI1BDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/mpay.vue").then(m => m.default || m)
  },
  {
    name: mpayOldfcCbTMliOtMeta?.name ?? "hk__pay-way-mpayOld",
    path: mpayOldfcCbTMliOtMeta?.path ?? "/hk/pay-way/mpayOld",
    meta: {...(mpayOldfcCbTMliOtMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"pay-way-mpayOld"}},
    alias: mpayOldfcCbTMliOtMeta?.alias || [],
    redirect: mpayOldfcCbTMliOtMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/mpayOld.vue").then(m => m.default || m)
  },
  {
    name: mpayOldfcCbTMliOtMeta?.name ?? "hk__zh-MO__pay-way-mpayOld",
    path: mpayOldfcCbTMliOtMeta?.path ?? "/hk/zh-MO/pay-way/mpayOld",
    meta: {...(mpayOldfcCbTMliOtMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"pay-way-mpayOld"}},
    alias: mpayOldfcCbTMliOtMeta?.alias || [],
    redirect: mpayOldfcCbTMliOtMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/mpayOld.vue").then(m => m.default || m)
  },
  {
    name: mpayOldfcCbTMliOtMeta?.name ?? "pay-way-mpayOld",
    path: mpayOldfcCbTMliOtMeta?.path ?? "/pay-way/mpayOld",
    meta: {...(mpayOldfcCbTMliOtMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"pay-way-mpayOld"}},
    alias: mpayOldfcCbTMliOtMeta?.alias || [],
    redirect: mpayOldfcCbTMliOtMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/mpayOld.vue").then(m => m.default || m)
  },
  {
    name: mpayOldfcCbTMliOtMeta?.name ?? "hk__zh-HK__pay-way-mpayOld",
    path: mpayOldfcCbTMliOtMeta?.path ?? "/hk/zh-HK/pay-way/mpayOld",
    meta: {...(mpayOldfcCbTMliOtMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"pay-way-mpayOld"}},
    alias: mpayOldfcCbTMliOtMeta?.alias || [],
    redirect: mpayOldfcCbTMliOtMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/mpayOld.vue").then(m => m.default || m)
  },
  {
    name: mpayOldfcCbTMliOtMeta?.name ?? "hk__zh-CN__pay-way-mpayOld",
    path: mpayOldfcCbTMliOtMeta?.path ?? "/hk/zh-CN/pay-way/mpayOld",
    meta: {...(mpayOldfcCbTMliOtMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"pay-way-mpayOld"}},
    alias: mpayOldfcCbTMliOtMeta?.alias || [],
    redirect: mpayOldfcCbTMliOtMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/mpayOld.vue").then(m => m.default || m)
  },
  {
    name: mpayOldfcCbTMliOtMeta?.name ?? "hk__en__pay-way-mpayOld",
    path: mpayOldfcCbTMliOtMeta?.path ?? "/hk/en/pay-way/mpayOld",
    meta: {...(mpayOldfcCbTMliOtMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"pay-way-mpayOld"}},
    alias: mpayOldfcCbTMliOtMeta?.alias || [],
    redirect: mpayOldfcCbTMliOtMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/mpayOld.vue").then(m => m.default || m)
  },
  {
    name: mpayOldfcCbTMliOtMeta?.name ?? "mo__pay-way-mpayOld",
    path: mpayOldfcCbTMliOtMeta?.path ?? "/mo/pay-way/mpayOld",
    meta: {...(mpayOldfcCbTMliOtMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"pay-way-mpayOld"}},
    alias: mpayOldfcCbTMliOtMeta?.alias || [],
    redirect: mpayOldfcCbTMliOtMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/mpayOld.vue").then(m => m.default || m)
  },
  {
    name: mpayOldfcCbTMliOtMeta?.name ?? "mo__zh-MO__pay-way-mpayOld",
    path: mpayOldfcCbTMliOtMeta?.path ?? "/mo/zh-MO/pay-way/mpayOld",
    meta: {...(mpayOldfcCbTMliOtMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"pay-way-mpayOld"}},
    alias: mpayOldfcCbTMliOtMeta?.alias || [],
    redirect: mpayOldfcCbTMliOtMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/mpayOld.vue").then(m => m.default || m)
  },
  {
    name: mpayOldfcCbTMliOtMeta?.name ?? "mo__zh-HK__pay-way-mpayOld",
    path: mpayOldfcCbTMliOtMeta?.path ?? "/mo/zh-HK/pay-way/mpayOld",
    meta: {...(mpayOldfcCbTMliOtMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"pay-way-mpayOld"}},
    alias: mpayOldfcCbTMliOtMeta?.alias || [],
    redirect: mpayOldfcCbTMliOtMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/mpayOld.vue").then(m => m.default || m)
  },
  {
    name: mpayOldfcCbTMliOtMeta?.name ?? "mo__zh-CN__pay-way-mpayOld",
    path: mpayOldfcCbTMliOtMeta?.path ?? "/mo/zh-CN/pay-way/mpayOld",
    meta: {...(mpayOldfcCbTMliOtMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"pay-way-mpayOld"}},
    alias: mpayOldfcCbTMliOtMeta?.alias || [],
    redirect: mpayOldfcCbTMliOtMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/mpayOld.vue").then(m => m.default || m)
  },
  {
    name: mpayOldfcCbTMliOtMeta?.name ?? "mo__en__pay-way-mpayOld",
    path: mpayOldfcCbTMliOtMeta?.path ?? "/mo/en/pay-way/mpayOld",
    meta: {...(mpayOldfcCbTMliOtMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"pay-way-mpayOld"}},
    alias: mpayOldfcCbTMliOtMeta?.alias || [],
    redirect: mpayOldfcCbTMliOtMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/mpayOld.vue").then(m => m.default || m)
  },
  {
    name: mpayOldfcCbTMliOtMeta?.name ?? "cn__pay-way-mpayOld",
    path: mpayOldfcCbTMliOtMeta?.path ?? "/cn/pay-way/mpayOld",
    meta: {...(mpayOldfcCbTMliOtMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"pay-way-mpayOld"}},
    alias: mpayOldfcCbTMliOtMeta?.alias || [],
    redirect: mpayOldfcCbTMliOtMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/mpayOld.vue").then(m => m.default || m)
  },
  {
    name: mpayOldfcCbTMliOtMeta?.name ?? "cn__zh-MO__pay-way-mpayOld",
    path: mpayOldfcCbTMliOtMeta?.path ?? "/cn/zh-MO/pay-way/mpayOld",
    meta: {...(mpayOldfcCbTMliOtMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"pay-way-mpayOld"}},
    alias: mpayOldfcCbTMliOtMeta?.alias || [],
    redirect: mpayOldfcCbTMliOtMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/mpayOld.vue").then(m => m.default || m)
  },
  {
    name: mpayOldfcCbTMliOtMeta?.name ?? "cn__zh-HK__pay-way-mpayOld",
    path: mpayOldfcCbTMliOtMeta?.path ?? "/cn/zh-HK/pay-way/mpayOld",
    meta: {...(mpayOldfcCbTMliOtMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"pay-way-mpayOld"}},
    alias: mpayOldfcCbTMliOtMeta?.alias || [],
    redirect: mpayOldfcCbTMliOtMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/mpayOld.vue").then(m => m.default || m)
  },
  {
    name: mpayOldfcCbTMliOtMeta?.name ?? "cn__zh-CN__pay-way-mpayOld",
    path: mpayOldfcCbTMliOtMeta?.path ?? "/cn/zh-CN/pay-way/mpayOld",
    meta: {...(mpayOldfcCbTMliOtMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"pay-way-mpayOld"}},
    alias: mpayOldfcCbTMliOtMeta?.alias || [],
    redirect: mpayOldfcCbTMliOtMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/mpayOld.vue").then(m => m.default || m)
  },
  {
    name: mpayOldfcCbTMliOtMeta?.name ?? "cn__en__pay-way-mpayOld",
    path: mpayOldfcCbTMliOtMeta?.path ?? "/cn/en/pay-way/mpayOld",
    meta: {...(mpayOldfcCbTMliOtMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"pay-way-mpayOld"}},
    alias: mpayOldfcCbTMliOtMeta?.alias || [],
    redirect: mpayOldfcCbTMliOtMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/mpayOld.vue").then(m => m.default || m)
  },
  {
    name: tapgovjLXYE5ONKMeta?.name ?? "hk__pay-way-tapgo",
    path: tapgovjLXYE5ONKMeta?.path ?? "/hk/pay-way/tapgo",
    meta: {...(tapgovjLXYE5ONKMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"pay-way-tapgo"}},
    alias: tapgovjLXYE5ONKMeta?.alias || [],
    redirect: tapgovjLXYE5ONKMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/tapgo.vue").then(m => m.default || m)
  },
  {
    name: tapgovjLXYE5ONKMeta?.name ?? "hk__zh-MO__pay-way-tapgo",
    path: tapgovjLXYE5ONKMeta?.path ?? "/hk/zh-MO/pay-way/tapgo",
    meta: {...(tapgovjLXYE5ONKMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"pay-way-tapgo"}},
    alias: tapgovjLXYE5ONKMeta?.alias || [],
    redirect: tapgovjLXYE5ONKMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/tapgo.vue").then(m => m.default || m)
  },
  {
    name: tapgovjLXYE5ONKMeta?.name ?? "pay-way-tapgo",
    path: tapgovjLXYE5ONKMeta?.path ?? "/pay-way/tapgo",
    meta: {...(tapgovjLXYE5ONKMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"pay-way-tapgo"}},
    alias: tapgovjLXYE5ONKMeta?.alias || [],
    redirect: tapgovjLXYE5ONKMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/tapgo.vue").then(m => m.default || m)
  },
  {
    name: tapgovjLXYE5ONKMeta?.name ?? "hk__zh-HK__pay-way-tapgo",
    path: tapgovjLXYE5ONKMeta?.path ?? "/hk/zh-HK/pay-way/tapgo",
    meta: {...(tapgovjLXYE5ONKMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"pay-way-tapgo"}},
    alias: tapgovjLXYE5ONKMeta?.alias || [],
    redirect: tapgovjLXYE5ONKMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/tapgo.vue").then(m => m.default || m)
  },
  {
    name: tapgovjLXYE5ONKMeta?.name ?? "hk__zh-CN__pay-way-tapgo",
    path: tapgovjLXYE5ONKMeta?.path ?? "/hk/zh-CN/pay-way/tapgo",
    meta: {...(tapgovjLXYE5ONKMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"pay-way-tapgo"}},
    alias: tapgovjLXYE5ONKMeta?.alias || [],
    redirect: tapgovjLXYE5ONKMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/tapgo.vue").then(m => m.default || m)
  },
  {
    name: tapgovjLXYE5ONKMeta?.name ?? "hk__en__pay-way-tapgo",
    path: tapgovjLXYE5ONKMeta?.path ?? "/hk/en/pay-way/tapgo",
    meta: {...(tapgovjLXYE5ONKMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"pay-way-tapgo"}},
    alias: tapgovjLXYE5ONKMeta?.alias || [],
    redirect: tapgovjLXYE5ONKMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/tapgo.vue").then(m => m.default || m)
  },
  {
    name: tapgovjLXYE5ONKMeta?.name ?? "mo__pay-way-tapgo",
    path: tapgovjLXYE5ONKMeta?.path ?? "/mo/pay-way/tapgo",
    meta: {...(tapgovjLXYE5ONKMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"pay-way-tapgo"}},
    alias: tapgovjLXYE5ONKMeta?.alias || [],
    redirect: tapgovjLXYE5ONKMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/tapgo.vue").then(m => m.default || m)
  },
  {
    name: tapgovjLXYE5ONKMeta?.name ?? "mo__zh-MO__pay-way-tapgo",
    path: tapgovjLXYE5ONKMeta?.path ?? "/mo/zh-MO/pay-way/tapgo",
    meta: {...(tapgovjLXYE5ONKMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"pay-way-tapgo"}},
    alias: tapgovjLXYE5ONKMeta?.alias || [],
    redirect: tapgovjLXYE5ONKMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/tapgo.vue").then(m => m.default || m)
  },
  {
    name: tapgovjLXYE5ONKMeta?.name ?? "mo__zh-HK__pay-way-tapgo",
    path: tapgovjLXYE5ONKMeta?.path ?? "/mo/zh-HK/pay-way/tapgo",
    meta: {...(tapgovjLXYE5ONKMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"pay-way-tapgo"}},
    alias: tapgovjLXYE5ONKMeta?.alias || [],
    redirect: tapgovjLXYE5ONKMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/tapgo.vue").then(m => m.default || m)
  },
  {
    name: tapgovjLXYE5ONKMeta?.name ?? "mo__zh-CN__pay-way-tapgo",
    path: tapgovjLXYE5ONKMeta?.path ?? "/mo/zh-CN/pay-way/tapgo",
    meta: {...(tapgovjLXYE5ONKMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"pay-way-tapgo"}},
    alias: tapgovjLXYE5ONKMeta?.alias || [],
    redirect: tapgovjLXYE5ONKMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/tapgo.vue").then(m => m.default || m)
  },
  {
    name: tapgovjLXYE5ONKMeta?.name ?? "mo__en__pay-way-tapgo",
    path: tapgovjLXYE5ONKMeta?.path ?? "/mo/en/pay-way/tapgo",
    meta: {...(tapgovjLXYE5ONKMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"pay-way-tapgo"}},
    alias: tapgovjLXYE5ONKMeta?.alias || [],
    redirect: tapgovjLXYE5ONKMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/tapgo.vue").then(m => m.default || m)
  },
  {
    name: tapgovjLXYE5ONKMeta?.name ?? "cn__pay-way-tapgo",
    path: tapgovjLXYE5ONKMeta?.path ?? "/cn/pay-way/tapgo",
    meta: {...(tapgovjLXYE5ONKMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"pay-way-tapgo"}},
    alias: tapgovjLXYE5ONKMeta?.alias || [],
    redirect: tapgovjLXYE5ONKMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/tapgo.vue").then(m => m.default || m)
  },
  {
    name: tapgovjLXYE5ONKMeta?.name ?? "cn__zh-MO__pay-way-tapgo",
    path: tapgovjLXYE5ONKMeta?.path ?? "/cn/zh-MO/pay-way/tapgo",
    meta: {...(tapgovjLXYE5ONKMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"pay-way-tapgo"}},
    alias: tapgovjLXYE5ONKMeta?.alias || [],
    redirect: tapgovjLXYE5ONKMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/tapgo.vue").then(m => m.default || m)
  },
  {
    name: tapgovjLXYE5ONKMeta?.name ?? "cn__zh-HK__pay-way-tapgo",
    path: tapgovjLXYE5ONKMeta?.path ?? "/cn/zh-HK/pay-way/tapgo",
    meta: {...(tapgovjLXYE5ONKMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"pay-way-tapgo"}},
    alias: tapgovjLXYE5ONKMeta?.alias || [],
    redirect: tapgovjLXYE5ONKMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/tapgo.vue").then(m => m.default || m)
  },
  {
    name: tapgovjLXYE5ONKMeta?.name ?? "cn__zh-CN__pay-way-tapgo",
    path: tapgovjLXYE5ONKMeta?.path ?? "/cn/zh-CN/pay-way/tapgo",
    meta: {...(tapgovjLXYE5ONKMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"pay-way-tapgo"}},
    alias: tapgovjLXYE5ONKMeta?.alias || [],
    redirect: tapgovjLXYE5ONKMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/tapgo.vue").then(m => m.default || m)
  },
  {
    name: tapgovjLXYE5ONKMeta?.name ?? "cn__en__pay-way-tapgo",
    path: tapgovjLXYE5ONKMeta?.path ?? "/cn/en/pay-way/tapgo",
    meta: {...(tapgovjLXYE5ONKMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"pay-way-tapgo"}},
    alias: tapgovjLXYE5ONKMeta?.alias || [],
    redirect: tapgovjLXYE5ONKMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/tapgo.vue").then(m => m.default || m)
  },
  {
    name: _91payId_933eE7d2h192Meta?.name ?? "hk__pay-way-transfer-paymentCode-payId",
    path: _91payId_933eE7d2h192Meta?.path ?? "/hk/pay-way/transfer-:paymentCode()/:payId()",
    meta: {...(_91payId_933eE7d2h192Meta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"pay-way-transfer-paymentCode-payId"}},
    alias: _91payId_933eE7d2h192Meta?.alias || [],
    redirect: _91payId_933eE7d2h192Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/transfer-[paymentCode]/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_933eE7d2h192Meta?.name ?? "hk__zh-MO__pay-way-transfer-paymentCode-payId",
    path: _91payId_933eE7d2h192Meta?.path ?? "/hk/zh-MO/pay-way/transfer-:paymentCode()/:payId()",
    meta: {...(_91payId_933eE7d2h192Meta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"pay-way-transfer-paymentCode-payId"}},
    alias: _91payId_933eE7d2h192Meta?.alias || [],
    redirect: _91payId_933eE7d2h192Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/transfer-[paymentCode]/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_933eE7d2h192Meta?.name ?? "pay-way-transfer-paymentCode-payId",
    path: _91payId_933eE7d2h192Meta?.path ?? "/pay-way/transfer-:paymentCode()/:payId()",
    meta: {...(_91payId_933eE7d2h192Meta || {}), ...{"siteCode":"","i18nCode":"","truePath":"pay-way-transfer-paymentCode-payId"}},
    alias: _91payId_933eE7d2h192Meta?.alias || [],
    redirect: _91payId_933eE7d2h192Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/transfer-[paymentCode]/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_933eE7d2h192Meta?.name ?? "hk__zh-HK__pay-way-transfer-paymentCode-payId",
    path: _91payId_933eE7d2h192Meta?.path ?? "/hk/zh-HK/pay-way/transfer-:paymentCode()/:payId()",
    meta: {...(_91payId_933eE7d2h192Meta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"pay-way-transfer-paymentCode-payId"}},
    alias: _91payId_933eE7d2h192Meta?.alias || [],
    redirect: _91payId_933eE7d2h192Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/transfer-[paymentCode]/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_933eE7d2h192Meta?.name ?? "hk__zh-CN__pay-way-transfer-paymentCode-payId",
    path: _91payId_933eE7d2h192Meta?.path ?? "/hk/zh-CN/pay-way/transfer-:paymentCode()/:payId()",
    meta: {...(_91payId_933eE7d2h192Meta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"pay-way-transfer-paymentCode-payId"}},
    alias: _91payId_933eE7d2h192Meta?.alias || [],
    redirect: _91payId_933eE7d2h192Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/transfer-[paymentCode]/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_933eE7d2h192Meta?.name ?? "hk__en__pay-way-transfer-paymentCode-payId",
    path: _91payId_933eE7d2h192Meta?.path ?? "/hk/en/pay-way/transfer-:paymentCode()/:payId()",
    meta: {...(_91payId_933eE7d2h192Meta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"pay-way-transfer-paymentCode-payId"}},
    alias: _91payId_933eE7d2h192Meta?.alias || [],
    redirect: _91payId_933eE7d2h192Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/transfer-[paymentCode]/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_933eE7d2h192Meta?.name ?? "mo__pay-way-transfer-paymentCode-payId",
    path: _91payId_933eE7d2h192Meta?.path ?? "/mo/pay-way/transfer-:paymentCode()/:payId()",
    meta: {...(_91payId_933eE7d2h192Meta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"pay-way-transfer-paymentCode-payId"}},
    alias: _91payId_933eE7d2h192Meta?.alias || [],
    redirect: _91payId_933eE7d2h192Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/transfer-[paymentCode]/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_933eE7d2h192Meta?.name ?? "mo__zh-MO__pay-way-transfer-paymentCode-payId",
    path: _91payId_933eE7d2h192Meta?.path ?? "/mo/zh-MO/pay-way/transfer-:paymentCode()/:payId()",
    meta: {...(_91payId_933eE7d2h192Meta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"pay-way-transfer-paymentCode-payId"}},
    alias: _91payId_933eE7d2h192Meta?.alias || [],
    redirect: _91payId_933eE7d2h192Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/transfer-[paymentCode]/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_933eE7d2h192Meta?.name ?? "mo__zh-HK__pay-way-transfer-paymentCode-payId",
    path: _91payId_933eE7d2h192Meta?.path ?? "/mo/zh-HK/pay-way/transfer-:paymentCode()/:payId()",
    meta: {...(_91payId_933eE7d2h192Meta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"pay-way-transfer-paymentCode-payId"}},
    alias: _91payId_933eE7d2h192Meta?.alias || [],
    redirect: _91payId_933eE7d2h192Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/transfer-[paymentCode]/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_933eE7d2h192Meta?.name ?? "mo__zh-CN__pay-way-transfer-paymentCode-payId",
    path: _91payId_933eE7d2h192Meta?.path ?? "/mo/zh-CN/pay-way/transfer-:paymentCode()/:payId()",
    meta: {...(_91payId_933eE7d2h192Meta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"pay-way-transfer-paymentCode-payId"}},
    alias: _91payId_933eE7d2h192Meta?.alias || [],
    redirect: _91payId_933eE7d2h192Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/transfer-[paymentCode]/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_933eE7d2h192Meta?.name ?? "mo__en__pay-way-transfer-paymentCode-payId",
    path: _91payId_933eE7d2h192Meta?.path ?? "/mo/en/pay-way/transfer-:paymentCode()/:payId()",
    meta: {...(_91payId_933eE7d2h192Meta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"pay-way-transfer-paymentCode-payId"}},
    alias: _91payId_933eE7d2h192Meta?.alias || [],
    redirect: _91payId_933eE7d2h192Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/transfer-[paymentCode]/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_933eE7d2h192Meta?.name ?? "cn__pay-way-transfer-paymentCode-payId",
    path: _91payId_933eE7d2h192Meta?.path ?? "/cn/pay-way/transfer-:paymentCode()/:payId()",
    meta: {...(_91payId_933eE7d2h192Meta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"pay-way-transfer-paymentCode-payId"}},
    alias: _91payId_933eE7d2h192Meta?.alias || [],
    redirect: _91payId_933eE7d2h192Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/transfer-[paymentCode]/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_933eE7d2h192Meta?.name ?? "cn__zh-MO__pay-way-transfer-paymentCode-payId",
    path: _91payId_933eE7d2h192Meta?.path ?? "/cn/zh-MO/pay-way/transfer-:paymentCode()/:payId()",
    meta: {...(_91payId_933eE7d2h192Meta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"pay-way-transfer-paymentCode-payId"}},
    alias: _91payId_933eE7d2h192Meta?.alias || [],
    redirect: _91payId_933eE7d2h192Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/transfer-[paymentCode]/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_933eE7d2h192Meta?.name ?? "cn__zh-HK__pay-way-transfer-paymentCode-payId",
    path: _91payId_933eE7d2h192Meta?.path ?? "/cn/zh-HK/pay-way/transfer-:paymentCode()/:payId()",
    meta: {...(_91payId_933eE7d2h192Meta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"pay-way-transfer-paymentCode-payId"}},
    alias: _91payId_933eE7d2h192Meta?.alias || [],
    redirect: _91payId_933eE7d2h192Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/transfer-[paymentCode]/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_933eE7d2h192Meta?.name ?? "cn__zh-CN__pay-way-transfer-paymentCode-payId",
    path: _91payId_933eE7d2h192Meta?.path ?? "/cn/zh-CN/pay-way/transfer-:paymentCode()/:payId()",
    meta: {...(_91payId_933eE7d2h192Meta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"pay-way-transfer-paymentCode-payId"}},
    alias: _91payId_933eE7d2h192Meta?.alias || [],
    redirect: _91payId_933eE7d2h192Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/transfer-[paymentCode]/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_933eE7d2h192Meta?.name ?? "cn__en__pay-way-transfer-paymentCode-payId",
    path: _91payId_933eE7d2h192Meta?.path ?? "/cn/en/pay-way/transfer-:paymentCode()/:payId()",
    meta: {...(_91payId_933eE7d2h192Meta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"pay-way-transfer-paymentCode-payId"}},
    alias: _91payId_933eE7d2h192Meta?.alias || [],
    redirect: _91payId_933eE7d2h192Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay-way/transfer-[paymentCode]/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93jOk07kwtsPMeta?.name ?? "hk__pay-payId",
    path: _91payId_93jOk07kwtsPMeta?.path ?? "/hk/pay/:payId()",
    meta: {...(_91payId_93jOk07kwtsPMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"pay-payId"}},
    alias: _91payId_93jOk07kwtsPMeta?.alias || [],
    redirect: _91payId_93jOk07kwtsPMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93jOk07kwtsPMeta?.name ?? "hk__zh-MO__pay-payId",
    path: _91payId_93jOk07kwtsPMeta?.path ?? "/hk/zh-MO/pay/:payId()",
    meta: {...(_91payId_93jOk07kwtsPMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"pay-payId"}},
    alias: _91payId_93jOk07kwtsPMeta?.alias || [],
    redirect: _91payId_93jOk07kwtsPMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93jOk07kwtsPMeta?.name ?? "pay-payId",
    path: _91payId_93jOk07kwtsPMeta?.path ?? "/pay/:payId()",
    meta: {...(_91payId_93jOk07kwtsPMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"pay-payId"}},
    alias: _91payId_93jOk07kwtsPMeta?.alias || [],
    redirect: _91payId_93jOk07kwtsPMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93jOk07kwtsPMeta?.name ?? "hk__zh-HK__pay-payId",
    path: _91payId_93jOk07kwtsPMeta?.path ?? "/hk/zh-HK/pay/:payId()",
    meta: {...(_91payId_93jOk07kwtsPMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"pay-payId"}},
    alias: _91payId_93jOk07kwtsPMeta?.alias || [],
    redirect: _91payId_93jOk07kwtsPMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93jOk07kwtsPMeta?.name ?? "hk__zh-CN__pay-payId",
    path: _91payId_93jOk07kwtsPMeta?.path ?? "/hk/zh-CN/pay/:payId()",
    meta: {...(_91payId_93jOk07kwtsPMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"pay-payId"}},
    alias: _91payId_93jOk07kwtsPMeta?.alias || [],
    redirect: _91payId_93jOk07kwtsPMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93jOk07kwtsPMeta?.name ?? "hk__en__pay-payId",
    path: _91payId_93jOk07kwtsPMeta?.path ?? "/hk/en/pay/:payId()",
    meta: {...(_91payId_93jOk07kwtsPMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"pay-payId"}},
    alias: _91payId_93jOk07kwtsPMeta?.alias || [],
    redirect: _91payId_93jOk07kwtsPMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93jOk07kwtsPMeta?.name ?? "mo__pay-payId",
    path: _91payId_93jOk07kwtsPMeta?.path ?? "/mo/pay/:payId()",
    meta: {...(_91payId_93jOk07kwtsPMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"pay-payId"}},
    alias: _91payId_93jOk07kwtsPMeta?.alias || [],
    redirect: _91payId_93jOk07kwtsPMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93jOk07kwtsPMeta?.name ?? "mo__zh-MO__pay-payId",
    path: _91payId_93jOk07kwtsPMeta?.path ?? "/mo/zh-MO/pay/:payId()",
    meta: {...(_91payId_93jOk07kwtsPMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"pay-payId"}},
    alias: _91payId_93jOk07kwtsPMeta?.alias || [],
    redirect: _91payId_93jOk07kwtsPMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93jOk07kwtsPMeta?.name ?? "mo__zh-HK__pay-payId",
    path: _91payId_93jOk07kwtsPMeta?.path ?? "/mo/zh-HK/pay/:payId()",
    meta: {...(_91payId_93jOk07kwtsPMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"pay-payId"}},
    alias: _91payId_93jOk07kwtsPMeta?.alias || [],
    redirect: _91payId_93jOk07kwtsPMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93jOk07kwtsPMeta?.name ?? "mo__zh-CN__pay-payId",
    path: _91payId_93jOk07kwtsPMeta?.path ?? "/mo/zh-CN/pay/:payId()",
    meta: {...(_91payId_93jOk07kwtsPMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"pay-payId"}},
    alias: _91payId_93jOk07kwtsPMeta?.alias || [],
    redirect: _91payId_93jOk07kwtsPMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93jOk07kwtsPMeta?.name ?? "mo__en__pay-payId",
    path: _91payId_93jOk07kwtsPMeta?.path ?? "/mo/en/pay/:payId()",
    meta: {...(_91payId_93jOk07kwtsPMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"pay-payId"}},
    alias: _91payId_93jOk07kwtsPMeta?.alias || [],
    redirect: _91payId_93jOk07kwtsPMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93jOk07kwtsPMeta?.name ?? "cn__pay-payId",
    path: _91payId_93jOk07kwtsPMeta?.path ?? "/cn/pay/:payId()",
    meta: {...(_91payId_93jOk07kwtsPMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"pay-payId"}},
    alias: _91payId_93jOk07kwtsPMeta?.alias || [],
    redirect: _91payId_93jOk07kwtsPMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93jOk07kwtsPMeta?.name ?? "cn__zh-MO__pay-payId",
    path: _91payId_93jOk07kwtsPMeta?.path ?? "/cn/zh-MO/pay/:payId()",
    meta: {...(_91payId_93jOk07kwtsPMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"pay-payId"}},
    alias: _91payId_93jOk07kwtsPMeta?.alias || [],
    redirect: _91payId_93jOk07kwtsPMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93jOk07kwtsPMeta?.name ?? "cn__zh-HK__pay-payId",
    path: _91payId_93jOk07kwtsPMeta?.path ?? "/cn/zh-HK/pay/:payId()",
    meta: {...(_91payId_93jOk07kwtsPMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"pay-payId"}},
    alias: _91payId_93jOk07kwtsPMeta?.alias || [],
    redirect: _91payId_93jOk07kwtsPMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93jOk07kwtsPMeta?.name ?? "cn__zh-CN__pay-payId",
    path: _91payId_93jOk07kwtsPMeta?.path ?? "/cn/zh-CN/pay/:payId()",
    meta: {...(_91payId_93jOk07kwtsPMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"pay-payId"}},
    alias: _91payId_93jOk07kwtsPMeta?.alias || [],
    redirect: _91payId_93jOk07kwtsPMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93jOk07kwtsPMeta?.name ?? "cn__en__pay-payId",
    path: _91payId_93jOk07kwtsPMeta?.path ?? "/cn/en/pay/:payId()",
    meta: {...(_91payId_93jOk07kwtsPMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"pay-payId"}},
    alias: _91payId_93jOk07kwtsPMeta?.alias || [],
    redirect: _91payId_93jOk07kwtsPMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93Y01xANPzQxMeta?.name ?? "hk__pay-loading-payId",
    path: _91payId_93Y01xANPzQxMeta?.path ?? "/hk/pay/loading/:payId()",
    meta: {...(_91payId_93Y01xANPzQxMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"pay-loading-payId"}},
    alias: _91payId_93Y01xANPzQxMeta?.alias || [],
    redirect: _91payId_93Y01xANPzQxMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/loading/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93Y01xANPzQxMeta?.name ?? "hk__zh-MO__pay-loading-payId",
    path: _91payId_93Y01xANPzQxMeta?.path ?? "/hk/zh-MO/pay/loading/:payId()",
    meta: {...(_91payId_93Y01xANPzQxMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"pay-loading-payId"}},
    alias: _91payId_93Y01xANPzQxMeta?.alias || [],
    redirect: _91payId_93Y01xANPzQxMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/loading/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93Y01xANPzQxMeta?.name ?? "pay-loading-payId",
    path: _91payId_93Y01xANPzQxMeta?.path ?? "/pay/loading/:payId()",
    meta: {...(_91payId_93Y01xANPzQxMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"pay-loading-payId"}},
    alias: _91payId_93Y01xANPzQxMeta?.alias || [],
    redirect: _91payId_93Y01xANPzQxMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/loading/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93Y01xANPzQxMeta?.name ?? "hk__zh-HK__pay-loading-payId",
    path: _91payId_93Y01xANPzQxMeta?.path ?? "/hk/zh-HK/pay/loading/:payId()",
    meta: {...(_91payId_93Y01xANPzQxMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"pay-loading-payId"}},
    alias: _91payId_93Y01xANPzQxMeta?.alias || [],
    redirect: _91payId_93Y01xANPzQxMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/loading/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93Y01xANPzQxMeta?.name ?? "hk__zh-CN__pay-loading-payId",
    path: _91payId_93Y01xANPzQxMeta?.path ?? "/hk/zh-CN/pay/loading/:payId()",
    meta: {...(_91payId_93Y01xANPzQxMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"pay-loading-payId"}},
    alias: _91payId_93Y01xANPzQxMeta?.alias || [],
    redirect: _91payId_93Y01xANPzQxMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/loading/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93Y01xANPzQxMeta?.name ?? "hk__en__pay-loading-payId",
    path: _91payId_93Y01xANPzQxMeta?.path ?? "/hk/en/pay/loading/:payId()",
    meta: {...(_91payId_93Y01xANPzQxMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"pay-loading-payId"}},
    alias: _91payId_93Y01xANPzQxMeta?.alias || [],
    redirect: _91payId_93Y01xANPzQxMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/loading/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93Y01xANPzQxMeta?.name ?? "mo__pay-loading-payId",
    path: _91payId_93Y01xANPzQxMeta?.path ?? "/mo/pay/loading/:payId()",
    meta: {...(_91payId_93Y01xANPzQxMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"pay-loading-payId"}},
    alias: _91payId_93Y01xANPzQxMeta?.alias || [],
    redirect: _91payId_93Y01xANPzQxMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/loading/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93Y01xANPzQxMeta?.name ?? "mo__zh-MO__pay-loading-payId",
    path: _91payId_93Y01xANPzQxMeta?.path ?? "/mo/zh-MO/pay/loading/:payId()",
    meta: {...(_91payId_93Y01xANPzQxMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"pay-loading-payId"}},
    alias: _91payId_93Y01xANPzQxMeta?.alias || [],
    redirect: _91payId_93Y01xANPzQxMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/loading/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93Y01xANPzQxMeta?.name ?? "mo__zh-HK__pay-loading-payId",
    path: _91payId_93Y01xANPzQxMeta?.path ?? "/mo/zh-HK/pay/loading/:payId()",
    meta: {...(_91payId_93Y01xANPzQxMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"pay-loading-payId"}},
    alias: _91payId_93Y01xANPzQxMeta?.alias || [],
    redirect: _91payId_93Y01xANPzQxMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/loading/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93Y01xANPzQxMeta?.name ?? "mo__zh-CN__pay-loading-payId",
    path: _91payId_93Y01xANPzQxMeta?.path ?? "/mo/zh-CN/pay/loading/:payId()",
    meta: {...(_91payId_93Y01xANPzQxMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"pay-loading-payId"}},
    alias: _91payId_93Y01xANPzQxMeta?.alias || [],
    redirect: _91payId_93Y01xANPzQxMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/loading/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93Y01xANPzQxMeta?.name ?? "mo__en__pay-loading-payId",
    path: _91payId_93Y01xANPzQxMeta?.path ?? "/mo/en/pay/loading/:payId()",
    meta: {...(_91payId_93Y01xANPzQxMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"pay-loading-payId"}},
    alias: _91payId_93Y01xANPzQxMeta?.alias || [],
    redirect: _91payId_93Y01xANPzQxMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/loading/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93Y01xANPzQxMeta?.name ?? "cn__pay-loading-payId",
    path: _91payId_93Y01xANPzQxMeta?.path ?? "/cn/pay/loading/:payId()",
    meta: {...(_91payId_93Y01xANPzQxMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"pay-loading-payId"}},
    alias: _91payId_93Y01xANPzQxMeta?.alias || [],
    redirect: _91payId_93Y01xANPzQxMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/loading/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93Y01xANPzQxMeta?.name ?? "cn__zh-MO__pay-loading-payId",
    path: _91payId_93Y01xANPzQxMeta?.path ?? "/cn/zh-MO/pay/loading/:payId()",
    meta: {...(_91payId_93Y01xANPzQxMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"pay-loading-payId"}},
    alias: _91payId_93Y01xANPzQxMeta?.alias || [],
    redirect: _91payId_93Y01xANPzQxMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/loading/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93Y01xANPzQxMeta?.name ?? "cn__zh-HK__pay-loading-payId",
    path: _91payId_93Y01xANPzQxMeta?.path ?? "/cn/zh-HK/pay/loading/:payId()",
    meta: {...(_91payId_93Y01xANPzQxMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"pay-loading-payId"}},
    alias: _91payId_93Y01xANPzQxMeta?.alias || [],
    redirect: _91payId_93Y01xANPzQxMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/loading/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93Y01xANPzQxMeta?.name ?? "cn__zh-CN__pay-loading-payId",
    path: _91payId_93Y01xANPzQxMeta?.path ?? "/cn/zh-CN/pay/loading/:payId()",
    meta: {...(_91payId_93Y01xANPzQxMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"pay-loading-payId"}},
    alias: _91payId_93Y01xANPzQxMeta?.alias || [],
    redirect: _91payId_93Y01xANPzQxMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/loading/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93Y01xANPzQxMeta?.name ?? "cn__en__pay-loading-payId",
    path: _91payId_93Y01xANPzQxMeta?.path ?? "/cn/en/pay/loading/:payId()",
    meta: {...(_91payId_93Y01xANPzQxMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"pay-loading-payId"}},
    alias: _91payId_93Y01xANPzQxMeta?.alias || [],
    redirect: _91payId_93Y01xANPzQxMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/loading/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93FpRGWnCH8hMeta?.name ?? "hk__pay-success-payId",
    path: _91payId_93FpRGWnCH8hMeta?.path ?? "/hk/pay/success/:payId()",
    meta: {...(_91payId_93FpRGWnCH8hMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"pay-success-payId"}},
    alias: _91payId_93FpRGWnCH8hMeta?.alias || [],
    redirect: _91payId_93FpRGWnCH8hMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/success/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93FpRGWnCH8hMeta?.name ?? "hk__zh-MO__pay-success-payId",
    path: _91payId_93FpRGWnCH8hMeta?.path ?? "/hk/zh-MO/pay/success/:payId()",
    meta: {...(_91payId_93FpRGWnCH8hMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"pay-success-payId"}},
    alias: _91payId_93FpRGWnCH8hMeta?.alias || [],
    redirect: _91payId_93FpRGWnCH8hMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/success/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93FpRGWnCH8hMeta?.name ?? "pay-success-payId",
    path: _91payId_93FpRGWnCH8hMeta?.path ?? "/pay/success/:payId()",
    meta: {...(_91payId_93FpRGWnCH8hMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"pay-success-payId"}},
    alias: _91payId_93FpRGWnCH8hMeta?.alias || [],
    redirect: _91payId_93FpRGWnCH8hMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/success/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93FpRGWnCH8hMeta?.name ?? "hk__zh-HK__pay-success-payId",
    path: _91payId_93FpRGWnCH8hMeta?.path ?? "/hk/zh-HK/pay/success/:payId()",
    meta: {...(_91payId_93FpRGWnCH8hMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"pay-success-payId"}},
    alias: _91payId_93FpRGWnCH8hMeta?.alias || [],
    redirect: _91payId_93FpRGWnCH8hMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/success/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93FpRGWnCH8hMeta?.name ?? "hk__zh-CN__pay-success-payId",
    path: _91payId_93FpRGWnCH8hMeta?.path ?? "/hk/zh-CN/pay/success/:payId()",
    meta: {...(_91payId_93FpRGWnCH8hMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"pay-success-payId"}},
    alias: _91payId_93FpRGWnCH8hMeta?.alias || [],
    redirect: _91payId_93FpRGWnCH8hMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/success/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93FpRGWnCH8hMeta?.name ?? "hk__en__pay-success-payId",
    path: _91payId_93FpRGWnCH8hMeta?.path ?? "/hk/en/pay/success/:payId()",
    meta: {...(_91payId_93FpRGWnCH8hMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"pay-success-payId"}},
    alias: _91payId_93FpRGWnCH8hMeta?.alias || [],
    redirect: _91payId_93FpRGWnCH8hMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/success/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93FpRGWnCH8hMeta?.name ?? "mo__pay-success-payId",
    path: _91payId_93FpRGWnCH8hMeta?.path ?? "/mo/pay/success/:payId()",
    meta: {...(_91payId_93FpRGWnCH8hMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"pay-success-payId"}},
    alias: _91payId_93FpRGWnCH8hMeta?.alias || [],
    redirect: _91payId_93FpRGWnCH8hMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/success/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93FpRGWnCH8hMeta?.name ?? "mo__zh-MO__pay-success-payId",
    path: _91payId_93FpRGWnCH8hMeta?.path ?? "/mo/zh-MO/pay/success/:payId()",
    meta: {...(_91payId_93FpRGWnCH8hMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"pay-success-payId"}},
    alias: _91payId_93FpRGWnCH8hMeta?.alias || [],
    redirect: _91payId_93FpRGWnCH8hMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/success/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93FpRGWnCH8hMeta?.name ?? "mo__zh-HK__pay-success-payId",
    path: _91payId_93FpRGWnCH8hMeta?.path ?? "/mo/zh-HK/pay/success/:payId()",
    meta: {...(_91payId_93FpRGWnCH8hMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"pay-success-payId"}},
    alias: _91payId_93FpRGWnCH8hMeta?.alias || [],
    redirect: _91payId_93FpRGWnCH8hMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/success/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93FpRGWnCH8hMeta?.name ?? "mo__zh-CN__pay-success-payId",
    path: _91payId_93FpRGWnCH8hMeta?.path ?? "/mo/zh-CN/pay/success/:payId()",
    meta: {...(_91payId_93FpRGWnCH8hMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"pay-success-payId"}},
    alias: _91payId_93FpRGWnCH8hMeta?.alias || [],
    redirect: _91payId_93FpRGWnCH8hMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/success/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93FpRGWnCH8hMeta?.name ?? "mo__en__pay-success-payId",
    path: _91payId_93FpRGWnCH8hMeta?.path ?? "/mo/en/pay/success/:payId()",
    meta: {...(_91payId_93FpRGWnCH8hMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"pay-success-payId"}},
    alias: _91payId_93FpRGWnCH8hMeta?.alias || [],
    redirect: _91payId_93FpRGWnCH8hMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/success/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93FpRGWnCH8hMeta?.name ?? "cn__pay-success-payId",
    path: _91payId_93FpRGWnCH8hMeta?.path ?? "/cn/pay/success/:payId()",
    meta: {...(_91payId_93FpRGWnCH8hMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"pay-success-payId"}},
    alias: _91payId_93FpRGWnCH8hMeta?.alias || [],
    redirect: _91payId_93FpRGWnCH8hMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/success/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93FpRGWnCH8hMeta?.name ?? "cn__zh-MO__pay-success-payId",
    path: _91payId_93FpRGWnCH8hMeta?.path ?? "/cn/zh-MO/pay/success/:payId()",
    meta: {...(_91payId_93FpRGWnCH8hMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"pay-success-payId"}},
    alias: _91payId_93FpRGWnCH8hMeta?.alias || [],
    redirect: _91payId_93FpRGWnCH8hMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/success/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93FpRGWnCH8hMeta?.name ?? "cn__zh-HK__pay-success-payId",
    path: _91payId_93FpRGWnCH8hMeta?.path ?? "/cn/zh-HK/pay/success/:payId()",
    meta: {...(_91payId_93FpRGWnCH8hMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"pay-success-payId"}},
    alias: _91payId_93FpRGWnCH8hMeta?.alias || [],
    redirect: _91payId_93FpRGWnCH8hMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/success/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93FpRGWnCH8hMeta?.name ?? "cn__zh-CN__pay-success-payId",
    path: _91payId_93FpRGWnCH8hMeta?.path ?? "/cn/zh-CN/pay/success/:payId()",
    meta: {...(_91payId_93FpRGWnCH8hMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"pay-success-payId"}},
    alias: _91payId_93FpRGWnCH8hMeta?.alias || [],
    redirect: _91payId_93FpRGWnCH8hMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/success/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91payId_93FpRGWnCH8hMeta?.name ?? "cn__en__pay-success-payId",
    path: _91payId_93FpRGWnCH8hMeta?.path ?? "/cn/en/pay/success/:payId()",
    meta: {...(_91payId_93FpRGWnCH8hMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"pay-success-payId"}},
    alias: _91payId_93FpRGWnCH8hMeta?.alias || [],
    redirect: _91payId_93FpRGWnCH8hMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pay/success/[payId].vue").then(m => m.default || m)
  },
  {
    name: _91id_93K9iRFu8HU3Meta?.name ?? "hk__post-detail-id",
    path: _91id_93K9iRFu8HU3Meta?.path ?? "/hk/post/detail/:id()",
    meta: {...(_91id_93K9iRFu8HU3Meta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"post-detail-id"}},
    alias: _91id_93K9iRFu8HU3Meta?.alias || [],
    redirect: _91id_93K9iRFu8HU3Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/post/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93K9iRFu8HU3Meta?.name ?? "hk__zh-MO__post-detail-id",
    path: _91id_93K9iRFu8HU3Meta?.path ?? "/hk/zh-MO/post/detail/:id()",
    meta: {...(_91id_93K9iRFu8HU3Meta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"post-detail-id"}},
    alias: _91id_93K9iRFu8HU3Meta?.alias || [],
    redirect: _91id_93K9iRFu8HU3Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/post/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93K9iRFu8HU3Meta?.name ?? "post-detail-id",
    path: _91id_93K9iRFu8HU3Meta?.path ?? "/post/detail/:id()",
    meta: {...(_91id_93K9iRFu8HU3Meta || {}), ...{"siteCode":"","i18nCode":"","truePath":"post-detail-id"}},
    alias: _91id_93K9iRFu8HU3Meta?.alias || [],
    redirect: _91id_93K9iRFu8HU3Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/post/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93K9iRFu8HU3Meta?.name ?? "hk__zh-HK__post-detail-id",
    path: _91id_93K9iRFu8HU3Meta?.path ?? "/hk/zh-HK/post/detail/:id()",
    meta: {...(_91id_93K9iRFu8HU3Meta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"post-detail-id"}},
    alias: _91id_93K9iRFu8HU3Meta?.alias || [],
    redirect: _91id_93K9iRFu8HU3Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/post/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93K9iRFu8HU3Meta?.name ?? "hk__zh-CN__post-detail-id",
    path: _91id_93K9iRFu8HU3Meta?.path ?? "/hk/zh-CN/post/detail/:id()",
    meta: {...(_91id_93K9iRFu8HU3Meta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"post-detail-id"}},
    alias: _91id_93K9iRFu8HU3Meta?.alias || [],
    redirect: _91id_93K9iRFu8HU3Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/post/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93K9iRFu8HU3Meta?.name ?? "hk__en__post-detail-id",
    path: _91id_93K9iRFu8HU3Meta?.path ?? "/hk/en/post/detail/:id()",
    meta: {...(_91id_93K9iRFu8HU3Meta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"post-detail-id"}},
    alias: _91id_93K9iRFu8HU3Meta?.alias || [],
    redirect: _91id_93K9iRFu8HU3Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/post/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93K9iRFu8HU3Meta?.name ?? "mo__post-detail-id",
    path: _91id_93K9iRFu8HU3Meta?.path ?? "/mo/post/detail/:id()",
    meta: {...(_91id_93K9iRFu8HU3Meta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"post-detail-id"}},
    alias: _91id_93K9iRFu8HU3Meta?.alias || [],
    redirect: _91id_93K9iRFu8HU3Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/post/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93K9iRFu8HU3Meta?.name ?? "mo__zh-MO__post-detail-id",
    path: _91id_93K9iRFu8HU3Meta?.path ?? "/mo/zh-MO/post/detail/:id()",
    meta: {...(_91id_93K9iRFu8HU3Meta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"post-detail-id"}},
    alias: _91id_93K9iRFu8HU3Meta?.alias || [],
    redirect: _91id_93K9iRFu8HU3Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/post/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93K9iRFu8HU3Meta?.name ?? "mo__zh-HK__post-detail-id",
    path: _91id_93K9iRFu8HU3Meta?.path ?? "/mo/zh-HK/post/detail/:id()",
    meta: {...(_91id_93K9iRFu8HU3Meta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"post-detail-id"}},
    alias: _91id_93K9iRFu8HU3Meta?.alias || [],
    redirect: _91id_93K9iRFu8HU3Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/post/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93K9iRFu8HU3Meta?.name ?? "mo__zh-CN__post-detail-id",
    path: _91id_93K9iRFu8HU3Meta?.path ?? "/mo/zh-CN/post/detail/:id()",
    meta: {...(_91id_93K9iRFu8HU3Meta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"post-detail-id"}},
    alias: _91id_93K9iRFu8HU3Meta?.alias || [],
    redirect: _91id_93K9iRFu8HU3Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/post/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93K9iRFu8HU3Meta?.name ?? "mo__en__post-detail-id",
    path: _91id_93K9iRFu8HU3Meta?.path ?? "/mo/en/post/detail/:id()",
    meta: {...(_91id_93K9iRFu8HU3Meta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"post-detail-id"}},
    alias: _91id_93K9iRFu8HU3Meta?.alias || [],
    redirect: _91id_93K9iRFu8HU3Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/post/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93K9iRFu8HU3Meta?.name ?? "cn__post-detail-id",
    path: _91id_93K9iRFu8HU3Meta?.path ?? "/cn/post/detail/:id()",
    meta: {...(_91id_93K9iRFu8HU3Meta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"post-detail-id"}},
    alias: _91id_93K9iRFu8HU3Meta?.alias || [],
    redirect: _91id_93K9iRFu8HU3Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/post/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93K9iRFu8HU3Meta?.name ?? "cn__zh-MO__post-detail-id",
    path: _91id_93K9iRFu8HU3Meta?.path ?? "/cn/zh-MO/post/detail/:id()",
    meta: {...(_91id_93K9iRFu8HU3Meta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"post-detail-id"}},
    alias: _91id_93K9iRFu8HU3Meta?.alias || [],
    redirect: _91id_93K9iRFu8HU3Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/post/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93K9iRFu8HU3Meta?.name ?? "cn__zh-HK__post-detail-id",
    path: _91id_93K9iRFu8HU3Meta?.path ?? "/cn/zh-HK/post/detail/:id()",
    meta: {...(_91id_93K9iRFu8HU3Meta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"post-detail-id"}},
    alias: _91id_93K9iRFu8HU3Meta?.alias || [],
    redirect: _91id_93K9iRFu8HU3Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/post/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93K9iRFu8HU3Meta?.name ?? "cn__zh-CN__post-detail-id",
    path: _91id_93K9iRFu8HU3Meta?.path ?? "/cn/zh-CN/post/detail/:id()",
    meta: {...(_91id_93K9iRFu8HU3Meta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"post-detail-id"}},
    alias: _91id_93K9iRFu8HU3Meta?.alias || [],
    redirect: _91id_93K9iRFu8HU3Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/post/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93K9iRFu8HU3Meta?.name ?? "cn__en__post-detail-id",
    path: _91id_93K9iRFu8HU3Meta?.path ?? "/cn/en/post/detail/:id()",
    meta: {...(_91id_93K9iRFu8HU3Meta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"post-detail-id"}},
    alias: _91id_93K9iRFu8HU3Meta?.alias || [],
    redirect: _91id_93K9iRFu8HU3Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/post/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: presaleWxgzYYhFAZMeta?.name ?? "hk__presale",
    path: presaleWxgzYYhFAZMeta?.path ?? "/hk/presale",
    meta: {...(presaleWxgzYYhFAZMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"presale"}},
    alias: presaleWxgzYYhFAZMeta?.alias || [],
    redirect: presaleWxgzYYhFAZMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/presale.vue").then(m => m.default || m)
  },
  {
    name: presaleWxgzYYhFAZMeta?.name ?? "hk__zh-MO__presale",
    path: presaleWxgzYYhFAZMeta?.path ?? "/hk/zh-MO/presale",
    meta: {...(presaleWxgzYYhFAZMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"presale"}},
    alias: presaleWxgzYYhFAZMeta?.alias || [],
    redirect: presaleWxgzYYhFAZMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/presale.vue").then(m => m.default || m)
  },
  {
    name: presaleWxgzYYhFAZMeta?.name ?? "presale",
    path: presaleWxgzYYhFAZMeta?.path ?? "/presale",
    meta: {...(presaleWxgzYYhFAZMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"presale"}},
    alias: presaleWxgzYYhFAZMeta?.alias || [],
    redirect: presaleWxgzYYhFAZMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/presale.vue").then(m => m.default || m)
  },
  {
    name: presaleWxgzYYhFAZMeta?.name ?? "hk__zh-HK__presale",
    path: presaleWxgzYYhFAZMeta?.path ?? "/hk/zh-HK/presale",
    meta: {...(presaleWxgzYYhFAZMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"presale"}},
    alias: presaleWxgzYYhFAZMeta?.alias || [],
    redirect: presaleWxgzYYhFAZMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/presale.vue").then(m => m.default || m)
  },
  {
    name: presaleWxgzYYhFAZMeta?.name ?? "hk__zh-CN__presale",
    path: presaleWxgzYYhFAZMeta?.path ?? "/hk/zh-CN/presale",
    meta: {...(presaleWxgzYYhFAZMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"presale"}},
    alias: presaleWxgzYYhFAZMeta?.alias || [],
    redirect: presaleWxgzYYhFAZMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/presale.vue").then(m => m.default || m)
  },
  {
    name: presaleWxgzYYhFAZMeta?.name ?? "hk__en__presale",
    path: presaleWxgzYYhFAZMeta?.path ?? "/hk/en/presale",
    meta: {...(presaleWxgzYYhFAZMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"presale"}},
    alias: presaleWxgzYYhFAZMeta?.alias || [],
    redirect: presaleWxgzYYhFAZMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/presale.vue").then(m => m.default || m)
  },
  {
    name: presaleWxgzYYhFAZMeta?.name ?? "mo__presale",
    path: presaleWxgzYYhFAZMeta?.path ?? "/mo/presale",
    meta: {...(presaleWxgzYYhFAZMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"presale"}},
    alias: presaleWxgzYYhFAZMeta?.alias || [],
    redirect: presaleWxgzYYhFAZMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/presale.vue").then(m => m.default || m)
  },
  {
    name: presaleWxgzYYhFAZMeta?.name ?? "mo__zh-MO__presale",
    path: presaleWxgzYYhFAZMeta?.path ?? "/mo/zh-MO/presale",
    meta: {...(presaleWxgzYYhFAZMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"presale"}},
    alias: presaleWxgzYYhFAZMeta?.alias || [],
    redirect: presaleWxgzYYhFAZMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/presale.vue").then(m => m.default || m)
  },
  {
    name: presaleWxgzYYhFAZMeta?.name ?? "mo__zh-HK__presale",
    path: presaleWxgzYYhFAZMeta?.path ?? "/mo/zh-HK/presale",
    meta: {...(presaleWxgzYYhFAZMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"presale"}},
    alias: presaleWxgzYYhFAZMeta?.alias || [],
    redirect: presaleWxgzYYhFAZMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/presale.vue").then(m => m.default || m)
  },
  {
    name: presaleWxgzYYhFAZMeta?.name ?? "mo__zh-CN__presale",
    path: presaleWxgzYYhFAZMeta?.path ?? "/mo/zh-CN/presale",
    meta: {...(presaleWxgzYYhFAZMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"presale"}},
    alias: presaleWxgzYYhFAZMeta?.alias || [],
    redirect: presaleWxgzYYhFAZMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/presale.vue").then(m => m.default || m)
  },
  {
    name: presaleWxgzYYhFAZMeta?.name ?? "mo__en__presale",
    path: presaleWxgzYYhFAZMeta?.path ?? "/mo/en/presale",
    meta: {...(presaleWxgzYYhFAZMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"presale"}},
    alias: presaleWxgzYYhFAZMeta?.alias || [],
    redirect: presaleWxgzYYhFAZMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/presale.vue").then(m => m.default || m)
  },
  {
    name: presaleWxgzYYhFAZMeta?.name ?? "cn__presale",
    path: presaleWxgzYYhFAZMeta?.path ?? "/cn/presale",
    meta: {...(presaleWxgzYYhFAZMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"presale"}},
    alias: presaleWxgzYYhFAZMeta?.alias || [],
    redirect: presaleWxgzYYhFAZMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/presale.vue").then(m => m.default || m)
  },
  {
    name: presaleWxgzYYhFAZMeta?.name ?? "cn__zh-MO__presale",
    path: presaleWxgzYYhFAZMeta?.path ?? "/cn/zh-MO/presale",
    meta: {...(presaleWxgzYYhFAZMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"presale"}},
    alias: presaleWxgzYYhFAZMeta?.alias || [],
    redirect: presaleWxgzYYhFAZMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/presale.vue").then(m => m.default || m)
  },
  {
    name: presaleWxgzYYhFAZMeta?.name ?? "cn__zh-HK__presale",
    path: presaleWxgzYYhFAZMeta?.path ?? "/cn/zh-HK/presale",
    meta: {...(presaleWxgzYYhFAZMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"presale"}},
    alias: presaleWxgzYYhFAZMeta?.alias || [],
    redirect: presaleWxgzYYhFAZMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/presale.vue").then(m => m.default || m)
  },
  {
    name: presaleWxgzYYhFAZMeta?.name ?? "cn__zh-CN__presale",
    path: presaleWxgzYYhFAZMeta?.path ?? "/cn/zh-CN/presale",
    meta: {...(presaleWxgzYYhFAZMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"presale"}},
    alias: presaleWxgzYYhFAZMeta?.alias || [],
    redirect: presaleWxgzYYhFAZMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/presale.vue").then(m => m.default || m)
  },
  {
    name: presaleWxgzYYhFAZMeta?.name ?? "cn__en__presale",
    path: presaleWxgzYYhFAZMeta?.path ?? "/cn/en/presale",
    meta: {...(presaleWxgzYYhFAZMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"presale"}},
    alias: presaleWxgzYYhFAZMeta?.alias || [],
    redirect: presaleWxgzYYhFAZMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/presale.vue").then(m => m.default || m)
  },
  {
    name: privacyAgreement14Cg3X6HJjMeta?.name ?? "hk__privacyAgreement",
    path: privacyAgreement14Cg3X6HJjMeta?.path ?? "/hk/privacyAgreement",
    meta: {...(privacyAgreement14Cg3X6HJjMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"privacyAgreement"}},
    alias: privacyAgreement14Cg3X6HJjMeta?.alias || [],
    redirect: privacyAgreement14Cg3X6HJjMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/privacyAgreement.vue").then(m => m.default || m)
  },
  {
    name: privacyAgreement14Cg3X6HJjMeta?.name ?? "hk__zh-MO__privacyAgreement",
    path: privacyAgreement14Cg3X6HJjMeta?.path ?? "/hk/zh-MO/privacyAgreement",
    meta: {...(privacyAgreement14Cg3X6HJjMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"privacyAgreement"}},
    alias: privacyAgreement14Cg3X6HJjMeta?.alias || [],
    redirect: privacyAgreement14Cg3X6HJjMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/privacyAgreement.vue").then(m => m.default || m)
  },
  {
    name: privacyAgreement14Cg3X6HJjMeta?.name ?? "privacyAgreement",
    path: privacyAgreement14Cg3X6HJjMeta?.path ?? "/privacyAgreement",
    meta: {...(privacyAgreement14Cg3X6HJjMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"privacyAgreement"}},
    alias: privacyAgreement14Cg3X6HJjMeta?.alias || [],
    redirect: privacyAgreement14Cg3X6HJjMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/privacyAgreement.vue").then(m => m.default || m)
  },
  {
    name: privacyAgreement14Cg3X6HJjMeta?.name ?? "hk__zh-HK__privacyAgreement",
    path: privacyAgreement14Cg3X6HJjMeta?.path ?? "/hk/zh-HK/privacyAgreement",
    meta: {...(privacyAgreement14Cg3X6HJjMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"privacyAgreement"}},
    alias: privacyAgreement14Cg3X6HJjMeta?.alias || [],
    redirect: privacyAgreement14Cg3X6HJjMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/privacyAgreement.vue").then(m => m.default || m)
  },
  {
    name: privacyAgreement14Cg3X6HJjMeta?.name ?? "hk__zh-CN__privacyAgreement",
    path: privacyAgreement14Cg3X6HJjMeta?.path ?? "/hk/zh-CN/privacyAgreement",
    meta: {...(privacyAgreement14Cg3X6HJjMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"privacyAgreement"}},
    alias: privacyAgreement14Cg3X6HJjMeta?.alias || [],
    redirect: privacyAgreement14Cg3X6HJjMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/privacyAgreement.vue").then(m => m.default || m)
  },
  {
    name: privacyAgreement14Cg3X6HJjMeta?.name ?? "hk__en__privacyAgreement",
    path: privacyAgreement14Cg3X6HJjMeta?.path ?? "/hk/en/privacyAgreement",
    meta: {...(privacyAgreement14Cg3X6HJjMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"privacyAgreement"}},
    alias: privacyAgreement14Cg3X6HJjMeta?.alias || [],
    redirect: privacyAgreement14Cg3X6HJjMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/privacyAgreement.vue").then(m => m.default || m)
  },
  {
    name: privacyAgreement14Cg3X6HJjMeta?.name ?? "mo__privacyAgreement",
    path: privacyAgreement14Cg3X6HJjMeta?.path ?? "/mo/privacyAgreement",
    meta: {...(privacyAgreement14Cg3X6HJjMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"privacyAgreement"}},
    alias: privacyAgreement14Cg3X6HJjMeta?.alias || [],
    redirect: privacyAgreement14Cg3X6HJjMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/privacyAgreement.vue").then(m => m.default || m)
  },
  {
    name: privacyAgreement14Cg3X6HJjMeta?.name ?? "mo__zh-MO__privacyAgreement",
    path: privacyAgreement14Cg3X6HJjMeta?.path ?? "/mo/zh-MO/privacyAgreement",
    meta: {...(privacyAgreement14Cg3X6HJjMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"privacyAgreement"}},
    alias: privacyAgreement14Cg3X6HJjMeta?.alias || [],
    redirect: privacyAgreement14Cg3X6HJjMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/privacyAgreement.vue").then(m => m.default || m)
  },
  {
    name: privacyAgreement14Cg3X6HJjMeta?.name ?? "mo__zh-HK__privacyAgreement",
    path: privacyAgreement14Cg3X6HJjMeta?.path ?? "/mo/zh-HK/privacyAgreement",
    meta: {...(privacyAgreement14Cg3X6HJjMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"privacyAgreement"}},
    alias: privacyAgreement14Cg3X6HJjMeta?.alias || [],
    redirect: privacyAgreement14Cg3X6HJjMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/privacyAgreement.vue").then(m => m.default || m)
  },
  {
    name: privacyAgreement14Cg3X6HJjMeta?.name ?? "mo__zh-CN__privacyAgreement",
    path: privacyAgreement14Cg3X6HJjMeta?.path ?? "/mo/zh-CN/privacyAgreement",
    meta: {...(privacyAgreement14Cg3X6HJjMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"privacyAgreement"}},
    alias: privacyAgreement14Cg3X6HJjMeta?.alias || [],
    redirect: privacyAgreement14Cg3X6HJjMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/privacyAgreement.vue").then(m => m.default || m)
  },
  {
    name: privacyAgreement14Cg3X6HJjMeta?.name ?? "mo__en__privacyAgreement",
    path: privacyAgreement14Cg3X6HJjMeta?.path ?? "/mo/en/privacyAgreement",
    meta: {...(privacyAgreement14Cg3X6HJjMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"privacyAgreement"}},
    alias: privacyAgreement14Cg3X6HJjMeta?.alias || [],
    redirect: privacyAgreement14Cg3X6HJjMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/privacyAgreement.vue").then(m => m.default || m)
  },
  {
    name: privacyAgreement14Cg3X6HJjMeta?.name ?? "cn__privacyAgreement",
    path: privacyAgreement14Cg3X6HJjMeta?.path ?? "/cn/privacyAgreement",
    meta: {...(privacyAgreement14Cg3X6HJjMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"privacyAgreement"}},
    alias: privacyAgreement14Cg3X6HJjMeta?.alias || [],
    redirect: privacyAgreement14Cg3X6HJjMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/privacyAgreement.vue").then(m => m.default || m)
  },
  {
    name: privacyAgreement14Cg3X6HJjMeta?.name ?? "cn__zh-MO__privacyAgreement",
    path: privacyAgreement14Cg3X6HJjMeta?.path ?? "/cn/zh-MO/privacyAgreement",
    meta: {...(privacyAgreement14Cg3X6HJjMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"privacyAgreement"}},
    alias: privacyAgreement14Cg3X6HJjMeta?.alias || [],
    redirect: privacyAgreement14Cg3X6HJjMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/privacyAgreement.vue").then(m => m.default || m)
  },
  {
    name: privacyAgreement14Cg3X6HJjMeta?.name ?? "cn__zh-HK__privacyAgreement",
    path: privacyAgreement14Cg3X6HJjMeta?.path ?? "/cn/zh-HK/privacyAgreement",
    meta: {...(privacyAgreement14Cg3X6HJjMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"privacyAgreement"}},
    alias: privacyAgreement14Cg3X6HJjMeta?.alias || [],
    redirect: privacyAgreement14Cg3X6HJjMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/privacyAgreement.vue").then(m => m.default || m)
  },
  {
    name: privacyAgreement14Cg3X6HJjMeta?.name ?? "cn__zh-CN__privacyAgreement",
    path: privacyAgreement14Cg3X6HJjMeta?.path ?? "/cn/zh-CN/privacyAgreement",
    meta: {...(privacyAgreement14Cg3X6HJjMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"privacyAgreement"}},
    alias: privacyAgreement14Cg3X6HJjMeta?.alias || [],
    redirect: privacyAgreement14Cg3X6HJjMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/privacyAgreement.vue").then(m => m.default || m)
  },
  {
    name: privacyAgreement14Cg3X6HJjMeta?.name ?? "cn__en__privacyAgreement",
    path: privacyAgreement14Cg3X6HJjMeta?.path ?? "/cn/en/privacyAgreement",
    meta: {...(privacyAgreement14Cg3X6HJjMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"privacyAgreement"}},
    alias: privacyAgreement14Cg3X6HJjMeta?.alias || [],
    redirect: privacyAgreement14Cg3X6HJjMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/privacyAgreement.vue").then(m => m.default || m)
  },
  {
    name: _91_91contentId_93_93hXs35S5OPzMeta?.name ?? "hk__product-skuId-contentId",
    path: _91_91contentId_93_93hXs35S5OPzMeta?.path ?? "/hk/product/:skuId()/:contentId?",
    meta: {...(_91_91contentId_93_93hXs35S5OPzMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"product-skuId-contentId"}},
    alias: _91_91contentId_93_93hXs35S5OPzMeta?.alias || [],
    redirect: _91_91contentId_93_93hXs35S5OPzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/product/[skuId]/[[contentId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91contentId_93_93hXs35S5OPzMeta?.name ?? "hk__zh-MO__product-skuId-contentId",
    path: _91_91contentId_93_93hXs35S5OPzMeta?.path ?? "/hk/zh-MO/product/:skuId()/:contentId?",
    meta: {...(_91_91contentId_93_93hXs35S5OPzMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"product-skuId-contentId"}},
    alias: _91_91contentId_93_93hXs35S5OPzMeta?.alias || [],
    redirect: _91_91contentId_93_93hXs35S5OPzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/product/[skuId]/[[contentId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91contentId_93_93hXs35S5OPzMeta?.name ?? "product-skuId-contentId",
    path: _91_91contentId_93_93hXs35S5OPzMeta?.path ?? "/product/:skuId()/:contentId?",
    meta: {...(_91_91contentId_93_93hXs35S5OPzMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"product-skuId-contentId"}},
    alias: _91_91contentId_93_93hXs35S5OPzMeta?.alias || [],
    redirect: _91_91contentId_93_93hXs35S5OPzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/product/[skuId]/[[contentId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91contentId_93_93hXs35S5OPzMeta?.name ?? "hk__zh-HK__product-skuId-contentId",
    path: _91_91contentId_93_93hXs35S5OPzMeta?.path ?? "/hk/zh-HK/product/:skuId()/:contentId?",
    meta: {...(_91_91contentId_93_93hXs35S5OPzMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"product-skuId-contentId"}},
    alias: _91_91contentId_93_93hXs35S5OPzMeta?.alias || [],
    redirect: _91_91contentId_93_93hXs35S5OPzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/product/[skuId]/[[contentId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91contentId_93_93hXs35S5OPzMeta?.name ?? "hk__zh-CN__product-skuId-contentId",
    path: _91_91contentId_93_93hXs35S5OPzMeta?.path ?? "/hk/zh-CN/product/:skuId()/:contentId?",
    meta: {...(_91_91contentId_93_93hXs35S5OPzMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"product-skuId-contentId"}},
    alias: _91_91contentId_93_93hXs35S5OPzMeta?.alias || [],
    redirect: _91_91contentId_93_93hXs35S5OPzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/product/[skuId]/[[contentId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91contentId_93_93hXs35S5OPzMeta?.name ?? "hk__en__product-skuId-contentId",
    path: _91_91contentId_93_93hXs35S5OPzMeta?.path ?? "/hk/en/product/:skuId()/:contentId?",
    meta: {...(_91_91contentId_93_93hXs35S5OPzMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"product-skuId-contentId"}},
    alias: _91_91contentId_93_93hXs35S5OPzMeta?.alias || [],
    redirect: _91_91contentId_93_93hXs35S5OPzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/product/[skuId]/[[contentId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91contentId_93_93hXs35S5OPzMeta?.name ?? "mo__product-skuId-contentId",
    path: _91_91contentId_93_93hXs35S5OPzMeta?.path ?? "/mo/product/:skuId()/:contentId?",
    meta: {...(_91_91contentId_93_93hXs35S5OPzMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"product-skuId-contentId"}},
    alias: _91_91contentId_93_93hXs35S5OPzMeta?.alias || [],
    redirect: _91_91contentId_93_93hXs35S5OPzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/product/[skuId]/[[contentId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91contentId_93_93hXs35S5OPzMeta?.name ?? "mo__zh-MO__product-skuId-contentId",
    path: _91_91contentId_93_93hXs35S5OPzMeta?.path ?? "/mo/zh-MO/product/:skuId()/:contentId?",
    meta: {...(_91_91contentId_93_93hXs35S5OPzMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"product-skuId-contentId"}},
    alias: _91_91contentId_93_93hXs35S5OPzMeta?.alias || [],
    redirect: _91_91contentId_93_93hXs35S5OPzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/product/[skuId]/[[contentId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91contentId_93_93hXs35S5OPzMeta?.name ?? "mo__zh-HK__product-skuId-contentId",
    path: _91_91contentId_93_93hXs35S5OPzMeta?.path ?? "/mo/zh-HK/product/:skuId()/:contentId?",
    meta: {...(_91_91contentId_93_93hXs35S5OPzMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"product-skuId-contentId"}},
    alias: _91_91contentId_93_93hXs35S5OPzMeta?.alias || [],
    redirect: _91_91contentId_93_93hXs35S5OPzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/product/[skuId]/[[contentId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91contentId_93_93hXs35S5OPzMeta?.name ?? "mo__zh-CN__product-skuId-contentId",
    path: _91_91contentId_93_93hXs35S5OPzMeta?.path ?? "/mo/zh-CN/product/:skuId()/:contentId?",
    meta: {...(_91_91contentId_93_93hXs35S5OPzMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"product-skuId-contentId"}},
    alias: _91_91contentId_93_93hXs35S5OPzMeta?.alias || [],
    redirect: _91_91contentId_93_93hXs35S5OPzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/product/[skuId]/[[contentId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91contentId_93_93hXs35S5OPzMeta?.name ?? "mo__en__product-skuId-contentId",
    path: _91_91contentId_93_93hXs35S5OPzMeta?.path ?? "/mo/en/product/:skuId()/:contentId?",
    meta: {...(_91_91contentId_93_93hXs35S5OPzMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"product-skuId-contentId"}},
    alias: _91_91contentId_93_93hXs35S5OPzMeta?.alias || [],
    redirect: _91_91contentId_93_93hXs35S5OPzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/product/[skuId]/[[contentId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91contentId_93_93hXs35S5OPzMeta?.name ?? "cn__product-skuId-contentId",
    path: _91_91contentId_93_93hXs35S5OPzMeta?.path ?? "/cn/product/:skuId()/:contentId?",
    meta: {...(_91_91contentId_93_93hXs35S5OPzMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"product-skuId-contentId"}},
    alias: _91_91contentId_93_93hXs35S5OPzMeta?.alias || [],
    redirect: _91_91contentId_93_93hXs35S5OPzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/product/[skuId]/[[contentId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91contentId_93_93hXs35S5OPzMeta?.name ?? "cn__zh-MO__product-skuId-contentId",
    path: _91_91contentId_93_93hXs35S5OPzMeta?.path ?? "/cn/zh-MO/product/:skuId()/:contentId?",
    meta: {...(_91_91contentId_93_93hXs35S5OPzMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"product-skuId-contentId"}},
    alias: _91_91contentId_93_93hXs35S5OPzMeta?.alias || [],
    redirect: _91_91contentId_93_93hXs35S5OPzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/product/[skuId]/[[contentId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91contentId_93_93hXs35S5OPzMeta?.name ?? "cn__zh-HK__product-skuId-contentId",
    path: _91_91contentId_93_93hXs35S5OPzMeta?.path ?? "/cn/zh-HK/product/:skuId()/:contentId?",
    meta: {...(_91_91contentId_93_93hXs35S5OPzMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"product-skuId-contentId"}},
    alias: _91_91contentId_93_93hXs35S5OPzMeta?.alias || [],
    redirect: _91_91contentId_93_93hXs35S5OPzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/product/[skuId]/[[contentId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91contentId_93_93hXs35S5OPzMeta?.name ?? "cn__zh-CN__product-skuId-contentId",
    path: _91_91contentId_93_93hXs35S5OPzMeta?.path ?? "/cn/zh-CN/product/:skuId()/:contentId?",
    meta: {...(_91_91contentId_93_93hXs35S5OPzMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"product-skuId-contentId"}},
    alias: _91_91contentId_93_93hXs35S5OPzMeta?.alias || [],
    redirect: _91_91contentId_93_93hXs35S5OPzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/product/[skuId]/[[contentId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91contentId_93_93hXs35S5OPzMeta?.name ?? "cn__en__product-skuId-contentId",
    path: _91_91contentId_93_93hXs35S5OPzMeta?.path ?? "/cn/en/product/:skuId()/:contentId?",
    meta: {...(_91_91contentId_93_93hXs35S5OPzMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"product-skuId-contentId"}},
    alias: _91_91contentId_93_93hXs35S5OPzMeta?.alias || [],
    redirect: _91_91contentId_93_93hXs35S5OPzMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/product/[skuId]/[[contentId]].vue").then(m => m.default || m)
  },
  {
    name: _91code_93c1Ktm6zJy5Meta?.name ?? "hk__promotion-code",
    path: _91code_93c1Ktm6zJy5Meta?.path ?? "/hk/promotion/:code()",
    meta: {...(_91code_93c1Ktm6zJy5Meta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"promotion-code"}},
    alias: _91code_93c1Ktm6zJy5Meta?.alias || [],
    redirect: _91code_93c1Ktm6zJy5Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/promotion/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93c1Ktm6zJy5Meta?.name ?? "hk__zh-MO__promotion-code",
    path: _91code_93c1Ktm6zJy5Meta?.path ?? "/hk/zh-MO/promotion/:code()",
    meta: {...(_91code_93c1Ktm6zJy5Meta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"promotion-code"}},
    alias: _91code_93c1Ktm6zJy5Meta?.alias || [],
    redirect: _91code_93c1Ktm6zJy5Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/promotion/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93c1Ktm6zJy5Meta?.name ?? "promotion-code",
    path: _91code_93c1Ktm6zJy5Meta?.path ?? "/promotion/:code()",
    meta: {...(_91code_93c1Ktm6zJy5Meta || {}), ...{"siteCode":"","i18nCode":"","truePath":"promotion-code"}},
    alias: _91code_93c1Ktm6zJy5Meta?.alias || [],
    redirect: _91code_93c1Ktm6zJy5Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/promotion/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93c1Ktm6zJy5Meta?.name ?? "hk__zh-HK__promotion-code",
    path: _91code_93c1Ktm6zJy5Meta?.path ?? "/hk/zh-HK/promotion/:code()",
    meta: {...(_91code_93c1Ktm6zJy5Meta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"promotion-code"}},
    alias: _91code_93c1Ktm6zJy5Meta?.alias || [],
    redirect: _91code_93c1Ktm6zJy5Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/promotion/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93c1Ktm6zJy5Meta?.name ?? "hk__zh-CN__promotion-code",
    path: _91code_93c1Ktm6zJy5Meta?.path ?? "/hk/zh-CN/promotion/:code()",
    meta: {...(_91code_93c1Ktm6zJy5Meta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"promotion-code"}},
    alias: _91code_93c1Ktm6zJy5Meta?.alias || [],
    redirect: _91code_93c1Ktm6zJy5Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/promotion/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93c1Ktm6zJy5Meta?.name ?? "hk__en__promotion-code",
    path: _91code_93c1Ktm6zJy5Meta?.path ?? "/hk/en/promotion/:code()",
    meta: {...(_91code_93c1Ktm6zJy5Meta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"promotion-code"}},
    alias: _91code_93c1Ktm6zJy5Meta?.alias || [],
    redirect: _91code_93c1Ktm6zJy5Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/promotion/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93c1Ktm6zJy5Meta?.name ?? "mo__promotion-code",
    path: _91code_93c1Ktm6zJy5Meta?.path ?? "/mo/promotion/:code()",
    meta: {...(_91code_93c1Ktm6zJy5Meta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"promotion-code"}},
    alias: _91code_93c1Ktm6zJy5Meta?.alias || [],
    redirect: _91code_93c1Ktm6zJy5Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/promotion/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93c1Ktm6zJy5Meta?.name ?? "mo__zh-MO__promotion-code",
    path: _91code_93c1Ktm6zJy5Meta?.path ?? "/mo/zh-MO/promotion/:code()",
    meta: {...(_91code_93c1Ktm6zJy5Meta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"promotion-code"}},
    alias: _91code_93c1Ktm6zJy5Meta?.alias || [],
    redirect: _91code_93c1Ktm6zJy5Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/promotion/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93c1Ktm6zJy5Meta?.name ?? "mo__zh-HK__promotion-code",
    path: _91code_93c1Ktm6zJy5Meta?.path ?? "/mo/zh-HK/promotion/:code()",
    meta: {...(_91code_93c1Ktm6zJy5Meta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"promotion-code"}},
    alias: _91code_93c1Ktm6zJy5Meta?.alias || [],
    redirect: _91code_93c1Ktm6zJy5Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/promotion/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93c1Ktm6zJy5Meta?.name ?? "mo__zh-CN__promotion-code",
    path: _91code_93c1Ktm6zJy5Meta?.path ?? "/mo/zh-CN/promotion/:code()",
    meta: {...(_91code_93c1Ktm6zJy5Meta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"promotion-code"}},
    alias: _91code_93c1Ktm6zJy5Meta?.alias || [],
    redirect: _91code_93c1Ktm6zJy5Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/promotion/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93c1Ktm6zJy5Meta?.name ?? "mo__en__promotion-code",
    path: _91code_93c1Ktm6zJy5Meta?.path ?? "/mo/en/promotion/:code()",
    meta: {...(_91code_93c1Ktm6zJy5Meta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"promotion-code"}},
    alias: _91code_93c1Ktm6zJy5Meta?.alias || [],
    redirect: _91code_93c1Ktm6zJy5Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/promotion/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93c1Ktm6zJy5Meta?.name ?? "cn__promotion-code",
    path: _91code_93c1Ktm6zJy5Meta?.path ?? "/cn/promotion/:code()",
    meta: {...(_91code_93c1Ktm6zJy5Meta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"promotion-code"}},
    alias: _91code_93c1Ktm6zJy5Meta?.alias || [],
    redirect: _91code_93c1Ktm6zJy5Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/promotion/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93c1Ktm6zJy5Meta?.name ?? "cn__zh-MO__promotion-code",
    path: _91code_93c1Ktm6zJy5Meta?.path ?? "/cn/zh-MO/promotion/:code()",
    meta: {...(_91code_93c1Ktm6zJy5Meta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"promotion-code"}},
    alias: _91code_93c1Ktm6zJy5Meta?.alias || [],
    redirect: _91code_93c1Ktm6zJy5Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/promotion/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93c1Ktm6zJy5Meta?.name ?? "cn__zh-HK__promotion-code",
    path: _91code_93c1Ktm6zJy5Meta?.path ?? "/cn/zh-HK/promotion/:code()",
    meta: {...(_91code_93c1Ktm6zJy5Meta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"promotion-code"}},
    alias: _91code_93c1Ktm6zJy5Meta?.alias || [],
    redirect: _91code_93c1Ktm6zJy5Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/promotion/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93c1Ktm6zJy5Meta?.name ?? "cn__zh-CN__promotion-code",
    path: _91code_93c1Ktm6zJy5Meta?.path ?? "/cn/zh-CN/promotion/:code()",
    meta: {...(_91code_93c1Ktm6zJy5Meta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"promotion-code"}},
    alias: _91code_93c1Ktm6zJy5Meta?.alias || [],
    redirect: _91code_93c1Ktm6zJy5Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/promotion/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93c1Ktm6zJy5Meta?.name ?? "cn__en__promotion-code",
    path: _91code_93c1Ktm6zJy5Meta?.path ?? "/cn/en/promotion/:code()",
    meta: {...(_91code_93c1Ktm6zJy5Meta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"promotion-code"}},
    alias: _91code_93c1Ktm6zJy5Meta?.alias || [],
    redirect: _91code_93c1Ktm6zJy5Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/promotion/[code].vue").then(m => m.default || m)
  },
  {
    name: pwdLogin6QIPNNB162Meta?.name ?? "hk__pwdLogin",
    path: pwdLogin6QIPNNB162Meta?.path ?? "/hk/pwdLogin",
    meta: {...(pwdLogin6QIPNNB162Meta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"pwdLogin"}},
    alias: pwdLogin6QIPNNB162Meta?.alias || [],
    redirect: pwdLogin6QIPNNB162Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pwdLogin.vue").then(m => m.default || m)
  },
  {
    name: pwdLogin6QIPNNB162Meta?.name ?? "hk__zh-MO__pwdLogin",
    path: pwdLogin6QIPNNB162Meta?.path ?? "/hk/zh-MO/pwdLogin",
    meta: {...(pwdLogin6QIPNNB162Meta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"pwdLogin"}},
    alias: pwdLogin6QIPNNB162Meta?.alias || [],
    redirect: pwdLogin6QIPNNB162Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pwdLogin.vue").then(m => m.default || m)
  },
  {
    name: pwdLogin6QIPNNB162Meta?.name ?? "pwdLogin",
    path: pwdLogin6QIPNNB162Meta?.path ?? "/pwdLogin",
    meta: {...(pwdLogin6QIPNNB162Meta || {}), ...{"siteCode":"","i18nCode":"","truePath":"pwdLogin"}},
    alias: pwdLogin6QIPNNB162Meta?.alias || [],
    redirect: pwdLogin6QIPNNB162Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pwdLogin.vue").then(m => m.default || m)
  },
  {
    name: pwdLogin6QIPNNB162Meta?.name ?? "hk__zh-HK__pwdLogin",
    path: pwdLogin6QIPNNB162Meta?.path ?? "/hk/zh-HK/pwdLogin",
    meta: {...(pwdLogin6QIPNNB162Meta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"pwdLogin"}},
    alias: pwdLogin6QIPNNB162Meta?.alias || [],
    redirect: pwdLogin6QIPNNB162Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pwdLogin.vue").then(m => m.default || m)
  },
  {
    name: pwdLogin6QIPNNB162Meta?.name ?? "hk__zh-CN__pwdLogin",
    path: pwdLogin6QIPNNB162Meta?.path ?? "/hk/zh-CN/pwdLogin",
    meta: {...(pwdLogin6QIPNNB162Meta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"pwdLogin"}},
    alias: pwdLogin6QIPNNB162Meta?.alias || [],
    redirect: pwdLogin6QIPNNB162Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pwdLogin.vue").then(m => m.default || m)
  },
  {
    name: pwdLogin6QIPNNB162Meta?.name ?? "hk__en__pwdLogin",
    path: pwdLogin6QIPNNB162Meta?.path ?? "/hk/en/pwdLogin",
    meta: {...(pwdLogin6QIPNNB162Meta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"pwdLogin"}},
    alias: pwdLogin6QIPNNB162Meta?.alias || [],
    redirect: pwdLogin6QIPNNB162Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pwdLogin.vue").then(m => m.default || m)
  },
  {
    name: pwdLogin6QIPNNB162Meta?.name ?? "mo__pwdLogin",
    path: pwdLogin6QIPNNB162Meta?.path ?? "/mo/pwdLogin",
    meta: {...(pwdLogin6QIPNNB162Meta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"pwdLogin"}},
    alias: pwdLogin6QIPNNB162Meta?.alias || [],
    redirect: pwdLogin6QIPNNB162Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pwdLogin.vue").then(m => m.default || m)
  },
  {
    name: pwdLogin6QIPNNB162Meta?.name ?? "mo__zh-MO__pwdLogin",
    path: pwdLogin6QIPNNB162Meta?.path ?? "/mo/zh-MO/pwdLogin",
    meta: {...(pwdLogin6QIPNNB162Meta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"pwdLogin"}},
    alias: pwdLogin6QIPNNB162Meta?.alias || [],
    redirect: pwdLogin6QIPNNB162Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pwdLogin.vue").then(m => m.default || m)
  },
  {
    name: pwdLogin6QIPNNB162Meta?.name ?? "mo__zh-HK__pwdLogin",
    path: pwdLogin6QIPNNB162Meta?.path ?? "/mo/zh-HK/pwdLogin",
    meta: {...(pwdLogin6QIPNNB162Meta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"pwdLogin"}},
    alias: pwdLogin6QIPNNB162Meta?.alias || [],
    redirect: pwdLogin6QIPNNB162Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pwdLogin.vue").then(m => m.default || m)
  },
  {
    name: pwdLogin6QIPNNB162Meta?.name ?? "mo__zh-CN__pwdLogin",
    path: pwdLogin6QIPNNB162Meta?.path ?? "/mo/zh-CN/pwdLogin",
    meta: {...(pwdLogin6QIPNNB162Meta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"pwdLogin"}},
    alias: pwdLogin6QIPNNB162Meta?.alias || [],
    redirect: pwdLogin6QIPNNB162Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pwdLogin.vue").then(m => m.default || m)
  },
  {
    name: pwdLogin6QIPNNB162Meta?.name ?? "mo__en__pwdLogin",
    path: pwdLogin6QIPNNB162Meta?.path ?? "/mo/en/pwdLogin",
    meta: {...(pwdLogin6QIPNNB162Meta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"pwdLogin"}},
    alias: pwdLogin6QIPNNB162Meta?.alias || [],
    redirect: pwdLogin6QIPNNB162Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pwdLogin.vue").then(m => m.default || m)
  },
  {
    name: pwdLogin6QIPNNB162Meta?.name ?? "cn__pwdLogin",
    path: pwdLogin6QIPNNB162Meta?.path ?? "/cn/pwdLogin",
    meta: {...(pwdLogin6QIPNNB162Meta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"pwdLogin"}},
    alias: pwdLogin6QIPNNB162Meta?.alias || [],
    redirect: pwdLogin6QIPNNB162Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pwdLogin.vue").then(m => m.default || m)
  },
  {
    name: pwdLogin6QIPNNB162Meta?.name ?? "cn__zh-MO__pwdLogin",
    path: pwdLogin6QIPNNB162Meta?.path ?? "/cn/zh-MO/pwdLogin",
    meta: {...(pwdLogin6QIPNNB162Meta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"pwdLogin"}},
    alias: pwdLogin6QIPNNB162Meta?.alias || [],
    redirect: pwdLogin6QIPNNB162Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pwdLogin.vue").then(m => m.default || m)
  },
  {
    name: pwdLogin6QIPNNB162Meta?.name ?? "cn__zh-HK__pwdLogin",
    path: pwdLogin6QIPNNB162Meta?.path ?? "/cn/zh-HK/pwdLogin",
    meta: {...(pwdLogin6QIPNNB162Meta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"pwdLogin"}},
    alias: pwdLogin6QIPNNB162Meta?.alias || [],
    redirect: pwdLogin6QIPNNB162Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pwdLogin.vue").then(m => m.default || m)
  },
  {
    name: pwdLogin6QIPNNB162Meta?.name ?? "cn__zh-CN__pwdLogin",
    path: pwdLogin6QIPNNB162Meta?.path ?? "/cn/zh-CN/pwdLogin",
    meta: {...(pwdLogin6QIPNNB162Meta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"pwdLogin"}},
    alias: pwdLogin6QIPNNB162Meta?.alias || [],
    redirect: pwdLogin6QIPNNB162Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pwdLogin.vue").then(m => m.default || m)
  },
  {
    name: pwdLogin6QIPNNB162Meta?.name ?? "cn__en__pwdLogin",
    path: pwdLogin6QIPNNB162Meta?.path ?? "/cn/en/pwdLogin",
    meta: {...(pwdLogin6QIPNNB162Meta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"pwdLogin"}},
    alias: pwdLogin6QIPNNB162Meta?.alias || [],
    redirect: pwdLogin6QIPNNB162Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/pwdLogin.vue").then(m => m.default || m)
  },
  {
    name: registerAgreementoXdbepnCGZMeta?.name ?? "hk__registerAgreement",
    path: registerAgreementoXdbepnCGZMeta?.path ?? "/hk/registerAgreement",
    meta: {...(registerAgreementoXdbepnCGZMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"registerAgreement"}},
    alias: registerAgreementoXdbepnCGZMeta?.alias || [],
    redirect: registerAgreementoXdbepnCGZMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/registerAgreement.vue").then(m => m.default || m)
  },
  {
    name: registerAgreementoXdbepnCGZMeta?.name ?? "hk__zh-MO__registerAgreement",
    path: registerAgreementoXdbepnCGZMeta?.path ?? "/hk/zh-MO/registerAgreement",
    meta: {...(registerAgreementoXdbepnCGZMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"registerAgreement"}},
    alias: registerAgreementoXdbepnCGZMeta?.alias || [],
    redirect: registerAgreementoXdbepnCGZMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/registerAgreement.vue").then(m => m.default || m)
  },
  {
    name: registerAgreementoXdbepnCGZMeta?.name ?? "registerAgreement",
    path: registerAgreementoXdbepnCGZMeta?.path ?? "/registerAgreement",
    meta: {...(registerAgreementoXdbepnCGZMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"registerAgreement"}},
    alias: registerAgreementoXdbepnCGZMeta?.alias || [],
    redirect: registerAgreementoXdbepnCGZMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/registerAgreement.vue").then(m => m.default || m)
  },
  {
    name: registerAgreementoXdbepnCGZMeta?.name ?? "hk__zh-HK__registerAgreement",
    path: registerAgreementoXdbepnCGZMeta?.path ?? "/hk/zh-HK/registerAgreement",
    meta: {...(registerAgreementoXdbepnCGZMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"registerAgreement"}},
    alias: registerAgreementoXdbepnCGZMeta?.alias || [],
    redirect: registerAgreementoXdbepnCGZMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/registerAgreement.vue").then(m => m.default || m)
  },
  {
    name: registerAgreementoXdbepnCGZMeta?.name ?? "hk__zh-CN__registerAgreement",
    path: registerAgreementoXdbepnCGZMeta?.path ?? "/hk/zh-CN/registerAgreement",
    meta: {...(registerAgreementoXdbepnCGZMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"registerAgreement"}},
    alias: registerAgreementoXdbepnCGZMeta?.alias || [],
    redirect: registerAgreementoXdbepnCGZMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/registerAgreement.vue").then(m => m.default || m)
  },
  {
    name: registerAgreementoXdbepnCGZMeta?.name ?? "hk__en__registerAgreement",
    path: registerAgreementoXdbepnCGZMeta?.path ?? "/hk/en/registerAgreement",
    meta: {...(registerAgreementoXdbepnCGZMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"registerAgreement"}},
    alias: registerAgreementoXdbepnCGZMeta?.alias || [],
    redirect: registerAgreementoXdbepnCGZMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/registerAgreement.vue").then(m => m.default || m)
  },
  {
    name: registerAgreementoXdbepnCGZMeta?.name ?? "mo__registerAgreement",
    path: registerAgreementoXdbepnCGZMeta?.path ?? "/mo/registerAgreement",
    meta: {...(registerAgreementoXdbepnCGZMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"registerAgreement"}},
    alias: registerAgreementoXdbepnCGZMeta?.alias || [],
    redirect: registerAgreementoXdbepnCGZMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/registerAgreement.vue").then(m => m.default || m)
  },
  {
    name: registerAgreementoXdbepnCGZMeta?.name ?? "mo__zh-MO__registerAgreement",
    path: registerAgreementoXdbepnCGZMeta?.path ?? "/mo/zh-MO/registerAgreement",
    meta: {...(registerAgreementoXdbepnCGZMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"registerAgreement"}},
    alias: registerAgreementoXdbepnCGZMeta?.alias || [],
    redirect: registerAgreementoXdbepnCGZMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/registerAgreement.vue").then(m => m.default || m)
  },
  {
    name: registerAgreementoXdbepnCGZMeta?.name ?? "mo__zh-HK__registerAgreement",
    path: registerAgreementoXdbepnCGZMeta?.path ?? "/mo/zh-HK/registerAgreement",
    meta: {...(registerAgreementoXdbepnCGZMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"registerAgreement"}},
    alias: registerAgreementoXdbepnCGZMeta?.alias || [],
    redirect: registerAgreementoXdbepnCGZMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/registerAgreement.vue").then(m => m.default || m)
  },
  {
    name: registerAgreementoXdbepnCGZMeta?.name ?? "mo__zh-CN__registerAgreement",
    path: registerAgreementoXdbepnCGZMeta?.path ?? "/mo/zh-CN/registerAgreement",
    meta: {...(registerAgreementoXdbepnCGZMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"registerAgreement"}},
    alias: registerAgreementoXdbepnCGZMeta?.alias || [],
    redirect: registerAgreementoXdbepnCGZMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/registerAgreement.vue").then(m => m.default || m)
  },
  {
    name: registerAgreementoXdbepnCGZMeta?.name ?? "mo__en__registerAgreement",
    path: registerAgreementoXdbepnCGZMeta?.path ?? "/mo/en/registerAgreement",
    meta: {...(registerAgreementoXdbepnCGZMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"registerAgreement"}},
    alias: registerAgreementoXdbepnCGZMeta?.alias || [],
    redirect: registerAgreementoXdbepnCGZMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/registerAgreement.vue").then(m => m.default || m)
  },
  {
    name: registerAgreementoXdbepnCGZMeta?.name ?? "cn__registerAgreement",
    path: registerAgreementoXdbepnCGZMeta?.path ?? "/cn/registerAgreement",
    meta: {...(registerAgreementoXdbepnCGZMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"registerAgreement"}},
    alias: registerAgreementoXdbepnCGZMeta?.alias || [],
    redirect: registerAgreementoXdbepnCGZMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/registerAgreement.vue").then(m => m.default || m)
  },
  {
    name: registerAgreementoXdbepnCGZMeta?.name ?? "cn__zh-MO__registerAgreement",
    path: registerAgreementoXdbepnCGZMeta?.path ?? "/cn/zh-MO/registerAgreement",
    meta: {...(registerAgreementoXdbepnCGZMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"registerAgreement"}},
    alias: registerAgreementoXdbepnCGZMeta?.alias || [],
    redirect: registerAgreementoXdbepnCGZMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/registerAgreement.vue").then(m => m.default || m)
  },
  {
    name: registerAgreementoXdbepnCGZMeta?.name ?? "cn__zh-HK__registerAgreement",
    path: registerAgreementoXdbepnCGZMeta?.path ?? "/cn/zh-HK/registerAgreement",
    meta: {...(registerAgreementoXdbepnCGZMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"registerAgreement"}},
    alias: registerAgreementoXdbepnCGZMeta?.alias || [],
    redirect: registerAgreementoXdbepnCGZMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/registerAgreement.vue").then(m => m.default || m)
  },
  {
    name: registerAgreementoXdbepnCGZMeta?.name ?? "cn__zh-CN__registerAgreement",
    path: registerAgreementoXdbepnCGZMeta?.path ?? "/cn/zh-CN/registerAgreement",
    meta: {...(registerAgreementoXdbepnCGZMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"registerAgreement"}},
    alias: registerAgreementoXdbepnCGZMeta?.alias || [],
    redirect: registerAgreementoXdbepnCGZMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/registerAgreement.vue").then(m => m.default || m)
  },
  {
    name: registerAgreementoXdbepnCGZMeta?.name ?? "cn__en__registerAgreement",
    path: registerAgreementoXdbepnCGZMeta?.path ?? "/cn/en/registerAgreement",
    meta: {...(registerAgreementoXdbepnCGZMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"registerAgreement"}},
    alias: registerAgreementoXdbepnCGZMeta?.alias || [],
    redirect: registerAgreementoXdbepnCGZMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/registerAgreement.vue").then(m => m.default || m)
  },
  {
    name: retrievePwdomTTL8xF00Meta?.name ?? "hk__retrievePwd",
    path: retrievePwdomTTL8xF00Meta?.path ?? "/hk/retrievePwd",
    meta: {...(retrievePwdomTTL8xF00Meta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"retrievePwd"}},
    alias: retrievePwdomTTL8xF00Meta?.alias || [],
    redirect: retrievePwdomTTL8xF00Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/retrievePwd.vue").then(m => m.default || m)
  },
  {
    name: retrievePwdomTTL8xF00Meta?.name ?? "hk__zh-MO__retrievePwd",
    path: retrievePwdomTTL8xF00Meta?.path ?? "/hk/zh-MO/retrievePwd",
    meta: {...(retrievePwdomTTL8xF00Meta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"retrievePwd"}},
    alias: retrievePwdomTTL8xF00Meta?.alias || [],
    redirect: retrievePwdomTTL8xF00Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/retrievePwd.vue").then(m => m.default || m)
  },
  {
    name: retrievePwdomTTL8xF00Meta?.name ?? "retrievePwd",
    path: retrievePwdomTTL8xF00Meta?.path ?? "/retrievePwd",
    meta: {...(retrievePwdomTTL8xF00Meta || {}), ...{"siteCode":"","i18nCode":"","truePath":"retrievePwd"}},
    alias: retrievePwdomTTL8xF00Meta?.alias || [],
    redirect: retrievePwdomTTL8xF00Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/retrievePwd.vue").then(m => m.default || m)
  },
  {
    name: retrievePwdomTTL8xF00Meta?.name ?? "hk__zh-HK__retrievePwd",
    path: retrievePwdomTTL8xF00Meta?.path ?? "/hk/zh-HK/retrievePwd",
    meta: {...(retrievePwdomTTL8xF00Meta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"retrievePwd"}},
    alias: retrievePwdomTTL8xF00Meta?.alias || [],
    redirect: retrievePwdomTTL8xF00Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/retrievePwd.vue").then(m => m.default || m)
  },
  {
    name: retrievePwdomTTL8xF00Meta?.name ?? "hk__zh-CN__retrievePwd",
    path: retrievePwdomTTL8xF00Meta?.path ?? "/hk/zh-CN/retrievePwd",
    meta: {...(retrievePwdomTTL8xF00Meta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"retrievePwd"}},
    alias: retrievePwdomTTL8xF00Meta?.alias || [],
    redirect: retrievePwdomTTL8xF00Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/retrievePwd.vue").then(m => m.default || m)
  },
  {
    name: retrievePwdomTTL8xF00Meta?.name ?? "hk__en__retrievePwd",
    path: retrievePwdomTTL8xF00Meta?.path ?? "/hk/en/retrievePwd",
    meta: {...(retrievePwdomTTL8xF00Meta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"retrievePwd"}},
    alias: retrievePwdomTTL8xF00Meta?.alias || [],
    redirect: retrievePwdomTTL8xF00Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/retrievePwd.vue").then(m => m.default || m)
  },
  {
    name: retrievePwdomTTL8xF00Meta?.name ?? "mo__retrievePwd",
    path: retrievePwdomTTL8xF00Meta?.path ?? "/mo/retrievePwd",
    meta: {...(retrievePwdomTTL8xF00Meta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"retrievePwd"}},
    alias: retrievePwdomTTL8xF00Meta?.alias || [],
    redirect: retrievePwdomTTL8xF00Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/retrievePwd.vue").then(m => m.default || m)
  },
  {
    name: retrievePwdomTTL8xF00Meta?.name ?? "mo__zh-MO__retrievePwd",
    path: retrievePwdomTTL8xF00Meta?.path ?? "/mo/zh-MO/retrievePwd",
    meta: {...(retrievePwdomTTL8xF00Meta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"retrievePwd"}},
    alias: retrievePwdomTTL8xF00Meta?.alias || [],
    redirect: retrievePwdomTTL8xF00Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/retrievePwd.vue").then(m => m.default || m)
  },
  {
    name: retrievePwdomTTL8xF00Meta?.name ?? "mo__zh-HK__retrievePwd",
    path: retrievePwdomTTL8xF00Meta?.path ?? "/mo/zh-HK/retrievePwd",
    meta: {...(retrievePwdomTTL8xF00Meta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"retrievePwd"}},
    alias: retrievePwdomTTL8xF00Meta?.alias || [],
    redirect: retrievePwdomTTL8xF00Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/retrievePwd.vue").then(m => m.default || m)
  },
  {
    name: retrievePwdomTTL8xF00Meta?.name ?? "mo__zh-CN__retrievePwd",
    path: retrievePwdomTTL8xF00Meta?.path ?? "/mo/zh-CN/retrievePwd",
    meta: {...(retrievePwdomTTL8xF00Meta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"retrievePwd"}},
    alias: retrievePwdomTTL8xF00Meta?.alias || [],
    redirect: retrievePwdomTTL8xF00Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/retrievePwd.vue").then(m => m.default || m)
  },
  {
    name: retrievePwdomTTL8xF00Meta?.name ?? "mo__en__retrievePwd",
    path: retrievePwdomTTL8xF00Meta?.path ?? "/mo/en/retrievePwd",
    meta: {...(retrievePwdomTTL8xF00Meta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"retrievePwd"}},
    alias: retrievePwdomTTL8xF00Meta?.alias || [],
    redirect: retrievePwdomTTL8xF00Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/retrievePwd.vue").then(m => m.default || m)
  },
  {
    name: retrievePwdomTTL8xF00Meta?.name ?? "cn__retrievePwd",
    path: retrievePwdomTTL8xF00Meta?.path ?? "/cn/retrievePwd",
    meta: {...(retrievePwdomTTL8xF00Meta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"retrievePwd"}},
    alias: retrievePwdomTTL8xF00Meta?.alias || [],
    redirect: retrievePwdomTTL8xF00Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/retrievePwd.vue").then(m => m.default || m)
  },
  {
    name: retrievePwdomTTL8xF00Meta?.name ?? "cn__zh-MO__retrievePwd",
    path: retrievePwdomTTL8xF00Meta?.path ?? "/cn/zh-MO/retrievePwd",
    meta: {...(retrievePwdomTTL8xF00Meta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"retrievePwd"}},
    alias: retrievePwdomTTL8xF00Meta?.alias || [],
    redirect: retrievePwdomTTL8xF00Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/retrievePwd.vue").then(m => m.default || m)
  },
  {
    name: retrievePwdomTTL8xF00Meta?.name ?? "cn__zh-HK__retrievePwd",
    path: retrievePwdomTTL8xF00Meta?.path ?? "/cn/zh-HK/retrievePwd",
    meta: {...(retrievePwdomTTL8xF00Meta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"retrievePwd"}},
    alias: retrievePwdomTTL8xF00Meta?.alias || [],
    redirect: retrievePwdomTTL8xF00Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/retrievePwd.vue").then(m => m.default || m)
  },
  {
    name: retrievePwdomTTL8xF00Meta?.name ?? "cn__zh-CN__retrievePwd",
    path: retrievePwdomTTL8xF00Meta?.path ?? "/cn/zh-CN/retrievePwd",
    meta: {...(retrievePwdomTTL8xF00Meta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"retrievePwd"}},
    alias: retrievePwdomTTL8xF00Meta?.alias || [],
    redirect: retrievePwdomTTL8xF00Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/retrievePwd.vue").then(m => m.default || m)
  },
  {
    name: retrievePwdomTTL8xF00Meta?.name ?? "cn__en__retrievePwd",
    path: retrievePwdomTTL8xF00Meta?.path ?? "/cn/en/retrievePwd",
    meta: {...(retrievePwdomTTL8xF00Meta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"retrievePwd"}},
    alias: retrievePwdomTTL8xF00Meta?.alias || [],
    redirect: retrievePwdomTTL8xF00Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/retrievePwd.vue").then(m => m.default || m)
  },
  {
    name: _91keyword_930H2kMDIqo2Meta?.name ?? "hk__search-keyword",
    path: _91keyword_930H2kMDIqo2Meta?.path ?? "/hk/search/:keyword()",
    meta: {...(_91keyword_930H2kMDIqo2Meta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"search-keyword"}},
    alias: _91keyword_930H2kMDIqo2Meta?.alias || [],
    redirect: _91keyword_930H2kMDIqo2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/search/[keyword].vue").then(m => m.default || m)
  },
  {
    name: _91keyword_930H2kMDIqo2Meta?.name ?? "hk__zh-MO__search-keyword",
    path: _91keyword_930H2kMDIqo2Meta?.path ?? "/hk/zh-MO/search/:keyword()",
    meta: {...(_91keyword_930H2kMDIqo2Meta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"search-keyword"}},
    alias: _91keyword_930H2kMDIqo2Meta?.alias || [],
    redirect: _91keyword_930H2kMDIqo2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/search/[keyword].vue").then(m => m.default || m)
  },
  {
    name: _91keyword_930H2kMDIqo2Meta?.name ?? "search-keyword",
    path: _91keyword_930H2kMDIqo2Meta?.path ?? "/search/:keyword()",
    meta: {...(_91keyword_930H2kMDIqo2Meta || {}), ...{"siteCode":"","i18nCode":"","truePath":"search-keyword"}},
    alias: _91keyword_930H2kMDIqo2Meta?.alias || [],
    redirect: _91keyword_930H2kMDIqo2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/search/[keyword].vue").then(m => m.default || m)
  },
  {
    name: _91keyword_930H2kMDIqo2Meta?.name ?? "hk__zh-HK__search-keyword",
    path: _91keyword_930H2kMDIqo2Meta?.path ?? "/hk/zh-HK/search/:keyword()",
    meta: {...(_91keyword_930H2kMDIqo2Meta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"search-keyword"}},
    alias: _91keyword_930H2kMDIqo2Meta?.alias || [],
    redirect: _91keyword_930H2kMDIqo2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/search/[keyword].vue").then(m => m.default || m)
  },
  {
    name: _91keyword_930H2kMDIqo2Meta?.name ?? "hk__zh-CN__search-keyword",
    path: _91keyword_930H2kMDIqo2Meta?.path ?? "/hk/zh-CN/search/:keyword()",
    meta: {...(_91keyword_930H2kMDIqo2Meta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"search-keyword"}},
    alias: _91keyword_930H2kMDIqo2Meta?.alias || [],
    redirect: _91keyword_930H2kMDIqo2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/search/[keyword].vue").then(m => m.default || m)
  },
  {
    name: _91keyword_930H2kMDIqo2Meta?.name ?? "hk__en__search-keyword",
    path: _91keyword_930H2kMDIqo2Meta?.path ?? "/hk/en/search/:keyword()",
    meta: {...(_91keyword_930H2kMDIqo2Meta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"search-keyword"}},
    alias: _91keyword_930H2kMDIqo2Meta?.alias || [],
    redirect: _91keyword_930H2kMDIqo2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/search/[keyword].vue").then(m => m.default || m)
  },
  {
    name: _91keyword_930H2kMDIqo2Meta?.name ?? "mo__search-keyword",
    path: _91keyword_930H2kMDIqo2Meta?.path ?? "/mo/search/:keyword()",
    meta: {...(_91keyword_930H2kMDIqo2Meta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"search-keyword"}},
    alias: _91keyword_930H2kMDIqo2Meta?.alias || [],
    redirect: _91keyword_930H2kMDIqo2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/search/[keyword].vue").then(m => m.default || m)
  },
  {
    name: _91keyword_930H2kMDIqo2Meta?.name ?? "mo__zh-MO__search-keyword",
    path: _91keyword_930H2kMDIqo2Meta?.path ?? "/mo/zh-MO/search/:keyword()",
    meta: {...(_91keyword_930H2kMDIqo2Meta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"search-keyword"}},
    alias: _91keyword_930H2kMDIqo2Meta?.alias || [],
    redirect: _91keyword_930H2kMDIqo2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/search/[keyword].vue").then(m => m.default || m)
  },
  {
    name: _91keyword_930H2kMDIqo2Meta?.name ?? "mo__zh-HK__search-keyword",
    path: _91keyword_930H2kMDIqo2Meta?.path ?? "/mo/zh-HK/search/:keyword()",
    meta: {...(_91keyword_930H2kMDIqo2Meta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"search-keyword"}},
    alias: _91keyword_930H2kMDIqo2Meta?.alias || [],
    redirect: _91keyword_930H2kMDIqo2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/search/[keyword].vue").then(m => m.default || m)
  },
  {
    name: _91keyword_930H2kMDIqo2Meta?.name ?? "mo__zh-CN__search-keyword",
    path: _91keyword_930H2kMDIqo2Meta?.path ?? "/mo/zh-CN/search/:keyword()",
    meta: {...(_91keyword_930H2kMDIqo2Meta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"search-keyword"}},
    alias: _91keyword_930H2kMDIqo2Meta?.alias || [],
    redirect: _91keyword_930H2kMDIqo2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/search/[keyword].vue").then(m => m.default || m)
  },
  {
    name: _91keyword_930H2kMDIqo2Meta?.name ?? "mo__en__search-keyword",
    path: _91keyword_930H2kMDIqo2Meta?.path ?? "/mo/en/search/:keyword()",
    meta: {...(_91keyword_930H2kMDIqo2Meta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"search-keyword"}},
    alias: _91keyword_930H2kMDIqo2Meta?.alias || [],
    redirect: _91keyword_930H2kMDIqo2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/search/[keyword].vue").then(m => m.default || m)
  },
  {
    name: _91keyword_930H2kMDIqo2Meta?.name ?? "cn__search-keyword",
    path: _91keyword_930H2kMDIqo2Meta?.path ?? "/cn/search/:keyword()",
    meta: {...(_91keyword_930H2kMDIqo2Meta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"search-keyword"}},
    alias: _91keyword_930H2kMDIqo2Meta?.alias || [],
    redirect: _91keyword_930H2kMDIqo2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/search/[keyword].vue").then(m => m.default || m)
  },
  {
    name: _91keyword_930H2kMDIqo2Meta?.name ?? "cn__zh-MO__search-keyword",
    path: _91keyword_930H2kMDIqo2Meta?.path ?? "/cn/zh-MO/search/:keyword()",
    meta: {...(_91keyword_930H2kMDIqo2Meta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"search-keyword"}},
    alias: _91keyword_930H2kMDIqo2Meta?.alias || [],
    redirect: _91keyword_930H2kMDIqo2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/search/[keyword].vue").then(m => m.default || m)
  },
  {
    name: _91keyword_930H2kMDIqo2Meta?.name ?? "cn__zh-HK__search-keyword",
    path: _91keyword_930H2kMDIqo2Meta?.path ?? "/cn/zh-HK/search/:keyword()",
    meta: {...(_91keyword_930H2kMDIqo2Meta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"search-keyword"}},
    alias: _91keyword_930H2kMDIqo2Meta?.alias || [],
    redirect: _91keyword_930H2kMDIqo2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/search/[keyword].vue").then(m => m.default || m)
  },
  {
    name: _91keyword_930H2kMDIqo2Meta?.name ?? "cn__zh-CN__search-keyword",
    path: _91keyword_930H2kMDIqo2Meta?.path ?? "/cn/zh-CN/search/:keyword()",
    meta: {...(_91keyword_930H2kMDIqo2Meta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"search-keyword"}},
    alias: _91keyword_930H2kMDIqo2Meta?.alias || [],
    redirect: _91keyword_930H2kMDIqo2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/search/[keyword].vue").then(m => m.default || m)
  },
  {
    name: _91keyword_930H2kMDIqo2Meta?.name ?? "cn__en__search-keyword",
    path: _91keyword_930H2kMDIqo2Meta?.path ?? "/cn/en/search/:keyword()",
    meta: {...(_91keyword_930H2kMDIqo2Meta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"search-keyword"}},
    alias: _91keyword_930H2kMDIqo2Meta?.alias || [],
    redirect: _91keyword_930H2kMDIqo2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/search/[keyword].vue").then(m => m.default || m)
  },
  {
    name: indexe1uqThM2KrMeta?.name ?? "hk__search",
    path: indexe1uqThM2KrMeta?.path ?? "/hk/search",
    meta: {...(indexe1uqThM2KrMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"search"}},
    alias: indexe1uqThM2KrMeta?.alias || [],
    redirect: indexe1uqThM2KrMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexe1uqThM2KrMeta?.name ?? "hk__zh-MO__search",
    path: indexe1uqThM2KrMeta?.path ?? "/hk/zh-MO/search",
    meta: {...(indexe1uqThM2KrMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"search"}},
    alias: indexe1uqThM2KrMeta?.alias || [],
    redirect: indexe1uqThM2KrMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexe1uqThM2KrMeta?.name ?? "search",
    path: indexe1uqThM2KrMeta?.path ?? "/search",
    meta: {...(indexe1uqThM2KrMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"search"}},
    alias: indexe1uqThM2KrMeta?.alias || [],
    redirect: indexe1uqThM2KrMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexe1uqThM2KrMeta?.name ?? "hk__zh-HK__search",
    path: indexe1uqThM2KrMeta?.path ?? "/hk/zh-HK/search",
    meta: {...(indexe1uqThM2KrMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"search"}},
    alias: indexe1uqThM2KrMeta?.alias || [],
    redirect: indexe1uqThM2KrMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexe1uqThM2KrMeta?.name ?? "hk__zh-CN__search",
    path: indexe1uqThM2KrMeta?.path ?? "/hk/zh-CN/search",
    meta: {...(indexe1uqThM2KrMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"search"}},
    alias: indexe1uqThM2KrMeta?.alias || [],
    redirect: indexe1uqThM2KrMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexe1uqThM2KrMeta?.name ?? "hk__en__search",
    path: indexe1uqThM2KrMeta?.path ?? "/hk/en/search",
    meta: {...(indexe1uqThM2KrMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"search"}},
    alias: indexe1uqThM2KrMeta?.alias || [],
    redirect: indexe1uqThM2KrMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexe1uqThM2KrMeta?.name ?? "mo__search",
    path: indexe1uqThM2KrMeta?.path ?? "/mo/search",
    meta: {...(indexe1uqThM2KrMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"search"}},
    alias: indexe1uqThM2KrMeta?.alias || [],
    redirect: indexe1uqThM2KrMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexe1uqThM2KrMeta?.name ?? "mo__zh-MO__search",
    path: indexe1uqThM2KrMeta?.path ?? "/mo/zh-MO/search",
    meta: {...(indexe1uqThM2KrMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"search"}},
    alias: indexe1uqThM2KrMeta?.alias || [],
    redirect: indexe1uqThM2KrMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexe1uqThM2KrMeta?.name ?? "mo__zh-HK__search",
    path: indexe1uqThM2KrMeta?.path ?? "/mo/zh-HK/search",
    meta: {...(indexe1uqThM2KrMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"search"}},
    alias: indexe1uqThM2KrMeta?.alias || [],
    redirect: indexe1uqThM2KrMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexe1uqThM2KrMeta?.name ?? "mo__zh-CN__search",
    path: indexe1uqThM2KrMeta?.path ?? "/mo/zh-CN/search",
    meta: {...(indexe1uqThM2KrMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"search"}},
    alias: indexe1uqThM2KrMeta?.alias || [],
    redirect: indexe1uqThM2KrMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexe1uqThM2KrMeta?.name ?? "mo__en__search",
    path: indexe1uqThM2KrMeta?.path ?? "/mo/en/search",
    meta: {...(indexe1uqThM2KrMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"search"}},
    alias: indexe1uqThM2KrMeta?.alias || [],
    redirect: indexe1uqThM2KrMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexe1uqThM2KrMeta?.name ?? "cn__search",
    path: indexe1uqThM2KrMeta?.path ?? "/cn/search",
    meta: {...(indexe1uqThM2KrMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"search"}},
    alias: indexe1uqThM2KrMeta?.alias || [],
    redirect: indexe1uqThM2KrMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexe1uqThM2KrMeta?.name ?? "cn__zh-MO__search",
    path: indexe1uqThM2KrMeta?.path ?? "/cn/zh-MO/search",
    meta: {...(indexe1uqThM2KrMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"search"}},
    alias: indexe1uqThM2KrMeta?.alias || [],
    redirect: indexe1uqThM2KrMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexe1uqThM2KrMeta?.name ?? "cn__zh-HK__search",
    path: indexe1uqThM2KrMeta?.path ?? "/cn/zh-HK/search",
    meta: {...(indexe1uqThM2KrMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"search"}},
    alias: indexe1uqThM2KrMeta?.alias || [],
    redirect: indexe1uqThM2KrMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexe1uqThM2KrMeta?.name ?? "cn__zh-CN__search",
    path: indexe1uqThM2KrMeta?.path ?? "/cn/zh-CN/search",
    meta: {...(indexe1uqThM2KrMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"search"}},
    alias: indexe1uqThM2KrMeta?.alias || [],
    redirect: indexe1uqThM2KrMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexe1uqThM2KrMeta?.name ?? "cn__en__search",
    path: indexe1uqThM2KrMeta?.path ?? "/cn/en/search",
    meta: {...(indexe1uqThM2KrMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"search"}},
    alias: indexe1uqThM2KrMeta?.alias || [],
    redirect: indexe1uqThM2KrMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: setPwdJyPIpcAFlvMeta?.name ?? "hk__setPwd",
    path: setPwdJyPIpcAFlvMeta?.path ?? "/hk/setPwd",
    meta: {...(setPwdJyPIpcAFlvMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"setPwd"}},
    alias: setPwdJyPIpcAFlvMeta?.alias || [],
    redirect: setPwdJyPIpcAFlvMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setPwd.vue").then(m => m.default || m)
  },
  {
    name: setPwdJyPIpcAFlvMeta?.name ?? "hk__zh-MO__setPwd",
    path: setPwdJyPIpcAFlvMeta?.path ?? "/hk/zh-MO/setPwd",
    meta: {...(setPwdJyPIpcAFlvMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"setPwd"}},
    alias: setPwdJyPIpcAFlvMeta?.alias || [],
    redirect: setPwdJyPIpcAFlvMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setPwd.vue").then(m => m.default || m)
  },
  {
    name: setPwdJyPIpcAFlvMeta?.name ?? "setPwd",
    path: setPwdJyPIpcAFlvMeta?.path ?? "/setPwd",
    meta: {...(setPwdJyPIpcAFlvMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"setPwd"}},
    alias: setPwdJyPIpcAFlvMeta?.alias || [],
    redirect: setPwdJyPIpcAFlvMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setPwd.vue").then(m => m.default || m)
  },
  {
    name: setPwdJyPIpcAFlvMeta?.name ?? "hk__zh-HK__setPwd",
    path: setPwdJyPIpcAFlvMeta?.path ?? "/hk/zh-HK/setPwd",
    meta: {...(setPwdJyPIpcAFlvMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"setPwd"}},
    alias: setPwdJyPIpcAFlvMeta?.alias || [],
    redirect: setPwdJyPIpcAFlvMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setPwd.vue").then(m => m.default || m)
  },
  {
    name: setPwdJyPIpcAFlvMeta?.name ?? "hk__zh-CN__setPwd",
    path: setPwdJyPIpcAFlvMeta?.path ?? "/hk/zh-CN/setPwd",
    meta: {...(setPwdJyPIpcAFlvMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"setPwd"}},
    alias: setPwdJyPIpcAFlvMeta?.alias || [],
    redirect: setPwdJyPIpcAFlvMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setPwd.vue").then(m => m.default || m)
  },
  {
    name: setPwdJyPIpcAFlvMeta?.name ?? "hk__en__setPwd",
    path: setPwdJyPIpcAFlvMeta?.path ?? "/hk/en/setPwd",
    meta: {...(setPwdJyPIpcAFlvMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"setPwd"}},
    alias: setPwdJyPIpcAFlvMeta?.alias || [],
    redirect: setPwdJyPIpcAFlvMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setPwd.vue").then(m => m.default || m)
  },
  {
    name: setPwdJyPIpcAFlvMeta?.name ?? "mo__setPwd",
    path: setPwdJyPIpcAFlvMeta?.path ?? "/mo/setPwd",
    meta: {...(setPwdJyPIpcAFlvMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"setPwd"}},
    alias: setPwdJyPIpcAFlvMeta?.alias || [],
    redirect: setPwdJyPIpcAFlvMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setPwd.vue").then(m => m.default || m)
  },
  {
    name: setPwdJyPIpcAFlvMeta?.name ?? "mo__zh-MO__setPwd",
    path: setPwdJyPIpcAFlvMeta?.path ?? "/mo/zh-MO/setPwd",
    meta: {...(setPwdJyPIpcAFlvMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"setPwd"}},
    alias: setPwdJyPIpcAFlvMeta?.alias || [],
    redirect: setPwdJyPIpcAFlvMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setPwd.vue").then(m => m.default || m)
  },
  {
    name: setPwdJyPIpcAFlvMeta?.name ?? "mo__zh-HK__setPwd",
    path: setPwdJyPIpcAFlvMeta?.path ?? "/mo/zh-HK/setPwd",
    meta: {...(setPwdJyPIpcAFlvMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"setPwd"}},
    alias: setPwdJyPIpcAFlvMeta?.alias || [],
    redirect: setPwdJyPIpcAFlvMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setPwd.vue").then(m => m.default || m)
  },
  {
    name: setPwdJyPIpcAFlvMeta?.name ?? "mo__zh-CN__setPwd",
    path: setPwdJyPIpcAFlvMeta?.path ?? "/mo/zh-CN/setPwd",
    meta: {...(setPwdJyPIpcAFlvMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"setPwd"}},
    alias: setPwdJyPIpcAFlvMeta?.alias || [],
    redirect: setPwdJyPIpcAFlvMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setPwd.vue").then(m => m.default || m)
  },
  {
    name: setPwdJyPIpcAFlvMeta?.name ?? "mo__en__setPwd",
    path: setPwdJyPIpcAFlvMeta?.path ?? "/mo/en/setPwd",
    meta: {...(setPwdJyPIpcAFlvMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"setPwd"}},
    alias: setPwdJyPIpcAFlvMeta?.alias || [],
    redirect: setPwdJyPIpcAFlvMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setPwd.vue").then(m => m.default || m)
  },
  {
    name: setPwdJyPIpcAFlvMeta?.name ?? "cn__setPwd",
    path: setPwdJyPIpcAFlvMeta?.path ?? "/cn/setPwd",
    meta: {...(setPwdJyPIpcAFlvMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"setPwd"}},
    alias: setPwdJyPIpcAFlvMeta?.alias || [],
    redirect: setPwdJyPIpcAFlvMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setPwd.vue").then(m => m.default || m)
  },
  {
    name: setPwdJyPIpcAFlvMeta?.name ?? "cn__zh-MO__setPwd",
    path: setPwdJyPIpcAFlvMeta?.path ?? "/cn/zh-MO/setPwd",
    meta: {...(setPwdJyPIpcAFlvMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"setPwd"}},
    alias: setPwdJyPIpcAFlvMeta?.alias || [],
    redirect: setPwdJyPIpcAFlvMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setPwd.vue").then(m => m.default || m)
  },
  {
    name: setPwdJyPIpcAFlvMeta?.name ?? "cn__zh-HK__setPwd",
    path: setPwdJyPIpcAFlvMeta?.path ?? "/cn/zh-HK/setPwd",
    meta: {...(setPwdJyPIpcAFlvMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"setPwd"}},
    alias: setPwdJyPIpcAFlvMeta?.alias || [],
    redirect: setPwdJyPIpcAFlvMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setPwd.vue").then(m => m.default || m)
  },
  {
    name: setPwdJyPIpcAFlvMeta?.name ?? "cn__zh-CN__setPwd",
    path: setPwdJyPIpcAFlvMeta?.path ?? "/cn/zh-CN/setPwd",
    meta: {...(setPwdJyPIpcAFlvMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"setPwd"}},
    alias: setPwdJyPIpcAFlvMeta?.alias || [],
    redirect: setPwdJyPIpcAFlvMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setPwd.vue").then(m => m.default || m)
  },
  {
    name: setPwdJyPIpcAFlvMeta?.name ?? "cn__en__setPwd",
    path: setPwdJyPIpcAFlvMeta?.path ?? "/cn/en/setPwd",
    meta: {...(setPwdJyPIpcAFlvMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"setPwd"}},
    alias: setPwdJyPIpcAFlvMeta?.alias || [],
    redirect: setPwdJyPIpcAFlvMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setPwd.vue").then(m => m.default || m)
  },
  {
    name: indexTUkIlrGAXTMeta?.name ?? "hk__setting",
    path: indexTUkIlrGAXTMeta?.path ?? "/hk/setting",
    meta: {...(indexTUkIlrGAXTMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"setting"}},
    alias: indexTUkIlrGAXTMeta?.alias || [],
    redirect: indexTUkIlrGAXTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/index.vue").then(m => m.default || m)
  },
  {
    name: indexTUkIlrGAXTMeta?.name ?? "hk__zh-MO__setting",
    path: indexTUkIlrGAXTMeta?.path ?? "/hk/zh-MO/setting",
    meta: {...(indexTUkIlrGAXTMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"setting"}},
    alias: indexTUkIlrGAXTMeta?.alias || [],
    redirect: indexTUkIlrGAXTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/index.vue").then(m => m.default || m)
  },
  {
    name: indexTUkIlrGAXTMeta?.name ?? "setting",
    path: indexTUkIlrGAXTMeta?.path ?? "/setting",
    meta: {...(indexTUkIlrGAXTMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"setting"}},
    alias: indexTUkIlrGAXTMeta?.alias || [],
    redirect: indexTUkIlrGAXTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/index.vue").then(m => m.default || m)
  },
  {
    name: indexTUkIlrGAXTMeta?.name ?? "hk__zh-HK__setting",
    path: indexTUkIlrGAXTMeta?.path ?? "/hk/zh-HK/setting",
    meta: {...(indexTUkIlrGAXTMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"setting"}},
    alias: indexTUkIlrGAXTMeta?.alias || [],
    redirect: indexTUkIlrGAXTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/index.vue").then(m => m.default || m)
  },
  {
    name: indexTUkIlrGAXTMeta?.name ?? "hk__zh-CN__setting",
    path: indexTUkIlrGAXTMeta?.path ?? "/hk/zh-CN/setting",
    meta: {...(indexTUkIlrGAXTMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"setting"}},
    alias: indexTUkIlrGAXTMeta?.alias || [],
    redirect: indexTUkIlrGAXTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/index.vue").then(m => m.default || m)
  },
  {
    name: indexTUkIlrGAXTMeta?.name ?? "hk__en__setting",
    path: indexTUkIlrGAXTMeta?.path ?? "/hk/en/setting",
    meta: {...(indexTUkIlrGAXTMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"setting"}},
    alias: indexTUkIlrGAXTMeta?.alias || [],
    redirect: indexTUkIlrGAXTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/index.vue").then(m => m.default || m)
  },
  {
    name: indexTUkIlrGAXTMeta?.name ?? "mo__setting",
    path: indexTUkIlrGAXTMeta?.path ?? "/mo/setting",
    meta: {...(indexTUkIlrGAXTMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"setting"}},
    alias: indexTUkIlrGAXTMeta?.alias || [],
    redirect: indexTUkIlrGAXTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/index.vue").then(m => m.default || m)
  },
  {
    name: indexTUkIlrGAXTMeta?.name ?? "mo__zh-MO__setting",
    path: indexTUkIlrGAXTMeta?.path ?? "/mo/zh-MO/setting",
    meta: {...(indexTUkIlrGAXTMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"setting"}},
    alias: indexTUkIlrGAXTMeta?.alias || [],
    redirect: indexTUkIlrGAXTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/index.vue").then(m => m.default || m)
  },
  {
    name: indexTUkIlrGAXTMeta?.name ?? "mo__zh-HK__setting",
    path: indexTUkIlrGAXTMeta?.path ?? "/mo/zh-HK/setting",
    meta: {...(indexTUkIlrGAXTMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"setting"}},
    alias: indexTUkIlrGAXTMeta?.alias || [],
    redirect: indexTUkIlrGAXTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/index.vue").then(m => m.default || m)
  },
  {
    name: indexTUkIlrGAXTMeta?.name ?? "mo__zh-CN__setting",
    path: indexTUkIlrGAXTMeta?.path ?? "/mo/zh-CN/setting",
    meta: {...(indexTUkIlrGAXTMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"setting"}},
    alias: indexTUkIlrGAXTMeta?.alias || [],
    redirect: indexTUkIlrGAXTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/index.vue").then(m => m.default || m)
  },
  {
    name: indexTUkIlrGAXTMeta?.name ?? "mo__en__setting",
    path: indexTUkIlrGAXTMeta?.path ?? "/mo/en/setting",
    meta: {...(indexTUkIlrGAXTMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"setting"}},
    alias: indexTUkIlrGAXTMeta?.alias || [],
    redirect: indexTUkIlrGAXTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/index.vue").then(m => m.default || m)
  },
  {
    name: indexTUkIlrGAXTMeta?.name ?? "cn__setting",
    path: indexTUkIlrGAXTMeta?.path ?? "/cn/setting",
    meta: {...(indexTUkIlrGAXTMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"setting"}},
    alias: indexTUkIlrGAXTMeta?.alias || [],
    redirect: indexTUkIlrGAXTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/index.vue").then(m => m.default || m)
  },
  {
    name: indexTUkIlrGAXTMeta?.name ?? "cn__zh-MO__setting",
    path: indexTUkIlrGAXTMeta?.path ?? "/cn/zh-MO/setting",
    meta: {...(indexTUkIlrGAXTMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"setting"}},
    alias: indexTUkIlrGAXTMeta?.alias || [],
    redirect: indexTUkIlrGAXTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/index.vue").then(m => m.default || m)
  },
  {
    name: indexTUkIlrGAXTMeta?.name ?? "cn__zh-HK__setting",
    path: indexTUkIlrGAXTMeta?.path ?? "/cn/zh-HK/setting",
    meta: {...(indexTUkIlrGAXTMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"setting"}},
    alias: indexTUkIlrGAXTMeta?.alias || [],
    redirect: indexTUkIlrGAXTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/index.vue").then(m => m.default || m)
  },
  {
    name: indexTUkIlrGAXTMeta?.name ?? "cn__zh-CN__setting",
    path: indexTUkIlrGAXTMeta?.path ?? "/cn/zh-CN/setting",
    meta: {...(indexTUkIlrGAXTMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"setting"}},
    alias: indexTUkIlrGAXTMeta?.alias || [],
    redirect: indexTUkIlrGAXTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/index.vue").then(m => m.default || m)
  },
  {
    name: indexTUkIlrGAXTMeta?.name ?? "cn__en__setting",
    path: indexTUkIlrGAXTMeta?.path ?? "/cn/en/setting",
    meta: {...(indexTUkIlrGAXTMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"setting"}},
    alias: indexTUkIlrGAXTMeta?.alias || [],
    redirect: indexTUkIlrGAXTMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/index.vue").then(m => m.default || m)
  },
  {
    name: emaildSDdg8ATc9Meta?.name ?? "hk__setting-update-email",
    path: emaildSDdg8ATc9Meta?.path ?? "/hk/setting/update/email",
    meta: {...(emaildSDdg8ATc9Meta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"setting-update-email"}},
    alias: emaildSDdg8ATc9Meta?.alias || [],
    redirect: emaildSDdg8ATc9Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/email.vue").then(m => m.default || m)
  },
  {
    name: emaildSDdg8ATc9Meta?.name ?? "hk__zh-MO__setting-update-email",
    path: emaildSDdg8ATc9Meta?.path ?? "/hk/zh-MO/setting/update/email",
    meta: {...(emaildSDdg8ATc9Meta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"setting-update-email"}},
    alias: emaildSDdg8ATc9Meta?.alias || [],
    redirect: emaildSDdg8ATc9Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/email.vue").then(m => m.default || m)
  },
  {
    name: emaildSDdg8ATc9Meta?.name ?? "setting-update-email",
    path: emaildSDdg8ATc9Meta?.path ?? "/setting/update/email",
    meta: {...(emaildSDdg8ATc9Meta || {}), ...{"siteCode":"","i18nCode":"","truePath":"setting-update-email"}},
    alias: emaildSDdg8ATc9Meta?.alias || [],
    redirect: emaildSDdg8ATc9Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/email.vue").then(m => m.default || m)
  },
  {
    name: emaildSDdg8ATc9Meta?.name ?? "hk__zh-HK__setting-update-email",
    path: emaildSDdg8ATc9Meta?.path ?? "/hk/zh-HK/setting/update/email",
    meta: {...(emaildSDdg8ATc9Meta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"setting-update-email"}},
    alias: emaildSDdg8ATc9Meta?.alias || [],
    redirect: emaildSDdg8ATc9Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/email.vue").then(m => m.default || m)
  },
  {
    name: emaildSDdg8ATc9Meta?.name ?? "hk__zh-CN__setting-update-email",
    path: emaildSDdg8ATc9Meta?.path ?? "/hk/zh-CN/setting/update/email",
    meta: {...(emaildSDdg8ATc9Meta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"setting-update-email"}},
    alias: emaildSDdg8ATc9Meta?.alias || [],
    redirect: emaildSDdg8ATc9Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/email.vue").then(m => m.default || m)
  },
  {
    name: emaildSDdg8ATc9Meta?.name ?? "hk__en__setting-update-email",
    path: emaildSDdg8ATc9Meta?.path ?? "/hk/en/setting/update/email",
    meta: {...(emaildSDdg8ATc9Meta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"setting-update-email"}},
    alias: emaildSDdg8ATc9Meta?.alias || [],
    redirect: emaildSDdg8ATc9Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/email.vue").then(m => m.default || m)
  },
  {
    name: emaildSDdg8ATc9Meta?.name ?? "mo__setting-update-email",
    path: emaildSDdg8ATc9Meta?.path ?? "/mo/setting/update/email",
    meta: {...(emaildSDdg8ATc9Meta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"setting-update-email"}},
    alias: emaildSDdg8ATc9Meta?.alias || [],
    redirect: emaildSDdg8ATc9Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/email.vue").then(m => m.default || m)
  },
  {
    name: emaildSDdg8ATc9Meta?.name ?? "mo__zh-MO__setting-update-email",
    path: emaildSDdg8ATc9Meta?.path ?? "/mo/zh-MO/setting/update/email",
    meta: {...(emaildSDdg8ATc9Meta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"setting-update-email"}},
    alias: emaildSDdg8ATc9Meta?.alias || [],
    redirect: emaildSDdg8ATc9Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/email.vue").then(m => m.default || m)
  },
  {
    name: emaildSDdg8ATc9Meta?.name ?? "mo__zh-HK__setting-update-email",
    path: emaildSDdg8ATc9Meta?.path ?? "/mo/zh-HK/setting/update/email",
    meta: {...(emaildSDdg8ATc9Meta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"setting-update-email"}},
    alias: emaildSDdg8ATc9Meta?.alias || [],
    redirect: emaildSDdg8ATc9Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/email.vue").then(m => m.default || m)
  },
  {
    name: emaildSDdg8ATc9Meta?.name ?? "mo__zh-CN__setting-update-email",
    path: emaildSDdg8ATc9Meta?.path ?? "/mo/zh-CN/setting/update/email",
    meta: {...(emaildSDdg8ATc9Meta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"setting-update-email"}},
    alias: emaildSDdg8ATc9Meta?.alias || [],
    redirect: emaildSDdg8ATc9Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/email.vue").then(m => m.default || m)
  },
  {
    name: emaildSDdg8ATc9Meta?.name ?? "mo__en__setting-update-email",
    path: emaildSDdg8ATc9Meta?.path ?? "/mo/en/setting/update/email",
    meta: {...(emaildSDdg8ATc9Meta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"setting-update-email"}},
    alias: emaildSDdg8ATc9Meta?.alias || [],
    redirect: emaildSDdg8ATc9Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/email.vue").then(m => m.default || m)
  },
  {
    name: emaildSDdg8ATc9Meta?.name ?? "cn__setting-update-email",
    path: emaildSDdg8ATc9Meta?.path ?? "/cn/setting/update/email",
    meta: {...(emaildSDdg8ATc9Meta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"setting-update-email"}},
    alias: emaildSDdg8ATc9Meta?.alias || [],
    redirect: emaildSDdg8ATc9Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/email.vue").then(m => m.default || m)
  },
  {
    name: emaildSDdg8ATc9Meta?.name ?? "cn__zh-MO__setting-update-email",
    path: emaildSDdg8ATc9Meta?.path ?? "/cn/zh-MO/setting/update/email",
    meta: {...(emaildSDdg8ATc9Meta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"setting-update-email"}},
    alias: emaildSDdg8ATc9Meta?.alias || [],
    redirect: emaildSDdg8ATc9Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/email.vue").then(m => m.default || m)
  },
  {
    name: emaildSDdg8ATc9Meta?.name ?? "cn__zh-HK__setting-update-email",
    path: emaildSDdg8ATc9Meta?.path ?? "/cn/zh-HK/setting/update/email",
    meta: {...(emaildSDdg8ATc9Meta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"setting-update-email"}},
    alias: emaildSDdg8ATc9Meta?.alias || [],
    redirect: emaildSDdg8ATc9Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/email.vue").then(m => m.default || m)
  },
  {
    name: emaildSDdg8ATc9Meta?.name ?? "cn__zh-CN__setting-update-email",
    path: emaildSDdg8ATc9Meta?.path ?? "/cn/zh-CN/setting/update/email",
    meta: {...(emaildSDdg8ATc9Meta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"setting-update-email"}},
    alias: emaildSDdg8ATc9Meta?.alias || [],
    redirect: emaildSDdg8ATc9Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/email.vue").then(m => m.default || m)
  },
  {
    name: emaildSDdg8ATc9Meta?.name ?? "cn__en__setting-update-email",
    path: emaildSDdg8ATc9Meta?.path ?? "/cn/en/setting/update/email",
    meta: {...(emaildSDdg8ATc9Meta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"setting-update-email"}},
    alias: emaildSDdg8ATc9Meta?.alias || [],
    redirect: emaildSDdg8ATc9Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/email.vue").then(m => m.default || m)
  },
  {
    name: nicknamexo5LR2DrFiMeta?.name ?? "hk__setting-update-nickname",
    path: nicknamexo5LR2DrFiMeta?.path ?? "/hk/setting/update/nickname",
    meta: {...(nicknamexo5LR2DrFiMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"setting-update-nickname"}},
    alias: nicknamexo5LR2DrFiMeta?.alias || [],
    redirect: nicknamexo5LR2DrFiMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/nickname.vue").then(m => m.default || m)
  },
  {
    name: nicknamexo5LR2DrFiMeta?.name ?? "hk__zh-MO__setting-update-nickname",
    path: nicknamexo5LR2DrFiMeta?.path ?? "/hk/zh-MO/setting/update/nickname",
    meta: {...(nicknamexo5LR2DrFiMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"setting-update-nickname"}},
    alias: nicknamexo5LR2DrFiMeta?.alias || [],
    redirect: nicknamexo5LR2DrFiMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/nickname.vue").then(m => m.default || m)
  },
  {
    name: nicknamexo5LR2DrFiMeta?.name ?? "setting-update-nickname",
    path: nicknamexo5LR2DrFiMeta?.path ?? "/setting/update/nickname",
    meta: {...(nicknamexo5LR2DrFiMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"setting-update-nickname"}},
    alias: nicknamexo5LR2DrFiMeta?.alias || [],
    redirect: nicknamexo5LR2DrFiMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/nickname.vue").then(m => m.default || m)
  },
  {
    name: nicknamexo5LR2DrFiMeta?.name ?? "hk__zh-HK__setting-update-nickname",
    path: nicknamexo5LR2DrFiMeta?.path ?? "/hk/zh-HK/setting/update/nickname",
    meta: {...(nicknamexo5LR2DrFiMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"setting-update-nickname"}},
    alias: nicknamexo5LR2DrFiMeta?.alias || [],
    redirect: nicknamexo5LR2DrFiMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/nickname.vue").then(m => m.default || m)
  },
  {
    name: nicknamexo5LR2DrFiMeta?.name ?? "hk__zh-CN__setting-update-nickname",
    path: nicknamexo5LR2DrFiMeta?.path ?? "/hk/zh-CN/setting/update/nickname",
    meta: {...(nicknamexo5LR2DrFiMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"setting-update-nickname"}},
    alias: nicknamexo5LR2DrFiMeta?.alias || [],
    redirect: nicknamexo5LR2DrFiMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/nickname.vue").then(m => m.default || m)
  },
  {
    name: nicknamexo5LR2DrFiMeta?.name ?? "hk__en__setting-update-nickname",
    path: nicknamexo5LR2DrFiMeta?.path ?? "/hk/en/setting/update/nickname",
    meta: {...(nicknamexo5LR2DrFiMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"setting-update-nickname"}},
    alias: nicknamexo5LR2DrFiMeta?.alias || [],
    redirect: nicknamexo5LR2DrFiMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/nickname.vue").then(m => m.default || m)
  },
  {
    name: nicknamexo5LR2DrFiMeta?.name ?? "mo__setting-update-nickname",
    path: nicknamexo5LR2DrFiMeta?.path ?? "/mo/setting/update/nickname",
    meta: {...(nicknamexo5LR2DrFiMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"setting-update-nickname"}},
    alias: nicknamexo5LR2DrFiMeta?.alias || [],
    redirect: nicknamexo5LR2DrFiMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/nickname.vue").then(m => m.default || m)
  },
  {
    name: nicknamexo5LR2DrFiMeta?.name ?? "mo__zh-MO__setting-update-nickname",
    path: nicknamexo5LR2DrFiMeta?.path ?? "/mo/zh-MO/setting/update/nickname",
    meta: {...(nicknamexo5LR2DrFiMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"setting-update-nickname"}},
    alias: nicknamexo5LR2DrFiMeta?.alias || [],
    redirect: nicknamexo5LR2DrFiMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/nickname.vue").then(m => m.default || m)
  },
  {
    name: nicknamexo5LR2DrFiMeta?.name ?? "mo__zh-HK__setting-update-nickname",
    path: nicknamexo5LR2DrFiMeta?.path ?? "/mo/zh-HK/setting/update/nickname",
    meta: {...(nicknamexo5LR2DrFiMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"setting-update-nickname"}},
    alias: nicknamexo5LR2DrFiMeta?.alias || [],
    redirect: nicknamexo5LR2DrFiMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/nickname.vue").then(m => m.default || m)
  },
  {
    name: nicknamexo5LR2DrFiMeta?.name ?? "mo__zh-CN__setting-update-nickname",
    path: nicknamexo5LR2DrFiMeta?.path ?? "/mo/zh-CN/setting/update/nickname",
    meta: {...(nicknamexo5LR2DrFiMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"setting-update-nickname"}},
    alias: nicknamexo5LR2DrFiMeta?.alias || [],
    redirect: nicknamexo5LR2DrFiMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/nickname.vue").then(m => m.default || m)
  },
  {
    name: nicknamexo5LR2DrFiMeta?.name ?? "mo__en__setting-update-nickname",
    path: nicknamexo5LR2DrFiMeta?.path ?? "/mo/en/setting/update/nickname",
    meta: {...(nicknamexo5LR2DrFiMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"setting-update-nickname"}},
    alias: nicknamexo5LR2DrFiMeta?.alias || [],
    redirect: nicknamexo5LR2DrFiMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/nickname.vue").then(m => m.default || m)
  },
  {
    name: nicknamexo5LR2DrFiMeta?.name ?? "cn__setting-update-nickname",
    path: nicknamexo5LR2DrFiMeta?.path ?? "/cn/setting/update/nickname",
    meta: {...(nicknamexo5LR2DrFiMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"setting-update-nickname"}},
    alias: nicknamexo5LR2DrFiMeta?.alias || [],
    redirect: nicknamexo5LR2DrFiMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/nickname.vue").then(m => m.default || m)
  },
  {
    name: nicknamexo5LR2DrFiMeta?.name ?? "cn__zh-MO__setting-update-nickname",
    path: nicknamexo5LR2DrFiMeta?.path ?? "/cn/zh-MO/setting/update/nickname",
    meta: {...(nicknamexo5LR2DrFiMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"setting-update-nickname"}},
    alias: nicknamexo5LR2DrFiMeta?.alias || [],
    redirect: nicknamexo5LR2DrFiMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/nickname.vue").then(m => m.default || m)
  },
  {
    name: nicknamexo5LR2DrFiMeta?.name ?? "cn__zh-HK__setting-update-nickname",
    path: nicknamexo5LR2DrFiMeta?.path ?? "/cn/zh-HK/setting/update/nickname",
    meta: {...(nicknamexo5LR2DrFiMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"setting-update-nickname"}},
    alias: nicknamexo5LR2DrFiMeta?.alias || [],
    redirect: nicknamexo5LR2DrFiMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/nickname.vue").then(m => m.default || m)
  },
  {
    name: nicknamexo5LR2DrFiMeta?.name ?? "cn__zh-CN__setting-update-nickname",
    path: nicknamexo5LR2DrFiMeta?.path ?? "/cn/zh-CN/setting/update/nickname",
    meta: {...(nicknamexo5LR2DrFiMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"setting-update-nickname"}},
    alias: nicknamexo5LR2DrFiMeta?.alias || [],
    redirect: nicknamexo5LR2DrFiMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/nickname.vue").then(m => m.default || m)
  },
  {
    name: nicknamexo5LR2DrFiMeta?.name ?? "cn__en__setting-update-nickname",
    path: nicknamexo5LR2DrFiMeta?.path ?? "/cn/en/setting/update/nickname",
    meta: {...(nicknamexo5LR2DrFiMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"setting-update-nickname"}},
    alias: nicknamexo5LR2DrFiMeta?.alias || [],
    redirect: nicknamexo5LR2DrFiMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/nickname.vue").then(m => m.default || m)
  },
  {
    name: phoneq2tZVAuLguMeta?.name ?? "hk__setting-update-phone",
    path: phoneq2tZVAuLguMeta?.path ?? "/hk/setting/update/phone",
    meta: {...(phoneq2tZVAuLguMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"setting-update-phone"}},
    alias: phoneq2tZVAuLguMeta?.alias || [],
    redirect: phoneq2tZVAuLguMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/phone.vue").then(m => m.default || m)
  },
  {
    name: phoneq2tZVAuLguMeta?.name ?? "hk__zh-MO__setting-update-phone",
    path: phoneq2tZVAuLguMeta?.path ?? "/hk/zh-MO/setting/update/phone",
    meta: {...(phoneq2tZVAuLguMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"setting-update-phone"}},
    alias: phoneq2tZVAuLguMeta?.alias || [],
    redirect: phoneq2tZVAuLguMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/phone.vue").then(m => m.default || m)
  },
  {
    name: phoneq2tZVAuLguMeta?.name ?? "setting-update-phone",
    path: phoneq2tZVAuLguMeta?.path ?? "/setting/update/phone",
    meta: {...(phoneq2tZVAuLguMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"setting-update-phone"}},
    alias: phoneq2tZVAuLguMeta?.alias || [],
    redirect: phoneq2tZVAuLguMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/phone.vue").then(m => m.default || m)
  },
  {
    name: phoneq2tZVAuLguMeta?.name ?? "hk__zh-HK__setting-update-phone",
    path: phoneq2tZVAuLguMeta?.path ?? "/hk/zh-HK/setting/update/phone",
    meta: {...(phoneq2tZVAuLguMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"setting-update-phone"}},
    alias: phoneq2tZVAuLguMeta?.alias || [],
    redirect: phoneq2tZVAuLguMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/phone.vue").then(m => m.default || m)
  },
  {
    name: phoneq2tZVAuLguMeta?.name ?? "hk__zh-CN__setting-update-phone",
    path: phoneq2tZVAuLguMeta?.path ?? "/hk/zh-CN/setting/update/phone",
    meta: {...(phoneq2tZVAuLguMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"setting-update-phone"}},
    alias: phoneq2tZVAuLguMeta?.alias || [],
    redirect: phoneq2tZVAuLguMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/phone.vue").then(m => m.default || m)
  },
  {
    name: phoneq2tZVAuLguMeta?.name ?? "hk__en__setting-update-phone",
    path: phoneq2tZVAuLguMeta?.path ?? "/hk/en/setting/update/phone",
    meta: {...(phoneq2tZVAuLguMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"setting-update-phone"}},
    alias: phoneq2tZVAuLguMeta?.alias || [],
    redirect: phoneq2tZVAuLguMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/phone.vue").then(m => m.default || m)
  },
  {
    name: phoneq2tZVAuLguMeta?.name ?? "mo__setting-update-phone",
    path: phoneq2tZVAuLguMeta?.path ?? "/mo/setting/update/phone",
    meta: {...(phoneq2tZVAuLguMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"setting-update-phone"}},
    alias: phoneq2tZVAuLguMeta?.alias || [],
    redirect: phoneq2tZVAuLguMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/phone.vue").then(m => m.default || m)
  },
  {
    name: phoneq2tZVAuLguMeta?.name ?? "mo__zh-MO__setting-update-phone",
    path: phoneq2tZVAuLguMeta?.path ?? "/mo/zh-MO/setting/update/phone",
    meta: {...(phoneq2tZVAuLguMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"setting-update-phone"}},
    alias: phoneq2tZVAuLguMeta?.alias || [],
    redirect: phoneq2tZVAuLguMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/phone.vue").then(m => m.default || m)
  },
  {
    name: phoneq2tZVAuLguMeta?.name ?? "mo__zh-HK__setting-update-phone",
    path: phoneq2tZVAuLguMeta?.path ?? "/mo/zh-HK/setting/update/phone",
    meta: {...(phoneq2tZVAuLguMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"setting-update-phone"}},
    alias: phoneq2tZVAuLguMeta?.alias || [],
    redirect: phoneq2tZVAuLguMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/phone.vue").then(m => m.default || m)
  },
  {
    name: phoneq2tZVAuLguMeta?.name ?? "mo__zh-CN__setting-update-phone",
    path: phoneq2tZVAuLguMeta?.path ?? "/mo/zh-CN/setting/update/phone",
    meta: {...(phoneq2tZVAuLguMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"setting-update-phone"}},
    alias: phoneq2tZVAuLguMeta?.alias || [],
    redirect: phoneq2tZVAuLguMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/phone.vue").then(m => m.default || m)
  },
  {
    name: phoneq2tZVAuLguMeta?.name ?? "mo__en__setting-update-phone",
    path: phoneq2tZVAuLguMeta?.path ?? "/mo/en/setting/update/phone",
    meta: {...(phoneq2tZVAuLguMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"setting-update-phone"}},
    alias: phoneq2tZVAuLguMeta?.alias || [],
    redirect: phoneq2tZVAuLguMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/phone.vue").then(m => m.default || m)
  },
  {
    name: phoneq2tZVAuLguMeta?.name ?? "cn__setting-update-phone",
    path: phoneq2tZVAuLguMeta?.path ?? "/cn/setting/update/phone",
    meta: {...(phoneq2tZVAuLguMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"setting-update-phone"}},
    alias: phoneq2tZVAuLguMeta?.alias || [],
    redirect: phoneq2tZVAuLguMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/phone.vue").then(m => m.default || m)
  },
  {
    name: phoneq2tZVAuLguMeta?.name ?? "cn__zh-MO__setting-update-phone",
    path: phoneq2tZVAuLguMeta?.path ?? "/cn/zh-MO/setting/update/phone",
    meta: {...(phoneq2tZVAuLguMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"setting-update-phone"}},
    alias: phoneq2tZVAuLguMeta?.alias || [],
    redirect: phoneq2tZVAuLguMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/phone.vue").then(m => m.default || m)
  },
  {
    name: phoneq2tZVAuLguMeta?.name ?? "cn__zh-HK__setting-update-phone",
    path: phoneq2tZVAuLguMeta?.path ?? "/cn/zh-HK/setting/update/phone",
    meta: {...(phoneq2tZVAuLguMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"setting-update-phone"}},
    alias: phoneq2tZVAuLguMeta?.alias || [],
    redirect: phoneq2tZVAuLguMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/phone.vue").then(m => m.default || m)
  },
  {
    name: phoneq2tZVAuLguMeta?.name ?? "cn__zh-CN__setting-update-phone",
    path: phoneq2tZVAuLguMeta?.path ?? "/cn/zh-CN/setting/update/phone",
    meta: {...(phoneq2tZVAuLguMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"setting-update-phone"}},
    alias: phoneq2tZVAuLguMeta?.alias || [],
    redirect: phoneq2tZVAuLguMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/phone.vue").then(m => m.default || m)
  },
  {
    name: phoneq2tZVAuLguMeta?.name ?? "cn__en__setting-update-phone",
    path: phoneq2tZVAuLguMeta?.path ?? "/cn/en/setting/update/phone",
    meta: {...(phoneq2tZVAuLguMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"setting-update-phone"}},
    alias: phoneq2tZVAuLguMeta?.alias || [],
    redirect: phoneq2tZVAuLguMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/phone.vue").then(m => m.default || m)
  },
  {
    name: indexXt61dKXHu9Meta?.name ?? "hk__setting-update-pwd",
    path: indexXt61dKXHu9Meta?.path ?? "/hk/setting/update/pwd",
    meta: {...(indexXt61dKXHu9Meta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"setting-update-pwd"}},
    alias: indexXt61dKXHu9Meta?.alias || [],
    redirect: indexXt61dKXHu9Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/index.vue").then(m => m.default || m)
  },
  {
    name: indexXt61dKXHu9Meta?.name ?? "hk__zh-MO__setting-update-pwd",
    path: indexXt61dKXHu9Meta?.path ?? "/hk/zh-MO/setting/update/pwd",
    meta: {...(indexXt61dKXHu9Meta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"setting-update-pwd"}},
    alias: indexXt61dKXHu9Meta?.alias || [],
    redirect: indexXt61dKXHu9Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/index.vue").then(m => m.default || m)
  },
  {
    name: indexXt61dKXHu9Meta?.name ?? "setting-update-pwd",
    path: indexXt61dKXHu9Meta?.path ?? "/setting/update/pwd",
    meta: {...(indexXt61dKXHu9Meta || {}), ...{"siteCode":"","i18nCode":"","truePath":"setting-update-pwd"}},
    alias: indexXt61dKXHu9Meta?.alias || [],
    redirect: indexXt61dKXHu9Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/index.vue").then(m => m.default || m)
  },
  {
    name: indexXt61dKXHu9Meta?.name ?? "hk__zh-HK__setting-update-pwd",
    path: indexXt61dKXHu9Meta?.path ?? "/hk/zh-HK/setting/update/pwd",
    meta: {...(indexXt61dKXHu9Meta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"setting-update-pwd"}},
    alias: indexXt61dKXHu9Meta?.alias || [],
    redirect: indexXt61dKXHu9Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/index.vue").then(m => m.default || m)
  },
  {
    name: indexXt61dKXHu9Meta?.name ?? "hk__zh-CN__setting-update-pwd",
    path: indexXt61dKXHu9Meta?.path ?? "/hk/zh-CN/setting/update/pwd",
    meta: {...(indexXt61dKXHu9Meta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"setting-update-pwd"}},
    alias: indexXt61dKXHu9Meta?.alias || [],
    redirect: indexXt61dKXHu9Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/index.vue").then(m => m.default || m)
  },
  {
    name: indexXt61dKXHu9Meta?.name ?? "hk__en__setting-update-pwd",
    path: indexXt61dKXHu9Meta?.path ?? "/hk/en/setting/update/pwd",
    meta: {...(indexXt61dKXHu9Meta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"setting-update-pwd"}},
    alias: indexXt61dKXHu9Meta?.alias || [],
    redirect: indexXt61dKXHu9Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/index.vue").then(m => m.default || m)
  },
  {
    name: indexXt61dKXHu9Meta?.name ?? "mo__setting-update-pwd",
    path: indexXt61dKXHu9Meta?.path ?? "/mo/setting/update/pwd",
    meta: {...(indexXt61dKXHu9Meta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"setting-update-pwd"}},
    alias: indexXt61dKXHu9Meta?.alias || [],
    redirect: indexXt61dKXHu9Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/index.vue").then(m => m.default || m)
  },
  {
    name: indexXt61dKXHu9Meta?.name ?? "mo__zh-MO__setting-update-pwd",
    path: indexXt61dKXHu9Meta?.path ?? "/mo/zh-MO/setting/update/pwd",
    meta: {...(indexXt61dKXHu9Meta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"setting-update-pwd"}},
    alias: indexXt61dKXHu9Meta?.alias || [],
    redirect: indexXt61dKXHu9Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/index.vue").then(m => m.default || m)
  },
  {
    name: indexXt61dKXHu9Meta?.name ?? "mo__zh-HK__setting-update-pwd",
    path: indexXt61dKXHu9Meta?.path ?? "/mo/zh-HK/setting/update/pwd",
    meta: {...(indexXt61dKXHu9Meta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"setting-update-pwd"}},
    alias: indexXt61dKXHu9Meta?.alias || [],
    redirect: indexXt61dKXHu9Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/index.vue").then(m => m.default || m)
  },
  {
    name: indexXt61dKXHu9Meta?.name ?? "mo__zh-CN__setting-update-pwd",
    path: indexXt61dKXHu9Meta?.path ?? "/mo/zh-CN/setting/update/pwd",
    meta: {...(indexXt61dKXHu9Meta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"setting-update-pwd"}},
    alias: indexXt61dKXHu9Meta?.alias || [],
    redirect: indexXt61dKXHu9Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/index.vue").then(m => m.default || m)
  },
  {
    name: indexXt61dKXHu9Meta?.name ?? "mo__en__setting-update-pwd",
    path: indexXt61dKXHu9Meta?.path ?? "/mo/en/setting/update/pwd",
    meta: {...(indexXt61dKXHu9Meta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"setting-update-pwd"}},
    alias: indexXt61dKXHu9Meta?.alias || [],
    redirect: indexXt61dKXHu9Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/index.vue").then(m => m.default || m)
  },
  {
    name: indexXt61dKXHu9Meta?.name ?? "cn__setting-update-pwd",
    path: indexXt61dKXHu9Meta?.path ?? "/cn/setting/update/pwd",
    meta: {...(indexXt61dKXHu9Meta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"setting-update-pwd"}},
    alias: indexXt61dKXHu9Meta?.alias || [],
    redirect: indexXt61dKXHu9Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/index.vue").then(m => m.default || m)
  },
  {
    name: indexXt61dKXHu9Meta?.name ?? "cn__zh-MO__setting-update-pwd",
    path: indexXt61dKXHu9Meta?.path ?? "/cn/zh-MO/setting/update/pwd",
    meta: {...(indexXt61dKXHu9Meta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"setting-update-pwd"}},
    alias: indexXt61dKXHu9Meta?.alias || [],
    redirect: indexXt61dKXHu9Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/index.vue").then(m => m.default || m)
  },
  {
    name: indexXt61dKXHu9Meta?.name ?? "cn__zh-HK__setting-update-pwd",
    path: indexXt61dKXHu9Meta?.path ?? "/cn/zh-HK/setting/update/pwd",
    meta: {...(indexXt61dKXHu9Meta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"setting-update-pwd"}},
    alias: indexXt61dKXHu9Meta?.alias || [],
    redirect: indexXt61dKXHu9Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/index.vue").then(m => m.default || m)
  },
  {
    name: indexXt61dKXHu9Meta?.name ?? "cn__zh-CN__setting-update-pwd",
    path: indexXt61dKXHu9Meta?.path ?? "/cn/zh-CN/setting/update/pwd",
    meta: {...(indexXt61dKXHu9Meta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"setting-update-pwd"}},
    alias: indexXt61dKXHu9Meta?.alias || [],
    redirect: indexXt61dKXHu9Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/index.vue").then(m => m.default || m)
  },
  {
    name: indexXt61dKXHu9Meta?.name ?? "cn__en__setting-update-pwd",
    path: indexXt61dKXHu9Meta?.path ?? "/cn/en/setting/update/pwd",
    meta: {...(indexXt61dKXHu9Meta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"setting-update-pwd"}},
    alias: indexXt61dKXHu9Meta?.alias || [],
    redirect: indexXt61dKXHu9Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/index.vue").then(m => m.default || m)
  },
  {
    name: oldPwdLoCk100djIMeta?.name ?? "hk__setting-update-pwd-oldPwd",
    path: oldPwdLoCk100djIMeta?.path ?? "/hk/setting/update/pwd/oldPwd",
    meta: {...(oldPwdLoCk100djIMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"setting-update-pwd-oldPwd"}},
    alias: oldPwdLoCk100djIMeta?.alias || [],
    redirect: oldPwdLoCk100djIMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/oldPwd.vue").then(m => m.default || m)
  },
  {
    name: oldPwdLoCk100djIMeta?.name ?? "hk__zh-MO__setting-update-pwd-oldPwd",
    path: oldPwdLoCk100djIMeta?.path ?? "/hk/zh-MO/setting/update/pwd/oldPwd",
    meta: {...(oldPwdLoCk100djIMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"setting-update-pwd-oldPwd"}},
    alias: oldPwdLoCk100djIMeta?.alias || [],
    redirect: oldPwdLoCk100djIMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/oldPwd.vue").then(m => m.default || m)
  },
  {
    name: oldPwdLoCk100djIMeta?.name ?? "setting-update-pwd-oldPwd",
    path: oldPwdLoCk100djIMeta?.path ?? "/setting/update/pwd/oldPwd",
    meta: {...(oldPwdLoCk100djIMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"setting-update-pwd-oldPwd"}},
    alias: oldPwdLoCk100djIMeta?.alias || [],
    redirect: oldPwdLoCk100djIMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/oldPwd.vue").then(m => m.default || m)
  },
  {
    name: oldPwdLoCk100djIMeta?.name ?? "hk__zh-HK__setting-update-pwd-oldPwd",
    path: oldPwdLoCk100djIMeta?.path ?? "/hk/zh-HK/setting/update/pwd/oldPwd",
    meta: {...(oldPwdLoCk100djIMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"setting-update-pwd-oldPwd"}},
    alias: oldPwdLoCk100djIMeta?.alias || [],
    redirect: oldPwdLoCk100djIMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/oldPwd.vue").then(m => m.default || m)
  },
  {
    name: oldPwdLoCk100djIMeta?.name ?? "hk__zh-CN__setting-update-pwd-oldPwd",
    path: oldPwdLoCk100djIMeta?.path ?? "/hk/zh-CN/setting/update/pwd/oldPwd",
    meta: {...(oldPwdLoCk100djIMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"setting-update-pwd-oldPwd"}},
    alias: oldPwdLoCk100djIMeta?.alias || [],
    redirect: oldPwdLoCk100djIMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/oldPwd.vue").then(m => m.default || m)
  },
  {
    name: oldPwdLoCk100djIMeta?.name ?? "hk__en__setting-update-pwd-oldPwd",
    path: oldPwdLoCk100djIMeta?.path ?? "/hk/en/setting/update/pwd/oldPwd",
    meta: {...(oldPwdLoCk100djIMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"setting-update-pwd-oldPwd"}},
    alias: oldPwdLoCk100djIMeta?.alias || [],
    redirect: oldPwdLoCk100djIMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/oldPwd.vue").then(m => m.default || m)
  },
  {
    name: oldPwdLoCk100djIMeta?.name ?? "mo__setting-update-pwd-oldPwd",
    path: oldPwdLoCk100djIMeta?.path ?? "/mo/setting/update/pwd/oldPwd",
    meta: {...(oldPwdLoCk100djIMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"setting-update-pwd-oldPwd"}},
    alias: oldPwdLoCk100djIMeta?.alias || [],
    redirect: oldPwdLoCk100djIMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/oldPwd.vue").then(m => m.default || m)
  },
  {
    name: oldPwdLoCk100djIMeta?.name ?? "mo__zh-MO__setting-update-pwd-oldPwd",
    path: oldPwdLoCk100djIMeta?.path ?? "/mo/zh-MO/setting/update/pwd/oldPwd",
    meta: {...(oldPwdLoCk100djIMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"setting-update-pwd-oldPwd"}},
    alias: oldPwdLoCk100djIMeta?.alias || [],
    redirect: oldPwdLoCk100djIMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/oldPwd.vue").then(m => m.default || m)
  },
  {
    name: oldPwdLoCk100djIMeta?.name ?? "mo__zh-HK__setting-update-pwd-oldPwd",
    path: oldPwdLoCk100djIMeta?.path ?? "/mo/zh-HK/setting/update/pwd/oldPwd",
    meta: {...(oldPwdLoCk100djIMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"setting-update-pwd-oldPwd"}},
    alias: oldPwdLoCk100djIMeta?.alias || [],
    redirect: oldPwdLoCk100djIMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/oldPwd.vue").then(m => m.default || m)
  },
  {
    name: oldPwdLoCk100djIMeta?.name ?? "mo__zh-CN__setting-update-pwd-oldPwd",
    path: oldPwdLoCk100djIMeta?.path ?? "/mo/zh-CN/setting/update/pwd/oldPwd",
    meta: {...(oldPwdLoCk100djIMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"setting-update-pwd-oldPwd"}},
    alias: oldPwdLoCk100djIMeta?.alias || [],
    redirect: oldPwdLoCk100djIMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/oldPwd.vue").then(m => m.default || m)
  },
  {
    name: oldPwdLoCk100djIMeta?.name ?? "mo__en__setting-update-pwd-oldPwd",
    path: oldPwdLoCk100djIMeta?.path ?? "/mo/en/setting/update/pwd/oldPwd",
    meta: {...(oldPwdLoCk100djIMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"setting-update-pwd-oldPwd"}},
    alias: oldPwdLoCk100djIMeta?.alias || [],
    redirect: oldPwdLoCk100djIMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/oldPwd.vue").then(m => m.default || m)
  },
  {
    name: oldPwdLoCk100djIMeta?.name ?? "cn__setting-update-pwd-oldPwd",
    path: oldPwdLoCk100djIMeta?.path ?? "/cn/setting/update/pwd/oldPwd",
    meta: {...(oldPwdLoCk100djIMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"setting-update-pwd-oldPwd"}},
    alias: oldPwdLoCk100djIMeta?.alias || [],
    redirect: oldPwdLoCk100djIMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/oldPwd.vue").then(m => m.default || m)
  },
  {
    name: oldPwdLoCk100djIMeta?.name ?? "cn__zh-MO__setting-update-pwd-oldPwd",
    path: oldPwdLoCk100djIMeta?.path ?? "/cn/zh-MO/setting/update/pwd/oldPwd",
    meta: {...(oldPwdLoCk100djIMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"setting-update-pwd-oldPwd"}},
    alias: oldPwdLoCk100djIMeta?.alias || [],
    redirect: oldPwdLoCk100djIMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/oldPwd.vue").then(m => m.default || m)
  },
  {
    name: oldPwdLoCk100djIMeta?.name ?? "cn__zh-HK__setting-update-pwd-oldPwd",
    path: oldPwdLoCk100djIMeta?.path ?? "/cn/zh-HK/setting/update/pwd/oldPwd",
    meta: {...(oldPwdLoCk100djIMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"setting-update-pwd-oldPwd"}},
    alias: oldPwdLoCk100djIMeta?.alias || [],
    redirect: oldPwdLoCk100djIMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/oldPwd.vue").then(m => m.default || m)
  },
  {
    name: oldPwdLoCk100djIMeta?.name ?? "cn__zh-CN__setting-update-pwd-oldPwd",
    path: oldPwdLoCk100djIMeta?.path ?? "/cn/zh-CN/setting/update/pwd/oldPwd",
    meta: {...(oldPwdLoCk100djIMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"setting-update-pwd-oldPwd"}},
    alias: oldPwdLoCk100djIMeta?.alias || [],
    redirect: oldPwdLoCk100djIMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/oldPwd.vue").then(m => m.default || m)
  },
  {
    name: oldPwdLoCk100djIMeta?.name ?? "cn__en__setting-update-pwd-oldPwd",
    path: oldPwdLoCk100djIMeta?.path ?? "/cn/en/setting/update/pwd/oldPwd",
    meta: {...(oldPwdLoCk100djIMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"setting-update-pwd-oldPwd"}},
    alias: oldPwdLoCk100djIMeta?.alias || [],
    redirect: oldPwdLoCk100djIMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/oldPwd.vue").then(m => m.default || m)
  },
  {
    name: smsqAwPUojNY2Meta?.name ?? "hk__setting-update-pwd-sms",
    path: smsqAwPUojNY2Meta?.path ?? "/hk/setting/update/pwd/sms",
    meta: {...(smsqAwPUojNY2Meta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"setting-update-pwd-sms"}},
    alias: smsqAwPUojNY2Meta?.alias || [],
    redirect: smsqAwPUojNY2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/sms.vue").then(m => m.default || m)
  },
  {
    name: smsqAwPUojNY2Meta?.name ?? "hk__zh-MO__setting-update-pwd-sms",
    path: smsqAwPUojNY2Meta?.path ?? "/hk/zh-MO/setting/update/pwd/sms",
    meta: {...(smsqAwPUojNY2Meta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"setting-update-pwd-sms"}},
    alias: smsqAwPUojNY2Meta?.alias || [],
    redirect: smsqAwPUojNY2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/sms.vue").then(m => m.default || m)
  },
  {
    name: smsqAwPUojNY2Meta?.name ?? "setting-update-pwd-sms",
    path: smsqAwPUojNY2Meta?.path ?? "/setting/update/pwd/sms",
    meta: {...(smsqAwPUojNY2Meta || {}), ...{"siteCode":"","i18nCode":"","truePath":"setting-update-pwd-sms"}},
    alias: smsqAwPUojNY2Meta?.alias || [],
    redirect: smsqAwPUojNY2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/sms.vue").then(m => m.default || m)
  },
  {
    name: smsqAwPUojNY2Meta?.name ?? "hk__zh-HK__setting-update-pwd-sms",
    path: smsqAwPUojNY2Meta?.path ?? "/hk/zh-HK/setting/update/pwd/sms",
    meta: {...(smsqAwPUojNY2Meta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"setting-update-pwd-sms"}},
    alias: smsqAwPUojNY2Meta?.alias || [],
    redirect: smsqAwPUojNY2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/sms.vue").then(m => m.default || m)
  },
  {
    name: smsqAwPUojNY2Meta?.name ?? "hk__zh-CN__setting-update-pwd-sms",
    path: smsqAwPUojNY2Meta?.path ?? "/hk/zh-CN/setting/update/pwd/sms",
    meta: {...(smsqAwPUojNY2Meta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"setting-update-pwd-sms"}},
    alias: smsqAwPUojNY2Meta?.alias || [],
    redirect: smsqAwPUojNY2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/sms.vue").then(m => m.default || m)
  },
  {
    name: smsqAwPUojNY2Meta?.name ?? "hk__en__setting-update-pwd-sms",
    path: smsqAwPUojNY2Meta?.path ?? "/hk/en/setting/update/pwd/sms",
    meta: {...(smsqAwPUojNY2Meta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"setting-update-pwd-sms"}},
    alias: smsqAwPUojNY2Meta?.alias || [],
    redirect: smsqAwPUojNY2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/sms.vue").then(m => m.default || m)
  },
  {
    name: smsqAwPUojNY2Meta?.name ?? "mo__setting-update-pwd-sms",
    path: smsqAwPUojNY2Meta?.path ?? "/mo/setting/update/pwd/sms",
    meta: {...(smsqAwPUojNY2Meta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"setting-update-pwd-sms"}},
    alias: smsqAwPUojNY2Meta?.alias || [],
    redirect: smsqAwPUojNY2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/sms.vue").then(m => m.default || m)
  },
  {
    name: smsqAwPUojNY2Meta?.name ?? "mo__zh-MO__setting-update-pwd-sms",
    path: smsqAwPUojNY2Meta?.path ?? "/mo/zh-MO/setting/update/pwd/sms",
    meta: {...(smsqAwPUojNY2Meta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"setting-update-pwd-sms"}},
    alias: smsqAwPUojNY2Meta?.alias || [],
    redirect: smsqAwPUojNY2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/sms.vue").then(m => m.default || m)
  },
  {
    name: smsqAwPUojNY2Meta?.name ?? "mo__zh-HK__setting-update-pwd-sms",
    path: smsqAwPUojNY2Meta?.path ?? "/mo/zh-HK/setting/update/pwd/sms",
    meta: {...(smsqAwPUojNY2Meta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"setting-update-pwd-sms"}},
    alias: smsqAwPUojNY2Meta?.alias || [],
    redirect: smsqAwPUojNY2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/sms.vue").then(m => m.default || m)
  },
  {
    name: smsqAwPUojNY2Meta?.name ?? "mo__zh-CN__setting-update-pwd-sms",
    path: smsqAwPUojNY2Meta?.path ?? "/mo/zh-CN/setting/update/pwd/sms",
    meta: {...(smsqAwPUojNY2Meta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"setting-update-pwd-sms"}},
    alias: smsqAwPUojNY2Meta?.alias || [],
    redirect: smsqAwPUojNY2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/sms.vue").then(m => m.default || m)
  },
  {
    name: smsqAwPUojNY2Meta?.name ?? "mo__en__setting-update-pwd-sms",
    path: smsqAwPUojNY2Meta?.path ?? "/mo/en/setting/update/pwd/sms",
    meta: {...(smsqAwPUojNY2Meta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"setting-update-pwd-sms"}},
    alias: smsqAwPUojNY2Meta?.alias || [],
    redirect: smsqAwPUojNY2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/sms.vue").then(m => m.default || m)
  },
  {
    name: smsqAwPUojNY2Meta?.name ?? "cn__setting-update-pwd-sms",
    path: smsqAwPUojNY2Meta?.path ?? "/cn/setting/update/pwd/sms",
    meta: {...(smsqAwPUojNY2Meta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"setting-update-pwd-sms"}},
    alias: smsqAwPUojNY2Meta?.alias || [],
    redirect: smsqAwPUojNY2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/sms.vue").then(m => m.default || m)
  },
  {
    name: smsqAwPUojNY2Meta?.name ?? "cn__zh-MO__setting-update-pwd-sms",
    path: smsqAwPUojNY2Meta?.path ?? "/cn/zh-MO/setting/update/pwd/sms",
    meta: {...(smsqAwPUojNY2Meta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"setting-update-pwd-sms"}},
    alias: smsqAwPUojNY2Meta?.alias || [],
    redirect: smsqAwPUojNY2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/sms.vue").then(m => m.default || m)
  },
  {
    name: smsqAwPUojNY2Meta?.name ?? "cn__zh-HK__setting-update-pwd-sms",
    path: smsqAwPUojNY2Meta?.path ?? "/cn/zh-HK/setting/update/pwd/sms",
    meta: {...(smsqAwPUojNY2Meta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"setting-update-pwd-sms"}},
    alias: smsqAwPUojNY2Meta?.alias || [],
    redirect: smsqAwPUojNY2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/sms.vue").then(m => m.default || m)
  },
  {
    name: smsqAwPUojNY2Meta?.name ?? "cn__zh-CN__setting-update-pwd-sms",
    path: smsqAwPUojNY2Meta?.path ?? "/cn/zh-CN/setting/update/pwd/sms",
    meta: {...(smsqAwPUojNY2Meta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"setting-update-pwd-sms"}},
    alias: smsqAwPUojNY2Meta?.alias || [],
    redirect: smsqAwPUojNY2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/sms.vue").then(m => m.default || m)
  },
  {
    name: smsqAwPUojNY2Meta?.name ?? "cn__en__setting-update-pwd-sms",
    path: smsqAwPUojNY2Meta?.path ?? "/cn/en/setting/update/pwd/sms",
    meta: {...(smsqAwPUojNY2Meta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"setting-update-pwd-sms"}},
    alias: smsqAwPUojNY2Meta?.alias || [],
    redirect: smsqAwPUojNY2Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/update/pwd/sms.vue").then(m => m.default || m)
  },
  {
    name: userInfoLn2qwELqeXMeta?.name ?? "hk__setting-userInfo",
    path: userInfoLn2qwELqeXMeta?.path ?? "/hk/setting/userInfo",
    meta: {...(userInfoLn2qwELqeXMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"setting-userInfo"}},
    alias: userInfoLn2qwELqeXMeta?.alias || [],
    redirect: userInfoLn2qwELqeXMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/userInfo.vue").then(m => m.default || m)
  },
  {
    name: userInfoLn2qwELqeXMeta?.name ?? "hk__zh-MO__setting-userInfo",
    path: userInfoLn2qwELqeXMeta?.path ?? "/hk/zh-MO/setting/userInfo",
    meta: {...(userInfoLn2qwELqeXMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"setting-userInfo"}},
    alias: userInfoLn2qwELqeXMeta?.alias || [],
    redirect: userInfoLn2qwELqeXMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/userInfo.vue").then(m => m.default || m)
  },
  {
    name: userInfoLn2qwELqeXMeta?.name ?? "setting-userInfo",
    path: userInfoLn2qwELqeXMeta?.path ?? "/setting/userInfo",
    meta: {...(userInfoLn2qwELqeXMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"setting-userInfo"}},
    alias: userInfoLn2qwELqeXMeta?.alias || [],
    redirect: userInfoLn2qwELqeXMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/userInfo.vue").then(m => m.default || m)
  },
  {
    name: userInfoLn2qwELqeXMeta?.name ?? "hk__zh-HK__setting-userInfo",
    path: userInfoLn2qwELqeXMeta?.path ?? "/hk/zh-HK/setting/userInfo",
    meta: {...(userInfoLn2qwELqeXMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"setting-userInfo"}},
    alias: userInfoLn2qwELqeXMeta?.alias || [],
    redirect: userInfoLn2qwELqeXMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/userInfo.vue").then(m => m.default || m)
  },
  {
    name: userInfoLn2qwELqeXMeta?.name ?? "hk__zh-CN__setting-userInfo",
    path: userInfoLn2qwELqeXMeta?.path ?? "/hk/zh-CN/setting/userInfo",
    meta: {...(userInfoLn2qwELqeXMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"setting-userInfo"}},
    alias: userInfoLn2qwELqeXMeta?.alias || [],
    redirect: userInfoLn2qwELqeXMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/userInfo.vue").then(m => m.default || m)
  },
  {
    name: userInfoLn2qwELqeXMeta?.name ?? "hk__en__setting-userInfo",
    path: userInfoLn2qwELqeXMeta?.path ?? "/hk/en/setting/userInfo",
    meta: {...(userInfoLn2qwELqeXMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"setting-userInfo"}},
    alias: userInfoLn2qwELqeXMeta?.alias || [],
    redirect: userInfoLn2qwELqeXMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/userInfo.vue").then(m => m.default || m)
  },
  {
    name: userInfoLn2qwELqeXMeta?.name ?? "mo__setting-userInfo",
    path: userInfoLn2qwELqeXMeta?.path ?? "/mo/setting/userInfo",
    meta: {...(userInfoLn2qwELqeXMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"setting-userInfo"}},
    alias: userInfoLn2qwELqeXMeta?.alias || [],
    redirect: userInfoLn2qwELqeXMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/userInfo.vue").then(m => m.default || m)
  },
  {
    name: userInfoLn2qwELqeXMeta?.name ?? "mo__zh-MO__setting-userInfo",
    path: userInfoLn2qwELqeXMeta?.path ?? "/mo/zh-MO/setting/userInfo",
    meta: {...(userInfoLn2qwELqeXMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"setting-userInfo"}},
    alias: userInfoLn2qwELqeXMeta?.alias || [],
    redirect: userInfoLn2qwELqeXMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/userInfo.vue").then(m => m.default || m)
  },
  {
    name: userInfoLn2qwELqeXMeta?.name ?? "mo__zh-HK__setting-userInfo",
    path: userInfoLn2qwELqeXMeta?.path ?? "/mo/zh-HK/setting/userInfo",
    meta: {...(userInfoLn2qwELqeXMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"setting-userInfo"}},
    alias: userInfoLn2qwELqeXMeta?.alias || [],
    redirect: userInfoLn2qwELqeXMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/userInfo.vue").then(m => m.default || m)
  },
  {
    name: userInfoLn2qwELqeXMeta?.name ?? "mo__zh-CN__setting-userInfo",
    path: userInfoLn2qwELqeXMeta?.path ?? "/mo/zh-CN/setting/userInfo",
    meta: {...(userInfoLn2qwELqeXMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"setting-userInfo"}},
    alias: userInfoLn2qwELqeXMeta?.alias || [],
    redirect: userInfoLn2qwELqeXMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/userInfo.vue").then(m => m.default || m)
  },
  {
    name: userInfoLn2qwELqeXMeta?.name ?? "mo__en__setting-userInfo",
    path: userInfoLn2qwELqeXMeta?.path ?? "/mo/en/setting/userInfo",
    meta: {...(userInfoLn2qwELqeXMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"setting-userInfo"}},
    alias: userInfoLn2qwELqeXMeta?.alias || [],
    redirect: userInfoLn2qwELqeXMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/userInfo.vue").then(m => m.default || m)
  },
  {
    name: userInfoLn2qwELqeXMeta?.name ?? "cn__setting-userInfo",
    path: userInfoLn2qwELqeXMeta?.path ?? "/cn/setting/userInfo",
    meta: {...(userInfoLn2qwELqeXMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"setting-userInfo"}},
    alias: userInfoLn2qwELqeXMeta?.alias || [],
    redirect: userInfoLn2qwELqeXMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/userInfo.vue").then(m => m.default || m)
  },
  {
    name: userInfoLn2qwELqeXMeta?.name ?? "cn__zh-MO__setting-userInfo",
    path: userInfoLn2qwELqeXMeta?.path ?? "/cn/zh-MO/setting/userInfo",
    meta: {...(userInfoLn2qwELqeXMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"setting-userInfo"}},
    alias: userInfoLn2qwELqeXMeta?.alias || [],
    redirect: userInfoLn2qwELqeXMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/userInfo.vue").then(m => m.default || m)
  },
  {
    name: userInfoLn2qwELqeXMeta?.name ?? "cn__zh-HK__setting-userInfo",
    path: userInfoLn2qwELqeXMeta?.path ?? "/cn/zh-HK/setting/userInfo",
    meta: {...(userInfoLn2qwELqeXMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"setting-userInfo"}},
    alias: userInfoLn2qwELqeXMeta?.alias || [],
    redirect: userInfoLn2qwELqeXMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/userInfo.vue").then(m => m.default || m)
  },
  {
    name: userInfoLn2qwELqeXMeta?.name ?? "cn__zh-CN__setting-userInfo",
    path: userInfoLn2qwELqeXMeta?.path ?? "/cn/zh-CN/setting/userInfo",
    meta: {...(userInfoLn2qwELqeXMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"setting-userInfo"}},
    alias: userInfoLn2qwELqeXMeta?.alias || [],
    redirect: userInfoLn2qwELqeXMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/userInfo.vue").then(m => m.default || m)
  },
  {
    name: userInfoLn2qwELqeXMeta?.name ?? "cn__en__setting-userInfo",
    path: userInfoLn2qwELqeXMeta?.path ?? "/cn/en/setting/userInfo",
    meta: {...(userInfoLn2qwELqeXMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"setting-userInfo"}},
    alias: userInfoLn2qwELqeXMeta?.alias || [],
    redirect: userInfoLn2qwELqeXMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/setting/userInfo.vue").then(m => m.default || m)
  },
  {
    name: _91orderId_93fZpLyZyIusMeta?.name ?? "hk__share-orderId",
    path: _91orderId_93fZpLyZyIusMeta?.path ?? "/hk/share/:orderId()",
    meta: {...(_91orderId_93fZpLyZyIusMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"share-orderId"}},
    alias: _91orderId_93fZpLyZyIusMeta?.alias || [],
    redirect: _91orderId_93fZpLyZyIusMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/share/[orderId].vue").then(m => m.default || m)
  },
  {
    name: _91orderId_93fZpLyZyIusMeta?.name ?? "hk__zh-MO__share-orderId",
    path: _91orderId_93fZpLyZyIusMeta?.path ?? "/hk/zh-MO/share/:orderId()",
    meta: {...(_91orderId_93fZpLyZyIusMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"share-orderId"}},
    alias: _91orderId_93fZpLyZyIusMeta?.alias || [],
    redirect: _91orderId_93fZpLyZyIusMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/share/[orderId].vue").then(m => m.default || m)
  },
  {
    name: _91orderId_93fZpLyZyIusMeta?.name ?? "share-orderId",
    path: _91orderId_93fZpLyZyIusMeta?.path ?? "/share/:orderId()",
    meta: {...(_91orderId_93fZpLyZyIusMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"share-orderId"}},
    alias: _91orderId_93fZpLyZyIusMeta?.alias || [],
    redirect: _91orderId_93fZpLyZyIusMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/share/[orderId].vue").then(m => m.default || m)
  },
  {
    name: _91orderId_93fZpLyZyIusMeta?.name ?? "hk__zh-HK__share-orderId",
    path: _91orderId_93fZpLyZyIusMeta?.path ?? "/hk/zh-HK/share/:orderId()",
    meta: {...(_91orderId_93fZpLyZyIusMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"share-orderId"}},
    alias: _91orderId_93fZpLyZyIusMeta?.alias || [],
    redirect: _91orderId_93fZpLyZyIusMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/share/[orderId].vue").then(m => m.default || m)
  },
  {
    name: _91orderId_93fZpLyZyIusMeta?.name ?? "hk__zh-CN__share-orderId",
    path: _91orderId_93fZpLyZyIusMeta?.path ?? "/hk/zh-CN/share/:orderId()",
    meta: {...(_91orderId_93fZpLyZyIusMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"share-orderId"}},
    alias: _91orderId_93fZpLyZyIusMeta?.alias || [],
    redirect: _91orderId_93fZpLyZyIusMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/share/[orderId].vue").then(m => m.default || m)
  },
  {
    name: _91orderId_93fZpLyZyIusMeta?.name ?? "hk__en__share-orderId",
    path: _91orderId_93fZpLyZyIusMeta?.path ?? "/hk/en/share/:orderId()",
    meta: {...(_91orderId_93fZpLyZyIusMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"share-orderId"}},
    alias: _91orderId_93fZpLyZyIusMeta?.alias || [],
    redirect: _91orderId_93fZpLyZyIusMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/share/[orderId].vue").then(m => m.default || m)
  },
  {
    name: _91orderId_93fZpLyZyIusMeta?.name ?? "mo__share-orderId",
    path: _91orderId_93fZpLyZyIusMeta?.path ?? "/mo/share/:orderId()",
    meta: {...(_91orderId_93fZpLyZyIusMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"share-orderId"}},
    alias: _91orderId_93fZpLyZyIusMeta?.alias || [],
    redirect: _91orderId_93fZpLyZyIusMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/share/[orderId].vue").then(m => m.default || m)
  },
  {
    name: _91orderId_93fZpLyZyIusMeta?.name ?? "mo__zh-MO__share-orderId",
    path: _91orderId_93fZpLyZyIusMeta?.path ?? "/mo/zh-MO/share/:orderId()",
    meta: {...(_91orderId_93fZpLyZyIusMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"share-orderId"}},
    alias: _91orderId_93fZpLyZyIusMeta?.alias || [],
    redirect: _91orderId_93fZpLyZyIusMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/share/[orderId].vue").then(m => m.default || m)
  },
  {
    name: _91orderId_93fZpLyZyIusMeta?.name ?? "mo__zh-HK__share-orderId",
    path: _91orderId_93fZpLyZyIusMeta?.path ?? "/mo/zh-HK/share/:orderId()",
    meta: {...(_91orderId_93fZpLyZyIusMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"share-orderId"}},
    alias: _91orderId_93fZpLyZyIusMeta?.alias || [],
    redirect: _91orderId_93fZpLyZyIusMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/share/[orderId].vue").then(m => m.default || m)
  },
  {
    name: _91orderId_93fZpLyZyIusMeta?.name ?? "mo__zh-CN__share-orderId",
    path: _91orderId_93fZpLyZyIusMeta?.path ?? "/mo/zh-CN/share/:orderId()",
    meta: {...(_91orderId_93fZpLyZyIusMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"share-orderId"}},
    alias: _91orderId_93fZpLyZyIusMeta?.alias || [],
    redirect: _91orderId_93fZpLyZyIusMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/share/[orderId].vue").then(m => m.default || m)
  },
  {
    name: _91orderId_93fZpLyZyIusMeta?.name ?? "mo__en__share-orderId",
    path: _91orderId_93fZpLyZyIusMeta?.path ?? "/mo/en/share/:orderId()",
    meta: {...(_91orderId_93fZpLyZyIusMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"share-orderId"}},
    alias: _91orderId_93fZpLyZyIusMeta?.alias || [],
    redirect: _91orderId_93fZpLyZyIusMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/share/[orderId].vue").then(m => m.default || m)
  },
  {
    name: _91orderId_93fZpLyZyIusMeta?.name ?? "cn__share-orderId",
    path: _91orderId_93fZpLyZyIusMeta?.path ?? "/cn/share/:orderId()",
    meta: {...(_91orderId_93fZpLyZyIusMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"share-orderId"}},
    alias: _91orderId_93fZpLyZyIusMeta?.alias || [],
    redirect: _91orderId_93fZpLyZyIusMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/share/[orderId].vue").then(m => m.default || m)
  },
  {
    name: _91orderId_93fZpLyZyIusMeta?.name ?? "cn__zh-MO__share-orderId",
    path: _91orderId_93fZpLyZyIusMeta?.path ?? "/cn/zh-MO/share/:orderId()",
    meta: {...(_91orderId_93fZpLyZyIusMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"share-orderId"}},
    alias: _91orderId_93fZpLyZyIusMeta?.alias || [],
    redirect: _91orderId_93fZpLyZyIusMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/share/[orderId].vue").then(m => m.default || m)
  },
  {
    name: _91orderId_93fZpLyZyIusMeta?.name ?? "cn__zh-HK__share-orderId",
    path: _91orderId_93fZpLyZyIusMeta?.path ?? "/cn/zh-HK/share/:orderId()",
    meta: {...(_91orderId_93fZpLyZyIusMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"share-orderId"}},
    alias: _91orderId_93fZpLyZyIusMeta?.alias || [],
    redirect: _91orderId_93fZpLyZyIusMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/share/[orderId].vue").then(m => m.default || m)
  },
  {
    name: _91orderId_93fZpLyZyIusMeta?.name ?? "cn__zh-CN__share-orderId",
    path: _91orderId_93fZpLyZyIusMeta?.path ?? "/cn/zh-CN/share/:orderId()",
    meta: {...(_91orderId_93fZpLyZyIusMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"share-orderId"}},
    alias: _91orderId_93fZpLyZyIusMeta?.alias || [],
    redirect: _91orderId_93fZpLyZyIusMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/share/[orderId].vue").then(m => m.default || m)
  },
  {
    name: _91orderId_93fZpLyZyIusMeta?.name ?? "cn__en__share-orderId",
    path: _91orderId_93fZpLyZyIusMeta?.path ?? "/cn/en/share/:orderId()",
    meta: {...(_91orderId_93fZpLyZyIusMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"share-orderId"}},
    alias: _91orderId_93fZpLyZyIusMeta?.alias || [],
    redirect: _91orderId_93fZpLyZyIusMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/share/[orderId].vue").then(m => m.default || m)
  },
  {
    name: listeBiD1ZDqrvMeta?.name ?? "hk__share-list",
    path: listeBiD1ZDqrvMeta?.path ?? "/hk/share/list",
    meta: {...(listeBiD1ZDqrvMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"share-list"}},
    alias: listeBiD1ZDqrvMeta?.alias || [],
    redirect: listeBiD1ZDqrvMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/share/list.vue").then(m => m.default || m)
  },
  {
    name: listeBiD1ZDqrvMeta?.name ?? "hk__zh-MO__share-list",
    path: listeBiD1ZDqrvMeta?.path ?? "/hk/zh-MO/share/list",
    meta: {...(listeBiD1ZDqrvMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"share-list"}},
    alias: listeBiD1ZDqrvMeta?.alias || [],
    redirect: listeBiD1ZDqrvMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/share/list.vue").then(m => m.default || m)
  },
  {
    name: listeBiD1ZDqrvMeta?.name ?? "share-list",
    path: listeBiD1ZDqrvMeta?.path ?? "/share/list",
    meta: {...(listeBiD1ZDqrvMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"share-list"}},
    alias: listeBiD1ZDqrvMeta?.alias || [],
    redirect: listeBiD1ZDqrvMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/share/list.vue").then(m => m.default || m)
  },
  {
    name: listeBiD1ZDqrvMeta?.name ?? "hk__zh-HK__share-list",
    path: listeBiD1ZDqrvMeta?.path ?? "/hk/zh-HK/share/list",
    meta: {...(listeBiD1ZDqrvMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"share-list"}},
    alias: listeBiD1ZDqrvMeta?.alias || [],
    redirect: listeBiD1ZDqrvMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/share/list.vue").then(m => m.default || m)
  },
  {
    name: listeBiD1ZDqrvMeta?.name ?? "hk__zh-CN__share-list",
    path: listeBiD1ZDqrvMeta?.path ?? "/hk/zh-CN/share/list",
    meta: {...(listeBiD1ZDqrvMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"share-list"}},
    alias: listeBiD1ZDqrvMeta?.alias || [],
    redirect: listeBiD1ZDqrvMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/share/list.vue").then(m => m.default || m)
  },
  {
    name: listeBiD1ZDqrvMeta?.name ?? "hk__en__share-list",
    path: listeBiD1ZDqrvMeta?.path ?? "/hk/en/share/list",
    meta: {...(listeBiD1ZDqrvMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"share-list"}},
    alias: listeBiD1ZDqrvMeta?.alias || [],
    redirect: listeBiD1ZDqrvMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/share/list.vue").then(m => m.default || m)
  },
  {
    name: listeBiD1ZDqrvMeta?.name ?? "mo__share-list",
    path: listeBiD1ZDqrvMeta?.path ?? "/mo/share/list",
    meta: {...(listeBiD1ZDqrvMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"share-list"}},
    alias: listeBiD1ZDqrvMeta?.alias || [],
    redirect: listeBiD1ZDqrvMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/share/list.vue").then(m => m.default || m)
  },
  {
    name: listeBiD1ZDqrvMeta?.name ?? "mo__zh-MO__share-list",
    path: listeBiD1ZDqrvMeta?.path ?? "/mo/zh-MO/share/list",
    meta: {...(listeBiD1ZDqrvMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"share-list"}},
    alias: listeBiD1ZDqrvMeta?.alias || [],
    redirect: listeBiD1ZDqrvMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/share/list.vue").then(m => m.default || m)
  },
  {
    name: listeBiD1ZDqrvMeta?.name ?? "mo__zh-HK__share-list",
    path: listeBiD1ZDqrvMeta?.path ?? "/mo/zh-HK/share/list",
    meta: {...(listeBiD1ZDqrvMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"share-list"}},
    alias: listeBiD1ZDqrvMeta?.alias || [],
    redirect: listeBiD1ZDqrvMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/share/list.vue").then(m => m.default || m)
  },
  {
    name: listeBiD1ZDqrvMeta?.name ?? "mo__zh-CN__share-list",
    path: listeBiD1ZDqrvMeta?.path ?? "/mo/zh-CN/share/list",
    meta: {...(listeBiD1ZDqrvMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"share-list"}},
    alias: listeBiD1ZDqrvMeta?.alias || [],
    redirect: listeBiD1ZDqrvMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/share/list.vue").then(m => m.default || m)
  },
  {
    name: listeBiD1ZDqrvMeta?.name ?? "mo__en__share-list",
    path: listeBiD1ZDqrvMeta?.path ?? "/mo/en/share/list",
    meta: {...(listeBiD1ZDqrvMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"share-list"}},
    alias: listeBiD1ZDqrvMeta?.alias || [],
    redirect: listeBiD1ZDqrvMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/share/list.vue").then(m => m.default || m)
  },
  {
    name: listeBiD1ZDqrvMeta?.name ?? "cn__share-list",
    path: listeBiD1ZDqrvMeta?.path ?? "/cn/share/list",
    meta: {...(listeBiD1ZDqrvMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"share-list"}},
    alias: listeBiD1ZDqrvMeta?.alias || [],
    redirect: listeBiD1ZDqrvMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/share/list.vue").then(m => m.default || m)
  },
  {
    name: listeBiD1ZDqrvMeta?.name ?? "cn__zh-MO__share-list",
    path: listeBiD1ZDqrvMeta?.path ?? "/cn/zh-MO/share/list",
    meta: {...(listeBiD1ZDqrvMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"share-list"}},
    alias: listeBiD1ZDqrvMeta?.alias || [],
    redirect: listeBiD1ZDqrvMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/share/list.vue").then(m => m.default || m)
  },
  {
    name: listeBiD1ZDqrvMeta?.name ?? "cn__zh-HK__share-list",
    path: listeBiD1ZDqrvMeta?.path ?? "/cn/zh-HK/share/list",
    meta: {...(listeBiD1ZDqrvMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"share-list"}},
    alias: listeBiD1ZDqrvMeta?.alias || [],
    redirect: listeBiD1ZDqrvMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/share/list.vue").then(m => m.default || m)
  },
  {
    name: listeBiD1ZDqrvMeta?.name ?? "cn__zh-CN__share-list",
    path: listeBiD1ZDqrvMeta?.path ?? "/cn/zh-CN/share/list",
    meta: {...(listeBiD1ZDqrvMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"share-list"}},
    alias: listeBiD1ZDqrvMeta?.alias || [],
    redirect: listeBiD1ZDqrvMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/share/list.vue").then(m => m.default || m)
  },
  {
    name: listeBiD1ZDqrvMeta?.name ?? "cn__en__share-list",
    path: listeBiD1ZDqrvMeta?.path ?? "/cn/en/share/list",
    meta: {...(listeBiD1ZDqrvMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"share-list"}},
    alias: listeBiD1ZDqrvMeta?.alias || [],
    redirect: listeBiD1ZDqrvMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/share/list.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Vb3ROE3gSDMeta?.name ?? "hk__storeOrder-detail-id",
    path: _91id_93Vb3ROE3gSDMeta?.path ?? "/hk/storeOrder/detail/:id()",
    meta: {...(_91id_93Vb3ROE3gSDMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"storeOrder-detail-id"}},
    alias: _91id_93Vb3ROE3gSDMeta?.alias || [],
    redirect: _91id_93Vb3ROE3gSDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Vb3ROE3gSDMeta?.name ?? "hk__zh-MO__storeOrder-detail-id",
    path: _91id_93Vb3ROE3gSDMeta?.path ?? "/hk/zh-MO/storeOrder/detail/:id()",
    meta: {...(_91id_93Vb3ROE3gSDMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"storeOrder-detail-id"}},
    alias: _91id_93Vb3ROE3gSDMeta?.alias || [],
    redirect: _91id_93Vb3ROE3gSDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Vb3ROE3gSDMeta?.name ?? "storeOrder-detail-id",
    path: _91id_93Vb3ROE3gSDMeta?.path ?? "/storeOrder/detail/:id()",
    meta: {...(_91id_93Vb3ROE3gSDMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"storeOrder-detail-id"}},
    alias: _91id_93Vb3ROE3gSDMeta?.alias || [],
    redirect: _91id_93Vb3ROE3gSDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Vb3ROE3gSDMeta?.name ?? "hk__zh-HK__storeOrder-detail-id",
    path: _91id_93Vb3ROE3gSDMeta?.path ?? "/hk/zh-HK/storeOrder/detail/:id()",
    meta: {...(_91id_93Vb3ROE3gSDMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"storeOrder-detail-id"}},
    alias: _91id_93Vb3ROE3gSDMeta?.alias || [],
    redirect: _91id_93Vb3ROE3gSDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Vb3ROE3gSDMeta?.name ?? "hk__zh-CN__storeOrder-detail-id",
    path: _91id_93Vb3ROE3gSDMeta?.path ?? "/hk/zh-CN/storeOrder/detail/:id()",
    meta: {...(_91id_93Vb3ROE3gSDMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"storeOrder-detail-id"}},
    alias: _91id_93Vb3ROE3gSDMeta?.alias || [],
    redirect: _91id_93Vb3ROE3gSDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Vb3ROE3gSDMeta?.name ?? "hk__en__storeOrder-detail-id",
    path: _91id_93Vb3ROE3gSDMeta?.path ?? "/hk/en/storeOrder/detail/:id()",
    meta: {...(_91id_93Vb3ROE3gSDMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"storeOrder-detail-id"}},
    alias: _91id_93Vb3ROE3gSDMeta?.alias || [],
    redirect: _91id_93Vb3ROE3gSDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Vb3ROE3gSDMeta?.name ?? "mo__storeOrder-detail-id",
    path: _91id_93Vb3ROE3gSDMeta?.path ?? "/mo/storeOrder/detail/:id()",
    meta: {...(_91id_93Vb3ROE3gSDMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"storeOrder-detail-id"}},
    alias: _91id_93Vb3ROE3gSDMeta?.alias || [],
    redirect: _91id_93Vb3ROE3gSDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Vb3ROE3gSDMeta?.name ?? "mo__zh-MO__storeOrder-detail-id",
    path: _91id_93Vb3ROE3gSDMeta?.path ?? "/mo/zh-MO/storeOrder/detail/:id()",
    meta: {...(_91id_93Vb3ROE3gSDMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"storeOrder-detail-id"}},
    alias: _91id_93Vb3ROE3gSDMeta?.alias || [],
    redirect: _91id_93Vb3ROE3gSDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Vb3ROE3gSDMeta?.name ?? "mo__zh-HK__storeOrder-detail-id",
    path: _91id_93Vb3ROE3gSDMeta?.path ?? "/mo/zh-HK/storeOrder/detail/:id()",
    meta: {...(_91id_93Vb3ROE3gSDMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"storeOrder-detail-id"}},
    alias: _91id_93Vb3ROE3gSDMeta?.alias || [],
    redirect: _91id_93Vb3ROE3gSDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Vb3ROE3gSDMeta?.name ?? "mo__zh-CN__storeOrder-detail-id",
    path: _91id_93Vb3ROE3gSDMeta?.path ?? "/mo/zh-CN/storeOrder/detail/:id()",
    meta: {...(_91id_93Vb3ROE3gSDMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"storeOrder-detail-id"}},
    alias: _91id_93Vb3ROE3gSDMeta?.alias || [],
    redirect: _91id_93Vb3ROE3gSDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Vb3ROE3gSDMeta?.name ?? "mo__en__storeOrder-detail-id",
    path: _91id_93Vb3ROE3gSDMeta?.path ?? "/mo/en/storeOrder/detail/:id()",
    meta: {...(_91id_93Vb3ROE3gSDMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"storeOrder-detail-id"}},
    alias: _91id_93Vb3ROE3gSDMeta?.alias || [],
    redirect: _91id_93Vb3ROE3gSDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Vb3ROE3gSDMeta?.name ?? "cn__storeOrder-detail-id",
    path: _91id_93Vb3ROE3gSDMeta?.path ?? "/cn/storeOrder/detail/:id()",
    meta: {...(_91id_93Vb3ROE3gSDMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"storeOrder-detail-id"}},
    alias: _91id_93Vb3ROE3gSDMeta?.alias || [],
    redirect: _91id_93Vb3ROE3gSDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Vb3ROE3gSDMeta?.name ?? "cn__zh-MO__storeOrder-detail-id",
    path: _91id_93Vb3ROE3gSDMeta?.path ?? "/cn/zh-MO/storeOrder/detail/:id()",
    meta: {...(_91id_93Vb3ROE3gSDMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"storeOrder-detail-id"}},
    alias: _91id_93Vb3ROE3gSDMeta?.alias || [],
    redirect: _91id_93Vb3ROE3gSDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Vb3ROE3gSDMeta?.name ?? "cn__zh-HK__storeOrder-detail-id",
    path: _91id_93Vb3ROE3gSDMeta?.path ?? "/cn/zh-HK/storeOrder/detail/:id()",
    meta: {...(_91id_93Vb3ROE3gSDMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"storeOrder-detail-id"}},
    alias: _91id_93Vb3ROE3gSDMeta?.alias || [],
    redirect: _91id_93Vb3ROE3gSDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Vb3ROE3gSDMeta?.name ?? "cn__zh-CN__storeOrder-detail-id",
    path: _91id_93Vb3ROE3gSDMeta?.path ?? "/cn/zh-CN/storeOrder/detail/:id()",
    meta: {...(_91id_93Vb3ROE3gSDMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"storeOrder-detail-id"}},
    alias: _91id_93Vb3ROE3gSDMeta?.alias || [],
    redirect: _91id_93Vb3ROE3gSDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Vb3ROE3gSDMeta?.name ?? "cn__en__storeOrder-detail-id",
    path: _91id_93Vb3ROE3gSDMeta?.path ?? "/cn/en/storeOrder/detail/:id()",
    meta: {...(_91id_93Vb3ROE3gSDMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"storeOrder-detail-id"}},
    alias: _91id_93Vb3ROE3gSDMeta?.alias || [],
    redirect: _91id_93Vb3ROE3gSDMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: listIpdMPcIRI8Meta?.name ?? "hk__storeOrder-list",
    path: listIpdMPcIRI8Meta?.path ?? "/hk/storeOrder/list",
    meta: {...(listIpdMPcIRI8Meta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"storeOrder-list"}},
    alias: listIpdMPcIRI8Meta?.alias || [],
    redirect: listIpdMPcIRI8Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/list.vue").then(m => m.default || m)
  },
  {
    name: listIpdMPcIRI8Meta?.name ?? "hk__zh-MO__storeOrder-list",
    path: listIpdMPcIRI8Meta?.path ?? "/hk/zh-MO/storeOrder/list",
    meta: {...(listIpdMPcIRI8Meta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"storeOrder-list"}},
    alias: listIpdMPcIRI8Meta?.alias || [],
    redirect: listIpdMPcIRI8Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/list.vue").then(m => m.default || m)
  },
  {
    name: listIpdMPcIRI8Meta?.name ?? "storeOrder-list",
    path: listIpdMPcIRI8Meta?.path ?? "/storeOrder/list",
    meta: {...(listIpdMPcIRI8Meta || {}), ...{"siteCode":"","i18nCode":"","truePath":"storeOrder-list"}},
    alias: listIpdMPcIRI8Meta?.alias || [],
    redirect: listIpdMPcIRI8Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/list.vue").then(m => m.default || m)
  },
  {
    name: listIpdMPcIRI8Meta?.name ?? "hk__zh-HK__storeOrder-list",
    path: listIpdMPcIRI8Meta?.path ?? "/hk/zh-HK/storeOrder/list",
    meta: {...(listIpdMPcIRI8Meta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"storeOrder-list"}},
    alias: listIpdMPcIRI8Meta?.alias || [],
    redirect: listIpdMPcIRI8Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/list.vue").then(m => m.default || m)
  },
  {
    name: listIpdMPcIRI8Meta?.name ?? "hk__zh-CN__storeOrder-list",
    path: listIpdMPcIRI8Meta?.path ?? "/hk/zh-CN/storeOrder/list",
    meta: {...(listIpdMPcIRI8Meta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"storeOrder-list"}},
    alias: listIpdMPcIRI8Meta?.alias || [],
    redirect: listIpdMPcIRI8Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/list.vue").then(m => m.default || m)
  },
  {
    name: listIpdMPcIRI8Meta?.name ?? "hk__en__storeOrder-list",
    path: listIpdMPcIRI8Meta?.path ?? "/hk/en/storeOrder/list",
    meta: {...(listIpdMPcIRI8Meta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"storeOrder-list"}},
    alias: listIpdMPcIRI8Meta?.alias || [],
    redirect: listIpdMPcIRI8Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/list.vue").then(m => m.default || m)
  },
  {
    name: listIpdMPcIRI8Meta?.name ?? "mo__storeOrder-list",
    path: listIpdMPcIRI8Meta?.path ?? "/mo/storeOrder/list",
    meta: {...(listIpdMPcIRI8Meta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"storeOrder-list"}},
    alias: listIpdMPcIRI8Meta?.alias || [],
    redirect: listIpdMPcIRI8Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/list.vue").then(m => m.default || m)
  },
  {
    name: listIpdMPcIRI8Meta?.name ?? "mo__zh-MO__storeOrder-list",
    path: listIpdMPcIRI8Meta?.path ?? "/mo/zh-MO/storeOrder/list",
    meta: {...(listIpdMPcIRI8Meta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"storeOrder-list"}},
    alias: listIpdMPcIRI8Meta?.alias || [],
    redirect: listIpdMPcIRI8Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/list.vue").then(m => m.default || m)
  },
  {
    name: listIpdMPcIRI8Meta?.name ?? "mo__zh-HK__storeOrder-list",
    path: listIpdMPcIRI8Meta?.path ?? "/mo/zh-HK/storeOrder/list",
    meta: {...(listIpdMPcIRI8Meta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"storeOrder-list"}},
    alias: listIpdMPcIRI8Meta?.alias || [],
    redirect: listIpdMPcIRI8Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/list.vue").then(m => m.default || m)
  },
  {
    name: listIpdMPcIRI8Meta?.name ?? "mo__zh-CN__storeOrder-list",
    path: listIpdMPcIRI8Meta?.path ?? "/mo/zh-CN/storeOrder/list",
    meta: {...(listIpdMPcIRI8Meta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"storeOrder-list"}},
    alias: listIpdMPcIRI8Meta?.alias || [],
    redirect: listIpdMPcIRI8Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/list.vue").then(m => m.default || m)
  },
  {
    name: listIpdMPcIRI8Meta?.name ?? "mo__en__storeOrder-list",
    path: listIpdMPcIRI8Meta?.path ?? "/mo/en/storeOrder/list",
    meta: {...(listIpdMPcIRI8Meta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"storeOrder-list"}},
    alias: listIpdMPcIRI8Meta?.alias || [],
    redirect: listIpdMPcIRI8Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/list.vue").then(m => m.default || m)
  },
  {
    name: listIpdMPcIRI8Meta?.name ?? "cn__storeOrder-list",
    path: listIpdMPcIRI8Meta?.path ?? "/cn/storeOrder/list",
    meta: {...(listIpdMPcIRI8Meta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"storeOrder-list"}},
    alias: listIpdMPcIRI8Meta?.alias || [],
    redirect: listIpdMPcIRI8Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/list.vue").then(m => m.default || m)
  },
  {
    name: listIpdMPcIRI8Meta?.name ?? "cn__zh-MO__storeOrder-list",
    path: listIpdMPcIRI8Meta?.path ?? "/cn/zh-MO/storeOrder/list",
    meta: {...(listIpdMPcIRI8Meta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"storeOrder-list"}},
    alias: listIpdMPcIRI8Meta?.alias || [],
    redirect: listIpdMPcIRI8Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/list.vue").then(m => m.default || m)
  },
  {
    name: listIpdMPcIRI8Meta?.name ?? "cn__zh-HK__storeOrder-list",
    path: listIpdMPcIRI8Meta?.path ?? "/cn/zh-HK/storeOrder/list",
    meta: {...(listIpdMPcIRI8Meta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"storeOrder-list"}},
    alias: listIpdMPcIRI8Meta?.alias || [],
    redirect: listIpdMPcIRI8Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/list.vue").then(m => m.default || m)
  },
  {
    name: listIpdMPcIRI8Meta?.name ?? "cn__zh-CN__storeOrder-list",
    path: listIpdMPcIRI8Meta?.path ?? "/cn/zh-CN/storeOrder/list",
    meta: {...(listIpdMPcIRI8Meta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"storeOrder-list"}},
    alias: listIpdMPcIRI8Meta?.alias || [],
    redirect: listIpdMPcIRI8Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/list.vue").then(m => m.default || m)
  },
  {
    name: listIpdMPcIRI8Meta?.name ?? "cn__en__storeOrder-list",
    path: listIpdMPcIRI8Meta?.path ?? "/cn/en/storeOrder/list",
    meta: {...(listIpdMPcIRI8Meta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"storeOrder-list"}},
    alias: listIpdMPcIRI8Meta?.alias || [],
    redirect: listIpdMPcIRI8Meta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/list.vue").then(m => m.default || m)
  },
  {
    name: indexLtdldiBZWSMeta?.name ?? "hk__storeOrder-search",
    path: indexLtdldiBZWSMeta?.path ?? "/hk/storeOrder/search",
    meta: {...(indexLtdldiBZWSMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"storeOrder-search"}},
    alias: indexLtdldiBZWSMeta?.alias || [],
    redirect: indexLtdldiBZWSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexLtdldiBZWSMeta?.name ?? "hk__zh-MO__storeOrder-search",
    path: indexLtdldiBZWSMeta?.path ?? "/hk/zh-MO/storeOrder/search",
    meta: {...(indexLtdldiBZWSMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"storeOrder-search"}},
    alias: indexLtdldiBZWSMeta?.alias || [],
    redirect: indexLtdldiBZWSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexLtdldiBZWSMeta?.name ?? "storeOrder-search",
    path: indexLtdldiBZWSMeta?.path ?? "/storeOrder/search",
    meta: {...(indexLtdldiBZWSMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"storeOrder-search"}},
    alias: indexLtdldiBZWSMeta?.alias || [],
    redirect: indexLtdldiBZWSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexLtdldiBZWSMeta?.name ?? "hk__zh-HK__storeOrder-search",
    path: indexLtdldiBZWSMeta?.path ?? "/hk/zh-HK/storeOrder/search",
    meta: {...(indexLtdldiBZWSMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"storeOrder-search"}},
    alias: indexLtdldiBZWSMeta?.alias || [],
    redirect: indexLtdldiBZWSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexLtdldiBZWSMeta?.name ?? "hk__zh-CN__storeOrder-search",
    path: indexLtdldiBZWSMeta?.path ?? "/hk/zh-CN/storeOrder/search",
    meta: {...(indexLtdldiBZWSMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"storeOrder-search"}},
    alias: indexLtdldiBZWSMeta?.alias || [],
    redirect: indexLtdldiBZWSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexLtdldiBZWSMeta?.name ?? "hk__en__storeOrder-search",
    path: indexLtdldiBZWSMeta?.path ?? "/hk/en/storeOrder/search",
    meta: {...(indexLtdldiBZWSMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"storeOrder-search"}},
    alias: indexLtdldiBZWSMeta?.alias || [],
    redirect: indexLtdldiBZWSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexLtdldiBZWSMeta?.name ?? "mo__storeOrder-search",
    path: indexLtdldiBZWSMeta?.path ?? "/mo/storeOrder/search",
    meta: {...(indexLtdldiBZWSMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"storeOrder-search"}},
    alias: indexLtdldiBZWSMeta?.alias || [],
    redirect: indexLtdldiBZWSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexLtdldiBZWSMeta?.name ?? "mo__zh-MO__storeOrder-search",
    path: indexLtdldiBZWSMeta?.path ?? "/mo/zh-MO/storeOrder/search",
    meta: {...(indexLtdldiBZWSMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"storeOrder-search"}},
    alias: indexLtdldiBZWSMeta?.alias || [],
    redirect: indexLtdldiBZWSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexLtdldiBZWSMeta?.name ?? "mo__zh-HK__storeOrder-search",
    path: indexLtdldiBZWSMeta?.path ?? "/mo/zh-HK/storeOrder/search",
    meta: {...(indexLtdldiBZWSMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"storeOrder-search"}},
    alias: indexLtdldiBZWSMeta?.alias || [],
    redirect: indexLtdldiBZWSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexLtdldiBZWSMeta?.name ?? "mo__zh-CN__storeOrder-search",
    path: indexLtdldiBZWSMeta?.path ?? "/mo/zh-CN/storeOrder/search",
    meta: {...(indexLtdldiBZWSMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"storeOrder-search"}},
    alias: indexLtdldiBZWSMeta?.alias || [],
    redirect: indexLtdldiBZWSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexLtdldiBZWSMeta?.name ?? "mo__en__storeOrder-search",
    path: indexLtdldiBZWSMeta?.path ?? "/mo/en/storeOrder/search",
    meta: {...(indexLtdldiBZWSMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"storeOrder-search"}},
    alias: indexLtdldiBZWSMeta?.alias || [],
    redirect: indexLtdldiBZWSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexLtdldiBZWSMeta?.name ?? "cn__storeOrder-search",
    path: indexLtdldiBZWSMeta?.path ?? "/cn/storeOrder/search",
    meta: {...(indexLtdldiBZWSMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"storeOrder-search"}},
    alias: indexLtdldiBZWSMeta?.alias || [],
    redirect: indexLtdldiBZWSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexLtdldiBZWSMeta?.name ?? "cn__zh-MO__storeOrder-search",
    path: indexLtdldiBZWSMeta?.path ?? "/cn/zh-MO/storeOrder/search",
    meta: {...(indexLtdldiBZWSMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"storeOrder-search"}},
    alias: indexLtdldiBZWSMeta?.alias || [],
    redirect: indexLtdldiBZWSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexLtdldiBZWSMeta?.name ?? "cn__zh-HK__storeOrder-search",
    path: indexLtdldiBZWSMeta?.path ?? "/cn/zh-HK/storeOrder/search",
    meta: {...(indexLtdldiBZWSMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"storeOrder-search"}},
    alias: indexLtdldiBZWSMeta?.alias || [],
    redirect: indexLtdldiBZWSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexLtdldiBZWSMeta?.name ?? "cn__zh-CN__storeOrder-search",
    path: indexLtdldiBZWSMeta?.path ?? "/cn/zh-CN/storeOrder/search",
    meta: {...(indexLtdldiBZWSMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"storeOrder-search"}},
    alias: indexLtdldiBZWSMeta?.alias || [],
    redirect: indexLtdldiBZWSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexLtdldiBZWSMeta?.name ?? "cn__en__storeOrder-search",
    path: indexLtdldiBZWSMeta?.path ?? "/cn/en/storeOrder/search",
    meta: {...(indexLtdldiBZWSMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"storeOrder-search"}},
    alias: indexLtdldiBZWSMeta?.alias || [],
    redirect: indexLtdldiBZWSMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/storeOrder/search/index.vue").then(m => m.default || m)
  },
  {
    name: detail_45_91id_93KQcmIi0ajWMeta?.name ?? "hk__track-detail-id",
    path: detail_45_91id_93KQcmIi0ajWMeta?.path ?? "/hk/track/detail-:id()",
    meta: {...(detail_45_91id_93KQcmIi0ajWMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"track-detail-id"}},
    alias: detail_45_91id_93KQcmIi0ajWMeta?.alias || [],
    redirect: detail_45_91id_93KQcmIi0ajWMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/track/detail-[id].vue").then(m => m.default || m)
  },
  {
    name: detail_45_91id_93KQcmIi0ajWMeta?.name ?? "hk__zh-MO__track-detail-id",
    path: detail_45_91id_93KQcmIi0ajWMeta?.path ?? "/hk/zh-MO/track/detail-:id()",
    meta: {...(detail_45_91id_93KQcmIi0ajWMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"track-detail-id"}},
    alias: detail_45_91id_93KQcmIi0ajWMeta?.alias || [],
    redirect: detail_45_91id_93KQcmIi0ajWMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/track/detail-[id].vue").then(m => m.default || m)
  },
  {
    name: detail_45_91id_93KQcmIi0ajWMeta?.name ?? "track-detail-id",
    path: detail_45_91id_93KQcmIi0ajWMeta?.path ?? "/track/detail-:id()",
    meta: {...(detail_45_91id_93KQcmIi0ajWMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"track-detail-id"}},
    alias: detail_45_91id_93KQcmIi0ajWMeta?.alias || [],
    redirect: detail_45_91id_93KQcmIi0ajWMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/track/detail-[id].vue").then(m => m.default || m)
  },
  {
    name: detail_45_91id_93KQcmIi0ajWMeta?.name ?? "hk__zh-HK__track-detail-id",
    path: detail_45_91id_93KQcmIi0ajWMeta?.path ?? "/hk/zh-HK/track/detail-:id()",
    meta: {...(detail_45_91id_93KQcmIi0ajWMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"track-detail-id"}},
    alias: detail_45_91id_93KQcmIi0ajWMeta?.alias || [],
    redirect: detail_45_91id_93KQcmIi0ajWMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/track/detail-[id].vue").then(m => m.default || m)
  },
  {
    name: detail_45_91id_93KQcmIi0ajWMeta?.name ?? "hk__zh-CN__track-detail-id",
    path: detail_45_91id_93KQcmIi0ajWMeta?.path ?? "/hk/zh-CN/track/detail-:id()",
    meta: {...(detail_45_91id_93KQcmIi0ajWMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"track-detail-id"}},
    alias: detail_45_91id_93KQcmIi0ajWMeta?.alias || [],
    redirect: detail_45_91id_93KQcmIi0ajWMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/track/detail-[id].vue").then(m => m.default || m)
  },
  {
    name: detail_45_91id_93KQcmIi0ajWMeta?.name ?? "hk__en__track-detail-id",
    path: detail_45_91id_93KQcmIi0ajWMeta?.path ?? "/hk/en/track/detail-:id()",
    meta: {...(detail_45_91id_93KQcmIi0ajWMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"track-detail-id"}},
    alias: detail_45_91id_93KQcmIi0ajWMeta?.alias || [],
    redirect: detail_45_91id_93KQcmIi0ajWMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/track/detail-[id].vue").then(m => m.default || m)
  },
  {
    name: detail_45_91id_93KQcmIi0ajWMeta?.name ?? "mo__track-detail-id",
    path: detail_45_91id_93KQcmIi0ajWMeta?.path ?? "/mo/track/detail-:id()",
    meta: {...(detail_45_91id_93KQcmIi0ajWMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"track-detail-id"}},
    alias: detail_45_91id_93KQcmIi0ajWMeta?.alias || [],
    redirect: detail_45_91id_93KQcmIi0ajWMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/track/detail-[id].vue").then(m => m.default || m)
  },
  {
    name: detail_45_91id_93KQcmIi0ajWMeta?.name ?? "mo__zh-MO__track-detail-id",
    path: detail_45_91id_93KQcmIi0ajWMeta?.path ?? "/mo/zh-MO/track/detail-:id()",
    meta: {...(detail_45_91id_93KQcmIi0ajWMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"track-detail-id"}},
    alias: detail_45_91id_93KQcmIi0ajWMeta?.alias || [],
    redirect: detail_45_91id_93KQcmIi0ajWMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/track/detail-[id].vue").then(m => m.default || m)
  },
  {
    name: detail_45_91id_93KQcmIi0ajWMeta?.name ?? "mo__zh-HK__track-detail-id",
    path: detail_45_91id_93KQcmIi0ajWMeta?.path ?? "/mo/zh-HK/track/detail-:id()",
    meta: {...(detail_45_91id_93KQcmIi0ajWMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"track-detail-id"}},
    alias: detail_45_91id_93KQcmIi0ajWMeta?.alias || [],
    redirect: detail_45_91id_93KQcmIi0ajWMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/track/detail-[id].vue").then(m => m.default || m)
  },
  {
    name: detail_45_91id_93KQcmIi0ajWMeta?.name ?? "mo__zh-CN__track-detail-id",
    path: detail_45_91id_93KQcmIi0ajWMeta?.path ?? "/mo/zh-CN/track/detail-:id()",
    meta: {...(detail_45_91id_93KQcmIi0ajWMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"track-detail-id"}},
    alias: detail_45_91id_93KQcmIi0ajWMeta?.alias || [],
    redirect: detail_45_91id_93KQcmIi0ajWMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/track/detail-[id].vue").then(m => m.default || m)
  },
  {
    name: detail_45_91id_93KQcmIi0ajWMeta?.name ?? "mo__en__track-detail-id",
    path: detail_45_91id_93KQcmIi0ajWMeta?.path ?? "/mo/en/track/detail-:id()",
    meta: {...(detail_45_91id_93KQcmIi0ajWMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"track-detail-id"}},
    alias: detail_45_91id_93KQcmIi0ajWMeta?.alias || [],
    redirect: detail_45_91id_93KQcmIi0ajWMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/track/detail-[id].vue").then(m => m.default || m)
  },
  {
    name: detail_45_91id_93KQcmIi0ajWMeta?.name ?? "cn__track-detail-id",
    path: detail_45_91id_93KQcmIi0ajWMeta?.path ?? "/cn/track/detail-:id()",
    meta: {...(detail_45_91id_93KQcmIi0ajWMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"track-detail-id"}},
    alias: detail_45_91id_93KQcmIi0ajWMeta?.alias || [],
    redirect: detail_45_91id_93KQcmIi0ajWMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/track/detail-[id].vue").then(m => m.default || m)
  },
  {
    name: detail_45_91id_93KQcmIi0ajWMeta?.name ?? "cn__zh-MO__track-detail-id",
    path: detail_45_91id_93KQcmIi0ajWMeta?.path ?? "/cn/zh-MO/track/detail-:id()",
    meta: {...(detail_45_91id_93KQcmIi0ajWMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"track-detail-id"}},
    alias: detail_45_91id_93KQcmIi0ajWMeta?.alias || [],
    redirect: detail_45_91id_93KQcmIi0ajWMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/track/detail-[id].vue").then(m => m.default || m)
  },
  {
    name: detail_45_91id_93KQcmIi0ajWMeta?.name ?? "cn__zh-HK__track-detail-id",
    path: detail_45_91id_93KQcmIi0ajWMeta?.path ?? "/cn/zh-HK/track/detail-:id()",
    meta: {...(detail_45_91id_93KQcmIi0ajWMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"track-detail-id"}},
    alias: detail_45_91id_93KQcmIi0ajWMeta?.alias || [],
    redirect: detail_45_91id_93KQcmIi0ajWMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/track/detail-[id].vue").then(m => m.default || m)
  },
  {
    name: detail_45_91id_93KQcmIi0ajWMeta?.name ?? "cn__zh-CN__track-detail-id",
    path: detail_45_91id_93KQcmIi0ajWMeta?.path ?? "/cn/zh-CN/track/detail-:id()",
    meta: {...(detail_45_91id_93KQcmIi0ajWMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"track-detail-id"}},
    alias: detail_45_91id_93KQcmIi0ajWMeta?.alias || [],
    redirect: detail_45_91id_93KQcmIi0ajWMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/track/detail-[id].vue").then(m => m.default || m)
  },
  {
    name: detail_45_91id_93KQcmIi0ajWMeta?.name ?? "cn__en__track-detail-id",
    path: detail_45_91id_93KQcmIi0ajWMeta?.path ?? "/cn/en/track/detail-:id()",
    meta: {...(detail_45_91id_93KQcmIi0ajWMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"track-detail-id"}},
    alias: detail_45_91id_93KQcmIi0ajWMeta?.alias || [],
    redirect: detail_45_91id_93KQcmIi0ajWMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/track/detail-[id].vue").then(m => m.default || m)
  },
  {
    name: list_45_91billNo_93iSA1ISahEJMeta?.name ?? "hk__track-list-billNo",
    path: list_45_91billNo_93iSA1ISahEJMeta?.path ?? "/hk/track/list-:billNo()",
    meta: {...(list_45_91billNo_93iSA1ISahEJMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"track-list-billNo"}},
    alias: list_45_91billNo_93iSA1ISahEJMeta?.alias || [],
    redirect: list_45_91billNo_93iSA1ISahEJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/track/list-[billNo].vue").then(m => m.default || m)
  },
  {
    name: list_45_91billNo_93iSA1ISahEJMeta?.name ?? "hk__zh-MO__track-list-billNo",
    path: list_45_91billNo_93iSA1ISahEJMeta?.path ?? "/hk/zh-MO/track/list-:billNo()",
    meta: {...(list_45_91billNo_93iSA1ISahEJMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"track-list-billNo"}},
    alias: list_45_91billNo_93iSA1ISahEJMeta?.alias || [],
    redirect: list_45_91billNo_93iSA1ISahEJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/track/list-[billNo].vue").then(m => m.default || m)
  },
  {
    name: list_45_91billNo_93iSA1ISahEJMeta?.name ?? "track-list-billNo",
    path: list_45_91billNo_93iSA1ISahEJMeta?.path ?? "/track/list-:billNo()",
    meta: {...(list_45_91billNo_93iSA1ISahEJMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"track-list-billNo"}},
    alias: list_45_91billNo_93iSA1ISahEJMeta?.alias || [],
    redirect: list_45_91billNo_93iSA1ISahEJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/track/list-[billNo].vue").then(m => m.default || m)
  },
  {
    name: list_45_91billNo_93iSA1ISahEJMeta?.name ?? "hk__zh-HK__track-list-billNo",
    path: list_45_91billNo_93iSA1ISahEJMeta?.path ?? "/hk/zh-HK/track/list-:billNo()",
    meta: {...(list_45_91billNo_93iSA1ISahEJMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"track-list-billNo"}},
    alias: list_45_91billNo_93iSA1ISahEJMeta?.alias || [],
    redirect: list_45_91billNo_93iSA1ISahEJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/track/list-[billNo].vue").then(m => m.default || m)
  },
  {
    name: list_45_91billNo_93iSA1ISahEJMeta?.name ?? "hk__zh-CN__track-list-billNo",
    path: list_45_91billNo_93iSA1ISahEJMeta?.path ?? "/hk/zh-CN/track/list-:billNo()",
    meta: {...(list_45_91billNo_93iSA1ISahEJMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"track-list-billNo"}},
    alias: list_45_91billNo_93iSA1ISahEJMeta?.alias || [],
    redirect: list_45_91billNo_93iSA1ISahEJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/track/list-[billNo].vue").then(m => m.default || m)
  },
  {
    name: list_45_91billNo_93iSA1ISahEJMeta?.name ?? "hk__en__track-list-billNo",
    path: list_45_91billNo_93iSA1ISahEJMeta?.path ?? "/hk/en/track/list-:billNo()",
    meta: {...(list_45_91billNo_93iSA1ISahEJMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"track-list-billNo"}},
    alias: list_45_91billNo_93iSA1ISahEJMeta?.alias || [],
    redirect: list_45_91billNo_93iSA1ISahEJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/track/list-[billNo].vue").then(m => m.default || m)
  },
  {
    name: list_45_91billNo_93iSA1ISahEJMeta?.name ?? "mo__track-list-billNo",
    path: list_45_91billNo_93iSA1ISahEJMeta?.path ?? "/mo/track/list-:billNo()",
    meta: {...(list_45_91billNo_93iSA1ISahEJMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"track-list-billNo"}},
    alias: list_45_91billNo_93iSA1ISahEJMeta?.alias || [],
    redirect: list_45_91billNo_93iSA1ISahEJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/track/list-[billNo].vue").then(m => m.default || m)
  },
  {
    name: list_45_91billNo_93iSA1ISahEJMeta?.name ?? "mo__zh-MO__track-list-billNo",
    path: list_45_91billNo_93iSA1ISahEJMeta?.path ?? "/mo/zh-MO/track/list-:billNo()",
    meta: {...(list_45_91billNo_93iSA1ISahEJMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"track-list-billNo"}},
    alias: list_45_91billNo_93iSA1ISahEJMeta?.alias || [],
    redirect: list_45_91billNo_93iSA1ISahEJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/track/list-[billNo].vue").then(m => m.default || m)
  },
  {
    name: list_45_91billNo_93iSA1ISahEJMeta?.name ?? "mo__zh-HK__track-list-billNo",
    path: list_45_91billNo_93iSA1ISahEJMeta?.path ?? "/mo/zh-HK/track/list-:billNo()",
    meta: {...(list_45_91billNo_93iSA1ISahEJMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"track-list-billNo"}},
    alias: list_45_91billNo_93iSA1ISahEJMeta?.alias || [],
    redirect: list_45_91billNo_93iSA1ISahEJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/track/list-[billNo].vue").then(m => m.default || m)
  },
  {
    name: list_45_91billNo_93iSA1ISahEJMeta?.name ?? "mo__zh-CN__track-list-billNo",
    path: list_45_91billNo_93iSA1ISahEJMeta?.path ?? "/mo/zh-CN/track/list-:billNo()",
    meta: {...(list_45_91billNo_93iSA1ISahEJMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"track-list-billNo"}},
    alias: list_45_91billNo_93iSA1ISahEJMeta?.alias || [],
    redirect: list_45_91billNo_93iSA1ISahEJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/track/list-[billNo].vue").then(m => m.default || m)
  },
  {
    name: list_45_91billNo_93iSA1ISahEJMeta?.name ?? "mo__en__track-list-billNo",
    path: list_45_91billNo_93iSA1ISahEJMeta?.path ?? "/mo/en/track/list-:billNo()",
    meta: {...(list_45_91billNo_93iSA1ISahEJMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"track-list-billNo"}},
    alias: list_45_91billNo_93iSA1ISahEJMeta?.alias || [],
    redirect: list_45_91billNo_93iSA1ISahEJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/track/list-[billNo].vue").then(m => m.default || m)
  },
  {
    name: list_45_91billNo_93iSA1ISahEJMeta?.name ?? "cn__track-list-billNo",
    path: list_45_91billNo_93iSA1ISahEJMeta?.path ?? "/cn/track/list-:billNo()",
    meta: {...(list_45_91billNo_93iSA1ISahEJMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"track-list-billNo"}},
    alias: list_45_91billNo_93iSA1ISahEJMeta?.alias || [],
    redirect: list_45_91billNo_93iSA1ISahEJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/track/list-[billNo].vue").then(m => m.default || m)
  },
  {
    name: list_45_91billNo_93iSA1ISahEJMeta?.name ?? "cn__zh-MO__track-list-billNo",
    path: list_45_91billNo_93iSA1ISahEJMeta?.path ?? "/cn/zh-MO/track/list-:billNo()",
    meta: {...(list_45_91billNo_93iSA1ISahEJMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"track-list-billNo"}},
    alias: list_45_91billNo_93iSA1ISahEJMeta?.alias || [],
    redirect: list_45_91billNo_93iSA1ISahEJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/track/list-[billNo].vue").then(m => m.default || m)
  },
  {
    name: list_45_91billNo_93iSA1ISahEJMeta?.name ?? "cn__zh-HK__track-list-billNo",
    path: list_45_91billNo_93iSA1ISahEJMeta?.path ?? "/cn/zh-HK/track/list-:billNo()",
    meta: {...(list_45_91billNo_93iSA1ISahEJMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"track-list-billNo"}},
    alias: list_45_91billNo_93iSA1ISahEJMeta?.alias || [],
    redirect: list_45_91billNo_93iSA1ISahEJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/track/list-[billNo].vue").then(m => m.default || m)
  },
  {
    name: list_45_91billNo_93iSA1ISahEJMeta?.name ?? "cn__zh-CN__track-list-billNo",
    path: list_45_91billNo_93iSA1ISahEJMeta?.path ?? "/cn/zh-CN/track/list-:billNo()",
    meta: {...(list_45_91billNo_93iSA1ISahEJMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"track-list-billNo"}},
    alias: list_45_91billNo_93iSA1ISahEJMeta?.alias || [],
    redirect: list_45_91billNo_93iSA1ISahEJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/track/list-[billNo].vue").then(m => m.default || m)
  },
  {
    name: list_45_91billNo_93iSA1ISahEJMeta?.name ?? "cn__en__track-list-billNo",
    path: list_45_91billNo_93iSA1ISahEJMeta?.path ?? "/cn/en/track/list-:billNo()",
    meta: {...(list_45_91billNo_93iSA1ISahEJMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"track-list-billNo"}},
    alias: list_45_91billNo_93iSA1ISahEJMeta?.alias || [],
    redirect: list_45_91billNo_93iSA1ISahEJMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/track/list-[billNo].vue").then(m => m.default || m)
  },
  {
    name: widget6WNDAOmh1NMeta?.name ?? "hk__widget",
    path: widget6WNDAOmh1NMeta?.path ?? "/hk/widget",
    meta: {...(widget6WNDAOmh1NMeta || {}), ...{"siteCode":"hk","i18nCode":"","truePath":"widget"}},
    alias: widget6WNDAOmh1NMeta?.alias || [],
    redirect: widget6WNDAOmh1NMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/widget.vue").then(m => m.default || m)
  },
  {
    name: widget6WNDAOmh1NMeta?.name ?? "hk__zh-MO__widget",
    path: widget6WNDAOmh1NMeta?.path ?? "/hk/zh-MO/widget",
    meta: {...(widget6WNDAOmh1NMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-MO","truePath":"widget"}},
    alias: widget6WNDAOmh1NMeta?.alias || [],
    redirect: widget6WNDAOmh1NMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/widget.vue").then(m => m.default || m)
  },
  {
    name: widget6WNDAOmh1NMeta?.name ?? "widget",
    path: widget6WNDAOmh1NMeta?.path ?? "/widget",
    meta: {...(widget6WNDAOmh1NMeta || {}), ...{"siteCode":"","i18nCode":"","truePath":"widget"}},
    alias: widget6WNDAOmh1NMeta?.alias || [],
    redirect: widget6WNDAOmh1NMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/widget.vue").then(m => m.default || m)
  },
  {
    name: widget6WNDAOmh1NMeta?.name ?? "hk__zh-HK__widget",
    path: widget6WNDAOmh1NMeta?.path ?? "/hk/zh-HK/widget",
    meta: {...(widget6WNDAOmh1NMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-HK","truePath":"widget"}},
    alias: widget6WNDAOmh1NMeta?.alias || [],
    redirect: widget6WNDAOmh1NMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/widget.vue").then(m => m.default || m)
  },
  {
    name: widget6WNDAOmh1NMeta?.name ?? "hk__zh-CN__widget",
    path: widget6WNDAOmh1NMeta?.path ?? "/hk/zh-CN/widget",
    meta: {...(widget6WNDAOmh1NMeta || {}), ...{"siteCode":"hk","i18nCode":"zh-CN","truePath":"widget"}},
    alias: widget6WNDAOmh1NMeta?.alias || [],
    redirect: widget6WNDAOmh1NMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/widget.vue").then(m => m.default || m)
  },
  {
    name: widget6WNDAOmh1NMeta?.name ?? "hk__en__widget",
    path: widget6WNDAOmh1NMeta?.path ?? "/hk/en/widget",
    meta: {...(widget6WNDAOmh1NMeta || {}), ...{"siteCode":"hk","i18nCode":"en","truePath":"widget"}},
    alias: widget6WNDAOmh1NMeta?.alias || [],
    redirect: widget6WNDAOmh1NMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/widget.vue").then(m => m.default || m)
  },
  {
    name: widget6WNDAOmh1NMeta?.name ?? "mo__widget",
    path: widget6WNDAOmh1NMeta?.path ?? "/mo/widget",
    meta: {...(widget6WNDAOmh1NMeta || {}), ...{"siteCode":"mo","i18nCode":"","truePath":"widget"}},
    alias: widget6WNDAOmh1NMeta?.alias || [],
    redirect: widget6WNDAOmh1NMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/widget.vue").then(m => m.default || m)
  },
  {
    name: widget6WNDAOmh1NMeta?.name ?? "mo__zh-MO__widget",
    path: widget6WNDAOmh1NMeta?.path ?? "/mo/zh-MO/widget",
    meta: {...(widget6WNDAOmh1NMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-MO","truePath":"widget"}},
    alias: widget6WNDAOmh1NMeta?.alias || [],
    redirect: widget6WNDAOmh1NMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/widget.vue").then(m => m.default || m)
  },
  {
    name: widget6WNDAOmh1NMeta?.name ?? "mo__zh-HK__widget",
    path: widget6WNDAOmh1NMeta?.path ?? "/mo/zh-HK/widget",
    meta: {...(widget6WNDAOmh1NMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-HK","truePath":"widget"}},
    alias: widget6WNDAOmh1NMeta?.alias || [],
    redirect: widget6WNDAOmh1NMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/widget.vue").then(m => m.default || m)
  },
  {
    name: widget6WNDAOmh1NMeta?.name ?? "mo__zh-CN__widget",
    path: widget6WNDAOmh1NMeta?.path ?? "/mo/zh-CN/widget",
    meta: {...(widget6WNDAOmh1NMeta || {}), ...{"siteCode":"mo","i18nCode":"zh-CN","truePath":"widget"}},
    alias: widget6WNDAOmh1NMeta?.alias || [],
    redirect: widget6WNDAOmh1NMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/widget.vue").then(m => m.default || m)
  },
  {
    name: widget6WNDAOmh1NMeta?.name ?? "mo__en__widget",
    path: widget6WNDAOmh1NMeta?.path ?? "/mo/en/widget",
    meta: {...(widget6WNDAOmh1NMeta || {}), ...{"siteCode":"mo","i18nCode":"en","truePath":"widget"}},
    alias: widget6WNDAOmh1NMeta?.alias || [],
    redirect: widget6WNDAOmh1NMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/widget.vue").then(m => m.default || m)
  },
  {
    name: widget6WNDAOmh1NMeta?.name ?? "cn__widget",
    path: widget6WNDAOmh1NMeta?.path ?? "/cn/widget",
    meta: {...(widget6WNDAOmh1NMeta || {}), ...{"siteCode":"cn","i18nCode":"","truePath":"widget"}},
    alias: widget6WNDAOmh1NMeta?.alias || [],
    redirect: widget6WNDAOmh1NMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/widget.vue").then(m => m.default || m)
  },
  {
    name: widget6WNDAOmh1NMeta?.name ?? "cn__zh-MO__widget",
    path: widget6WNDAOmh1NMeta?.path ?? "/cn/zh-MO/widget",
    meta: {...(widget6WNDAOmh1NMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-MO","truePath":"widget"}},
    alias: widget6WNDAOmh1NMeta?.alias || [],
    redirect: widget6WNDAOmh1NMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/widget.vue").then(m => m.default || m)
  },
  {
    name: widget6WNDAOmh1NMeta?.name ?? "cn__zh-HK__widget",
    path: widget6WNDAOmh1NMeta?.path ?? "/cn/zh-HK/widget",
    meta: {...(widget6WNDAOmh1NMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-HK","truePath":"widget"}},
    alias: widget6WNDAOmh1NMeta?.alias || [],
    redirect: widget6WNDAOmh1NMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/widget.vue").then(m => m.default || m)
  },
  {
    name: widget6WNDAOmh1NMeta?.name ?? "cn__zh-CN__widget",
    path: widget6WNDAOmh1NMeta?.path ?? "/cn/zh-CN/widget",
    meta: {...(widget6WNDAOmh1NMeta || {}), ...{"siteCode":"cn","i18nCode":"zh-CN","truePath":"widget"}},
    alias: widget6WNDAOmh1NMeta?.alias || [],
    redirect: widget6WNDAOmh1NMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/widget.vue").then(m => m.default || m)
  },
  {
    name: widget6WNDAOmh1NMeta?.name ?? "cn__en__widget",
    path: widget6WNDAOmh1NMeta?.path ?? "/cn/en/widget",
    meta: {...(widget6WNDAOmh1NMeta || {}), ...{"siteCode":"cn","i18nCode":"en","truePath":"widget"}},
    alias: widget6WNDAOmh1NMeta?.alias || [],
    redirect: widget6WNDAOmh1NMeta?.redirect || undefined,
    component: () => import("/root/workspace/f2f-e3plus-newki-front_NY1p/pages/widget.vue").then(m => m.default || m)
  }
]