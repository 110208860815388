<template>
  <!-- <div class="newki-img" :style="styleObj">
    <img width="100%" height="100%" :src="ossSrc" :alt="alt" />
  </div> -->
  <van-image
    class="newki-image"
    :width="block ? '100%' : `${px2rem(width)}rem`"
    :height="block ? 'auto' : `${px2rem(height)}rem`"
    :src="ossSrc"
    :radius="radius"
    :round="round"
    :block="block"
    :lazy-load="lazyLoad"
    :alt="alt"
    v-bind="{ ...options }"
  >
    <template v-slot:loading>
      <slot name="loading">
        <van-image
          :width="`${px2rem(width)}rem`"
          :height="`${px2rem(height)}rem`"
          :radius="radius"
          :round="round"
          :block="block"
          :lazy-load="lazyLoad"
          :alt="alt"
          :src="errorImg"
        />
      </slot>
    </template>
    <template v-slot:error>
      <slot name="error">
        <van-image
          :width="`${px2rem(width)}rem`"
          :height="`${px2rem(height)}rem`"
          :radius="radius"
          :round="round"
          :block="block"
          :lazy-load="lazyLoad"
          :alt="alt"
          :src="errorImg"
        />
      </slot>
    </template>
  </van-image>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
const props = defineProps({
  options: {
    type: Object,
    required: false,
  },
  src: {
    // type: [String, null],
    required: true,
  },
  width: {
    type: [String, Number],
    required: true,
  },
  height: {
    type: [String, Number],
    required: true,
  },
  radius: {
    type: [String, Number],
    required: false,
    default: 0,
  },
  round: {
    type: Boolean,
    required: false,
    default: false,
  },
  block: {
    type: Boolean,
    required: false,
    default: false,
  },
  lazyLoad: {
    // 需配合LazyLoad組件使用
    type: Boolean,
    required: false,
    default: true,
  },
  alt: {
    type: String,
    required: false,
    default: '',
  },
  // OSS切圖規則，不需要傳w、h這兩個參數
  rules: {
    type: Object,
    default() {},
  },
  errorImage: {
    type: String,
    required: false,
    default: 'empty/normal.svg',
  },
})

const styleObj = ref({})

const buildStyleObj = () => {
  let radius: string = '0px'

  if (typeof props.radius === 'number') {
    radius = `${props.radius}px`
  }

  if (typeof props.radius === 'string') {
    radius = props.radius.includes('px') || props.radius.includes('%') ? props.radius : `${props.radius}px`
  }

  styleObj.value = {
    width: props.block ? '100%' : `${px2rem(props.width)}rem`,
    height: props.block ? 'auto' : `${px2rem(props.height)}rem`,
    borderRadius: props.round ? '50%' : radius,
  }
}

buildStyleObj()

const hasComputedSrc = ref(false)

const ossSrc = computed(() => {
  // if (hasComputedSrc.value) return ossSrc.value
  let src: string = useOSSImage(props.src as string, {
    w: Number(props.width),
    h: Number(props.height),
    ...props.rules,
  })
  hasComputedSrc.value = true
  return src
})

const errorImg = computed(() => {
  return new URL(`../assets/images/${props.errorImage}`, import.meta.url).href
})
</script>

<style scoped lang="scss">
:deep(.van-image__error) {
  background-color: $white;
}
.newki-img {
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.newki-image {
  // background-color: $bg-color;
  @include flex();
  border: none;
  outline: none;

  :deep(.van-image__img) {
    // background-color: $bg-color;
  }
}
</style>
