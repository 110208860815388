<template>
  <div class="item-box" :style="widgetStyle">
    <div class="pic-list picture" :style="widgetLayout">
      <!-- 正常模式 -->
      <template v-if="showMode === 'Normal'">
        <div class="pic-item" :class="{ 'auto-fit-mode': wData.columns == '1' }" v-for="(item, index) in wData.items">
          <div class="pic-content">
            <div class="item-image" v-if="item.serverPath" :style="[imgBoxStyle, imgStyle]">
              <NewkiImage
                :src="item.serverPath"
                v-if="item.actionCode === ActionType.POPCHAT"
                @click="useContentAction(item)"
                :width="appWidth"
                :height="appWidth"
                :rules="{ newkiId: 'Render_Picture', dpr: 3 }"
                :block="true"
              >
                <template #loading>
                  <div class="loading-box">
                    <van-image
                      :width="`12rem`"
                      :height="`12rem`"
                      :radius="20"
                      :round="true"
                      :block="true"
                      :lazy-load="true"
                      alt=""
                      :src="HolderImage"
                    />
                  </div>
                </template>
              </NewkiImage>
              <NuxtLink v-else :to="useContentActionNuxtLink(item)">
                <NewkiImage
                  :src="item.serverPath"
                  :width="appWidth"
                  :height="appWidth"
                  :rules="{ newkiId: 'Render_Picture', dpr: 3 }"
                  :block="true"
                >
                  <template #loading>
                    <div class="loading-box">
                      <van-image
                        :width="`12rem`"
                        :height="`12rem`"
                        :radius="20"
                        :round="true"
                        :block="true"
                        :lazy-load="true"
                        alt=""
                        :src="HolderImage"
                      />
                    </div>
                  </template>
                </NewkiImage>
              </NuxtLink>
            </div>
          </div>
        </div>
      </template>
      <!-- 折行模式 -->
      <template v-if="showMode === 'Expand'">
        <template v-for="(item, index) in wData.items">
          <div class="pic-item" v-if="index < showNumber">
            <div class="pic-content">
              <div class="item-image" v-if="item.serverPath" :style="[imgBoxStyle]">
                <NewkiImage
                  v-if="item.actionCode === ActionType.POPCHAT"
                  @click="useContentAction(item)"
                  :src="item.serverPath"
                  :width="appWidth"
                  :height="appWidth"
                  :rules="{ newkiId: 'Render_Picture', dpr: 3 }"
                  :block="true"
                />
                <NuxtLink v-else :to="useContentActionNuxtLink(item)">
                  <NewkiImage
                    :src="item.serverPath"
                    :width="appWidth"
                    :height="appWidth"
                    :rules="{ newkiId: 'Render_Picture', dpr: 3 }"
                    :block="true"
                  />
                </NuxtLink>
              </div>
              <div
                class="mask-tips"
                v-if="index === showNumber - 1 && wData.items.length !== showNumber"
                :style="[maskStyle]"
                @click="openImagePreview(index)"
              >
                +{{ wData.items.length - showNumber }}
              </div>
            </div>
          </div>
        </template>
        <!-- <div class="pic-item show-remain" v-if="wData.items.length > showNumber">
      <div>{{ wData.items.length - showNumber + 1 }}</div>
    </div> -->
      </template>
    </div>
    <NewkiPreview :data="wData.items" :positionIndex="imagePositionIndex" v-model="imagePreviewShow" />
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useStyle, useShowNum, useShowMode } from './renderHooks'
import { useBroswerStore } from '~/store/broswer'
import HolderImage from '~/assets/images/empty/normal.svg'
const { appWidth } = storeToRefs(useBroswerStore())
import { ActionType } from '@/enum/actionType'

const props = defineProps({
  wData: {
    type: Object,
    required: false,
    default: {},
  },
})

const imageHolder: Ref<string> = ref('')

const imagePreviewShow: Ref<boolean> = ref(false)

const imagePositionIndex = ref(0)

// 组件样式
const widgetStyle: any = useStyle(props.wData).widgetStyle

// 元素样式
const imgStyle: any = useStyle(props.wData).imgStyle
const imgBoxStyle: any = useStyle(props.wData).imgBoxStyle

// 遮罩样式
const maskStyle: any = useStyle(props.wData).maskStyle

// 组件的布局
const widgetLayout: any = useShowMode(props.wData)

// 显示模式
const showMode: any = useShowNum(props.wData).showMode

// 最大显示数
const showNumber: Ref<number> = useShowNum(props.wData).showNumber

// 打開圖片預覽
const openImagePreview = (index: number) => {
  imagePositionIndex.value = index
  imagePreviewShow.value = true
}

onMounted(() => {
  // console.log("props.wData:", props.wData);
})
</script>

<style scoped lang="scss">
.item-box {
  position: relative;
  z-index: 10;
  background-size: cover;
  background-position: center center;

  &:hover {
    .delete {
      @include flex();
    }

    .controller {
      @include flex(column, flex-start, flex-start);
      .tips {
        // @include flex();
      }
    }
  }

  .pic-list {
    &.picture {
      color: inherit;
    }

    .pic-item {
      @include flex();
      position: relative;
      z-index: 10;
      width: 100%;
      height: 100%;
      padding-bottom: 100%;

      &.pointer {
        cursor: pointer;
      }

      &.not-allowed {
        cursor: not-allowed;
      }

      &.auto-fit-mode {
        padding-bottom: unset;

        .pic-content {
          position: unset;
          inset: unset;
          width: 100%;

          .item-image {
            position: unset;
            inset: unset;
            overflow: hidden;

            .loading-box {
              @include flex();
            }
          }
        }
      }

      .pic-content {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        // border: 1px solid #efefef;
        overflow: hidden;

        .item-image {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 10;

          img,
          div {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .mask-tips {
          @include flex();
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: 10;
          background-color: rgba(0, 0, 0, 0.6);
          font-size: 20px;
          // font-weight: bold;
          color: #fff;
          cursor: pointer;
          border-radius: 5px;
        }
      }
    }
  }
}
</style>
