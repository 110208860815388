<template>
  <van-field
    ref="inputCode"
    class="input-field"
    name="graphicCodeInput"
    v-model="graphicCode"
    :placeholder="t('login.graphicCode')"
    :rules="[{ required: true, validator: validatorInput, message: t('login.graphicCode'), trigger: 'onSubmit' }]"
    maxlength="4"
  >
    <template #extra>
      <div class="extra-content" @click="getCode">
        <template v-if="graphicUrl">
          <img :src="graphicUrl" alt="qrCode" />
        </template>
        <template v-else>
          <van-loading type="spinner" />
        </template>
      </div>
    </template>
  </van-field>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { apiGetGraphicCode, apiCheckGraphicCode } from '~/service/api/login'
import type { FieldInstance } from 'vant'

const { t } = useI18n()
const graphicCode = ref('')
const graphicUrl = ref('')
const graphicKey = ref('')
const inputCode = ref<FieldInstance>()

onMounted(() => {
  getCode()
})

const getCode = async () => {
  const data = await apiGetGraphicCode()
  if (data) {
    graphicUrl.value = data.imageUrl
    graphicKey.value = data.imageKey
  }
}

const validatorInput = async (val: any, rule: any) => {
  if (val.length === 4) {
    let data = await apiCheckGraphicCode(val, graphicKey.value)
    getCode()
    if (data && !data.status) {
      // 驗證失敗
      rule.message = t('login.graphicRightCode')
      return false
    } else {
      return true
    }
  } else {
    rule.message = t('login.graphicCode')
    return false
  }
}
</script>

<style scoped lang="scss">
.input-field {
  border: 1px solid $border-color;
  border-radius: 8px;
  margin-top: 26px;
  overflow: unset;

  .van-field__value {
    overflow: unset;
  }
  :deep(.van-field__error-message) {
    position: absolute;
    bottom: -36px;
    left: -10px;
  }
}
.extra-content {
  position: absolute;
  top: 0;
  right: 0;

  img {
    height: 44px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:hover {
    cursor: pointer;
  }
}
</style>
