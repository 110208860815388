<template>
  <SectionCard :title="t('render.review.share')" text="share" :style="widgetStyle">
    <div class="share-widget">
      <div class="swiper-box">
        <ShareSwiper
          @onSlideChange="handleSlideChange"
          :wData="wData"
          :dataList="dataList"
          class="share-swiper"
          name="share-swiper"
          v-if="dataList.length"
        />
      </div>
      <NuxtLink :to="onOnlineClick()">
        <OneLine :text="onlineText" :avatar="onlineAvatar" color="#aace37"></OneLine>
      </NuxtLink>
      <div class="bg-box">
        <img :src="LiveReact" width="375" alt="" />
      </div>
    </div>
  </SectionCard>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import OneLine from '~/components/render/OneLine.vue'
import LiveReact from '~/assets/images/common/live-react.svg'
import ShareSwiper from '~/components/render/ShareSwiper.vue'
import { getExperienceShare } from '~/service/api/home'
import SectionCard from '~/components/render/SectionCard.vue'
import { useStyle } from './renderHooks'
const { t } = useI18n()

const onlineActiveId = ref('')
const onlineText = ref('')
const onlineAvatar = ref('')

const props = defineProps({
  wData: {
    type: Object,
    required: false,
    default: {},
  },
})

const dataList: any = ref([])

// 组件样式
const widgetStyle: any = useStyle(props.wData).widgetStyle

const pick = (data: any, index: number) => {
  onlineActiveId.value = data && data[index]?.id
  onlineText.value = data && data[index]?.text
  onlineAvatar.value = data && data[index]?.memberAvatar
}

const handleSlideChange = (index: number) => {
  // console.log("handleSlideChange:", index);
  pick(dataList.value, index)
}

const onOnlineClick = () => {
  return routerNuxtLink('post-detail-id', {
    params: {
      id: onlineActiveId.value,
    },
  })
}

// 服務端調用
const { data } = await useAsyncData(`getExperienceShare-${useNanoid(6)}`, () => getExperienceShare(), {
  lazy: true,
  default: () => ({
    dataList: [],
  }),
})

watch(
  data,
  val => {
    if (val) {
      dataList.value = val
      pick(val, 0)
    }
  },
  { immediate: true }
)

// const getData = async () => {
//   let data = await getExperienceShare()
//   if (data) {
//     dataList.value = data
//     pick(data, 0)
//   }
// }

onMounted(() => {
  //getData()
})
</script>

<style scoped lang="scss">
.share-widget {
  @include flex(column, flex-start, center);
  width: 100%;
  height: 0;
  padding-bottom: 116.5%;

  $pd: 12px;

  .swiper-box {
    width: 100%;
    padding: 0 $pd;
  }

  .one-line-widget {
    position: relative;
    z-index: 10;
    margin-top: 18px;
    padding: 0 24px;

    :deep(.content-box) {
      background-color: rgba(247, 250, 235, 1);
      box-shadow: 0px 4px 10px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.1);
    }
  }

  .bg-box {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
    z-index: 9;
    padding: 0 14px;

    img {
      display: block;
      width: 100%;
      object-fit: cover;
    }
  }
}
</style>
