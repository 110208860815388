<template>
  <div class="product-group" :style="widgetStyle">
    <van-tabs
      class="tag-container"
      v-model:active="activeTab"
      :title-active-color="wData.tagColor"
      :title-inactive-color="textDefault"
      :color="wData.tagColor"
      :ellipsis="false"
      @change="onTabsChange"
      ref="tagsRef"
      v-if="tabList.length > 1"
    >
      <van-tab v-for="item in tabList" :title="item.name" :name="item.id">
        <template #title>
          <div class="tag-label">{{ item.name }}</div>
        </template>
      </van-tab>
    </van-tabs>
    <div class="goods-box">
      <van-list
        v-model:loading="loading"
        class="good-list"
        :class="{ row: wData.layout === 'Horizontal', col: wData.layout === 'Vertical' }"
        :finished="finished"
        @load="onLoad"
        :finished-text="noMoreText"
      >
        <div class="empty-box" v-if="!productList.length">
          <NewkiEmpty :text="t('common.noContent')" :recommend="false" />
        </div>
        <NuxtLink v-for="item in productList" :key="item.id + item.groupId + item.skuId" :to="goDetail(item.skuId)">
          <NewkiGoodsItem
            :key="item.skuId"
            :imgSize="imgSize"
            :imgSrc="item.image"
            :layout="wData.layout"
            :goodsSpec="item.saleAttrDesc"
            :goodsName="item.goodsName"
            :price="isVip ? item.memberPrice : item.retailPrice"
            :tag-list="item.promotionDesc || []"
            :retailPrice="item.retailPrice"
            :show-origin-price="true"
            :img-block="wData.layout === 'Horizontal'"
            :vipPrice="item.memberPrice"
            :box="false"
            :is-stop="true"
            :isPresellGood="item.supportPresell == '1'"
            v-if="item.skuId"
          >
            <template #marketingProgram v-if="item?.marketingProgram && wData.layout === 'Horizontal'">
              <div class="intro">
                <span class="intro-text text-truncate">{{ item?.marketingProgram }}</span>
                <div class="intro-bg"></div>
              </div>
            </template>
            <template #action v-if="item.supportPresell == '1'">
              <div class="add-cart disabled" @click.stop.prevent="() => {}">
                <NewkiSvg name="nav-cart" :size="16" color="#ffffff" />
              </div>
            </template>
            <template #action v-else>
              <div
                class="add-cart"
                @click.stop.prevent="onActionClick(BtnType.addCart, item.skuId)"
                v-if="wData.buttonType === PromotionButtonType.CART"
              >
                <NewkiSvg name="nav-cart" :size="16" color="#ffffff" />
              </div>
              <div
                class="do-buy"
                @click.stop.prevent="onActionClick(BtnType.buyNow, item.skuId)"
                v-if="wData.buttonType === PromotionButtonType.BUY"
              >
                <NewkiSvg name="buy" :size="16" :filled="true" />
              </div>
            </template>
          </NewkiGoodsItem>
        </NuxtLink>
      </van-list>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, watch, nextTick } from 'vue'
import NewkiGoodsItem from '~/components/NewkiGoodsItem.vue'
import { useStyle } from './renderHooks'
import { useMemberStore } from '~/store/member'
import { apiGetAvailable, apiGetSkuGroup } from '~/service/api/advertisement'
import { PromotionButtonType } from '~/enum/buttonType'
import { BtnType } from '~/enum/sku'
import { useColorsStore } from '~/store/colors'
import { routerNuxtLink } from '~/composables/routerNuxtLink'

const { memberInfo } = storeToRefs(useMemberStore())
const { textDefault } = storeToRefs(useColorsStore())

const { t } = useI18n()
const noMoreText = ref('')
const loading = ref(false) // 數據加載中
const finished = ref(false) // 分頁全部加載完成
let pageNum = ref(1)

const tagsRef: any = ref(null)

const imgSize: Ref<number | undefined> = ref(undefined)

const props = defineProps({
  wData: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits(['isMounted', 'actionClick'])

// 组件样式
const widgetStyle: any = useStyle(props.wData).widgetStyle

// 当前的 tab index
const activeTab = ref(0)

// 当前 tab list
const tabList: any = ref([])

// 商品列表
const productList: any = ref([])

// 按鈕點擊
const onActionClick = async (type: number, skuId: string) => {
  emit('actionClick', {
    type,
    skuId: skuId,
  })
}

// 當前 tab 所对应的商品组 id
const currentGroupId: Ref<string | number> = ref('')

const isVip = computed(() => {
  return memberInfo.value.payMember
})

watch(
  () => productList.value,
  val => {
    // console.log('noMoreText:', noMoreText.value)
    if (val.length) {
      // noMoreText.value = t('common.noMore')
    }
  },
  { immediate: true }
)

watch(
  () => currentGroupId.value,
  val => {
    // console.log('currentGroupId:', currentGroupId.value)
    if (currentGroupId.value) {
      onLoad(currentGroupId.value, true)
    }
  },
  { immediate: true }
)

// 1、初始化數據，拼裝 ids
const initData = () => {
  let ids: string[] = []
  props.wData.items.forEach((item: any) => {
    ids.push(item?.id)
  })
  getTagList({ ids: [...ids] })
}

// 2、獲取標籤列表
const getTagList = async (postData: any) => {
  let data = await apiGetAvailable(Date.now(), postData)
  if (data) {
    tabList.value = data
    currentGroupId.value = data[0].id ?? ''
  } else {
    tabList.value = []
  }
}

// 3、獲取商品列表
// const getSkuGroup = async (groupId: string = currentGroupId.value) => {
//   let data = await apiGetSkuGroup({ groupId })

//   if (data) {
//     productList.value = data.list
//     console.log('Sku List:', data.list)
//   } else {
//     productList.value = []
//   }
// }

async function onLoad(_groupId: string | number = currentGroupId.value, isForceUpdate: boolean = false) {
  // console.log('currentGroupId:', _groupId)

  if (!_groupId) {
    finished.value = true
    return false
  }

  let data = await apiGetSkuGroup({ groupId: _groupId }, pageNum.value, 15, Date.now())

  if (isForceUpdate) {
    productList.value = []
    pageNum.value = 1
    finished.value = false
    loading.value = false
  }

  if (data && data.list) {
    productList.value.push(...data.list)
  } else {
    finished.value = true
  }
  if (data) {
    loading.value = false
    if (!data.hasNextPage) {
      finished.value = true
      // noMoreText.value = t('common.noMore')
    } else {
      pageNum.value++
      finished.value = false
    }
  }
  // loading.value = true
  // finished.value = false
}

// 當標籤改變
const onTabsChange = async (name: string | number, title: string) => {
  // getSkuGroup(name.toString())
  currentGroupId.value = name
  pageNum.value = 1
}

const initSize = () => {
  let screenWidth = document.body?.getBoundingClientRect().width

  let padding = props.wData?.widgetStyle?.paddingLeft + props.wData?.widgetStyle?.paddingRight
  let _imgSize: number = (140 / 375) * (screenWidth - padding)
  imgSize.value = _imgSize
}

const goDetail = (id: number | string) => {
  return routerNuxtLink('product-skuId', { params: { skuId: id } })
}

onMounted(async () => {
  props.wData.layout === 'Horizontal' ? initSize() : ''
  initData()
  tagsRef.value?.resize()
  await nextTick()
  emit('isMounted', { name: props.wData.name, isMounted: true })
})
</script>

<style scoped lang="scss">
.product-group {
  @include flex(column, flex-start, flex-start);
  width: 100%;
  padding: 16px 12px 0;
  background-color: $white;

  &.row {
    gap: 4px;
  }

  :deep(.tag-container) {
    width: 100%;
    .tag-label {
      font-weight: 500;
    }
  }

  :deep(.goods-item-card) {
    padding: 12px;
    margin-bottom: 0;
    width: 100%;
  }

  .goods-box {
    width: 100%;

    .good-list {
      @include flex(row, flex-start, flex-start);
      flex-wrap: wrap;
      position: relative;
      z-index: 10;
      padding: 0 12px;
      a {
        display: contents;
      }
      &.row {
        :deep(.goods-item-card) {
          // flex: 1;
          width: 50%;
        }
      }

      .empty-box {
        width: 100%;
      }

      :deep(.van-list__loading) {
        position: absolute;
        bottom: -12px;
        left: 0;
        right: 0;
        z-index: 10;
      }
    }
  }
}

$size: 22px;

.add-cart,
.do-buy {
  @include flex();
  width: $size;
  height: $size;
  background-image: $discount-bg;
  border-radius: 50%;
  color: $white;

  &.disabled {
    background-image: unset;
    background-color: $placeholder-color;
  }
}
.intro {
  position: relative;
  margin: 4px 0 0 0;
  display: inline-block;
  box-sizing: border-box;
  max-width: 136px;

  .intro-text {
    width: 100%;
    font-weight: 700;
    color: $primary;
    position: relative;
    z-index: 1;
    line-height: 1;
    display: inline-block;
    padding: 0 4px;
  }
  .intro-bg {
    width: 100%;
    height: 9px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 99px;
    background-color: $primary-50;
    z-index: 0;
  }
}
</style>
