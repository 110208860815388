<template>
  <div class="toolbar-container">
    <div class="toolbar-item" @click="openSelect('order')">
      <NewkiSvg name="order-fill" :size="20" :color="primary.default" />
      <span class="text">{{ t('chat.sendOrder') }}</span>
    </div>
    <div class="toolbar-item" @click="openSelect('history')">
      <NewkiSvg name="goods-fill" :size="20" :color="primary.default" />
      <span class="text">{{ t('chat.goods') }}</span>
    </div>
    <div class="toolbar-item" @click="searchOrder">
      <NewkiSvg name="search-fill" :size="20" :color="primary.default" />
      <span class="text">{{ t('chat.searchOrder') }}</span>
    </div>
  </div>
  <NewkiGoodsSelect ref="goodsSelectRef" @selected="selected" />
</template>

<script setup lang="ts">
import NewkiSvg from '~/components/NewkiSvg.vue'
import NewkiGoodsSelect from '~/components/NewkiGoodsSelect.vue'
import { useColorsStore } from '~/store/colors'
import { useImStore } from '~/store/im'

const { t } = useI18n()
const { primary } = storeToRefs(useColorsStore())
const { setSpecialMessage } = useImStore()
const goodsSelectRef = ref<InstanceType<typeof NewkiGoodsSelect> | null>(null)
const emit = defineEmits(['close'])

function selected(data: any[], type: string) {
  let params = {
    code: 'dynamicTemplates',
    type: '',
    content: {},
  }
  if (type === 'order') {
    params.type = 'system-order'
    params.content = {
      billNo: data[0].billNo,
      billId: data[0].billId,
      freightAmount: data[0].freightAmount,
      payableAmount: data[0].payableAmount,
      createTime: data[0].createTime,
      payTime: data[0].payTime,
      deliveryTypeDesc: data[0].deliveryTypeDesc,
      receiver: data[0].receiver,
      receiverTel: data[0].receiverTel,
      receiverAddress: data[0].receiverAddress,
      billStatusDesc: data[0].billStatusDesc,
      goodsList: data[0].goodsList,
      isMall: 1,
    }
  } else {
    params.type = 'system-product'
    params.content = {
      skuId: data[0].skuId,
      goodsId: data[0].goodsId,
      goodsImage: data[0].image,
      goodsName: data[0].goodsName,
      attr: data[0].saleAttrDesc,
      payPrice: data[0].memberPrice,
      standardPrice: data[0].retailPrice,
      supportPresell: data[0].supportPresell,
    }
  }
  setSpecialMessage(params)
}

function openSelect(key: string) {
  goodsSelectRef.value?.open(key)
}

function searchOrder() {
  emit('close', 'order-list')
}
</script>

<style scoped lang="scss">
.toolbar-container {
  display: flex;
  .toolbar-item {
    display: flex;
    align-items: center;
    padding: 4px 14px;
    background-color: $white;
    border-radius: 99px;
    margin-left: 8px;
    @include cursor();

    &:hover {
      cursor: pointer;
    }

    .text {
      font-size: 14px;
      line-height: 20px;
      color: $text-color-default;
      margin-left: 4px;
    }
  }
}
</style>
