<template>
  <div class="rich-text-render" :style="widgetStyle">
    <div class="text ql-editor" v-html="richText"></div>
    <!-- <quill-editor v-model="richText" theme="snow" v-if="richText"></quill-editor> -->
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useStyle } from './renderHooks'

// import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import '@vueup/vue-quill/dist/vue-quill.core.css'
import '@vueup/vue-quill/dist/vue-quill.bubble.css'

// 舊版本
// import 'quill/dist/quill.core.css'
// import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'

const props = defineProps({
  wData: {
    type: Object,
    required: true,
  },
})

const richText: Ref<string> = ref(props.wData?.items[0]?.richText)

const widgetStyle: any = useStyle(props.wData).widgetStyle

onMounted(() => {
  // console.log('wData:', props.wData)
})
</script>

<style scoped lang="scss">
.rich-text-render {
  .ql-editor {
    padding: 0;
  }
  :deep(.ql-editor) {
    img {
      max-width: 100%;
    }
  }
}
</style>
