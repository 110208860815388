<template>
  <div class="order-container" @click="jump">
    <div v-if="!isNewData">
      <span class="text">{{ t('orderDetail.order.no') }}：</span><span class="order-no">{{ params.orderNo }}</span>
    </div>
    <div v-else class="bill-status">
      <span class="text">{{ t('order.status.status') }}</span>
      <span class="status">{{ params.billStatusDesc }}</span>
    </div>
    <template v-if="isNewData">
      <!--  友现新数据格式    -->
      <div class="order-info-container" v-for="(item, index) in params.goodsList" :key="index">
        <NewkiImage class="order-img" :src="item.goodsImage" :width="54" :height="54" :radius="6" />
        <div class="order-info">
          <div class="order-info-name-one">{{ item.goodsName }}</div>
          <div class="order-info-attr">{{ item.attr }}</div>
          <div class="price-container">
            <NewkiPrice :price="item.retailPrice || item.standardPrice" :price-size="13" :currey-size="13" />
            <span class="count">X{{ item.buyQty }}</span>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="order-info-container">
        <NewkiImage class="order-img" :src="params.img" :width="54" :height="54" :radius="6" />
        <div class="order-info">
          <div class="order-info-name">{{ params.name }}</div>
          <div class="order-info-attr">{{ params.attr }}</div>
        </div>
      </div>
    </template>
    <template v-if="isNewData">
      <div class="pay-info-container">
        <div class="pay-info-item" v-if="params.freightAmount">
          <div class="label">{{ t('orderDetail.order.freight') }}</div>
          <NewkiPrice :price="params.freightAmount" :price-size="12" :currey-size="12" />
        </div>
        <div class="pay-info-item" v-if="params.payableAmount">
          <div class="label">{{ t('orderDetail.order.shouldPaid') }}</div>
          <NewkiPrice :price="params.payableAmount" :price-size="12" :currey-size="12" />
        </div>
        <div class="pay-info-item">
          <div class="label">{{ t('orderDetail.order.no') }}</div>
          <div class="label">{{ params.billNo ? params.billNo : '-' }}</div>
        </div>
        <div class="pay-info-item">
          <div class="label">{{ t('orderDetail.order.createTime') }}</div>
          <div class="label">{{ params.createTime ? dayjs(params.createTime).format('YYYY-MM-DD HH:mm') : '-' }}</div>
        </div>
        <div class="pay-info-item">
          <div class="label">{{ t('orderDetail.order.payTime') }}</div>
          <div class="label">{{ params.payTime ? dayjs(params.payTime).format('YYYY-MM-DD HH:mm') : '-' }}</div>
        </div>
        <div class="pay-info-item">
          <div class="label">{{ t('delivery.title') }}</div>
          <div class="label">{{ params.deliveryTypeDesc ? params.deliveryTypeDesc : '-' }}</div>
        </div>
        <div class="pay-info-item">
          <div class="label">{{ t('cabinet.userName') }}</div>
          <div class="label">{{ params.receiver ? params.receiver : '-' }}</div>
        </div>
        <div class="pay-info-item">
          <div class="label">{{ t('cabinet.phoneNum') }}</div>
          <div class="label">{{ params.receiverTel ? params.receiverTel : '-' }}</div>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import dayjs from 'dayjs'
const { t } = useI18n()

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits(['close'])

const isNewData = computed(() => {
  return props.params.goodsList && props.params.goodsList.length
})

function jump() {
  emit('close', 'card-cancel')
  routerPush('order-detail-id', { params: { id: props.params.orderId || props.params.billId } })
}
</script>

<style scoped lang="scss">
.bill-status {
  display: flex;
  justify-content: space-between;
  font-size: 13px;

  .text {
    color: $text-color-default;
  }
  .status {
    color: $price;
  }
}
.order-container {
  width: 240px;
  box-sizing: border-box;
  padding: 10px 12px;
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .text {
    font-size: 13px;
    color: $text-color-default;
  }
  .order-no {
    font-size: 13px;
    color: $primary-500;
  }
  .order-info-container {
    margin-top: 6px;
    display: flex;

    .order-img {
      width: 54px;
      height: 54px;
      border-radius: 6px;
      margin-right: 10px;
      flex-shrink: 0;
    }
    .order-info {
      padding: 5px 0;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      .order-info-name-one {
        @include line(1);
        font-size: 11px;
        color: #111827;
      }
      .order-info-name {
        @include line(2);
        font-size: 11px;
        color: #111827;
      }
      .order-info-attr {
        @include line(1);
        margin-top: 5px;
        font-size: 11px;
        color: $text-color-assist;
      }
      .price-container {
        margin-top: auto;
        display: flex;
        justify-content: space-between;

        .count {
          font-size: 13px;
          color: $text-color-assist;
        }
      }
    }
  }

  .pay-info-container {
    margin-top: 6px;
    padding-top: 8px;
    border-top: 1px solid #ebf0ff;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .pay-info-item {
      display: flex;
      justify-content: space-between;

      .label {
        font-size: 12px;
        color: $text-color-default;
      }
    }
  }
}
</style>
