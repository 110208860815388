<template>
  <div class="newki-buy-vip">
    <!-- 未購買 -->
    <div class="buy-status" v-if="devMode || buyVipStatus === 0">
      <div class="button-box">
        <van-button class="buy-button" round @click.stop="buyNowClick">
          <div class="buy-now" v-html="t('render.button.buyNow', { price: marketPrice })"></div>
        </van-button>
        <div class="tips">
          <span>{{ t('render.button.tips') }}</span>
        </div>
      </div>
      <div class="buy-tips-box">
        <span class="checkbox">
          <van-checkbox class="agress" v-model="isAgree" :icon-size="14" checked-color="#1f2734">
            {{ t('render.button.agree') }}
          </van-checkbox>
        </span>
        <span class="agreement" @click="openVipRegisterAgreement()">{{ t('render.button.articleTitle') }}</span>
      </div>
    </div>
    <!-- 品友生效中 -->
    <div class="buy-status" v-if="devMode || buyVipStatus === 4">
      <div class="button-box">
        <van-button class="buy-button" round @click.stop="routerPush('my')">
          {{ t('render.promotion.inEffect') }}
        </van-button>
      </div>
    </div>
    <!-- 您有訂單加購了品友，完成支付後可享有權益 -->
    <div class="buy-status" v-if="devMode || buyVipStatus === 5">
      <div class="bill-processes">
        <span v-html="t('render.promotion.exist')"></span>
      </div>
      <div class="button-box">
        <van-button class="buy-button chat small" round plain @click.stop="routerPush('chat')">
          {{ t('render.button.chat') }}
        </van-button>
        <van-button class="buy-button small" round @click.stop="goOrderDetailPage">{{
          t('render.button.goPay')
        }}</van-button>
      </div>
    </div>

    <!-- 支付到賬中確認中 -->
    <div class="buy-status" v-if="devMode || buyVipStatus === 1">
      <div class="bill-processes">
        <span v-html="t('render.promotion.buyVipNow')"></span>
      </div>
      <div class="button-box">
        <van-button class="buy-button chat small" round plain @click.stop="routerPush('chat')">
          {{ t('render.button.chat') }}
        </van-button>
        <van-button class="buy-button small" round @click.stop="goOrderPay">{{ t('render.button.goPay') }}</van-button>
      </div>
    </div>
    <!-- 重新支付 -->
    <div class="buy-status" v-if="devMode || buyVipStatus === 2">
      <div class="bill-processes">
        <span v-html="t('render.promotion.billError')"></span>
      </div>
      <div class="button-box">
        <van-button class="buy-button chat small" round plain @click.stop="routerPush('chat')">
          {{ t('render.button.chat') }}
        </van-button>
        <van-button class="buy-button small" round @click.stop="goOrderPay">{{ t('render.button.goPay') }}</van-button>
      </div>
    </div>
    <!-- 支付尾款 -->
    <div class="buy-status" v-if="devMode || buyVipStatus === 3">
      <div class="bill-processes">
        <span v-html="t('render.promotion.partialPayment')"></span>
      </div>
      <div class="button-box">
        <van-button class="buy-button chat small" round plain @click.stop="routerPush('chat')">
          {{ t('render.button.chat') }}
        </van-button>
        <van-button class="buy-button small" round @click.stop="goOrderPay">{{ t('render.button.goPay') }}</van-button>
      </div>
    </div>

    <NewkiArticle
      ref="articleRef"
      :showSureBtn="true"
      @sure="sureHandler"
      @close="closeHandler"
      :scena="[Article.vipRegisterAgreement]"
    />
  </div>
  <LoginDialog ref="loginDialog" @callBack="callBack" />
</template>

<script setup lang="ts">
import { ref, watch, nextTick, onMounted } from 'vue'
import { Article } from '~/enum/article'
import NewkiArticle from '~/components/NewkiArticle.vue'
import { apiBuyVip, apiGetVipInfo, apiCanBuyLevel } from '~/service/api/member'
import type { IVipPackage } from '~/types/IVipPackage'
import { PayStatus, OrderVipType } from '~/enum/order'
import LoginDialog from '~/components/LoginDialog.vue'

const { t } = useI18n()

const emit = defineEmits(['isMounted'])

const loginDialog = ref<InstanceType<typeof LoginDialog> | null>(null)

const articleRef = ref<InstanceType<typeof NewkiArticle> | null>(null)

// 是否在开发者模式：用来调试 UI
const devMode = ref(false)

// 購買會員 - 類型
const buyVipStatus: Ref<number | null> = ref(null)

// 是否登录
const isLogin = ref(false)

// 是否已同意条款
const isAgree = ref(false)

// 会员可购买的价钱
const marketPrice = ref(0)

// 会员等级id
const levelId = ref('')

// 支付 id
const payId = ref('')

// 關聯的訂單 id
const sourceBillId = ref('')

// 该用户的当前购买详情
const vipDetail = ref(null)

watch(
  () => useIsLogin(),
  val => {
    isLogin.value = val
    getStart()
  },
  { immediate: true }
)

// 点击立即开通
function buyNowClick() {
  if (!isLogin.value) {
    // console.log('Login first')
    loginDialog.value?.showModal()
    return false
  }
  if (!isAgree.value) {
    isAgree.value = true
    openVipRegisterAgreement()
    return false
  }
  console.log('buy now')
  buyVip()
}

function goOrderPay() {
  goPayPage(payId.value, sourceBillId.value)
}

// 文章弹窗
function openVipRegisterAgreement() {
  articleRef.value?.open()
}

// 隐私条款 - 确认
async function sureHandler() {
  if (isAgree.value) {
    await nextTick()
    buyVip()
  } else {
    isAgree.value = true
  }
}
// 隐私条款 - 关闭
function closeHandler() {}

function buildShow(data: any) {
  // 付款状态
  const status = data?.activeVipBill?.payStatus

  // 購買會員的類型
  const type: OrderVipType = data?.activeVipBill?.type

  // 是否能够购买
  const canBuy = data?.canBuy

  /* 
    1、未登入 + 可購買
    2、已登录 + 无當前生效的套餐 + 接口数据 canBuy 为 true
    3、登入後，該用戶無：支付確認中、支付異常、部份支付的會員訂單
  */
  let rule0 =
    (!isLogin.value && canBuy) ||
    (isLogin.value && !data?.activeVipBill && canBuy) ||
    (isLogin.value &&
      canBuy &&
      status != PayStatus.confirming &&
      status != PayStatus.error &&
      status != PayStatus.partial)

  // 支付狀態：支付確認中
  let rule1 = isLogin.value && !canBuy && status == PayStatus.confirming

  // 支付狀態：支付異常
  let rule2 = isLogin.value && !canBuy && status == PayStatus.error

  // 支付狀態：部份支付
  let rule3 = isLogin.value && !canBuy && status == PayStatus.partial

  // 支付狀態：已付款(該用戶判斷為不可購買品友時，即當前已購買過)
  let rule4 = isLogin.value && !canBuy && status == PayStatus.paid

  // 支付狀態：未付款 + 訂單為加購（您有訂單加購了品友，完成支付後可享有權益）
  let rule5 = isLogin.value && !canBuy && status == PayStatus.unpaid && type == OrderVipType.addon

  if (rule0) {
    buyVipStatus.value = 0
  } else if (rule1) {
    buyVipStatus.value = 1
  } else if (rule2) {
    buyVipStatus.value = 2
  } else if (rule3) {
    buyVipStatus.value = 3
  } else if (rule4) {
    buyVipStatus.value = 4
  } else if (rule5) {
    buyVipStatus.value = 5
  } else {
    buyVipStatus.value = null
  }
}

async function getCanBuyLevel() {
  let data = await apiCanBuyLevel()
  console.log('GetCanBuyLevel:', data)
  data ? (vipDetail.value = data) : null

  if (data?.canBuyLevel) {
    levelId.value = data.canBuyLevel.levelId || ''
    marketPrice.value = data.canBuyLevel.marketPrice || 0
  }

  if (data?.activeVipBill) {
    payId.value = data?.activeVipBill.payId || ''
    sourceBillId.value = data?.activeVipBill.sourceBillId || ''
  }

  buildShow(data)
}

async function buyVip(id: string = levelId.value) {
  useLoading(true)
  let data = await apiBuyVip(id)
  console.log('BuyVip:', data)

  if (data) {
    useLoading(false)
    payId.value = data.payId
    goPayPage(data.payId, '0')
  } else {
    useLoading(false)
    // showToast({
    //   message: '未滿足開通會員條件',
    //   duration: 3000,
    // })
  }
}

// 去收銀台
function goPayPage(_payId: string = payId.value, billId: string = '0') {
  console.log('payId:', _payId)
  // 單獨購買模式下，添加 isBuyVipMode: 1
  routerPush('pay-payId', { params: { payId: _payId }, query: { billId: billId, isBuyVipMode: '1' } })
}

// 去訂單詳情頁
function goOrderDetailPage() {
  routerPush('order-detail-id', { params: { id: sourceBillId.value } })
}

function callBack(flag: boolean) {
  console.log('success：', flag)
}

async function getStart() {
  getCanBuyLevel()
}

onMounted(() => {
  emit('isMounted', { name: 'buyVip', isMounted: true })
})
</script>

<style scoped lang="scss">
.newki-buy-vip {
  @include flex(column, flex-start, center);
  gap: 10px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: env(safe-area-inset-bottom);
  z-index: 100;
  width: 100%;
  background-color: $white;
  border-top: 2px solid rgba(0, 0, 0, 0.03);
  background-color: #efefef;

  .buy-status {
    @include flex(column, flex-start, center);
    width: 100%;
    padding: 20px 0 16px;
    background-color: $white;

    .bill-processes {
      @include flex(column, flex-start, center);
      margin-bottom: 10px;

      span {
        @include flex();
        text-align: center;
      }
    }

    .button-box {
      @include flex();
      gap: 10px;
      position: relative;
      z-index: 10;

      :deep(.van-button) {
        .buy-now {
          @include flex(row, center, baseline);
          span {
            font-size: $text-2xl;
            font-weight: bold;
            margin: 0 2px;
          }
        }
      }

      .buy-button {
        @include flex();
        width: 260px;
        height: 40px;
        color: $vip-text;
        font-size: $text-md;
        font-weight: bold;
        background-image: $vip-bg;
        border-radius: 20px;
        border: none;

        &.small {
          width: 125px;
        }

        &.chat {
          background-image: unset;
          background-color: $discount-tinge;
        }
      }

      .tips {
        @include flex();
        @include cursor();
        position: absolute;
        top: -11px;
        right: -10px;
        z-index: 10;
        background-image: $discount-bg;
        color: $white;
        font-size: $text-sm;
        line-height: $text-sm;
        min-width: 76px;
        height: 22px;
        border-radius: 11px;
      }
    }

    .buy-tips-box {
      @include flex();
      margin-top: 8px;

      .checkbox {
        margin-right: 4px;
      }

      :deep(.van-checkbox) {
        .van-checkbox__label {
          color: $text-color-default;
          font-size: $text-sm;
        }
      }
      .agreement {
        @include cursor();
        color: $vip-text;
        font-size: $text-sm;
      }
    }
  }
}
</style>
