<template>
  <div class="product-container" @click="jump">
    <NewkiImage class="product-img" :src="params.img || params.goodsImage" :width="240" :height="240" />
    <div class="product-info">
      <div class="product-name">{{ params.name || params.goodsName }}</div>
      <div class="product-attr">{{ params.attr }}</div>
      <div class="product-price" v-if="params.payPrice || params.standardPrice">
        <div v-if="params.supportPresell === '1'" class="presell">{{ t('common.newAndPresell') }}</div>
        <div class="vip-price" v-else-if="params.payPrice">
          <VipPrice :price="params.payPrice" small />
          <div class="price-origin-container">
            <span class="price-origin"> {{ t('promotion.originPrice') }} </span>
            <NewkiPrice
              v-if="params.standardPrice"
              :price="params.standardPrice"
              :price-size="13"
              :currey-size="13"
              :color="textAssist"
            />
          </div>
        </div>
        <div class="retail-price" v-else>
          <NewkiPrice :price="params.standardPrice" :price-size="17" :currey-size="17" :bold="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { routerPush } from '~/composables/routerPush'
import { useColorsStore } from '~/store/colors'
const { textAssist } = storeToRefs(useColorsStore())

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits(['close'])
const { t } = useI18n()

function jump() {
  emit('close', 'card-cancel')
  routerPush('product-skuId-contentId', { params: { skuId: +props.params.skuId } })
}
</script>

<style scoped lang="scss">
.product-container {
  width: 240px;
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .product-img {
    width: 200px;
    height: 200px;

    :deep(.van-image__img) {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }
  }

  .product-info {
    padding: 8px;
    .product-name {
      font-size: 15px;
      line-height: 20px;
      color: $text-color-default;
      @include line(2);
    }
    .product-attr {
      margin-top: 6px;
      font-size: 13px;
      color: $text-color-assist;
    }
    .product-price {
      margin-top: 10px;
      width: 100%;

      .price-origin-container {
        display: flex;
        align-items: center;
      }
      .vip-price {
        display: flex;
        align-items: center;
      }
    }
  }
}
.price-origin {
  margin: 2px 4px 0 8px;
  font-size: $text-sm;
  color: $text-color-assist;
}
.presell {
  color: $presell-price;
  font-weight: 700;
}
</style>
