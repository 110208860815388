<template>
  <SectionCard :title="t('render.review.video')" text="newki" :style="widgetStyle">
    <template v-for="(item, index) in wData.items">
      <NewkiVideo
        :fitVideoSize="buildVideoOption(index).fitVideoSize"
        :url="buildVideoOption(index).url"
        :block="buildVideoOption(index).block"
        :seekedStatus="buildVideoOption(index).seekedStatus"
        :playerOptions="{
          controls: true,
        }"
        :showMuteIcon="true"
        @onVideoStatusChange="handleVideoStatusChange"
        v-if="buildVideoOption(index).url"
      />
    </template>
  </SectionCard>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import NewkiVideo from '~/components/NewkiVideo.vue'
import SectionCard from '~/components/render/SectionCard.vue'
import { useStyle } from './renderHooks'
const { t } = useI18n()

const props = defineProps({
  wData: {
    type: Object,
    required: false,
    default: () => {},
  },
})

// 组件样式
const widgetStyle: any = useStyle(props.wData).widgetStyle

// 视频播放动画状态
const videoStatus = ref('')

const handleVideoStatusChange = (data: any) => {
  switch (data.eventName) {
    case 'play':
      videoStatus.value = 'play'
      break
    case 'pause':
      videoStatus.value = 'pause'
      break
    case 'ended':
      videoStatus.value = 'ended'
      break
    default:
      break
  }
}

// 生成播放器配置
const buildVideoOption = (index: number) => {
  let videoOption = {
    url: props.wData.items[index].serverPath,
    poster: props.wData.items[index]?.videoCoverPath,
    fitVideoSize: 'fixWidth',
    seekedStatus: 'pause',
    block: true,
  }
  return videoOption
}
</script>

<style scoped lang="scss">
.video-widget {
  @include flex(column, flex-start, center);
  width: 100%;
  height: 60vh;
}
</style>
