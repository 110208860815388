<template>
  <van-action-sheet
    class="site-list-sheet"
    v-model:show="show"
    :title="t('common.siteSelectTitle')"
    :cancel-text="t('common.cancel')"
    teleport="body"
    :close-on-popstate="true"
    :lazy-render="true"
    :safe-area-inset-bottom="true"
    :closeable="false"
    @close="closeModal"
  >
    <template v-for="item in siteList">
      <van-cell class="site-item" :title="item.name" @click.stop="onItemClick(item)" v-if="item.frontDisplay != 0">
        <div class="text" :class="{ active: item.value === currentSite }">{{ item.text }}</div>
      </van-cell>
    </template>
  </van-action-sheet>
</template>

<script setup lang="ts">
import { watch, nextTick } from 'vue'
import { useSystemStore } from '~/store/system'

const { t } = useI18n()
const show = ref(false)
const { toggleSiteSelectShow } = useSystemStore()
const { siteList, currentSite, showSiteSelect } = storeToRefs(useSystemStore())

const selectSite = ref<any[]>([])

// 当前站点数据
const siteId = useCookie('newki-site').value

watch(
  showSiteSelect,
  val => {
    show.value = val
  },
  { immediate: true }
)

watch(
  () => currentSite.value,
  value => {
    selectSite.value = [value]
  },
  {
    immediate: true,
    deep: true,
  }
)

const onItemClick = async (item: any) => {
  SetSiteInfo(item.value, item.defaultLanguageCode, item.sendWay)
  // 存儲到 cookie 裡面，過期時間為一個月
  useCookie('newki-site-selected', { maxAge: 60 * 60 * 24 * 30 }).value = item.value
  await nextTick()
  const route = useRoute()

  let code: string = item.code
  let defaultLanguageCode: string = item.defaultLanguageCode
  let search: string = window.location.search
  let truePath: string = route.meta.truePath === 'index' ? '' : route.meta.truePath
  let tempList = truePath.split('-')
  for (let i = 0; i < tempList.length; i++) {
    if (route.params[tempList[i]]) {
      tempList[i] = route.params[tempList[i]]
    }
  }
  if (tempList[tempList.length - 1] === 'contentId') {
    // todo 修復在商品詳情登錄站點改變時，沒有contentId這個參數時路由錯誤，先暫時用這個方法處理
    tempList.splice(tempList.length - 1, 1)
  }
  truePath = tempList.join('/')
  let url = `/${code}/${defaultLanguageCode}/${truePath}${search}`
  window.history.pushState({}, '', url)
  let t = setTimeout(() => {
    clearTimeout(t)
    // useRefreshApp()
    window.location.reload()
    sessionStorage.removeItem('pagePosition')
  }, 100)
  // 立即關閉彈窗
  toggleSiteSelectShow(false)
  // window.location.href = url
}

function closeModal() {
  // 点击取消的时候，默认把 cookie 的 site-id 设置到 newki-site-selected
  const timer = setTimeout(() => {
    useCookie('newki-site-selected', { maxAge: 60 * 60 * 24 * 30 }).value = siteId
    clearTimeout(timer)
  }, 1000)
  toggleSiteSelectShow(false)
}

function showModal() {
  show.value = true
}

defineExpose({
  showModal,
})
</script>

<style lang="scss" scoped>
.site-list-sheet {
  .site-item {
    @include cursor();
    display: flex;
    .text {
      flex: 1;
      text-align: center;
      color: $primary;
      font-size: 15px;
      transition: font-size 0.2s ease;

      &.active {
        font-weight: bold;
        font-size: 17px;
      }
    }
  }
}
</style>
