<template>
  <div class="text-render" :style="widgetStyle">
    <div class="text">{{ text }}</div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useStyle } from './renderHooks'

const props = defineProps({
  wData: {
    type: Object,
    required: true,
  },
})

const text: Ref<string> = ref(props.wData?.items[0]?.text)

const widgetStyle: any = useStyle(props.wData).widgetStyle

onMounted(() => {
  // console.log('wData:', props.wData)
})
</script>

<style scoped lang="scss">
.text {
  width: 100%;
  font-size: 14px;
  color: #333;
  white-space: pre-wrap;
}
</style>
